import React, { Component } from 'react'

export class Separator extends Component {
    

    render() {

        let {mode, type, className} = this.props
        return (
            <div
                className = {mode === "big" 
                                ? `h-0.5 bg-green-comelit mt-12 mb-12 w-1/3 mx-auto ${type === 'mobile' && 'sm:hidden'} ${className}` // Render BIG Separator
                                : `h-px bg-gray-200 mt-4 w-1/3 mx-auto ${type === 'mobile' && 'sm:hidden'} ${className}`
                        }  // Render NORMAL Separator
            >
            </div>
        )
    }
}

Separator.defaultProps = {  
    className: ''
}  