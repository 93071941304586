import React, { Component } from 'react'

import { SecondaryTitle } from '../elements/SecondaryTitle'
import { AlertCenter } from '../elements/AlertCenter'
import { Plate } from '../compounds/Plate'


export class Plates extends Component {
    constructor(props){
        super(props)
        this.onChangePlate = this.onChangePlate.bind(this)

        this.state = {
            alert: {
                showAlert: false,
                title: `${this.props.t("switch_no_disponible")}`,
                message: `${this.props.t("switch_33_viviendas")}`,
                continueText: false,
                buttons: true,
                buttonsType: 'close',
                type: 'plate_switch'
            }
        }
    }

    onChangePlate = (plate, keyPlate) => {
        let plates = this.props.plates

        // Controlamos cambios a posteriori en el número de portales / accesos
        while ( this.props.plates.length > this.props.totalPlates ){
            plates.pop()
        }

        plates[keyPlate] = plate
        this.props.handlePlatesChange([...plates])
    }
    
    changeShowAlert = (response) => (e) => {       
        this.setState({
            alert: {
                ...this.state.alert,
                showAlert: !this.state.alert.showAlert
            }
        })
    }

    renderPlates = (platesSection, totalPlates, portalInfo, simulatorType) => {        
        let plates = [];
        
        for (let i = 0; i < platesSection; i++){

            let homes = this.props.type === "secondary" && portalInfo[i] !== undefined ? portalInfo[i].homes : this.props.houses
            let switchDisabled
            // Para edificio se miran únicamente las viviendas de los portales
            if (simulatorType === "edifice")
                switchDisabled = portalInfo[i].homes > 33
            // Para mancomunidad se miran viviendas totales para placas principales y los de cada portal para accesos secundarios
            else{
                switchDisabled = homes > 33
            }
            
            plates.push(
                <Plate 
                    totalPlates = { totalPlates }
                    type = {this.props.type}
                    numPlate = {i}
                    key = {i}
                    homes = {homes}
                    systemType = {this.props.systemType}
                    onChangePlate = {this.onChangePlate}
                    handlePlateApply = {this.props.handlePlateApply}
                    infoPlate = {this.props.plates[i]}
                    plates = {this.props.plates}
                    simulatorType = {simulatorType}
                    switchDisabled = { switchDisabled}
                    changeShowAlert = { this.changeShowAlert() }
                    tooltipSwitch = { `${this.props.t("switch_33_viviendas")}` }
                    t = { this.props.t}
                />
            )
        }
        return plates;
    }

    render() {
        const { platesSection, totalPlates, type, portalInfo, simulatorType } = this.props
        let platesRender = this.renderPlates(platesSection, totalPlates, portalInfo, simulatorType)
        return (
            <div>
                { 
                this.state.alert.showAlert && 
                    <AlertCenter 
                        alert = { this.state.alert }
                        changeShowAlert = { this.changeShowAlert }
                        t = { this.props.t}
                    />
                }
                { simulatorType === "association" &&
                    <SecondaryTitle>{type === 'main' ? `${this.props.t("placas_principales")}` : `${this.props.t("placas_secundarias")}`}</SecondaryTitle>
                }
                {platesRender}
            </div>
        )
    }
}