import React, { Component } from 'react'

import { ButtonPrevious } from '../components/elements/ButtonPrevious'
import { ButtonNext } from '../components/elements/ButtonNext'
import { MainTitle } from '../components/elements/MainTitle'
import { InputText } from '../components/elements/InputText'
import { Collapsible } from '../components/elements/Collapsible'
import { CheckBoxInput } from '../components/elements/CheckBoxInput'
import { RadioInput } from '../components/elements/RadioInput'
import { AlertCenter } from '../components/elements/AlertCenter'
import { ButtonScrollDown } from '../components/elements/ButtonScrollDown'

import { SystemType } from '../components/compounds/SystemType'
import { Houses } from '../components/compounds/Houses'
import { InstalationSummary } from '../components/compounds/InstalationSummary'
import { KitStepTwo } from '../components/compounds/kits/KitStepTwo'
import KitStepThree from '../components/compounds/kits/KitStepThree'

import { getAvailableKits } from '../other/ApiCalls'
import { scrollTop } from '../other/Utils'

export class KitSimulator extends Component {    
    constructor(props) {
        super(props)
        this.state = {
            plateModelVisible: false,
            plateModelsAvailable: null,
            alert: {
                showAlert: false,
                title: '',
                message: '',
                continueText: false,
                buttons: false
            }
        }
    }

    componentDidMount(){
        if (this.props.step === 1){
            this.checkPlateModelShow()
        }
    }

    componentDidUpdate(prevProps){
        if (prevProps.systemType !== this.props.systemType || prevProps.houses !== this.props.houses 
                || prevProps.surface !== this.props.surface || prevProps.embedded !== this.props.embedded)
        // if (!areSameObjects( prevProps, this.props) && this.props.step === 1){
            this.checkPlateModelShow()
        // }
    }

    checkNextStep = (step) => {
        switch (step) {
            case 1:
                return this.checkStep_One()                
            case 2:
                return this.checkStep_Two()
            default:
                break;
        }
    }

    checkStep_One = () => {
        let { systemType, houses, surface, embedded, plateModel } = this.props

        if (systemType === '') return false
        if (houses === 0) return false
        if (!(surface || embedded)) return false
        if (plateModel === '') return false

        return true
    }
    
    checkStep_Two = () => {
        let { kit } = this.props

        if (kit === "") return false

        return true
    }

    handleNextStep = (step) => (e) => {
        e.preventDefault()
        this.props.changeStep(step+1)
        scrollTop()
    }

    handlePreviousStep = (step) => (e) => {
        this.props.changeStep(step-1)
        scrollTop()
    }
    
    handleChange = (caller) => (e) => {
        caller === "surface" &&
            this.props.changeSurface(e.target.checked)
        caller === "embedded" && 
            this.props.changeEmbedded(e.target.checked)
    }

    handlePlateModelChange = (plateModel) => {
        this.props.changePlateModel(plateModel)
    }

    renderStep = (step) => {
        switch (step) {
            case 1:
                return this.renderStep_One()
            case 2:
                return this.renderStep_Two()
            case 3:
                return this.renderStep_Three()
            default:
                break;
        }
    }
    
    renderStep_One() {

        let { plateModelVisible, plateModelsAvailable } = this.state

        let renderedPlateModels = []
        if ( plateModelVisible && plateModelsAvailable && plateModelsAvailable.length > 0)
            renderedPlateModels = this.renderPlateModelsAvailable(plateModelsAvailable)

        return (
            <div>
                <InputText name = "reference"
                    label = {`${this.props.t("referencia")}: `}
                    value = {this.props.reference}
                    onChangeText = {this.props.changeReference}
                />
                <Collapsible title = {`${this.props.t("tipo_sistema")}`} >
                    <SystemType type = "kit"
                        onChange = { this.props.changeSystemType }
                        selected = { this.props.systemType }
                        t = { this.props.t }
                    />
                </Collapsible>                
                <Collapsible title = {`${this.props.t("viviendas")}`} >
                    <Houses numHouses = { 4 }
                        onChange = { this.props.changeHouses }
                        selected = { this.props.houses }
                    />
                </Collapsible>
                <Collapsible title = {`${this.props.t("tipo_montaje")}`} >
                    <div className={`grid grid-cols-2 gap-4 sm:items-center`}>
                        <CheckBoxInput 
                            name = {`surface`}
                            label = {`${this.props.t("superficie")}`}
                            onChange = {this.handleChange('surface')}
                            checked = {this.props.surface}
                        />
                        <CheckBoxInput 
                            name = {`embedded`}
                            label = {this.props.t("empotrar")}
                            onChange = {this.handleChange('embedded')}
                            checked = {this.props.embedded}
                        />
                    </div>
                </Collapsible>
                {
                    plateModelVisible && 
                        <Collapsible title = {`${this.props.t("modelo_placa")}`} >
                            <div className={`grid grid-cols-2 sm:grid-cols-3 gap-4 sm:items-center`}>
                                { renderedPlateModels }
                            </div>
                        </Collapsible>

                }
            </div>
        )
    }
    
    renderStep_Two() {
        return(
            <div>
                <InstalationSummary 
                    reference = {this.props.reference}
                    systemType = {this.props.systemType}
                    houses = {this.props.houses}
                    plateModel = {this.props.plateModel}
                    simulatorType = {'kit'}
                    t = {this.props.t}
                />
                <KitStepTwo 
                    systemType = {this.props.systemType}
                    houses = {this.props.houses}
                    embedded = {this.props.embedded}
                    surface = {this.props.surface}
                    plateModel = {this.props.plateModel}
                    kit = {this.props.kit}
                    internalUnitsHouse = {this.props.internalUnitsHouse}
                    additionalAccess = {this.props.additionalAccess}
                    additionalInternalUnit = {this.props.additionalInternalUnit}
                    changeSelectedKit = {this.props.changeSelectedKit}
                    changeAdditionalAccess = {this.props.changeAdditionalAccess}
                    changeAdditionalInternalUnit = {this.props.changeAdditionalInternalUnit}
                    changeInternalUnitHouse = {this.props.changeInternalUnitHouse}
                    t = {this.props.t}
                />
            </div>
        )
    }

    renderStep_Three() {
        return(
            <div>
                <InstalationSummary 
                    systemType = {this.props.systemType}
                    houses = {this.props.houses}
                    plateModel = {this.props.plateModel}
                    simulatorType = {'kit'}
                    t = {this.props.t}
                />
                <KitStepThree
                    houses = {this.props.houses}
                    embedded = {this.props.embedded}
                    surface = {this.props.surface}
                    kit = {this.props.kit}
                    additionalAccess = {this.props.additionalAccess}
                    additionalInternalUnit = {this.props.additionalInternalUnit}
                    internalUnitsHouse = {this.props.internalUnitsHouse}
                    budget = {this.props.budget}
                    setBudget = {this.props.setBudget}
                    reference = { this.props.reference }
                    changeDiscount = {this.props.changeDiscount}
                    changeDiscountToAllProducts = {this.props.changeDiscountToAllProducts}
                    clientName = { this.props.clientName }
                    clientAtt = { this.props.clientAtt }
                    clientMail = { this.props.clientMail }
                    clientDirection = { this.props.clientDirection }
                    clientPhone = { this.props.clientPhone }
                    clientLogo = { this.props.clientLogo }
                    comelitComercial = { this.props.comelitComercial }
                    comelitEmail = { this.props.comelitEmail }
                    changeComelitComercial = { this.props.changeComelitComercial }
                    changeComelitEmail = { this.props.changeComelitEmail }
                    changeClientName = { this.props.changeClientName }
                    changeClientAtt = { this.props.changeClientAtt }
                    changeClientMail = { this.props.changeClientMail }
                    changeClientDirection = { this.props.changeClientDirection }
                    changeClientPhone = { this.props.changeClientPhone }
                    changeClientLogo = { this.props.changeClientLogo }
                    exportType = { this.props.exportType }
                    changeExportType = { this.props.changeExportType }
                    addLineToBudget = { this.props.addLineToBudget }
                    deleteAditionalLineBudget = { this.props.deleteAditionalLineBudget }
                    t = {this.props.t}
                    changeInstallationCost = { this.props.changeInstallationCost }
                />
            </div>
        )
    }

    checkPlateModelShow(){
        if ( this.props.systemType !== "" && this.props.houses !== 0 && ( this.props.surface || this.props.embedded )){
            getAvailableKits( this.props.systemType, this.props.houses, this.props.embedded, this.props.surface, '' )
            .then( products => {
                if (products !== null && products.length > 0){
                    let plateModelsAvailable = [...new Set(products.map( product => product.plate_model ))]

                    // Comprobamos si la placa seleccionada, si hay, está o no
                    if (this.props.plateModel !== '' && !plateModelsAvailable.includes( this.props.plateModel.toUpperCase() )){
                        this.handlePlateModelChange('')
                    }
                    this.setState({
                        plateModelVisible: true,
                        plateModelsAvailable: plateModelsAvailable
                    })
                } else{
                    this.setState({
                        plateModelVisible: false,
                        plateModelsAvailable: null,
                        alert: {
                            showAlert: true,
                            title: `${this.props.t("kit_no_disponible")}`,
                            message: `${this.props.t("kits_no_compatibles")}`,
                            continueText: false,
                            buttons: true,
                            buttonsType: 'close'
                        }
                    })
                    this.handlePlateModelChange('')
                }
            })
        } else{                
            this.setState({
                plateModelVisible: false,
                plateModelsAvailable: null
            })
            this.handlePlateModelChange('')
        }
    }

    renderPlateModelsAvailable(plateModels) {
        
        // Obtenemos las distintas placas
        let renderedPlateModels = []
        plateModels.forEach(plateModel => {
            
            plateModel = plateModel.toLowerCase()            
            //ULTRA, SWITCH, CIAO, QUADRA, IKALL, EXTRA, VISTO

            renderedPlateModels.push(
                <RadioInput
                    key = {plateModel}
                    name = {plateModel}
                    value = {plateModel}
                    image = {
                        <img src = {`../../logo-${plateModel}.png`} alt={`${this.props.t("logo")} ${plateModel.toUpperCase()}`} className="mx-auto w-28" />
                    }
                    className = "w-1"
                    onChange = { () => this.handlePlateModelChange(plateModel) }
                    checked = {this.props.plateModel === plateModel}
                />
            )
        });
        
        return renderedPlateModels

    }
    
    changeAlert = (response) => (resul) => {
        this.setState({
            alert: {
                showAlert: false,
                title: '',
                message: '',
                continueText: false,
                buttons: false
            }
        })
    }

    render(){
        
        let stepRender = this.renderStep(this.props.step)
        let alert = this.state.alert

        // console.log("Kit Simulator PROPS: ", this.props);

        return(
            <div className = "container mx-auto" >
                { 
                    alert.showAlert &&
                        <AlertCenter 
                            alert = { alert }
                            changeShowAlert = { this.changeAlert }
                            t = { this.props.t }
                        />
                }   
                <MainTitle> Kits </MainTitle>
                { stepRender }
                <ButtonScrollDown />
                <div className = "flex justify-between">
                        <ButtonPrevious onClick = { this.handlePreviousStep(this.props.step) }
                            step = { this.props.step }
                            resetReducer = { this.props.resetReducer }
                            t = { this.props.t }
                        />
                        { this.props.step !== 3 &&
                            <ButtonNext onClick = { this.handleNextStep(this.props.step) }
                                disabled = {!this.checkNextStep(this.props.step) }
                                t = { this.props.t }
                            />
                        }
                </div>
            </div>
        )
    }
}