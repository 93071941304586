import React, { Component } from 'react'

import { ModuleItem } from '../elements/ModuleItem'
import { ItemTypes } from '../../other/ItemTypes'
import { PushButton } from '../elements/PushButton'

import { loader } from '../../other/Utils'
import { getAvailableProductsStep } from '../../other/ApiCalls'

export class ModulesSimulator extends Component {    
    constructor(props){
        super(props)
        this.state = {
            isLoading: true,
            products: [],
            renderedProducts: []
        }
    }

    componentDidMount(){
        this.setElementHeight()

        let assembly = this.props.additional ? this.props.plateInfo.additionalContents[this.props.indexAdditional].assembly : this.props.plateInfo.assembly

        getAvailableProductsStep(assembly, this.props.systemType, this.props.plateInfo.plateModel, this.props.plateInfo.controlType, 1, this.props.location)
            .then(products => this.setState({products: products, isLoading: false}))
    }

    componentDidUpdate() {
        this.setElementHeight()
        this.renderModules()
    }
    
    setElementHeight() {
        if (document.querySelector('#div-configurator')){
            if (window.innerWidth >= 640){
                let configuratorHeight = document.querySelector('#div-configurator').offsetHeight
                document.querySelector('#div-modules').style.height = `${ configuratorHeight }px`
            } else{
                document.querySelector('#div-modules').style.height = '30vh'
            }
        }
    }

    checkIfAddProduct( plateModel, type, additional, category, product){
        
        let article_code = product.article_code
        if ( plateModel === "ultra"){

            // Comprobamos si es placa principal y producto UT2010 o UT2020 (solo habilitados para portales internos) -> NO SE AÑADE
            if (type === "main" && (article_code === "UT2010" || article_code === "UT2020"))
                return false

            // Comprobamos si es portal y categorías GRUPO AUDIO y GRUPO AUDIO/VIDEO, solo añadimos UT1020 o UT2020
            if (type === "secondary" && (category === 9 || category === 10)){
                
                // Si es tipo audio solo mostrar UT1010
                if (this.props.systemType === "audio" && article_code !== "UT1010") return false

                // Si es EDIFICIO
                if ( this.props.simulatorType === "edifice" ){
                    // Si no es acceso adicional
                    if (!additional ){
                        if (this.props.plateInfo.concierge){
                            // Vídeo con conserjería -> Solo UT1020
                            if (this.props.systemType === "video" && article_code !== "UT1020") return false
                        } 
                        else{
                            // Vídeo sin conserjería -> Solo UT2020
                            if (this.props.systemType === "video" && article_code !== "UT2020") return false
                        }

                    // Si es acceso adicional
                    } else{
                        
                        if (this.props.plateInfo.concierge){
                            // Vídeo con conserjería -> Solo UT1010 o UT1020
                            if (this.props.systemType === "video" && !["UT1020","UT1010"].includes(article_code)) return false
                        } 
                        else{
                            // Vídeo sin conserjería -> Solo UT2020 o UT2010
                            if (this.props.systemType === "video" && !["UT2020","UT2010"].includes(article_code)) return false
                        }
                    }
                }
                // Si es MANCOMUNIDAD
                else if (this.props.simulatorType === "association"){
                    if (article_code !== "UT2010" && article_code !== "UT2020" && this.props.systemType !== "audio") return false
                }
            }
        }

        if ( plateModel === "vandalcom"){
            if (type === "secondary" && (category === 9 || category === 10)) {
                // Para tipo audio solo 3262S/0, 3262S/1 y 3262S/2
                if (this.props.systemType === "audio"){
                    if (!["3262S/0", "3262S/1", "3262S/2"].includes(article_code)) return false
                } else{
                    // Comprobamos si es portal y categorías GRUPO AUDIO y GRUPO AUDIO/VIDEO, solo añadimos 3268S/0, 3268S/1 y 3268S/2
                    if (!additional){
                        if (!["3268S/0", "3268S/1", "3268S/2"].includes(article_code)) return false
                    } else{
                        // Comprobamos si es adicional y categorías GRUPO AUDIO y GRUPO AUDIO/VIDEO, solo añadimos 3268S/0, 3268S/1 y 3268S/2
                        if (!["3268S/0", "3268S/1", "3268S/2", "3262S/0", "3262S/1", "3262S/2"].includes(article_code)) return false
                    }
                }
                
            }
            
            // Si no tiene control de acceso no mostramos ni 3188SB ni 3179S
            if (this.props.plateInfo.controlType === "--"){
                if (article_code === "3188SB" || article_code === "3179S") return false
            } else {
                // Comprobamos si es CONTROL DE ACCESO BASIC o ADVANCE, no añadimos el 3188SB
                if ((this.props.plateInfo.controlType === "basic" || this.props.plateInfo.controlType === "advance") && article_code === "3188SB")
                    return false
                
                // Comprobamos si es CONTROL DE ACCESO TECLADO, no añadimos el 3179S
                if (this.props.plateInfo.controlType === "teclado" && article_code === "3179S")
                    return false       
            }
        }

        if ( plateModel === "switch"){
            
            // Comprobamos el número de viviendas
            let num_calls = product.num_calls
            if (num_calls) {
                let splitted_calls = num_calls.split("--")
                let num_calls_min = splitted_calls[0]
                let num_calls_max = splitted_calls[splitted_calls.length-1]

                let houses = this.props.houses
                if (!num_calls_max && houses !== parseInt(num_calls_min)){
                    return false
                } else if (num_calls_max && (houses < parseInt(num_calls_min) || houses > parseInt(num_calls_max))){
                    return false
                }
            }

            // Comprobamos si tiene control de accesos o no
            let productsBasicAdvance = ["IX0101CO", "IX0102CO", "IX0103CO", "IX0104CO"]
            let productsKeyboard = ["IX0101KP", "IX0102KP", "IX0103KP", "IX0104KP"]
            if ( productsBasicAdvance.includes(product.article_code) || productsKeyboard.includes(product.article_code) ){
                // Si no tiene control de accesos no se incluyen
                if (!this.props.plateInfo.accessControl) return false
                // Si es un producto de control de accesos de teclado, pero no tenemos esa característica
                if (productsKeyboard.includes(product.article_code) && this.props.plateInfo.controlType !== "teclado") return false
                // Si es un producto de control de accesos basic o advance, pero no tenemos esa característica
                if (productsBasicAdvance.includes(product.article_code) && !["basic","advance"].includes(this.props.plateInfo.controlType) ) return false
            }
            // Si no tenemos PLACA MONOBLOCK no mostramos MODULOS ADICIONALES
            if (product.id_category === 11 && this.props.switchPlateEmpty) return false

            // Comprobamos si las columnas de la PLACA MONOBLOCK coinciden con las de los MODULOS ADICIONALES
            if (product.id_category === 11) {
                if (product.num_columns !== this.props.switchPlateNumColumns) return false
            }
            
        }

        return true
    }

    renderModules() {

        let plateModel = this.props.plateInfo.plateModel
        let products = this.state.products

        // Obtenemos las categorías de los productos disponibles
        let categories = [...new Set(
            products.map( product => (
                    {
                        id_category: product.id_category,
                        ds_category: ([5,6,7].includes(product.id_category) ? this.props.t("controles_acceso") : product.ds_category )
                    }
                )
            )
        )];
                         
        // Eliminamos duplicados
        categories = Array.from(new Set(categories.map(a => a.id_category)))
            .map(id_category => {
                return categories.find(a => a.id_category === id_category)
            })
        
        let productsGrouped = {}
        products.forEach(product => {
            if( !productsGrouped.hasOwnProperty(product.id_category)){
                productsGrouped[product.id_category] = {
                    products: []
                }
            }
            productsGrouped[product.id_category].products.push(product)
        })

        // Ordenamos las categorías
        for (let i = 0; i < categories.length; i++) {
            const category = categories[i];
            if (category.id_category === 12){
                categories[i] = {
                    id: category.id_category,
                    name: category.ds_category,
                    order: 1
                }
            } else{
                categories[i] = {
                    id: category.id_category,
                    name: category.ds_category,
                    order: 0
                }
            }
        }
        categories.sort(
            (a, b) => {
                if (a.order < b.order) {
                    return 1;
                }
                if (a.order > b.order) {
                return -1;
                }
                // a must be equal to b
                return 0;
            }
        )

        let renderedProducts = []
        let key = 1
        categories.forEach(category => {
            let renderedModules = []
            productsGrouped[category.id].products.forEach(product => {
                let itemType = ItemTypes.MODULE

                // La placa ULTRA tiene combinacioness, PULSADORES, BOTONES etc.
                if (plateModel === "ultra"){
                    if ( product.article_code === "UT9270")
                        itemType = ItemTypes.MODULE_DOUBLE
                    // PULSADORES Y TAPAS
                    if ( category.id === 13 )
                        if ( product.article_code === "3064S" || product.article_code === "UT9200")
                            itemType = ItemTypes.MODULE_FULL_BUTTONS
                        else
                            itemType = ItemTypes.PUSH_BUTTON
                    // GRUPO AUDIO y GRUPO AUDIO/VIDEO
                    if ( [9,10].includes(category.id) )
                        itemType = ItemTypes.MODULE_PUSH_BUTTON
                }
                
                // La placa SWITCH tiene solo PLACAS MONOBLOCK y MODULOS, pero algunos pueden ir arriba y otros abajo
                if (plateModel === "switch"){
                    let switchTopModules = ["IX9104", "IX9204", "IX9304"]
                    let switchBottomModules = ["IX9101", "IX9201", "IX9301", "IX9102", "IX9202", "IX9302"]
                    itemType = ItemTypes.MODULE_TURN
                    if (switchTopModules.includes(product.article_code))
                        itemType = ItemTypes.MODULE_TOP
                    if (switchBottomModules.includes(product.article_code))
                        itemType = ItemTypes.MODULE_BOTTOM
                    if (product.id_category === 12){
                        itemType = ItemTypes.MONOBLOCK_PLATE
                    }
                }

                if ( this.checkIfAddProduct(plateModel, this.props.type, this.props.additional, category.id, product ) ){
                   
                    let urlImagen = product.image_url                    
                    let urlImagenSwitchAudio = product.image_url
                    let urlImagenSwitchVideo = product.image_url
                    if (!product.image_url.includes('http')){

                        if (plateModel === "switch"){
                            if (product.id_category === 12){
                                urlImagenSwitchAudio = `../products/switch/audio/${product.image_url}`
                                urlImagenSwitchVideo =  `../products/switch/video/${product.image_url}`
                                urlImagen = `../products/switch/${this.props.systemType}/${product.image_url}`
                            }                            
                            else{
                                urlImagenSwitchAudio = `../products/switch/${product.image_url}`
                                urlImagenSwitchVideo = `../products/switch/${product.image_url}`
                                urlImagen = `../products/switch/${product.image_url}`
                            }
                        } else{
                            urlImagen = `../products/${ product.image_url }`
                        }
                        
                        if (!product.image_url || product.image_url === ""){
                            urlImagen = '../products/not_found.png'
                        }
                    }
                    if ( itemType === ItemTypes.PUSH_BUTTON ){
                        renderedModules.push(
                            <PushButton 
                                key = { key } 
                                src = { urlImagen }
                                deepLink = { product.link || "--" }
                                articleCode = {product.article_code}
                                eanCode = {product.eancode}
                                description = { product.description }
                                toolTip = { product.tooltip }
                                idCategory = { product.id_category }
                                category = { category.name }
                                pvp = { product.pvp }
                                relatedArticles = { product.related_articles }
                                numCalls = { product.num_calls || 0 }
                                numColumns = { product.num_columns || 0 }
                                location = { "simulator" }                        
                                type = { this.props.type } 
                                itemType = {ItemTypes.PUSH_BUTTON}
                                numPlate = { this.props.numPlate } 
                                additional = { this.props.additional } 
                                indexAdditional = { this.props.indexAdditional }
                                addPushButtonToFirstSlot = { this.props.addPushButtonToFirstSlot }
                                t = {this.props.t}
                            />
                        )
                    } else{

                        // Si estamos ante Switch, hay que añadir uno de audio y otro de video en algunos casos
                        // En EDIFICIO y VIDEO a todos los accesos adicionales , en MANCOMUNIDAD y VIDEO a todos
                        if ((product.id_category === 12 && plateModel === "switch" && this.props.systemType === "video" 
                                && ( this.props.simulatorType === "association" || (this.props.simulatorType === "edifice"  && this.props.additional))) || this.props.location === 'only-simulator' && plateModel === "switch" ){
                            
                            // Versión VIDEO
                            renderedModules.push(
                                <ModuleItem 
                                    key = { `${key}VD` } 
                                    src = { urlImagenSwitchVideo }
                                    deepLink = { product.link || "--" }
                                    articleCode = {`${product.article_code}-VD`}
                                    eanCode = {product.eancode}
                                    description = { product.description }
                                    toolTip = { product.tooltip }
                                    idCategory = { product.id_category }
                                    category = { category.name }
                                    pvp = { product.pvp }
                                    relatedArticles = { product.related_articles }
                                    numCalls = { product.num_calls || 0 }
                                    numColumns = { product.num_columns || 0 }
                                    location = "simulator" 
                                    buttonsContent = {[]}
                                    addItemToFirstSlot = { this.props.addItemToFirstSlot } 
                                    type = { this.props.type } 
                                    itemType = { itemType }
                                    numPlate = { this.props.numPlate } 
                                    additional = { this.props.additional } 
                                    indexAdditional = { this.props.indexAdditional } 
                                    t = {this.props.t}
                                />
                            )

                            // Versión AUDIO
                            renderedModules.push(
                                <ModuleItem 
                                    key = { `${key}AD` } 
                                    src = { urlImagenSwitchAudio }
                                    deepLink = { product.link || "--" }
                                    articleCode = {`${product.article_code}-AD`}
                                    eanCode = {product.eancode}
                                    description = { product.description }
                                    toolTip = { product.tooltip }
                                    idCategory = { product.id_category }
                                    category = { category.name }
                                    pvp = { product.pvp }
                                    relatedArticles = { product.related_articles }
                                    numCalls = { product.num_calls || 0 }
                                    numColumns = { product.num_columns || 0 }
                                    location = "simulator" 
                                    buttonsContent = {[]}
                                    addItemToFirstSlot = { this.props.addItemToFirstSlot } 
                                    type = { this.props.type } 
                                    itemType = { itemType }
                                    numPlate = { this.props.numPlate } 
                                    additional = { this.props.additional } 
                                    indexAdditional = { this.props.indexAdditional } 
                                    t = {this.props.t}
                                />
                            )
                            

                        } else{
                            renderedModules.push(
                                <ModuleItem 
                                    key = { key } 
                                    src = { urlImagen }
                                    deepLink = { product.link || "--" }
                                    articleCode = {product.article_code}
                                    eanCode = {product.eancode}
                                    description = { product.description }
                                    toolTip = { product.tooltip }
                                    idCategory = { product.id_category }
                                    category = { category.name }
                                    pvp = { product.pvp }
                                    relatedArticles = { product.related_articles }
                                    numCalls = { product.num_calls || 0 }
                                    numColumns = { product.num_columns || 0 }
                                    location = "simulator" 
                                    buttonsContent = {[]}
                                    addItemToFirstSlot = { this.props.addItemToFirstSlot } 
                                    type = { this.props.type } 
                                    itemType = { itemType }
                                    numPlate = { this.props.numPlate } 
                                    additional = { this.props.additional } 
                                    indexAdditional = { this.props.indexAdditional } 
                                    t = {this.props.t}
                                />
                            )
                        }
                    }

                    key += 1
                }
            });

            // Eliminamos categorías vacías
            if (renderedModules.length){
                renderedProducts.push(
                    <div key={category.name}>
                        <h3 className="mb-2 font-semibold text-center">{ category.name }</h3>                
                        <div className="flex flex-wrap justify-center">
                            { renderedModules }
                        </div>
                    </div>
                )
            }
        });

        return renderedProducts
    }
    
    render() {

        let isLoading = this.state.isLoading
        let renderedProducts = this.renderModules()

        return (
            <div className="p-4 m-4 overflow-y-scroll bg-gray-300 sm:w-1/4 sm:mr-3 sm:ml-4"  id="div-modules">
                {isLoading 
                    && loader(this.props.t)
                }
                <div className="flex flex-wrap justify-center">
                    {renderedProducts}
                </div>
            </div>
        )
    }
}
