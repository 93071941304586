import React, { Component } from 'react'

import {RadioInput} from '../elements/RadioInput'

export class SystemType extends Component {

    updateSystemType = (event) => {
        this.props.onChange(event.target.name)
    }

    render() {

        const {type, selected} = this.props

        return (
            
            <div className={`grid ${type === "kit" ? "grid-cols-4 sm:grid-cols-3" : "grid-cols-2"}  gap-4 sm:items-center`}>            
            { type === "kit" &&
                <div className="col-span-2 col-start-2 sm:col-span-1">
                    <RadioInput
                        name = 'smartphone'
                        label = {`${this.props.t("video_compatible_smartphone")}`}
                        checked = {selected === 'smartphone'}
                        image = {
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" viewBox="0 0 20 20" fill="#388244">
                                <path fillRule="evenodd" d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z" clipRule="evenodd" />
                            </svg>
                        }
                        onChange = {this.updateSystemType}
                    />
                </div>
            }
                <div className={ type === "kit" ? "col-span-2 sm:col-span-1" : ""}>
                    <RadioInput
                        name = {'video'}
                        label = {`${this.props.t("video")}`}
                        checked = {selected === 'video'}
                        image = {
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" viewBox="0 0 20 20" fill="#388244">
                                <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                            </svg>
                        }
                        onChange = {this.updateSystemType}
                        className = "w-1"
                    />
                </div>
                <div className={ type === "kit" ? "col-span-2 sm:col-span-1" : ""}>
                    <RadioInput
                        name = {'audio'}
                        label = {`${this.props.t("audio")}`}
                        checked = {selected === 'audio'}
                        image = {
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" viewBox="0 0 20 20" fill="#388244">
                                <path fillRule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clipRule="evenodd" />
                            </svg>
                        }
                        onChange = {this.updateSystemType}
                    />
                </div>
            </div>
        )
    }
}