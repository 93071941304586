import React from 'react'

export function Footer(props){

  const { t } = props

  return(
    <footer className="App-footer">
      <div className="container mx-auto grid grid-cols-2 sm:grid-cols-3 gap-2">
        <div className="flex flex-col">
          <a href={`${t("link_quienes_somos")}`} target='blank' rel="noreferrer" className='mb-2'>{t("quienes_somos")}</a>
          <a href={`${t("link_comelit_mundo")}`} target='blank' rel="noreferrer" className='mb-2'>{t("comelit_mundo")}</a>
          <a href={`${t("link_calidad")}`} target='blank' rel="noreferrer" className='mb-2'>{t("calidad_certificaciones")}</a>
          <a href={`${t("link_trabaja")}`} target='blank' rel="noreferrer" className='mb-2'>{t("trabaja_nosotros")}</a>            
        </div>
        <div className="flex flex-col">        
          <a href={`${t("link_design")}`} target='blank' rel="noreferrer" className='mb-2'>{t("design_milano")}</a>
          <a href={`${t("link_proyectos")}`} target='blank' rel="noreferrer" className='mb-2'>{t("proyectos_exito")}</a>
          <a href={`${t("link_noticias")}`} target='blank' rel="noreferrer" className='mb-2'>{t("noticias")}</a>
        </div>
        <div className="flex flex-col">        
          <p>{t("siguenos_en")}</p>
          <div className="flex flex-row social justify-between">
            <a href={`${t("facebook")}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
            <a href={`${t("linkedin")}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a>
            <a href={`${t("youtube")}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube"></i></a>
            <a href={`${t("instagram")}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <hr />
        <p>
          Comelit Group S.p.A. - Via Don Arrigoni 5 - 24020 Rovetta S. Lorenzo BG Italy <br/>
          tel. +39 0346 750 011 - fax +39 0346 71436 - {t("email")}: <a href="mailto:info@comelit.it" target="_blank" rel="noreferrer">info@comelit.it</a> - 
            <a href="mailto:export.department@comelit.it" target="_blank" rel="noreferrer">export.department@comelit.it</a><br />
            P.IVA/C.F./Reg. Imprese BG 01546400167 - R.E.A. 216249 - Capitale Sociale € 3.000.000,00 <br/>
            Copyright ® 2021 COMELIT S.p.A. - All rights reserved - <a href="http://www.murosoft.es" target="_blank" rel="noreferrer">Credits</a> - 
              <a href="https://staticpro.comelitgroup.com/filescms/files/INFORMATIVA_SOBRE_LA_OPERATIVIDAD_DE_LA_GARANTÍA_LEGAL_PARA_LAS_VENTAS_EN_LÍNEA.pdf" 
              target="_blank" rel="noreferrer">Garantía para ventas online</a> - <a href="https://www.comelitgroup.com/es-es/privacy-policy/" target="_blank" rel="noreferrer">Privacy-policy</a> - 
              <a href="https://www.comelitgroup.com/es-es/disclaimer/" target="_blank" rel="noreferrer">Disclaimer</a>
        </p>
      </div>
    </footer>
  )
}