import React, { useState } from 'react'

import { Button } from './Button'
import { useFormFields } from '../../other/helpers/hooksFormInput'
import HttpService from '../../services/HttpService'

import { displayErrorMessages } from '../../other/helpers/displayErrors'

export default function AlertUserInfo(props){
    
    const http = new HttpService
    let { userInfo, crmRelation, applyRates, idAccountCRM, changeShowAlert } = props
    
    const userLocation = localStorage.getItem("comelit-user-language")

    const initialForm = {
        userId: userInfo ? userInfo.id : '',
        company: userInfo ? userInfo.company : '',
        contact: userInfo ? userInfo.contact : '',
        cif: userInfo ? userInfo.cif : '',
        phone: userInfo ? userInfo.phone : '',
        province: userInfo ? userInfo.province : '',
        location: userInfo ? userInfo.location : '',
        address: userInfo ? userInfo.address : ''
    }

    const [errorMessage, setErrorMessage] = useState('')

    const clearAuthErrDiv = () => {
        let authErr = document.querySelector("#authError");
        authErr.innerHTML = "";
    }

    const [fields, handleFieldChange] = useFormFields( initialForm )

    const editUserInfo = () => {
        http.postData(fields, "admin/update-user", "admin-token")
        .then((resul) => {
            
            if (resul.success){
                setTimeout(changeShowAlert(true), 0);
            }
            else{
                setErrorMessage(resul.error)
                setTimeout(() => {
                    clearAuthErrDiv()
                    setErrorMessage('')
                }, 3000);
            }
            return
        })
             
    }

    return (
        <div>
            <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-screen overflow-y-auto bg-gray-500 bg-opacity-75 bg-op">
                <div className="relative z-30 pt-6 pb-6 pl-4 pr-4 mt-4 mb-4 ml-4 mr-4 overflow-y-auto bg-white rounded max-h-5/6 md:p-14">
                    <div className='absolute cursor-pointer right-2 top-2'
                        onClick = { changeShowAlert(false) }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>

                    <div className="text-xl font-semibold text-center uppercase">
                        {props.t("info_usuario")}
                    </div>
                    <div className='mt-4 text-left'>
                        <p className='font-normal'>
                            <label htmlFor="company" className="mr-2 font-bold text-left text-slate-500 w-36">{props.t("empresa")}</label>
                            <input type="text" name="company" id="company" placeholder={`${props.t("empresa")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.company} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="contact" className="mr-2 font-bold text-left text-slate-500 w-36">{props.t("persona_contacto")}</label>
                            <input type="text" name="contact" id="contact" placeholder={`${props.t("persona_contacto")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.contact} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>                            
                            <label htmlFor="cif" className="mr-2 font-bold text-left text-slate-500 w-36">{props.t("cif_dni")}</label>
                            <input type="text" name="cif" id="cif" placeholder={`${props.t("cif_dni")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.cif} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <span className='block mr-2 font-bold text-left text-slate-500'>{props.t("correo_electronico")}:</span>
                            <span className='block w-full p-2 mt-1 mb-4 bg-gray-100 border-2'>{userInfo.email}</span>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="phone" className="mr-2 font-bold text-left text-slate-500 w-36">{props.t("telefono")}</label>
                            <input type="tel" name="phone" id="phone" placeholder={`${props.t("telefono")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.phone} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="province" className="mr-2 font-bold text-left text-slate-500 w-36">{props.t("provincia")}: </label>
                            <input type="text" name="province" id="province" placeholder={`${props.t("provincia")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.province} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="location" className="mr-2 font-bold text-left text-slate-500 w-36">{props.t("localidad")}: </label>
                            <input type="text" name="location" id="location" placeholder={`${props.t("localidad")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.location} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="address" className="mr-2 font-bold text-left text-slate-500 w-36">{props.t("direccion")}: </label>
                            <input type="text" name="address" id="address" placeholder={`${props.t("direccion")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.address} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <span className='block mr-2 font-bold text-left text-slate-500'>{props.t("tipo_usuario")}:</span>
                            <span className='block w-full p-2 mt-1 mb-4 bg-gray-100 border-2'>{userInfo.user_type}</span>
                        </p> 
                        {
                            userLocation === 'es' &&
                                <p className='font-normal'>
                                    <span className='block mr-2 font-bold text-left text-slate-500'>{props.t("relacion_crm")}:</span>
                                    {
                                        crmRelation !== '' 
                                            ? <span className='block w-full p-2 mt-1 mb-4 bg-gray-100 border-2'>{crmRelation} ({idAccountCRM ? `${props.t("empresa")}` : `${props.t("usuario")}`})</span>
                                            : <span className='block w-full p-2 mt-1 mb-4 bg-gray-100 border-2'>{props.t("no_relacionado")}</span>
                                    }
                                </p>
                        }
                        <p className='font-normal'>
                            <span className='block mr-2 font-bold text-left text-slate-500'>{props.t("activo")}:</span>
                            <span className='block w-full p-2 mt-1 mb-4 bg-gray-100 border-2'>{userInfo.active ? `${props.t("si")}` : `${props.t("no")}`}</span>
                        </p>
                        {
                            userLocation === 'es' &&
                                <p className='font-normal'>
                                    <span className='block mr-2 font-bold text-left text-slate-500'>{props.t("aplica_tarifas")}:</span>
                                    <span className='block w-full p-2 mt-1 mb-4 bg-gray-100 border-2'>{applyRates}</span>
                                </p>
                        }
                    </div>
                    
                    <div id="authError" className='mt-2'></div>
                    <div id="errorMessage">
                    {
                        errorMessage != '' && displayErrorMessages(errorMessage, document.querySelector("#authError"))
                    }
                    </div>

                    <div className="mt-4 text-center">
                        <Button 
                            text={`${props.t("cerrar")}`}
                            type='secondary' 
                            className='mt-2 uppercase sm:mt-0 w-28' 
                            onClick = { changeShowAlert(false) }
                        />
                        <Button 
                            text={`${props.t("modificar")}`}
                            type='normal' 
                            className='uppercase w-28' 
                            onClick = { editUserInfo }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}   