import { SignUpService, LoginUserService, LogoutUserService, UpdatePasswordService, RequestRestorePasswordService, RestorePasswordService, MailConfirmService } from '../../services/AuthService'
import { languages } from '../../other/constants/constants.js';
import i18next from 'i18next'

export const signUpAction = (credentials) => {
    return (dispatch) => {
        dispatch({ type: 'RESTART_AUTH_RESPONSE' })
        dispatch({ type: 'LOADING' })

        SignUpService(credentials).then((res) => {
                if (res.hasOwnProperty('success') && res.success === true) {
                    dispatch({ type: 'SIGNUP_SUCCESS', res })

                } else if (res.hasOwnProperty('success') && res.success === false) {
                    dispatch({ type: 'SIGNUP_ERROR', res })
                }
            },
            error => {
                dispatch({ type: 'CODE_ERROR', error })
            })
    }
}

export const clearUserAuthState = () => {
    return (dispatch) => {
        dispatch({ type: 'RESTART_AUTH_RESPONSE' });
    }
}

export const loginAction = (credentials, props) => {
    return (dispatch) => {
        dispatch({ type: 'RESTART_AUTH_RESPONSE' })
        dispatch({ type: 'LOADING' })

        LoginUserService(credentials).then((res) => {
                if (res.hasOwnProperty('success') && res.success === true) {
                    localStorage.setItem('user-token', res.token)

                    if (res.user && res.user.country_id) {
                        const userLanguage = languages.find(lang => lang.id === res.user.country_id)

                        if (userLanguage) {
                            i18next.changeLanguage(userLanguage.code)
                            localStorage.setItem('comelit-user-language', userLanguage.code)
                        }
                    }

                    if (res.admin_token)
                        localStorage.setItem('admin-token', res.admin_token)
                    else
                        localStorage.removeItem('admin-token')

                    dispatch({ type: 'LOGIN_SUCCESS' })
                    props.history.push('/')

                } else if (res.hasOwnProperty('success') && res.success === false) {
                    dispatch({ type: 'LOGIN_ERROR', res })
                }
            },
            error => {
                dispatch({ type: 'CODE_ERROR', error })
            })
    }
}

export const logoutAction = (props) => {
    return (dispatch) => {
        dispatch({ type: 'RESTART_AUTH_RESPONSE' })

        LogoutUserService().then((res) => {
                if (res.hasOwnProperty('success') && res.success === true) {

                    localStorage.removeItem('user-token')
                    localStorage.removeItem('admin-token')

                    dispatch({ type: 'LOGOUT_SUCCESS' })

                } else if (res.hasOwnProperty('success') && res.success === false) {
                    dispatch({ type: 'LOGOUT_ERROR', res })
                }
            },
            error => {
                dispatch({ type: 'CODE_ERROR', error })
            })
    }
}

export const updatePasswordAction = (credentials, props) => {
    return (dispatch) => {
        dispatch({ type: 'RESTART_AUTH_RESPONSE' })
        dispatch({ type: 'LOADING' })

        UpdatePasswordService(credentials).then((res) => {
                if (res.hasOwnProperty('success') && res.success === true) {

                    dispatch({ type: 'PASSWORD_UPDATE_SUCCESS', res })
                    setTimeout(() => {
                        props.history.push('/')
                    }, 2000);

                } else if (res.hasOwnProperty('success') && res.success === false) {
                    dispatch({ type: 'PASSWORD_UPDATE_ERROR', res })
                }
            },
            error => {
                dispatch({ type: 'CODE_ERROR', error })
            })
    }
}

export const requestRestorePasswordAction = (credentials, props) => {
    return (dispatch) => {
        dispatch({ type: 'RESTART_AUTH_RESPONSE' })
        dispatch({ type: 'LOADING' })

        RequestRestorePasswordService(credentials).then((res) => {
                if (res.hasOwnProperty('success') && res.success === true) {

                    dispatch({ type: 'REQUEST_PASSWORD_RESTORE_SUCCESS', res })

                    props &&
                        setTimeout(() => {
                            props.history.push('/')
                        }, 3000);

                } else if (res.hasOwnProperty('success') && res.success === false) {
                    dispatch({ type: 'REQUEST_PASSWORD_RESTORE_ERROR', res })
                }
            },
            error => {
                dispatch({ type: 'CODE_ERROR', error })
            })
    }
}

export const restorePasswordAction = (credentials, props) => {
    return (dispatch) => {
        dispatch({ type: 'RESTART_AUTH_RESPONSE' })
        dispatch({ type: 'LOADING' })

        RestorePasswordService(credentials).then((res) => {
                if (res.hasOwnProperty('success') && res.success === true) {
                    dispatch({ type: 'PASSWORD_RESTORE_SUCCESS', res })

                    localStorage.removeItem('user-token')
                    localStorage.removeItem('admin-token')

                    setTimeout(() => {
                        props.history.push('/')
                    }, 2000);

                } else if (res.hasOwnProperty('success') && res.success === false) {
                    dispatch({ type: 'PASSWORD_RESTORE_ERROR', res })
                }
            },
            error => {
                dispatch({ type: 'CODE_ERROR', error })
            })
    }
}

export const mailConfirmAction = (mailToken) => {
    return (dispatch) => {
        dispatch({ type: 'RESTART_AUTH_RESPONSE' })
        dispatch({ type: 'LOADING' })

        MailConfirmService(mailToken).then((res) => {
                if (res.hasOwnProperty('success') && res.success === true) {
                    dispatch({ type: 'MAIL_CONFIRM_SUCCESS', res })

                } else if (res.hasOwnProperty('success') && res.success === false) {
                    dispatch({ type: 'MAIL_CONFIRM_ERROR', res })
                }
            },
            error => {
                dispatch({ type: 'CODE_ERROR', error })
            })
    }
}