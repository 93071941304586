import HttpService from "../services/HttpService"
const http = new HttpService()

export function getAvailableProductsStep ( assembly, systemType, plateModel, controlType, step, location='default' ) {    
    const userLocation = localStorage.getItem("comelit-user-language")
    return http.postData(
        {
            "type": systemType || '',
            "plate_model": plateModel || '',
            "assembly": assembly || '',
            "system_type": "",
            "distribution_type": "",
            "control_type": controlType || '',
            "user-location": userLocation,
            "location": location
        }, 
        "product/getAvailable/" + step, "user-token"
    )
}

export function getRelatedProducts ( articleCode, type ) {
    const userLocation = localStorage.getItem("comelit-user-language")
    
    articleCode = articleCode.replace("/","--")
    let fetchURL = type === "kit" 
        ? `kits/getRelatedProducts`
        : `product/getRelatedProducts`

    return http.postData(
        {
            "article_code": articleCode,
            "location": userLocation
        }, 
        fetchURL, "user-token"
    )
}

export function getFrameBudget (plateModel, assembly, numSlots, houses, switchColumns, plateMonoblock) {
    const userLocation = localStorage.getItem("comelit-user-language")
    return http.postData(
        {
            "assembly": assembly,
            "plate_model": plateModel,
            "num_slots": numSlots,
            "houses": houses,
            "switch_columns": switchColumns,
            "plate_monoblock": plateMonoblock,
            "location": userLocation
        }, 
        "budget/getFrameProducts", "user-token"
    )
}

export function getProductTrimFrame ( numSlots) {
    const userLocation = localStorage.getItem("comelit-user-language")
    return http.postData(
        {
            "num_slots": numSlots,
            "location": userLocation
        }, 
        "budget/getProductTrimFrame", "user-token"
    )
}

export function getProductVisor ( assembly, numSlots) {
    const userLocation = localStorage.getItem("comelit-user-language")
    return http.postData(
        {
            "assembly": assembly,
            "num_slots": numSlots,
            "location": userLocation
        }, 
        "budget/getProductVisor", "user-token"
    )
}

export function getConfigurationProducts (simulatorType, systemType , mainAccess, internalPortals, houses, instalationType, additionalAcces, 
        audioConcierges, videoConcierges, portalsMultipleVertical, platesWithSK) {
    
    const userLocation = localStorage.getItem("comelit-user-language")
    return http.postData(
        {
            "type": systemType,
            "main_access": mainAccess,
            "internal_portals": internalPortals,
            "houses": houses,
            "instalation_type": instalationType,
            "total_additional_access": additionalAcces,
            "total_concierges_audio": audioConcierges,
            "total_concierges_video": videoConcierges,
            "portals_multiple_vertical": portalsMultipleVertical,
            "plates_with_sk": platesWithSK,
            "location": userLocation
        }, 
        "budget/getAdditionalProducts/" + simulatorType, "user-token"
    )
}

export function getAvailableKits ( type, houses, embedded, surface, plateModel ) {
    const userLocation = localStorage.getItem("comelit-user-language")

    return http.postData(
        {
            "type": type,
            "houses": houses,
            "embedded": embedded,
            "surface": surface,
            "plate_model": plateModel,
            "location": userLocation
        }, 
        "kits/getAvailableKits", "user-token"
    )
}

export function getAvailableInternalUnits ( kit, systemType ) {
    const userLocation = localStorage.getItem("comelit-user-language")

    return http.postData(
        {
            "kit": kit,
            "systemType": systemType,
            "location": userLocation
        }, 
        "kits/getAvailableInternalUnits", "user-token"
    )
}

export function getKitBudget ( kit, houses, embedded, surface, additionalAccess, additionalInternalUnit, internalUnitsHouse ) {
    const userLocation = localStorage.getItem("comelit-user-language")
    return http.postData(
        {
            "kit": kit, 
            "houses": houses, 
            "embedded": embedded, 
            "surface": surface, 
            "additionalAccess": additionalAccess, 
            "additionalInternalUnit": additionalInternalUnit, 
            "internalUnitsHouse": internalUnitsHouse,
            "location": userLocation
        }, 
        "budget/getKitBudget", "user-token"
    )
}

export function createBudget () {
    return http.getData("budget/create", "user-token")
}