import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom'
import { Provider } from 'react-redux'

import i18n from "i18next";
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import { store }  from './createStore'
import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['es', 'pt', 'en'],
    fallbackLng: "es",
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: '/languages/{{lng}}.json',
    },
    detection: { 
      order: ['localStorage', 'cookie', 'sessionStorage', 'path', 'subdomain'],
      caches: ['cookie'],
      lookupLocalStorage: 'comelit-user-language',
    },
    react: { useSuspense: false },
});

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store}>
        <App/>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
