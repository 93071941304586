import React, { Component } from 'react'

import { SecondaryTitle } from '../elements/SecondaryTitle';
import { Button } from '../elements/Button';
import { CheckBoxInput } from '../elements/CheckBoxInput'

import { DisplaySimulator } from './DisplaySimulator';
import { Separator } from '../elements/Separator';

export class StepThree extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            showConfigurator: false,
            numPlateSelected: '',
            typeSelected: '',
            additional: false,
            indexAdditional: 0,
            modalApplyVisible: false,
            plateModelSelected: '',
            applyPlates: []
        }
    }

    handleClickConfigurator = (numPlate, type, additional, indexAdditional) => (e) => {
        this.setState({
            showConfigurator: !this.state.showConfigurator,
            numPlateSelected: numPlate,
            typeSelected: type,
            additional: additional,
            indexAdditional: indexAdditional
        })
    }

    handleSimulatorClose = () => {
        this.setState({
            showConfigurator: false,
            numPlateSelected: '',
            typeSelected: ''
        })
    }

    renderConfiguratorButtons = ( numPlate, plateInfo, type, simulatorType ) => {
        
        let buttons = []

        let referencePlates = type === "main" ? [...this.props.mainPlates] : [...this.props.secondaryPlates]
        let plates = referencePlates.filter(plate => plate.plateModel === plateInfo.plateModel)
        let totalPlates = plates.length
        plates.forEach(plate => {
            totalPlates += plate.additionalContents.length
        });

        let questionButton = ''
        if (simulatorType === "association") {
            questionButton = `${type === 'main' ? `${this.props.t("aplicar_disenho_placas_principales")}` : `${this.props.t("aplicar_disenho_placas_secundarias")}`}`
        } else {
            questionButton = `${this.props.t("aplicar_disenho_portales")}`
        }

        // Botones para la placa principal
        buttons.push(
            <div key={0}>
                <Button 
                    type = 'normal' 
                    text = { plateInfo.configurationComplete ? `${this.props.t("placa_configurada").toUpperCase()}` : `${this.props.t("configurar_placa").toUpperCase()}` }
                    className = { plateInfo.configurationComplete ? ' bg-gray-400' : '' }
                    onClick = {this.handleClickConfigurator(numPlate, type, false, 0)}
                />
                {
                    plateInfo.image !== '--' &&
                        <img src={ plateInfo.image } alt="" className="w-52 mx-auto mt-2" />
                }
                {
                    totalPlates > 1 && 
                        <div className="mt-2 text-center">
                            <Button
                                type = 'normal' 
                                text = { questionButton }
                                disabled = { !plateInfo.configurationComplete }
                                onClick = { () => { this.clickApplyPlates( numPlate, type, 0, plateInfo.plateModel ) }}
                            />   
                        </div>
                }
            </div>
        )          

        // Botones para los accesos adicionales
        if (plateInfo.additionalContents.length > 0){
            for (let i = 0; i < plateInfo.additionalContents.length; i++) {
                buttons.push(
                    <div className='mt-6' key={i+1}>
                        <div className="mb-1"><span className="font-semibold uppercase">{this.props.t("acceso_adicional")} {i+1}</span></div>
                        <Button 
                            type = 'normal' 
                            text = { plateInfo.additionalContents[i].configurationComplete ? `${this.props.t("placa_configurada").toUpperCase()}` : `${this.props.t("configurar_placa").toUpperCase()}` }
                            className = { plateInfo.additionalContents[i].configurationComplete ? ' bg-gray-400' : '' }
                            onClick = {this.handleClickConfigurator(numPlate, type, true, i)}
                        />
                        {
                            plateInfo.additionalContents[i].image !== '--' &&
                                <img src={ plateInfo.additionalContents[i].image } alt="" className="w-52 mx-auto mt-2" />
                        }
                        {
                            totalPlates > 1 &&
                                <div className="mt-2 text-center">
                                    <Button
                                        type = 'normal' 
                                        text = { questionButton }
                                        disabled = { !plateInfo.additionalContents[i].configurationComplete }
                                        onClick = { () => { this.clickApplyPlates( numPlate, type, plateInfo.additionalContents[i].idAdditional, plateInfo.plateModel ) }}
                                    />   
                                </div>
                        }
                    </div>
                )            
            }
        }        
        
        return buttons
    }

    renderPlatesSummary = (type, platesInfo, simulatorType, portalInfo = undefined) => {
        let render = [];
        if (platesInfo.length === 0) return ''
        for (let i = 0; i < platesInfo.length; i++) {
            let auxTitle = ""
            
            if (portalInfo !== undefined)
                auxTitle = portalInfo[i] !== undefined ? ` - ${portalInfo[i].homes} ` + (portalInfo[i].homes > 1 ? `${this.props.t("viviendas")}` : `${this.props.t("vivienda")}`) : ''

            const plate = platesInfo[i];
            render.push(
                <div className="text-center bg-gray-50 pt-4 pb-4" key={i}>
                    <p className="text-lg font-semibold uppercase">{simulatorType === "association" ? `${this.props.t("placa")}` : `${this.props.t("portal")}`} Nº {i+1}{type === "secondary" ? auxTitle : ''}</p>
                    <p className="mb-4"><span className="font-semibold">{this.props.t("tipo_placa")}:</span> <span className="uppercase">{plate.plateModel}</span></p>
                    {this.renderConfiguratorButtons( i, plate, type, simulatorType )}
                </div>
            )
        }

        return render
    }
    
    clickApplyPlates = ( numPlate, type, indexAdditional, plateModel ) => {
        this.setState({
            modalApplyVisible: true,
            plateModelSelected: plateModel,
            typeSelected: type,
            numPlateSelected: numPlate,
            indexAdditional: indexAdditional,
            applyPlates: []
        })
    }

    renderModalApply = () => {
        
        let platesApply = []
        let referencePlates = this.state.typeSelected === "main" ? [...this.props.mainPlates] : [...this.props.secondaryPlates]
        
        for (let i = 0; i < referencePlates.length; i++) {
            
            // Solo si es el mismo modelo de placa
            if (this.state.plateModelSelected === referencePlates[i].plateModel){
                (this.state.numPlateSelected !== i || (this.state.numPlateSelected === i && this.state.indexAdditional !== 0)) &&
                    platesApply.push({
                        type: 'normal',
                        parent: '',
                        numPlate: i
                    })
                for (let j = 0; j < referencePlates[i].additionalContents.length; j++) {
                    if (referencePlates[i].additionalContents[j].idAdditional !== this.state.indexAdditional || i !== this.state.numPlateSelected){
                        platesApply.push({
                            type: 'additional',
                            parent: i,
                            numPlate: referencePlates[i].additionalContents[j].idAdditional
                        })
                    }
                }
            }
        }

        let applyRender = []
        let key = 0
        platesApply.forEach(element => {

            let label = element.type === "additional"
                ? `${this.props.t("placa")} ${ element.parent + 1 } - ${this.props.t("acceso_adicional")} ${ element.numPlate }`
                : `${this.props.t("placa")} ${ element.numPlate + 1 }`

            let stateApplyPlates = this.state.applyPlates
            let resul = stateApplyPlates.find( plate => plate.type === element.type && plate.numPlate === element.numPlate && plate.parent === element.parent)

            applyRender.push(
                <CheckBoxInput 
                    key = { key }
                    name = { `applyPlate${key}` }
                    label = { label }
                    onChange = { this.savePlatesApply(element) }
                    checked = { resul !== undefined ? true : false }
                />
            )
            key += 1
        });

        return(
            <div>
                <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex items-center justify-center">
                    <div className="bg-white max-h-5/6 overflow-y-auto z-30 mt-4 mb-4 relative pt-6 pb-6 pl-4 pr-4 md:p-14 rounded mr-4 ml-4">
                        <div className='absolute cursor-pointer right-2 top-2'
                            onClick={
                                () => this.setState({ 
                                    modalApplyVisible: false,
                                    plateModelSelected: '',
                                    typeSelected: '',
                                    numPlateSelected: '',
                                    indexAdditional: 0
                                })
                            }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>

                        <div className="text-center text-xl uppercase font-semibold">
                            { this.state.typeSelected === "main" ? `${this.props.t("aplicar_disenho_principal", {number_plate:this.state.numPlateSelected+1})}` : `${this.props.t("aplicar_disenho_secundaria", {number_plate:this.state.numPlateSelected+1})}` }
                        </div>
                        <Separator />
                        <div className="text-xl font-semibold mt-4">{ this.state.typeSelected === "main" ? `${this.props.t("aplicar_a_placas_secundarias")}` : `${this.props.t("aplicar_a_placas_principales")}` }:</div>
                        { applyRender }
                        <div className="text-center mt-6">
                            <Button 
                                type = 'normal' 
                                text = {`${this.props.t("aceptar")}`}
                                onClick = {() => this.handleAcceptApply() }
                                className = 'p-2 pl-6 pr-6'
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    savePlatesApply = (element) => (event) => {
        let stateApplyPlates = this.state.applyPlates
        if (event.target.checked){
            stateApplyPlates.push(element)
        } else{
            let indexElement = stateApplyPlates.findIndex(plate => plate.numPlate === element.numPlate && plate.parent === element.parent)
            indexElement !== -1 && stateApplyPlates.splice(indexElement,1)
        }
        this.setState({
            applyPlates: stateApplyPlates
        })
    }

    handleAcceptApply = () => {
        let { typeSelected, numPlateSelected, indexAdditional, applyPlates } = this.state
        this.props.applyDisplayConfiguration( typeSelected, numPlateSelected, indexAdditional, applyPlates )

        this.setState({ 
            modalApplyVisible: false,
            plateModelSelected: '',
            typeSelected: '',
            numPlateSelected: '',
            indexAdditional: 0,
            applyPlates: []
        })
    }

    render() {

        const { mainPlates, secondaryPlates, portalInfo, simulatorType } = this.props
        
        let main = simulatorType === "association" ? this.renderPlatesSummary("main", mainPlates, simulatorType) : ''
        let secondary = this.renderPlatesSummary("secondary", secondaryPlates, simulatorType, portalInfo)

        let {showConfigurator, numPlateSelected, typeSelected, additional, indexAdditional, modalApplyVisible } = this.state

        let renderedModal = modalApplyVisible && this.renderModalApply(this.props)

        return (
            <div>            
                { modalApplyVisible && renderedModal }
                {main !== '' &&
                    <div className="mb-12 mt-12">
                        <SecondaryTitle className="mb-4">{this.props.t("placas_principales")}</SecondaryTitle>
                        <div className="grid sm:grid-cols-2 m:grid-cols-3 gap-y-8 gap-x-6 justify-center">
                            {main}
                        </div>
                    </div>
                }
                {secondary !== '' && 
                    <div>
                        {main !== '' &&
                            <div>
                                <Separator mode="big" />
                                <SecondaryTitle className="mb-4">{this.props.t("placas_secundarias")}</SecondaryTitle>
                            </div>
                        }
                    </div>
                }
                <div className="grid sm:grid-cols-2 m:grid-cols-3 gap-y-8 gap-x-6">
                    {secondary}
                </div>
                { showConfigurator && 
                    <DisplaySimulator 
                        onClose = { this.handleSimulatorClose }
                        numPlate = { numPlateSelected }
                        type = { typeSelected }
                        plates = { typeSelected === "main" ? mainPlates : secondaryPlates }
                        portalInfo = { portalInfo }
                        systemType = { this.props.systemType }
                        houses = {this.props.houses}
                        simulatorType = {this.props.simulatorType}
                        changePlateAssemblyType = { this.props.changePlateAssemblyType }
                        addSubPlate = { this.props.addSubPlate }
                        removeSubPlate = { this.props.removeSubPlate }
                        addSlotToSubPlate = { this.props.addSlotToSubPlate }
                        removeSlotOfSubPlate = { this.props.removeSlotOfSubPlate }
                        addItemToSlot = { this.props.addItemToSlot }
                        removeItemOfSlot = { this.props.removeItemOfSlot }
                        addItemToFirstSlot = { this.props.addItemToFirstSlot }
                        restartPlateDisplay = { this.props.restartPlateDisplay }
                        restartPlatePushButtons = { this.props.restartPlatePushButtons }
                        setPlateVisorValue = { this.props.setPlateVisorValue }
                        setPlateTrimFrameValue = { this.props.setPlateTrimFrameValue }
                        changePlateConfigurated = { this.props.changePlateConfigurated }
                        additional = { additional }
                        indexAdditional = { indexAdditional }
                        addPushButtonToSlot = { this.props.addPushButtonToSlot }    
                        addPushButtonToAllPlate = { this.props.addPushButtonToAllPlate }
                        addPushButtonToFirstSlot = { this.props.addPushButtonToFirstSlot }    
                        t = { this.props.t }               
                    />
                }
            </div>
        )
    }
}