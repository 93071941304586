import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { useFormFields } from '../other/helpers/hooksFormInput'
import { useDispatch, useSelector } from 'react-redux'
import { signUpAction, clearUserAuthState } from '../store/actions/AuthActions'
import { displayErrorMessages } from '../other/helpers/displayErrors'
import { loader } from '../other/Utils'
import { languages } from '../other/constants/constants.js';

export function Register(props) {    
    const dispatch = useDispatch()
    const authResponse = useSelector(state => state.userAuth.userAuthResponse)
    const { t } = props

    const [ isLoading, setIsLoading ] = useState(false)

    const userLocation = localStorage.getItem("comelit-user-language")

    const initialForm = {
        company: '',
        contact: '',
        cif: '',
        email: '',
        phone: '',
        province: '',
        location: '',
        address: '',
        country: userLocation,
        password: '',
        password_confirm: '',
        user_location: userLocation
    }

    const [fields, handleFieldChange] = useFormFields( initialForm )
        
    useEffect(
        () => { dispatch(clearUserAuthState()) }, []
    )

    const successMessage = (successMessage) => {
        return <div dangerouslySetInnerHTML={{__html:'<div class="p-4 bg-green-400 text-green-800">' + successMessage + '</div>'}} ></div>
    }
    
    const clearAuthErrDiv = () => {
        let authErr = document.querySelector("#authError");
        authErr.innerHTML = "";
    }

    const userRegister = (e) => {
        e.preventDefault()
        setIsLoading(true)
        clearAuthErrDiv()

        if ( fields.password !== fields.password_confirm){
            document.querySelector("#authError").innerHTML = `<div class='p-4 bg-red-200 text-red-800 rounded-md'>${t("contrasenha_coincidir")}</div>`;
            setIsLoading(false)
        }
        else{
            dispatch(signUpAction(fields))
            setIsLoading(false)
        }
    }

    const changeCountry = (country) => {
        handleFieldChange(
            {
                target: {
                    id: 'country',
                    value: country
                }
            }
        )
    }
    
    return(
        <div className='container flex mx-auto home'>
            { isLoading && loader(props.t) }
            <form id="registration" className="w-full max-w-3xl pt-10 pb-10 pl-6 pr-6 border-2 rounded-md sm:w-3/4 mt-14 mb-14 bg-gray-50" onSubmit={userRegister}>
                <h1 className='mb-4 text-2xl text-center text-green-comelit'>{t("registrar_usuario")}</h1>

                <label htmlFor="company" className="mr-2 font-bold text-left text-slate-500 w-36">{t("empresa")}</label>
                <input type="text" name="company" id="company" placeholder={`${t("empresa")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.company} onChange={handleFieldChange}/>
                
                <label htmlFor="contact" className="mr-2 font-bold text-left text-slate-500 w-36">{t("persona_contacto")}: </label>
                <input type="text" name="contact" id="contact" placeholder={`${t("persona_contacto")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.contact} onChange={handleFieldChange}/>
                
                <label htmlFor="cif" className="mr-2 font-bold text-left text-slate-500 w-36">{t("cif_dni")}: </label>
                <input type="text" name="cif" id="cif" placeholder={`${t("cif_dni")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.cif} onChange={handleFieldChange}/>
                
                <label htmlFor="email" className="mr-2 font-bold text-left text-slate-500 w-36">{t("correo_electronico")}: </label>
                <input type="email" name="email" id="email" placeholder={`${t("correo_electronico")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.email} onChange={handleFieldChange}/>
                
                <label htmlFor="phone" className="mr-2 font-bold text-left text-slate-500 w-36">{t("telefono")}: </label>
                <input type="tel" name="phone" id="phone" placeholder={`${t("telefono")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.phone} onChange={handleFieldChange}/>
                
                <label htmlFor="province" className="mr-2 font-bold text-left text-slate-500 w-36">{t("provincia")}: </label>
                <input type="text" name="province" id="province" placeholder={`${t("provincia")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.province} onChange={handleFieldChange}/>
                                
                <label htmlFor="location" className="mr-2 font-bold text-left text-slate-500 w-36">{t("localidad")}: </label>
                <input type="text" name="location" id="location" placeholder={`${t("localidad")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.location} onChange={handleFieldChange}/>
                
                <label htmlFor="address" className="mr-2 font-bold text-left text-slate-500 w-36">{t("direccion")}: </label>
                <input type="text" name="address" id="address" placeholder="Dirección" className="w-full p-2 mt-1 mb-4 border-2" autoComplete='on' value={fields.address} onChange={handleFieldChange}/>
                
                <div className='flex items-center'>
                    <label htmlFor="country" className="mr-4 font-bold text-left text-slate-500">{t("pais")}: </label>
                    <div className="lang"> 
                        <div className={`flag ${fields.country}`}></div>
                        <ul className="dropdown">
                            {
                                languages.map( language => {
                                    if (language.code !== fields.country) {
                                        return <li onClick = { () => changeCountry(language.code) } key={language.code} >
                                             <div value = {language.code} className = {`flag ${language.code}`} ></div>
                                          </li>
                                    }
                                    return null
                                })
                            }
                        </ul>
                    </div>
                </div>

                <div className='h-px max-w-sm mx-auto mt-4 mb-8 bg-green-comelit'></div>

                <label htmlFor="password" className="mr-2 font-bold text-left text-slate-500 w-36">{t("contrasenha")}: </label>
                <input type="password" name="password" id="password" placeholder={`${t("contrasenha")}`}className="w-full p-2 mt-1 mb-4 border-2" autoComplete='off' value={fields.password} onChange={handleFieldChange}/>
                
                <label htmlFor="password_confirm" className="mr-2 font-bold text-left text-slate-500 w-36">{t("confirmar_contrasenha")}: </label>
                <input type="password" name="password_confirm" id="password_confirm" placeholder={`${t("confirmar_contrasenha")}`} className="w-full p-2 mt-1 mb-4 border-2" autoComplete='off' value={fields.password_confirm} onChange={handleFieldChange}/>

                <div id="authError" className='mt-2'></div>
                
                <div id="authResponse">
                {
                    authResponse !== '' && authResponse.success === true
                        ? successMessage(authResponse.message)
                        : authResponse.success === false 
                            && displayErrorMessages(authResponse.error, document.querySelector("#authError"))
                }
                </div>
                
                <div className='text-center'>
                    <input type="submit" value={`${t("crear_usuario")}`} className="pt-4 pb-4 pl-8 pr-8 mx-auto mt-4 text-white rounded cursor-pointer bg-green-comelit"/>
                </div>

                <Link to = {{pathname: '/user/login'}} className="text-center hover:cursor-pointer">
                    <p className='mt-4 underline' >{t("volver_login")}</p>
                </Link>
            </form>
        </div>
    )
}