import { PushButton } from './PushButton';

export const PushButtonContainer = (props) => {

    let { numPlate, idSubPlate, idSlot, additional, indexAdditional, type, itemType, assembly, plateModel, buttonsContent } = props
    let { addPushButtonToSlot, addPushButtonToFirstSlot, addPushButtonToAllPlate } = props

    let renderedPushButtons = []
    for (let i = 0; i < buttonsContent.length; i++) {
        const buttonContent = buttonsContent[i];

        let position = 'left-0 '
        if (buttonsContent.length === 1){
            position += 'bottom-0'
        }
        else {
            if (i === 0){
                position += 'top-0'
            }
            if (i === 1){
                position += assembly === "surface" ? 'top-46' : 'top-39'
            }
            if (i === 2){
                position += assembly === "surface" ? 'top-91' : 'top-77'
            }
            if (i === 3 ){
                position += 'bottom-0'
            }
        }

        renderedPushButtons.push(
            <PushButton
                key = { i }
                src = { buttonContent.src }
                deepLink = { buttonContent.deepLink }
                articleCode = {buttonContent.articleCode}
                eanCode = {buttonContent.eanCode}
                description = { buttonContent.description }
                toolTip = { buttonContent.toolTip }
                idCategory = { buttonContent.idCategory }
                category = { buttonContent.category }
                pvp = { buttonContent.pvp }
                numCalls = { buttonContent.numCalls }
                idButton = { buttonContent.idButton }
                buttonsContent = { buttonsContent }
                plateInfo = { props.plateInfo }
                location = { itemType }
                plateModel = { plateModel } 
                assembly = { assembly }
                numPlate = { numPlate }
                idSubPlate = { idSubPlate }
                idSlot = { idSlot }
                type = { type }
                additional = { additional }
                indexAdditional = { indexAdditional }
                addPushButtonToSlot = { addPushButtonToSlot }
                addPushButtonToAllPlate = { addPushButtonToAllPlate }
                addPushButtonToFirstSlot = { addPushButtonToFirstSlot }
                position = { position }
                t = {props.t}
            />

        )
    }

    return renderedPushButtons
};
