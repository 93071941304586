import React, { Fragment, useState } from 'react';

import { CheckBoxInput } from '../../elements/CheckBoxInput';
import { Button } from '../../elements/Button';
import { getTableBudgetTitle, formatoEuro, loader, areSameObjects } from '../../../other/Utils';

import { PDFDownloadLink, Document, Page, Text, View, Link, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import fontThin from '../../../fonts/ttf/HelveticaNeueLTW1G-Th.ttf'
import fontBold from '../../../fonts/ttf/HelveticaNeueLTW1G-Bd.ttf'
import fontBlack from '../../../fonts/ttf/HelveticaNeueLTW1G-Blk.ttf'
import fontMedium from '../../../fonts/ttf/HelveticaNeueLTW1G-Md.ttf'

import PDFHeader from './PDFHeader'
import PDFPlatesBudget from './PDFPlatesBudget'
import PDFTable from './PDFTable'

import HttpService from '../../../services/HttpService'
import { createBudget } from '../../../other/ApiCalls'
import { useFormFields } from '../../../other/helpers/hooksFormInput'
import AlertThirdPartyForm from '../../elements/AlertThirdPartyForm';
import AlertAddLineToBudget from '../../elements/AlertAddLineToBudget';

const http = new HttpService()

Font.register({
    family: 'Helvetica Neue',
    fonts: [
        { src: fontThin, fontWeight: 'thin' },
        { src: fontBold, fontWeight: 'bold' },
        { src: fontBlack, fontWeight: 'heavy' },
        { src: fontMedium, fontWeight: 'medium' },
    ]
})

const styles = StyleSheet.create({
    body: {
        padding: "20px",
        paddingBottom: "30px",
        fontSize: '12',
        position: "relative"
    },
    fontLight:{
        fontFamily: 'Helvetica Neue',
        fontWeight: 'light'
    },
    fontBold:{
        fontFamily: 'Helvetica Neue',
        fontWeight: 'bold'
    },
    header:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    image:{
        width: '150px',
        height: 'auto'
    },
    subheader:{
        textAlign: 'center',
        width: '100%'
    },
    title:{
        color: '#009640',
        fontSize: '14',
        // borderWidth: '1',
        // borderColor: '#9ca3af',
        padding: '5px 10px 5px 10px',
        fontFamily: 'Helvetica Neue',
        fontWeight: 'bold'
    },
    subtitle:{
        // marginTop: '5px',
        width: '100%',
        fontFamily: 'Helvetica Neue',
        fontWeight: 'light'
    },
    info:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '20px'
    },
    separator:{
        height: '0.5px',
        backgroundColor: 'gray',
        width: '65%',
        marginTop: '15px',
        marginBottom: '15px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    budgetSection:{
        backgroundColor: '#f3f4f6',
        paddingLeft: '15px',
        paddingRight: '15px',
        paddingBottom: '0px',
        marginBottom: '20px'
    },
    netTotal:{
        display: 'flex',
        justifyContent: 'flex-end'
    },
    disclaimer: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 20,
        right: 0,
        marginTop: '30px',
        paddingRight: '15px'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center'
    }
})

const treatBudgetInfoProduct = (info, budget, index) => {

    budget[index].plates.forEach(plate => {
        plate.content.forEach(product => {
            product.numRow = info.rowNumber
            info.pvpTotal += product.sub_pvp
            info.netTotal += product.sub_tn
            info.rowNumber += 1
        });
    });
}

const treatAdditionalLinesProduct = (info, budget, index) => {
    budget[index].plates[0].content.forEach(product => {
        product.numRow = info.rowNumber
        info.pvpTotal += product.sub_pvp
        info.netTotal += product.sub_tn
        info.rowNumber += 1
    });
}

const getPlatesContent = (plates, type, simulatorType, portalInfo, pdfExport, t) => {

    let content = []
    for (let i = 0; i < plates.length; i++) {
        const plate = plates[i];

        if (plates[i].content){
            content.push(
                <View>
                    {
                        (type === "main" || type === "secondary") &&
                            <Text style={[styles.fontBold , {textTransform:'uppercase', textAlign:'center', marginTop: i>0 ? '35px' : '5px', marginBottom:'5px'}]} wrap={false}>{ getTableBudgetTitle(type, plate.numPlate, portalInfo, simulatorType, i, t) }</Text>
                    }
                    <PDFTable
                        simulatorType = {simulatorType}
                        products = {plates[i].content}
                        type= {type}
                        pdfExport = {pdfExport}
                        t = {t}
                    />
                </View>
            )
        }
    }

    return(
        <View>
            { content }
        </View>
    )
}

const exportKitBudget = (pdfExport, props, budgetReference, thirdPartyBudget, thirdPartyFields) => {

    const { t } = props

    let simulatorType = props.simulatorType

    let kitIndex = props.budget.findIndex(budget => budget.type === "kit")

    let additionalIndex = props.budget.findIndex(budget => budget.type === "additional_access")
    let containsAdditional = props.budget[additionalIndex].products.length > 0

    let additionalMonitorsIndex = props.budget.findIndex(budget => budget.type === "additional_monitors")
    let containsMonitors = props.budget[additionalMonitorsIndex].products.length > 0

    let additionalLinesIndex = props.budget.findIndex(budget => budget.type === "additional_lines")
    let containsAditionalLines = additionalLinesIndex !== -1 && props.budget[additionalLinesIndex].products.length > 0

    let installationCostIndex = props.budget.findIndex(budget => budget.type === "installation" )
    let installationCost = props.budget[installationCostIndex].pvp || 0

    // Añadimos los números de columna y calculamos precio total
    let budgetCopy = props.budget
    let rowNumber = 1
    let pvpTotal = 0
    let netTotal = 0
    budgetCopy.forEach(budgetType => {
        if (budgetType.products){
            budgetType.products.forEach(product => {
                product.numRow = rowNumber
                pvpTotal += product.sub_pvp
                netTotal += product.sub_tn
                rowNumber += 1
            });
        }
    });

    return (
        <Document>
            <Page style={styles.body}>
                <PDFHeader
                    props = { {...props, budgetReference, thirdPartyBudget, thirdPartyFields} }
                />
                {/* REFERENCIA */}
                <View style={{width:'100%', marginBottom:'20px', textAlign:'center'}}>
                    <Text style={styles.fontBold}>{props.reference}</Text>
                </View>
                {/* TABLAS PRESUPUESTO */}
                <View style={styles.budgetSection}>
                    <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("kit").toUpperCase()}</Text>
                    <PDFTable
                        simulatorType = {simulatorType}
                        products = {props.budget[kitIndex].products}
                        type = "kit"
                        pdfExport = {pdfExport}
                        t = {t}
                    />
                </View>
                {
                    containsAdditional &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("acceso_adicionales").toUpperCase()}</Text>
                            <PDFTable
                                simulatorType = {simulatorType}
                                products = {props.budget[additionalIndex].products}
                                type="additional_access"
                                pdfExport = {pdfExport}
                                t = {t}
                            />
                        </View>
                }
                {
                    containsMonitors &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("monitores_adicionales").toUpperCase()}</Text>
                            <PDFTable
                                simulatorType = {simulatorType}
                                products = {props.budget[additionalMonitorsIndex].products}
                                type="additional_monitors"
                                pdfExport = {pdfExport}
                                t = {t}
                            />
                        </View>
                }
                {
                    containsAditionalLines &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("lineas_adicionales").toUpperCase()}</Text>
                            <PDFTable
                                simulatorType = {simulatorType}
                                products = {props.budget[additionalLinesIndex].products}
                                type="additional_lines"
                                pdfExport = {pdfExport}
                                t = {t}
                            />
                        </View>
                }
                {
                    pdfExport !== 'no-prices' &&
                        <Fragment>
                            {/* PRECIO TOTAL */}
                            <View wrap={false}>
                                <View>
                                    <Text style={styles.separator}></Text>
                                </View>
                                <View style={{width:'100%', textAlign:'center'}}>
                                    <Text style={styles.fontLight}>{t("total_pvp")}: <Text style={styles.fontBold}>{ formatoEuro( pvpTotal, true ) }</Text></Text>
                                </View>
                                {
                                    pdfExport !== "only-pvp" &&
                                        <View style={{width:'30%', marginLeft:'auto', marginTop:'10px'}}>
                                            <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:'5px', border:'2px solid #f3f4f6'}}>
                                                <Text style={styles.fontLight}>{t("total_neto")}:{"\n"}({t("sin_iva")})</Text>
                                                <Text style={styles.fontBold}>{ formatoEuro( netTotal, true ) }</Text>
                                            </View>
                                        </View>
                                }
                            </View>
                            {
                                installationCost > 0 &&
                                    <View style={{width:'100%', textAlign:'center', marginTop:'10px', fontSize:10}}>
                                        <Text style={styles.fontLight}>(*) Valoración estimada de puesta en marcha: <Text style={styles.fontBold}>{ formatoEuro(installationCost, true) } </Text></Text>
                                    </View>
                            }
                        </Fragment>
                }
                {/* DISCLAIMER */}
                <Disclaimer t = {t} />
                {/* PAGE NUMBER */}
                <Text style={[ styles.pageNumber, styles.fontLight ]} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
}

const exportAssociationBudget = (pdfExport, props, budgetReference, thirdPartyBudget, thirdPartyFields) => {

    const { t } = props

    // Añadimos los números de columna y calculamos precio total
    let budgetCopy = props.budget
    let rowNumber = 1
    let pvpTotal = 0
    let netTotal = 0
    let info = {rowNumber, pvpTotal, netTotal}

    // Placas pricipales
    let mainIndex = budgetCopy.findIndex(budget => budget.type === "main")
    let containsMain = mainIndex !== -1
    if (containsMain){
        treatBudgetInfoProduct(info, budgetCopy, mainIndex)
    }

    // Placas secundarias
    let secondaryIndex = budgetCopy.findIndex(budget => budget.type === "secondary")
    let containsSecondary = secondaryIndex !== -1
    if (containsSecondary){
        treatBudgetInfoProduct(info, budgetCopy, secondaryIndex)
    }

    // Unidades internas
    let internalUnitsIndex = budgetCopy.findIndex(budget => budget.type === "internal_units")
    let containsInternalUnits = internalUnitsIndex !== -1
    if (containsInternalUnits){
        treatBudgetInfoProduct(info, budgetCopy, internalUnitsIndex)
    }

    // Accesorios
    let accessoriesIndex = budgetCopy.findIndex(budget => budget.type === "accessories")
    let containsAccessories = accessoriesIndex !== -1
    if (containsAccessories){
        treatBudgetInfoProduct(info, budgetCopy, accessoriesIndex)
    }

    // Elementos comunes
    let commonElementsIndex = budgetCopy.findIndex(budget => budget.type === "common_elements")
    let containsCommonElements = commonElementsIndex !== -1
    if (containsCommonElements){
        treatBudgetInfoProduct(info, budgetCopy, commonElementsIndex)
    }

    // Conserjería
    let conciergeIndex = budgetCopy.findIndex(budget => budget.type === "concierge")
    let containsConcierge = conciergeIndex !== -1
    if (containsConcierge){
        treatBudgetInfoProduct(info, budgetCopy, conciergeIndex)
    }

    let additionalLinesIndex = budgetCopy.findIndex(budget => budget.type === "additional_lines")
    let containsAditionalLines = additionalLinesIndex !== -1 && budgetCopy[additionalLinesIndex].plates[0].content.length > 0
    if (containsAditionalLines){
        treatAdditionalLinesProduct(info, budgetCopy, additionalLinesIndex)
    }

    let installationCostIndex = props.budget.findIndex(budget => budget.type === "installation" )
    let installationCost = props.budget[installationCostIndex].pvp || 0

    return (
        <Document>
            <Page style={styles.body}>
                {/* HEADER */}
                <PDFHeader
                    props = { {...props, budgetReference, thirdPartyBudget, thirdPartyFields} }
                />
                {/* REFERENCIA */}
                <View style={{width:'100%', marginBottom:'10px', textAlign:'center'}}>
                    <Text style={styles.fontBold}>{props.reference}</Text>
                </View>
                {/* TABLAS PRESUPUESTO */}
                {
                    containsMain &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("placas_principales").toUpperCase()}</Text>
                            { getPlatesContent(budgetCopy[mainIndex].plates, "main", props.simulatorType, props.portalInfo, pdfExport, t) }
                        </View>
                }
                {
                    containsSecondary &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{props.simulatorType === "edifice" ? `${t("portales").toUpperCase()}` : `${t("placas_secundarias")}`}</Text>
                            { getPlatesContent(budgetCopy[secondaryIndex].plates, "secondary", props.simulatorType, props.portalInfo, pdfExport, t) }
                        </View>
                }
                {
                    containsInternalUnits &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("unidades_internas").toUpperCase()}</Text>
                            { getPlatesContent(budgetCopy[internalUnitsIndex].plates, "internal_units", props.simulatorType, props.portalInfo, pdfExport, t) }
                        </View>
                }
                {
                    containsAccessories &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("accesorios").toUpperCase()}</Text>
                            { getPlatesContent(budgetCopy[accessoriesIndex].plates, "accessories", props.simulatorType, props.portalInfo, pdfExport, t) }
                        </View>
                }
                {
                    containsCommonElements &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{props.simulatorType === "edifice" ? `${t("equipos")}` : `${t("elementos_comunes")}`}</Text>
                            { getPlatesContent(budgetCopy[commonElementsIndex].plates, "common_elements", props.simulatorType, props.portalInfo, pdfExport, t) }
                        </View>
                }
                {
                    containsConcierge &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("conserjería").toUpperCase()}</Text>
                            { getPlatesContent(budgetCopy[conciergeIndex].plates, "concierge", props.simulatorType, props.portalInfo, pdfExport, t) }
                        </View>
                }
                {
                    containsAditionalLines &&
                        <View style={styles.budgetSection}>
                            <Text style={[styles.fontBold, {textAlign: 'center', paddingTop: '15px'}]} wrap={false}>{t("lineas_adicionales").toUpperCase()}</Text>
                            { getPlatesContent(budgetCopy[additionalLinesIndex].plates, "additional_lines", props.simulatorType, props.portalInfo, pdfExport, t) }
                        </View>
                }
                {
                    pdfExport !== 'no-prices' &&
                        <Fragment>
                            {/* PRECIO TOTAL */}
                            <View wrap={false}>
                                <View>
                                    <Text style={styles.separator}></Text>
                                </View>
                                <View style={{width:'100%', textAlign:'center'}}>
                                    <Text style={styles.fontLight}>{t("total_pvp")}: <Text style={styles.fontBold}>{ formatoEuro( info.pvpTotal, true ) }</Text></Text>
                                </View>
                                {
                                    pdfExport !== "only-pvp" &&
                                        <View style={{width:'30%', marginLeft:'auto', marginTop:'10px'}}>
                                            <View style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center', padding:'5px', border:'2px solid #f3f4f6'}}>
                                                <Text style={styles.fontLight}>{t("total_neto")}:{"\n"}({t("sin_iva")})</Text>
                                                <Text style={styles.fontBold}>{ formatoEuro( info.netTotal, true ) }</Text>
                                            </View>
                                        </View>
                                }
                                {
                                    installationCost > 0 &&
                                        <View style={{width:'100%', textAlign:'center', marginTop:'10px', fontSize:10}}>
                                            <Text style={styles.fontLight}>(*) Valoración estimada de puesta en marcha: <Text style={styles.fontBold}>{ formatoEuro(installationCost, true) } </Text></Text>
                                        </View>
                                }
                            </View>
                        </Fragment>
                }
                {/* IMÁGENES PLACAS */}
                <PDFPlatesBudget
                    mainPlates = { props.mainPlates }
                    secondaryPlates = { props.secondaryPlates }
                    simulatorType = { props.simulatorType }
                    portalInfo = { props.portalInfo }
                    t = { t }
                />
                {/* DISCLAIMER */}
                <Disclaimer t = {t}/>
                {/* PAGE NUMBER */}
                <Text style={[ styles.pageNumber, styles.fontLight ]} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed />
            </Page>
        </Document>
    )
}

const Disclaimer = (props) => {
    return (
        <Text style={[styles.fontLight, styles.disclaimer]} wrap={false}>
            <Link style={{marginBottom:'10px', color:"black"}} src={props.t("link_calidad")}>{props.t("certificaciones_comelit")}</Link>{"\n"}
            {props.t("disclaimer_presupuesto")}
        </Text>
    )
}

function BudgetExport(props) {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ pdfDocument, setPDFDocument ] = useState(null)
    const [ budgetReference, setBudgetReference] = useState('')
    const [ showAddLine, setShowAddLine ] = useState(false)

    const { exportToCRM, setExportToCRM, t }= props
    const userLocation = localStorage.getItem("comelit-user-language")

    const initialThirdPartyInfo = {
        social_reason: '',
        address: '',
        phone: '',
        att: '',
        email: ''
    }

    const [ thirdPartyFields, handleFieldChange ] = useFormFields( initialThirdPartyInfo )
    const [ showThirdPartyForm, setShowThirdPartyForm ] = useState(false)
    
    const createOrderCRM = (accountId, branchId, opportunityId, salesRepId, budgetTotal, year, budgetReference, email, numberOrder) => {

        return http.postDataForceManager(
            {
                "accountId": accountId,
                "archived": false,
                "blocked": false,
                "branchId": branchId,
                "currencyId": 1,
                "opportunityId": opportunityId,
                "readOnly": false,
                "salesRepId": salesRepId,
                "statusId": 1, // Pendiente
                "reference": budgetReference,
                "total": budgetTotal,
                "year": year,
                "number": numberOrder,
                "comment": "Presupuesto realizado por: " + email
            }, process.env.REACT_APP_FORCE_MANAGER_URL + "salesorders")
        .then( resul => resul.id ? resul.id : null )
    }

    const getCRMOrderNumber = (year) => {
        return http.getDataForceManager(process.env.REACT_APP_FORCE_MANAGER_URL + `salesorders?where=year=${year}&order=number`, false)
        .then( (resul) => {
            return resul[0] && resul[0].number ? resul[0].number + 1 : null
        })
    }

    const getProductId = (articleCode) => {
        return http.getDataForceManager(`${process.env.REACT_APP_FORCE_MANAGER_URL}products?where=extId='${articleCode}'%20AND%20deleted='false'`, false)
        .then( resul => resul.length > 0 ? resul[0].id : '' )
    }

    const insertOrderLineCRM = (product, productId, orderId) => {
        let productPrice = (product.nt_und !== 0 && product.dto_1 === 0 && product.dto_2 === 0 && product.dto_3 === 0) ? product.nt_und : product.pvp
        return http.postDataForceManager(
            {
                "quantity": product.quantity,
                "price": productPrice,
                "description": product.description,
                "discount1": product.dto_1,
                "discount2": product.dto_2,
                "discount3": product.dto_3,
                "discount4": 0,
                "total": product.sub_tn,
                "productId": productId,
                "salesOrderId": orderId//,
                // "order": ,	                    Integer	The position of the Order Line in the whole Lines list
            }, process.env.REACT_APP_FORCE_MANAGER_URL + "salesOrdersLines")
        .then((resul) => {
            if (resul.code && resul.code === 903){
                return insertOrderLineCRM(product, productId, orderId)
            } else{
                return resul
            }
        })
    }

    async function createOrderLinesCRM(budget, simulatorType, orderId){
        for (let i = 0; i < budget.length; i++) {
            const budgetType = budget[i];

            if ( ["additional_lines", "installation"].includes(budgetType.type) )
                continue

            if (simulatorType === "kit"){
                for (let j = 0; j < budgetType.products.length; j++) {
                    const product = budgetType.products[j];
                    let productId = await getProductId(product.articleCode)
                    await insertOrderLineCRM(product, productId, orderId)
                }
            } else{
                if ( !["individual","installation"].includes(budgetType.type) ){
                    for (let j = 0; j < budgetType.plates.length; j++) {
                        const plate = budgetType.plates[j];
                        for (let z = 0; z < plate.content.length; z++) {
                            const product = plate.content[z];
                            let productId = await getProductId(product.articleCode)
                            await insertOrderLineCRM(product, productId, orderId)

                        }
                    }
                }
            }
        }
    }

    const getBudgetTotal = (budget, simulatorType, destination = '') => {

        let budgetTotal = 0
        for (let i = 0; i < budget.length; i++) {
            const budgetType = budget[i];

            if ( destination === "CRM" && ["additional_lines", "installation"].includes(budgetType.type) ){
                continue
            }
            if (simulatorType === "kit"){
                for (let j = 0; j < budgetType.products.length; j++) {
                    const product = budgetType.products[j];
                    budgetTotal += product.sub_tn
                }
            }
            else{
                !["individual","installation"].includes(budgetType.type) &&
                    budgetType.plates.forEach(plate => {
                        plate.content.forEach(product => {
                            budgetTotal += product.sub_tn
                        })
                    })
            }
        }

        return budgetTotal
    }

    const exportBudgetToCRM = (budgetReference, setExportToCRM) => {
        return new Promise( async (resolve ,reject) => {

            // Ponemos el export a FALSE, porque ya hemos exportado
            setExportToCRM(false)

            let { userInfo, accountInfo } = props
            let branchId = accountInfo.branchId ? accountInfo.branchId.id : 16 // Comelit España
            let reference = 'WEB' + (props.reference !== '' ? ' - ' + props.reference : '') + ' (' +  budgetReference + ')'
            let salesRepId = -1

            // Asignamos el responsable de empresa como responsable del presupuesto
            if ( accountInfo.salesRepId1 && accountInfo.salesRepId1.id)
                salesRepId = parseInt(accountInfo.salesRepId1.id)
            // Si no tiene responsable, asignamos el usuario que está creando el presupuesto
            if ( salesRepId === -1 && userInfo.id_user_crm )
                salesRepId = parseInt(userInfo.id_user_crm)
            // Si no a STAFF COMELIT
            if ( salesRepId === -1 )
                salesRepId = 83

            // Obtenemos el total del presupuesto
            let budgetTotal = getBudgetTotal(props.budget, props.simulatorType, "CRM")

            // Establecemos la fecha de cierre en +10 días
            let date = new Date()
            let year = date.getFullYear()

            // Creamos el ORDER asociado a la oportunidad correspondiente
            // Si la OFERTA es mayor de 1000€ -> "AUTOCREAROPP"
            // Cliente interno / Administrador -> "Ofertas WEB Internos"
            // Cliente externo /Cliente desconocido -> "Ofertas Web Externos"
            let opportunityId = -1
            if (budgetTotal > 1000)
                opportunityId = 5503 // AUTOCREAROPP
            else if ([1,2].includes(userInfo.id_user_type))
                opportunityId = 16868 // Ofertas WEB Internos
            else
                opportunityId = 16869 // Ofertas Web Externos


            let numberOrder = await getCRMOrderNumber(year)
            let orderId = await createOrderCRM(props.accountInfo.id, branchId, opportunityId, salesRepId, budgetTotal, year, reference, userInfo.email, numberOrder)
            if (orderId){
                await createOrderLinesCRM(props.budget, props.simulatorType, orderId)
            }

            resolve()
        })
    }

    const getDocuments = (type, props, budgetReference) => {
        if ( props.simulatorType === "kit")
            return exportKitBudget(type, props, budgetReference, thirdPartyBudget, thirdPartyFields)

        if ( props.simulatorType === "edifice" || props.simulatorType === "association")
            return exportAssociationBudget(type, props, budgetReference, thirdPartyBudget, thirdPartyFields)
    }

    async function exportPDF(type, props){
        if ( type !== ""){

            setIsLoading(true)

            let budgetReferenceResponse = await createBudget()
            let budgetReferenceAux = budgetReferenceResponse.success ? budgetReferenceResponse.message : '--'

            let pdfDoc = await getDocuments(type, props, budgetReferenceAux)
            setPDFDocument(pdfDoc)
            setBudgetReference(budgetReferenceAux)
            setIsLoading(false)
        }
    }

    const changeExport = (type, props) => {
        setPDFDocument(null)
        props.changeExportType(type)
        exportPDF(type, props)
    }

    const changeThirdPartyBudget = () => {
        !thirdPartyBudget &&
            setShowThirdPartyForm(true)
        setThirdPartyBudget(!thirdPartyBudget)
    }

    let { thirdPartyBudget, setThirdPartyBudget } = props

    return(
        <div>
            { isLoading && loader(t) }
            {
                props.userInfo.id_user_type === 3 &&
                    <div className={`mt-8 ${thirdPartyBudget && 'flex items-center justify-center'}`}>
                        <CheckBoxInput
                            name = {`only-pvp`}
                            label = {`${t("oferta_tercero")}`}
                            onChange = { changeThirdPartyBudget }
                            checked = { thirdPartyBudget }
                        />
                        {
                            thirdPartyBudget &&
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mt-4 ml-2 cursor-pointer"
                                    viewBox="0 0 20 20" fill="currentColor" onClick={ () => setShowThirdPartyForm(true) }
                                >
                                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                                    <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                                </svg>
                        }
                    </div>
            }
            {
                thirdPartyBudget &&
                    <div className='mt-2 mb-8 text-center'>
                        <Button
                            text= {`${t("anhadir_linea")}`}
                            type= 'normal'
                            className = 'uppercase'
                            onClick = { () => setShowAddLine(!showAddLine) }
                        />
                    </div>
            }
            {
                showAddLine &&
                    <AlertAddLineToBudget
                        budget = { props.budget }
                        addLineToBudget = { props.addLineToBudget }
                        setShowAddLine = { setShowAddLine }
                    />
            }
            <h4 className="mt-4 mb-2 text-xl font-semibold text-center uppercase">{t("exportar_presupuesto")}</h4>
            {
                userLocation === "es" ?
                    <Fragment>
                        <div className={ props.userInfo.id_user_type === 4 ? 'flex justify-center' : 'grid grid-cols-2 sm:grid-cols-4'}>
                            <CheckBoxInput
                                name = {`only-pvp`}
                                label = {`${t("solo_pvp")}`}
                                onChange = { () => changeExport('only-pvp', props) }
                                checked = { props.exportType === 'only-pvp' }
                            />
                            {
                                props.userInfo.id_user_type !== 4 &&
                                    <Fragment>
                                        <CheckBoxInput
                                            name = {`net-without-discount`}
                                            label = {`${t("netos")}`}
                                            onChange = { () => changeExport('net-without-discount', props) }
                                            checked = { props.exportType === 'net-without-discount' }
                                        />
                                        <CheckBoxInput
                                            name = {`pvp-total-net`}
                                            label = {`${t("pvp_neto")}`}
                                            onChange = { () => changeExport('pvp-total-net', props) }
                                            checked = { props.exportType === 'pvp-total-net' }
                                        />
                                        <CheckBoxInput
                                            name = {`pvp-discount-net`}
                                            label = {`${t("pvp_dto_neto")}`}
                                            onChange = { () => changeExport('pvp-discount-net', props) }
                                            checked = { props.exportType === 'pvp-discount-net' }
                                        />
                                    </Fragment>
                            }
                        </div>
                    </Fragment>
                :
                    <CheckBoxInput
                        name = {`no-prices`}
                        label = {`${t("no_prices")}`}
                        onChange = { () => changeExport('no-prices', props) }
                        checked = { props.exportType === 'no-prices' }
                    />
            }

            {/* Botones exportación */}
            <div className="flex flex-col items-center justify-center mt-4">
                {
                    ( !pdfDocument || props.exportType === '' || ( userLocation === "es" && [1,2].includes(props.userInfo.id_user_type) && areSameObjects(props.accountInfo, []) ))
                        ?
                            <Button
                                disabled = { true }
                                text= {t("exportar_pdf")}
                                type= 'normal'
                                className = 'uppercase w-36'
                            />
                        :
                            <div onClick = { () => userLocation === "es" && exportToCRM && exportBudgetToCRM(budgetReference, setExportToCRM) }>
                                <PDFDownloadLink
                                        document = { pdfDocument }
                                        fileName = { `${budgetReference}.pdf` }
                                        className = "col-span-2 p-2 mx-4 text-lg text-center text-white uppercase rounded cursor-pointer disabled:opacity-50 sm:col-span-1 bg-green-comelit w-36"
                                >
                                    {({ blob, url, loading, error }) =>
                                        loading ? `${t("generando")}...` : `${t("exportar_pdf").toUpperCase()}`
                                    }
                                </PDFDownloadLink>
                            </div>
                            // <BlobProvider document={pdfDocument}>
                            //     {({ blob, url, loading, error }) => {
                            //         return (
                            //             <a href={ url } target="_blank" className={`${loading ? "opacity-50" : ''} p-2 text-lg rounded cursor-pointer text-white col-span-2 sm:col-span-1 mx-4  bg-green-comelit uppercase w-36`}>
                            //                 {loading ? "GENERANDO..." : "EXPORTAR PDF"}
                            //             </a>
                            //         )
                            //     }}
                            // </BlobProvider>
                }
                {
                    userLocation === "es" && ([1,2].includes(props.userInfo.id_user_type) && areSameObjects(props.accountInfo, [])) &&
                        <p className='mt-2 text-sm' >(*) {t("seleccionar_cliente_exportar")}</p>
                }
                {/* <Button
                    disabled = {pdfExport === ''}
                    text= 'Exportar PDF'
                    type= 'normal'
                    className = 'uppercase w-36'
                    onClick = { exportPDF }
                />
                <Button
                    disabled = {pdfExport === ''}
                    text='Exportar Excel'
                    type='normal'
                    className='uppercase w-36'
                    // onClick = { exportBudgetExcel }
                /> */}
            </div>
            {
                thirdPartyBudget && showThirdPartyForm &&
                    <AlertThirdPartyForm
                        thirdPartyFields = { thirdPartyFields }
                        handleFieldChange = { handleFieldChange }
                        setShowThirdPartyForm = { setShowThirdPartyForm }
                        t = { t }
                    />
            }
        </div>
    )
}

export default BudgetExport