import fetchIntercept from 'fetch-intercept';

export default function HttpInterceptor(store) {
 
    fetchIntercept.register({
        request: function (url, config) {
            // Modify the url or config here
            return [url, config];
        },

        requestError: function (error) {
            // Called when an error occured during another 'request' interceptor call
            return Promise.reject(error);
        },

        response: function (response) {
            // Modify the reponse object
            if ( !response.url.includes('api.forcemanager.com') && response.status === 401 ){
                localStorage.removeItem("user-token")
                localStorage.removeItem("admin-token")
                
                window.location.href = '/user/login';
            }
            return response
        },

        responseError: function (error) {
            // Handle an fetch error
            return Promise.reject(error);
        }
    })
    
}