// import { isArray } from "@craco/craco/lib/utils"
// import { areSameObjects } from "../other/Utils"

export default class HttpService {

    url = process.env.REACT_APP_API_URL

    postData = async(item, added_url, tokenId = "") => {
        const token = await localStorage.getItem(tokenId)
        const userLocation = await localStorage.getItem('comelit-user-language')

        const requestOptions = this.postRequestOptions(token, item, userLocation)

        return fetch( this.url + '/' + added_url, requestOptions ).then(
            response => response.json()
        )
    }
    
    getData = async( added_url, tokenId = "") => {
        const token = await localStorage.getItem(tokenId)
        const userLocation = await localStorage.getItem('comelit-user-language')

        const requestOptions = this.getRequestOptions(token, userLocation)
        
        return fetch( this.url + '/' + added_url, requestOptions ).then(
            response => response.json()
        )
    }
    
    deleteData = async(item, added_url, tokenId = "") => {
        const token = await localStorage.getItem(tokenId)
        const userLocation = await localStorage.getItem('comelit-user-language')

        const requestOptions = this.deleteRequestOptions(token, item, userLocation)

        return fetch( this.url + '/' + added_url, requestOptions ).then(
            response => response.json()
        )
    }

    getHeaders(token, userLocation){
        return {
            'Authorization': 'Bearer ' + token,
            'Content-type': 'application/json',
            'X-localization': userLocation 
        }
    }

    getRequestOptions = (token, userLocation = null) => {
        let headers = this.getHeaders(token, userLocation)
        let requestOptions = {
            method: "GET",
            headers: headers,
            redirect: 'follow'
        }

        return requestOptions
    }
    
    postRequestOptions = (token, item, userLocation = null) => {
        let headers = this.getHeaders(token, userLocation)
        let requestOptions = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(item),
            redirect: 'follow'
        }

        return requestOptions
    }

    deleteRequestOptions = (token, item, userLocation = null) => {
        let headers = this.getHeaders(token, userLocation)
        let requestOptions = {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(item),
            redirect: 'follow'
        }

        return requestOptions
    }

    getForceToken = async() => {
        
        let forceToken = await localStorage.getItem("force-token")
        if (forceToken === null || forceToken === ""){
            let loginRes = await this.loginForceManager()
            forceToken = loginRes.token
            localStorage.setItem("force-token",forceToken)
        }
        return forceToken
    }

    loginForceManager = async() => {
        let bodyJSON = JSON.stringify(
            {
                username: process.env.REACT_APP_FORCE_PUBLIC,
                password: process.env.REACT_APP_FORCE_PRIVATE
            }
        )
        return fetch(  process.env.REACT_APP_FORCE_MANAGER_URL + "login", {
            method: "POST",
            headers: {
                'Content-type': 'application/json',
                'Accept': '*/*'
            },
            body: bodyJSON,
            redirect: 'follow'
        }).then(
            response => response.json()
        )
    }

    postDataForceManager = async( item, url ) => {
        
        let forceToken = await this.getForceToken()
        const requestOptions = this.postForceRequestOptions(forceToken, item)

        return fetch( url, requestOptions ).then( async (response) => {

            // Comprobamos si el token ha caducado
            if (response && response.code && response.code == 2){
                localStorage.removeItem("force-token")
                let forceToken = await this.getForceToken()
                localStorage.setItem("force-token", forceToken)
                return this.postDataForceManager(item, url);
            }

            return response.json()
        })
    }

    getDataForceManager = async( url, pagination = true, page = 0, previousResponse = [] ) => {

        let forceToken = await this.getForceToken()
        const requestOptions = this.getForceRequestOptions(forceToken)
        
        let urlFetch = url
        if (pagination)
            urlFetch = url.includes("?") ? `${url}&page=${page}` : `${url}?page=${page}`

        return fetch( urlFetch, requestOptions )
            .then( response => response.json())
            .then( async newResponse => {

                // Comprobamos si el token ha caducado
                if (newResponse && newResponse.code && newResponse.code == 2){
                    localStorage.removeItem("force-token")
                    let forceToken = await this.getForceToken()
                    localStorage.setItem("force-token", forceToken)
                    return this.getDataForceManager(url, pagination, page, previousResponse);
                }

                // Si la respuesta no es array la devolvemos
                if (!Array.isArray(newResponse))
                    return newResponse

                const response = [...previousResponse, ...newResponse]; // Combine the two arrays

                if ( pagination && newResponse.length !== 0 ) {
                    page++;
                    return this.getDataForceManager(url, pagination, page, response);
                }

                return response;
            })
    }
    
    getForceRequestOptions = (token) => {
        let requestOptions = {
            method: "GET",
            headers: {
                'X-Session-Key': token,
                'Content-type': 'application/json'
            },
            redirect: 'follow'
        }

        return requestOptions
    }
    
    postForceRequestOptions = (token, item) => {
        let requestOptions = {
            method: "POST",
            headers: {
                'X-Session-Key': token,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(item),
            redirect: 'follow'
        }

        return requestOptions
    }
}