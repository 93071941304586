import React, {Component} from 'react'

export class Button extends Component {
    
    render() {
        const {onClick, disabled, type, text, className} = this.props

        let classes = `p-2 text-lg rounded cursor-pointer text-white disabled:opacity-50 col-span-2 sm:col-span-1 mx-4 `
        classes += type === 'normal'
            ? !className.includes('bg') && ` bg-green-comelit `
            : !className.includes('bg') && ` bg-gray-400 bg-opacity-75 `
        classes += className

        return (
            <button
                className = {classes}
                onClick = {onClick}
                disabled = {disabled}
            >
                {text}
            </button>
        )
    }
}

Button.defaultProps = {
    className: '',
    disabled: false,
    type: 'normal',
    text: ''
}