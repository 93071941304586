import React, { Component } from 'react'

// import {InputNumber} from '../elements/InputNumber'
import { Combo } from '../elements/Combo'

export class EdificeInfo extends Component {

    handleEdificeInfoChange = (event) => {
        this.props.onChangeEdificeInfo(event.target.name, event.target.value, this.props.simulatorType) 
    }

    render() {

        const { houses, mainAccess, internalPortals, simulatorType } = this.props

        let maxHouses = 100 * (internalPortals === 0 ? 1 : internalPortals) // 100 viviendas por cada portal interno
        let maxMainAccess = 4 // 4 accesos principales máximo
        let maxPortals = mainAccess === 0 ? 25 : 8 // 25 portales si solo hay un
        maxPortals = simulatorType === "edifice" ? 20 : maxPortals

        return (
            <div className = {`grid grid-cols-2 ${simulatorType === "association" && 'sm:grid-cols-3'}`}>
                {/*Accesos principales*/}
                { simulatorType === "association" && 
                    <Combo 
                        name = "mainAccess"
                        label = {`${this.props.t("accesos_principales")}`}
                        value = {mainAccess}
                        onChange = {this.handleEdificeInfoChange}
                        image = {
                            <img src='./principal-access.png' alt={`${this.props.t("accesos_principales")}`} className="w-80 mx-auto"/>
                        }
                        options = {[...Array(maxMainAccess).keys()].map( i => i+1)}
                        t = {this.props.t}
                    />
                }
                {/*Portales internos*/}
                <Combo 
                    name = "internalPortals"
                    label = { simulatorType === "association" ? `${this.props.t("portales_internos")}` : `${this.props.t("portales")}`}
                    value = {internalPortals}
                    onChange = {this.handleEdificeInfoChange}
                    image = {                        
                        <img src='./secondary-access.png' alt={`${this.props.t("accesos_secundarios")}`} className="w-80 mx-auto" />
                    }
                    options = {[...Array(maxPortals).keys()].map( i => i+1)}
                    t = {this.props.t}
                />
                {/*Viviendas totales*/}
                <Combo 
                    name = "houses"
                    label = {`${this.props.t("viviendas_totales")}`}
                    value = {houses}
                    onChange = {this.handleEdificeInfoChange}                    
                    image = {                        
                        <img src='./total-homes.png' alt={`${this.props.t("viviendas_totales")}`} className="w-80 mx-auto" />
                    }
                    options = {[...Array(maxHouses).keys()].map( i => i+1)}
                    t = {this.props.t}
                />                
            </div>
        )
    }
}