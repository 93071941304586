import React, { Component } from 'react'

import { capitalize } from '../../other/Utils'

export class InstalationSummary extends Component {
    render() {
        return (
            <div className="text-xl mt-4 mb-4 bg-gray-50 p-4 rounded">
                <h3 className="text-xl font-semibold uppercase underline mb-2">{this.props.t("resumen_configuracion")}</h3>
                { this.props.simulatorType === "kit" 
                ? 
                    <div className='flex justify-between flex-wrap'>
                        <p><span className="font-semibold uppercase">{this.props.t("tipo")}:</span> {capitalize(this.props.systemType)}</p>
                        <p><span className="font-semibold uppercase">{this.props.t("viviendas")}:</span> {this.props.houses}</p>
                        <p><span className="font-semibold uppercase">{this.props.t("modelo_placa")}:</span> {capitalize(this.props.plateModel)}</p>
                    </div>
                :
                    <div className="flex justify-between flex-wrap text-left">
                        {this.props.reference && <p><span className="font-semibold uppercase">{this.props.t("referencia")}:</span> {this.props.reference}</p>}                    
                        <p><span className="font-semibold uppercase">{this.props.t("tipo")}:</span> {capitalize(this.props.systemType)}</p>
                        {
                            this.props.simulatorType === "association" &&
                                <p><span className="font-semibold uppercase">{this.props.t("accesos_principales")}:</span> {this.props.mainAccess}</p>
                        }
                        <p><span className="font-semibold uppercase">{this.props.t("portales")} {this.props.simulatorType === "association" ? "internos" : ''}:</span> {this.props.internalPortals}</p>
                        <p><span className="font-semibold uppercase">{this.props.t("total_viviendas")}:</span> {this.props.houses}</p>                
                    </div>
                }
            </div>
        )
    }
}