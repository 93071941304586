import { connect } from "react-redux";
import * as edificeActions from '../store/actions/edificeActions';

import {EdificeSimulator} from '../pages/EdificeSimulator'

const mapStateToProps = state => {
    return {
        reference: state.reference,
        step: state.step ,
        systemType: state.systemType ,
        mainAccess: state.mainAccess ,
        internalPortals: state.internalPortals ,
        houses: state.houses ,
        instalationType: state.instalationType ,
        portalInfo: state.portalInfo ,
        mainPlates: state.mainPlates ,
        mainConcierge: state.mainConcierge ,
        conciergeSystemType: state.conciergeSystemType,
        secondaryPlates: state.secondaryPlates ,
        alert: state.alert ,
        accessories: state.accessories,
        budget: state.budget,
        clientName: state.clientName,
        clientAtt: state.clientAtt,
        clientMail: state.clientMail,
        clientDirection: state.clientDirection,
        clientPhone: state.clientPhone,
        clientLogo: state.clientLogo,
        comelitComercial: state.comelitComercial,
        comelitEmail: state.comelitEmail,
        exportType: state.exportType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetReducer: () => dispatch(edificeActions.resetReducer()),
        changeReference: (reference) => dispatch(edificeActions.changeReference(reference)),
        changeStep: (step) => dispatch(edificeActions.changeStep(step)),
        changeSystemType: (type) => dispatch(edificeActions.changeSystemType(type)),
        changeEdificeInfo: (caller, value, simulatorType) => dispatch(edificeActions.changeEdificeInfo(caller, value, simulatorType)),
        changeInstalationType: (type) => dispatch(edificeActions.changeInstalationType(type)),
        changePortalInfo: (portalInfo) => dispatch(edificeActions.changePortalInfo(portalInfo)),
        changeElementPortalInfo: (parentPortal, applyPortals) => dispatch(edificeActions.changeElementPortalInfo(parentPortal, applyPortals)),
        setPortalsNotApplied: () => dispatch(edificeActions.setPortalsNotApplied()),
        changeMainConcierge: (concierge) => dispatch(edificeActions.changeMainConcierge(concierge)),
        changeConciergeSystemType: (conciergeSystem) => dispatch(edificeActions.changeConciergeSystemType(conciergeSystem)),
        changeMainPlates: (plates) => dispatch(edificeActions.changeMainPlates(plates)),
        changeSecondaryPlates: (plates) => dispatch(edificeActions.changeSecondaryPlates(plates)),
        handlePlateApply: (type, parentPlate, applyPlates) => dispatch(edificeActions.handlePlateApply(type, parentPlate, applyPlates)),
        changePlateAssemblyType: (numPlate, type, additional, indexAdditional, assembly) => dispatch(edificeActions.changePlateAssemblyType(numPlate, type, additional, indexAdditional, assembly)),
        addSubPlate: (numPlate, type, additional, indexAdditional) => dispatch(edificeActions.addSubPlate(numPlate, type, additional, indexAdditional)),
        removeSubPlate: (numPlate, type, additional, indexAdditional) => dispatch(edificeActions.removeSubPlate(numPlate, type, additional, indexAdditional)),
        addSlotToSubPlate: (numPlate, numSubPlate, type, additional, indexAdditional, location) => dispatch(edificeActions.addSlotToSubPlate(numPlate, numSubPlate, type, additional, indexAdditional, location)),
        removeSlotOfSubPlate: (numPlate, numSubPlate, type, additional, indexAdditional, location) => dispatch(edificeActions.removeSlotOfSubPlate(numPlate, numSubPlate, type, additional, indexAdditional, location)),
        addItemToSlot: (numPlate, idSubPlate, idSlot, slotContent, originalItem, type, additional, indexAdditional) => dispatch(edificeActions.addItemToSlot(numPlate, idSubPlate, idSlot, slotContent, originalItem, type, additional, indexAdditional)),
        removeItemOfSlot: (numPlate, idSubPlate, idSlot, type, additional, indexAdditional, dropResult) => dispatch(edificeActions.removeItemOfSlot(numPlate, idSubPlate, idSlot, type, additional, indexAdditional, dropResult)),
        addItemToFirstSlot: (numPlate, slotContent, type, additional, indexAdditional, switchLocation) => dispatch(edificeActions.addItemToFirstSlot(numPlate, slotContent, type, additional, indexAdditional, switchLocation)),
        restartPlateDisplay: (numPlate, type, additional, indexAdditional) => dispatch(edificeActions.restartPlateDisplay(numPlate, type, additional, indexAdditional)),
        setPlateVisorValue: (value, numPlate, type, additional, indexAdditional) => dispatch(edificeActions.setPlateVisorValue(value, numPlate, type, additional, indexAdditional)),
        setPlateTrimFrameValue: (value, numPlate, type, additional, indexAdditional) => dispatch(edificeActions.setPlateTrimFrameValue(value, numPlate, type, additional, indexAdditional)),
        changePlateConfigurated: (numPlate, type, additional, indexAdditional, completed, image) => dispatch(edificeActions.changePlateConfigurated(numPlate, type, additional, indexAdditional, completed, image)),
        applyDisplayConfiguration: (type, numPlate, idAdditional, applyPlates) => dispatch(edificeActions.applyDisplayConfiguration(type, numPlate, idAdditional, applyPlates)),
        addPushButtonToSlot: (numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional) => dispatch(edificeActions.addPushButtonToSlot(numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional)),
        addPushButtonToFirstSlot: (numPlate, buttonContent, type, additional, indexAdditional) => dispatch(edificeActions.addPushButtonToFirstSlot(numPlate, buttonContent, type, additional, indexAdditional)),
        restartPlatePushButtons: (numPlate, type, additional, indexAdditional) => dispatch(edificeActions.restartPlatePushButtons(numPlate, type, additional, indexAdditional)),
        addPushButtonToAllPlate: (numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional) => dispatch(edificeActions.addPushButtonToAllPlate(numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional)),
        changeAccessoryQuantity: (articleCode, description, image, link, pvp, relatedArticles, category, quantity) => dispatch(edificeActions.changeAccessoryQuantity(articleCode, description, image, link, pvp, relatedArticles, category, quantity)),
        setBudget: (budget) => dispatch(edificeActions.setBudget(budget)),
        changeDiscount: (articleCode, type, numPlate, target, value) => dispatch(edificeActions.changeDiscount(articleCode, type, numPlate, target, value)),
        changeDiscountToAllProducts: (discountName, value) => dispatch(edificeActions.changeDiscountToAllProducts(discountName, value)),
        restartPlateContents: (indexPlate, type) => dispatch(edificeActions.restartPlateContents(indexPlate, type)),
        changeClientName: (clientName) => dispatch(edificeActions.changeClientName(clientName)),
        changeClientAtt: (clientAtt) => dispatch(edificeActions.changeClientAtt(clientAtt)),
        changeClientMail: (clientMail) => dispatch(edificeActions.changeClientMail(clientMail)),
        changeClientDirection: (clientDirection) => dispatch(edificeActions.changeClientDirection(clientDirection)),
        changeClientPhone: (clientPhone) => dispatch(edificeActions.changeClientPhone(clientPhone)),
        changeClientLogo: (clientLogo) => dispatch(edificeActions.changeClientLogo(clientLogo)),
        changeComelitComercial: (comelitComercial) => dispatch(edificeActions.changeComelitComercial(comelitComercial)),
        changeComelitEmail: (comelitEmail) => dispatch(edificeActions.changeComelitEmail(comelitEmail)),
        changeExportType: (exportType) => dispatch(edificeActions.changeExportType(exportType)),
        addLineToBudget: (budgetLine) => dispatch(edificeActions.addLineToBudget(budgetLine)),
        deleteAditionalLineBudget: (additionalLineIndex) => dispatch(edificeActions.deleteAditionalLineBudget(additionalLineIndex)),
        changeInstallationCost: (installationCost) => dispatch(edificeActions.changeInstallationCost(installationCost))
    }
}

const createConnection = connect(
    mapStateToProps,
    mapDispatchToProps
)

export const EdificeSimulatorContainer = createConnection(EdificeSimulator)