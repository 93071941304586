import React, { Component } from 'react'

export class Collapsible extends Component {    
    state = {collapse:false}

    constructor(props){
        super(props)
        this.state = {collapse: this.props.isCollapse}
    }

    handleClick = () => {
        this.setState({collapse: !this.state.collapse})
    }

    _renderArrow(){
        return (
            this.state.collapse ?
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 justify-self-end" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
            : 
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 justify-self-end" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 15l7-7 7 7" />
                </svg>
        )
    }

    render() {

        const {title, children} = this.props

        return (
            <div>
                <div className="bg-gray-100 p-4 mt-4 flex rounded-md items-center justify-between cursor-pointer" onClick={this.handleClick}>
                    <h2 className="font-semibold text-xl uppercase">{title}</h2>
                    {this._renderArrow()}
                </div>
                <div className={`border-2 rounded-md pt-4 pb-8 pl-6 pr-6 ${this.state.collapse && "hidden" }`}>
                    {children}
                </div>
            </div>
        )
    }
}

Collapsible.defaultProps = {
    isCollapse: false
}