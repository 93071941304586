import React, {useState} from 'react'

export const ButtonScrollDown = () => {

    const [visible, setVisible] = useState(false) 
    
    const toggleVisible = () => { 
      const scrolled = document.documentElement.scrollTop;
      let documentHeight = document.documentElement.scrollHeight
      let windowHeight = window.innerHeight
      if (scrolled + windowHeight >= documentHeight ){ 
        setVisible(false) 
      }  
      else { 
        setVisible(true) 
      } 
    }

    const scrollToBottom = () =>{ 
        window.scrollTo({ 
          top: document.documentElement.scrollHeight, 
          behavior: 'smooth'
        }); 
      }; 

    window.addEventListener('scroll', toggleVisible); 
    
    return (
        <button 
            onClick={scrollToBottom} 
            className="fixed bottom-4 right-6 z-10"
            style={{display: visible ? 'inline' : 'none'}}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 bg-green-comelit bg-opacity-75 text-white" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
        </button>
    )
}