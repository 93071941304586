import React from 'react'
import i18next from 'i18next'
import { languages } from '../../other/constants/constants.js';

export default function LanguageSwitcherSelector(props) {
    
    const changeUserLenguage = (language) => {
        props.setUserLanguage(language)
        i18next.changeLanguage(language)
    }

    // https://iconos8.es/icon/set/flags/color
    // https://www.youtube.com/watch?v=w04LXKlusCQ

    const options = languages.map( language => {
        if (language.code !== props.userLanguage) {
            return <li onClick = {() => changeUserLenguage(language.code)} key={language.code} >
                    <div value = {language.code} className = {`flag ${language.code}`} ></div>
                </li>
        }
        return null
    })

    return (
        <div className="lang"> 
            <div className={`flag ${props.userLanguage}`}></div>
            <ul className="dropdown">
                {options}
            </ul>
        </div>
    )
}