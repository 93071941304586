import React, { Component } from 'react'

import { CheckBoxInput } from '../elements/CheckBoxInput'
import { RadioInput } from '../elements/RadioInput'
import { Separator } from '../elements/Separator'
import { Combo } from '../elements/Combo'
import { Button } from '../elements/Button'
import { cloneDeep } from 'lodash'

import { areSameObjects } from '../../other/Utils'
 
const EMPTY_CONTENT = [{
    idSubPlate: 1,
    subPlateContent: 
    [
        {
            idSlot: 1,
            slotContent: [],
            location: '--'
        }
    ]
}]

export class Plate extends Component {    
    constructor (props){
        super(props)
        this.handleChange = this.handleChange.bind(this)

        let {infoPlate} = this.props
        this.state = {
            plateModel: !infoPlate ? '' : infoPlate.plateModel,
            accessControl: !infoPlate ? false : infoPlate.accessControl,
            controlType: !infoPlate ? '--' : infoPlate.controlType,
            concierge: !infoPlate ? false : infoPlate.concierge,
            conciergeSystemType: !infoPlate ? "--" : infoPlate.conciergeSystemType,
            aditionalAccess: !infoPlate ? false : infoPlate.aditionalAccess,
            numAditionalAccess: !infoPlate ? 0 : infoPlate.numAditionalAccess,
            applyConfiguration: !infoPlate ? false : infoPlate.applyConfiguration,
            modalApplyVisible: !infoPlate ? false : infoPlate.modalApplyVisible,
            assembly: !infoPlate ? "--" : infoPlate.assembly,
            numSubPlates: !infoPlate ? 1 : infoPlate.numSubPlates,
            configurationComplete: !infoPlate ? false : infoPlate.configurationComplete,
            image: !infoPlate ? "--" : infoPlate.image,
            visor: !infoPlate ? false : infoPlate.visor,
            trimFrame: !infoPlate ? false : infoPlate.trimFrame,
            contents: !infoPlate ? EMPTY_CONTENT : cloneDeep(infoPlate.contents) ,
            additionalContents: !infoPlate ? [] : cloneDeep(infoPlate.additionalContents),
            applyPlates: []
        } 
        this.props.onChangePlate(this.state, this.props.numPlate)
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!areSameObjects(prevState, this.state)){
            this.props.onChangePlate(this.state, this.props.numPlate, this.state.applyConfiguration)
            return
        }
        if (this.props.infoPlate && !areSameObjects(this.props.infoPlate, this.state)){
            this.setState({
                plateModel: this.props.infoPlate.plateModel,
                accessControl: this.props.infoPlate.accessControl,
                controlType: this.props.infoPlate.controlType,
                concierge: this.props.infoPlate.concierge,
                conciergeSystemType: this.props.infoPlate.conciergeSystemType,
                aditionalAccess: this.props.infoPlate.aditionalAccess,
                numAditionalAccess: this.props.infoPlate.numAditionalAccess,
                applyConfiguration: this.props.infoPlate.applyConfiguration,
                modalApplyVisible: this.props.infoPlate.modalApplyVisible,
                assembly: this.props.infoPlate.assembly,
                numSubPlates: this.props.infoPlate.numSubPlates,
                configurationComplete: this.props.configurationComplete,
                image: this.props.infoPlate.image,
                visor: this.props.infoPlate.visor,
                trimFrame: this.props.infoPlate.trimFrame,
                contents: this.props.infoPlate.contents,
                additionalContents: cloneDeep(this.props.infoPlate.additionalContents),
                applyPlates: this.props.infoPlate.applyPlates
            })
        }
    }

    changeConcierge = (event) => {
        let concierge = event.target.checked
        let conciergeSystemType = ( this.props.systemType === "audio" && concierge ) ? "audio" : this.state.conciergeSystemType
        conciergeSystemType = !concierge ? "--" : conciergeSystemType
        // Comprobamos si hay conserjería y sistema video, ponemos a vacío para elegir
        conciergeSystemType = concierge && this.props.systemType === "video" && conciergeSystemType === "--" ? "" : conciergeSystemType

        this.setState({
            concierge: concierge,
            conciergeSystemType: conciergeSystemType
        })
    }

    changeConciergeSystemType = (event) => {
        this.setState({
            conciergeSystemType: event.target.name.substring(0,5)
        })
    }

    handleChange = (caller) => (e) => {
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value
        
        if (this.state.applyConfiguration){
            this.setState({
                applyConfiguration: false
            })
        }

        let numAditionalAccess = this.state.numAditionalAccess
        if (caller === 'aditionalAccess'){
            numAditionalAccess = value ? 1 : 0
        }
        
        numAditionalAccess = caller === "numAditionalAccess" ? value : numAditionalAccess
        
        // Modificamos los accesos adicionales
        let newAdditional = this.state.additionalContents
        if (numAditionalAccess > 0 && this.state.additionalContents.length !== numAditionalAccess){
            newAdditional = []
            for (let i = 1; i <= numAditionalAccess; i++) {
                newAdditional.push(
                    {
                        idAdditional: i,
                        image: '--',
                        configurationComplete: false,
                        numSubPlates: 1,
                        assembly: '--',
                        visor: false,
                        trimFrame: false,
                        contents: EMPTY_CONTENT
                    }
                )                
            }
        }

        // Si tenemos más accesos adicionales de los marcados, los quitamos
        while (newAdditional.length > numAditionalAccess) {
            newAdditional.pop()
        }
        
        // Si el caller es accessControl y va desmarcado, quitamos controlType
        let controlType = caller === "controlType" ? value : this.state.controlType
        if (caller === "accessControl")
            controlType = value ? '' : '--'

        // Reiniciamos los contenidos si se cambia MODELO DE PLACA o CONTROL DE ACCESOS
        let { contents, configurationComplete, image, visor, trimFrame } = this.state        
        if (["plateModel", "accessControl", "controlType"].includes(caller)){
            contents = EMPTY_CONTENT
            configurationComplete = false
            image = "--"
            visor = false
            trimFrame = false
            newAdditional.forEach(additional => {
                additional.contents = EMPTY_CONTENT
                additional.configurationComplete = false
                additional.image = "--"
                additional.visor = false
                additional.trimFrame = false
            });
        }
        
        this.setState({
            [caller]: value,
            contents: contents,
            configurationComplete: configurationComplete,
            image: image,
            visor: visor,
            trimFrame: trimFrame,
            controlType: controlType,
            additionalContents: cloneDeep(newAdditional),
            numAditionalAccess: numAditionalAccess
        })
    }

    handleApplyOtherPlates = () => {        
        this.setState({
            applyConfiguration: !this.state.applyConfiguration,
            modalApplyVisible: !this.state.modalApplyVisible
        })
    }

    savePlatesApply = (id) => (event) => {        
        if (event.target.checked){
            let newPlatesApply = [...this.state.applyPlates ]
            newPlatesApply.push(id)
            this.setState({
                applyPlates: newPlatesApply
            })
        }
        else{
            this.setState({
                applyPlates: this.state.applyPlates.filter( 
                    (plate) => {
                        return plate !== id;
                    })
            })

        }
    }

    handleApply = () => {
        
        this.props.handlePlateApply( this.props.type, this.state, this.state.applyPlates )

        this.setState({ 
            modalApplyVisible: false,
            applyPlates: []
        })
    }

    renderModalApply = (numPlate) => {
        let platesApply = []
        for (let i = 0; i < this.props.plates.length; i++) {
            i !== numPlate &&
                platesApply.push(
                    <CheckBoxInput 
                        key = {i}
                        name = {`applyPlate${i}`}
                        label = {`${this.props.t("placa")} ${i+1}`}
                        onChange = { this.savePlatesApply(i)}
                        checked = { this.state.applyPlates.includes(i) }
                    />
                )
        }

        return(
            <div>
                <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex items-center justify-center">
                    <div className="bg-white max-h-5/6 overflow-y-auto z-30 mt-4 mb-4 relative pt-6 pb-6 pl-4 pr-4 md:p-14 rounded mr-4 ml-4">
                        <div className='absolute cursor-pointer right-2 top-2'
                            onClick={() => this.setState({modalApplyVisible:false})}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>

                        <div className="text-center text-xl uppercase font-semibold">{this.props.type==="main" ? `${this.props.t("configuracion_placa_principal", {number_plate:numPlate+1})}` : `${this.props.t("configuracion_placa_secundaria", {number_plate:numPlate+1})}`}</div>
                        <Separator />
                        <div className="text-xl font-semibold mt-4">{this.props.t("aplicar_a")}:</div>
                        {platesApply}
                        <div className="text-center mt-6">
                            <Button 
                                type = 'normal' 
                                text = {`${this.props.t("aceptar")}`}
                                onClick = {() => this.handleApply() }
                                className = 'p-2 pl-6 pr-6'
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        
        let {totalPlates, numPlate, type, homes, simulatorType, switchDisabled} = this.props        
        let {plateModel, accessControl, controlType, aditionalAccess, numAditionalAccess } = this.state

        let applyAllDisabled = !( totalPlates > 1 && plateModel !== '' && ((accessControl && controlType !== '--') || !accessControl ))
        let auxTitle = ''
        if (type === "secondary" && homes)
            auxTitle = ` - ${homes} ` + (homes > 1 ? `${this.props.t("viviendas")}` : `${this.props.t("vivienda")}`)

        let modal = this.state.modalApplyVisible === true && this.renderModalApply(this.props.numPlate)

        return (
            <div>
                {modal}
                <div className="bg-gray-50 mt-4 mb-4 p-4">                
                    <p className="text-center text-lg font-semibold uppercase">{simulatorType === "association" ? `${this.props.t("placa")}` : `${this.props.t("portal")}`} Nº {numPlate+1}{auxTitle}</p>
                    {/* Tipo de pulsador */}
                    <div className="sm:grid sm:grid-cols-3 sm:gap-x-2">
                        <RadioInput
                            name = {`ultra${numPlate}-${type}`}
                            value = 'ultra'
                            image = {
                                <img src = "../../logo-ultra.png" alt={`${this.props.t("logo")} ULTRA"`} className="w-28 mx-auto" />
                            }
                            className = "w-1"
                            onChange = {this.handleChange('plateModel')}
                            checked = {plateModel === 'ultra'}
                        />
                        <RadioInput
                            name = {`switch${numPlate}-${type}`}
                            value = 'switch'
                            image = {
                                <img src = "../../logo-switch.png" alt={`${this.props.t("logo")} SWITCH"`} className={`w-28 mx-auto ${ switchDisabled ? 'opacity-50' : ''}`} />
                            }
                            className = "w-1"
                            onChange = {this.handleChange('plateModel')}
                            checked = {plateModel === 'switch'}
                            disabled = { switchDisabled }
                            onClick = { () => { switchDisabled && this.props.changeShowAlert()}}
                            tooltip = { switchDisabled ? this.props.tooltipSwitch : null}
                        />
                        <RadioInput
                            name = {`vandalcom${numPlate}-${type}`}
                            value = 'vandalcom'
                            image = {
                                <img src = {`../../logo-vandalcom.png`} alt={`${this.props.t("logo")} VANDALCOM"`} className="w-28 mx-auto" />
                            }
                            className = "w-1"
                            onChange = {this.handleChange('plateModel')}
                            checked = {plateModel === 'vandalcom'}
                        />
                    </div>
                    
                    {type === "secondary" &&
                        <div>
                        
                            <Separator type="mobile"/>

                            {/* Accesos adicionales */}
                            <CheckBoxInput 
                                name = {`aditionalAccess${numPlate}-${type}`}
                                label = {this.props.t("acceso_adicionales")}
                                image = {
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" viewBox="0 0 20 20" fill="#388244">
                                        <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
                                    </svg>
                                }
                                onChange = {this.handleChange('aditionalAccess')}
                                checked = {aditionalAccess}
                            />
                            {aditionalAccess && 
                                <Combo 
                                    key = {`numAditionalAccess${numPlate}-${type}`}
                                    dataKey = {`numAditionalAccess`}
                                    name = "numAditionalAccess"
                                    label = {`${this.props.t("numero_accesos_adicionales")}`}
                                    secondLabel = ""
                                    onChange = {this.handleChange('numAditionalAccess')}
                                    value = {numAditionalAccess}
                                    options = {[...Array(3).keys()].map( i => i+1)}
                                    t = {this.props.t}                                    
                                />
                            }
                        </div>
                    }
                    
                    <Separator type="mobile"/>
                    
                    {/* Control de accesos */}
                    <CheckBoxInput 
                        name = "accessControl"
                        label = {`${this.props.t("control_accesos")}`}
                        image = {
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" viewBox="0 0 20 20" fill="#388244">
                                <path fillRule="evenodd" d="M6.625 2.655A9 9 0 0119 11a1 1 0 11-2 0 7 7 0 00-9.625-6.492 1 1 0 11-.75-1.853zM4.662 4.959A1 1 0 014.75 6.37 6.97 6.97 0 003 11a1 1 0 11-2 0 8.97 8.97 0 012.25-5.953 1 1 0 011.412-.088z" clipRule="evenodd" />
                                <path fillRule="evenodd" d="M5 11a5 5 0 1110 0 1 1 0 11-2 0 3 3 0 10-6 0c0 1.677-.345 3.276-.968 4.729a1 1 0 11-1.838-.789A9.964 9.964 0 005 11zm8.921 2.012a1 1 0 01.831 1.145 19.86 19.86 0 01-.545 2.436 1 1 0 11-1.92-.558c.207-.713.371-1.445.49-2.192a1 1 0 011.144-.83z" clipRule="evenodd" />
                                <path fillRule="evenodd" d="M10 10a1 1 0 011 1c0 2.236-.46 4.368-1.29 6.304a1 1 0 01-1.838-.789A13.952 13.952 0 009 11a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                        }
                        onChange = {this.handleChange('accessControl')}
                        checked = {accessControl}
                    />
                    {accessControl && 
                        <div className="sm:flex sm:justify-around sm:items-center">
                            <RadioInput
                                name = {`basic${numPlate}-${type}`}
                                value = 'basic'
                                label = {'Basic'}
                                image = {
                                    <img className="w-20 mx-auto" src='./control-basic.png' alt={`${this.props.t("control_accesos")} BASIC`} />
                                }
                                className = "w-1"
                                onChange = {this.handleChange('controlType')}
                                checked = {controlType === 'basic'}
                            />
                            <RadioInput
                                name = {`advance${numPlate}-${type}`}
                                value = 'advance'
                                label = {'Advance'}
                                image = {
                                    <img className="w-20 mx-auto" src='./control-advance.png' alt={`${this.props.t("control_accesos")} ADVANCE`} />
                                }
                                className = "w-1"
                                onChange = {this.handleChange('controlType')}
                                checked = {controlType === 'advance'}
                            />
                            <RadioInput
                                name = {`$${numPlate}-${type}`}
                                value = 'teclado'
                                label = {`${this.props.t("teclado")}`}
                                image = {
                                    <img className="w-20 mx-auto" src='./control-teclado.png' alt={`${this.props.t("control_accesos_teclado")}`} />
                                }
                                className = "w-1"
                                onChange = {this.handleChange('controlType')}
                                checked = {controlType === 'teclado'}
                            />
                        </div>
                    }
                    {/* Conserjería */}
                    {
                        simulatorType === "edifice" &&
                            <div>
                                <Separator type="mobile"/>
                                <CheckBoxInput 
                                    name = "mainConcierge"
                                    label = {this.props.t("conserjería")}
                                    onChange = { this.changeConcierge }
                                    checked = {this.state.concierge}
                                />
                                {this.state.concierge && this.props.systemType === "video" &&
                                    <div className="grid grid-cols-2 gap-4 sm:items-center">
                                        <RadioInput
                                            name = {`video${numPlate}`}
                                            label = {`${this.props.t("video")}`}
                                            checked = {this.state.conciergeSystemType === 'video'}
                                            image = {
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" viewBox="0 0 20 20" fill="#388244">
                                                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z" />
                                                </svg>
                                            }
                                            onChange = {this.changeConciergeSystemType}
                                            className = "w-1"
                                        />
                                        <RadioInput
                                            name = {`audio${numPlate}`}
                                            label = {`${this.props.t("audio")}`}
                                            checked = {this.state.conciergeSystemType === 'audio'}
                                            image = {
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" viewBox="0 0 20 20" fill="#388244">
                                                    <path fillRule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clipRule="evenodd" />
                                                </svg>
                                            }
                                            onChange = {this.changeConciergeSystemType}
                                        />
                                    </div>
                                }
                            </div>
                    }
                      
                    {totalPlates > 1 &&
                        <div>
                            <Separator />

                            <div 
                                className="col-span-2 mt-2 text-center"
                            >
                                <Button 
                                    className = ""
                                    name = "applyConfiguration"
                                    text = {`${simulatorType === "association" ? (type === 'main' ? `${this.props.t("aplicar_placas_principales")}` : `${this.props.t("aplicar_placas_secundarias")}`) : `${this.props.t("aplicar_otros_portales")}`}`}
                                    onClick = { () => this.handleApplyOtherPlates() }
                                    disabled = { applyAllDisabled }
                                />
                            </div>
                        </div>    
                    }
                </div>
            </div>             
        )
    }
}