import { useDrag, useDrop } from "react-dnd";
import { useState } from "react";
import { ItemTypes } from '../../other/ItemTypes'

import { Button } from "./Button";

const styleUltraEmbedded = {
    height: '38px'
};

const styleUltraSurface = {
    height: '45px'
};

const renderModalApply = (item, props, setItem, setModal) => {
    return(
        <div>
            <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-screen overflow-y-auto bg-gray-500 bg-opacity-75 bg-op">
                <div className="relative z-30 pt-6 pb-6 pl-4 pr-4 mt-4 mb-4 ml-4 mr-4 overflow-y-auto bg-white rounded max-h-5/6 md:p-14">
                    <div className='absolute cursor-pointer right-2 top-2'
                        onClick={ () => addPushButton(false, item, props, setItem, setModal) } >
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <div className="text-xl font-semibold text-center uppercase">{props.t("anhadir_pulsador")}</div>
                    <div className="mt-2 text-lg font-semibold text-center">{props.t("anhadir_pulsador_resto")}</div>
                    <div className="mt-6 text-center">
                        <Button 
                            type = 'normal' 
                            text = {`${props.t("si")}`}
                            onClick = { () => addPushButton(true, item, props, setItem, setModal) }
                            className='uppercase w-28'
                        />
                        <Button 
                            type = 'secondary' 
                            text = {`${props.t("no")}`}
                            onClick = { () => addPushButton(false, item, props, setItem, setModal) }
                            className='mt-2 uppercase sm:mt-0 w-28' 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const emptySpacesPlate = (plateInfo, additional, indexAdditional) => {
    let emptySpaces = 0
    
    let plateContents = additional 
        ? plateInfo.additionalContents[indexAdditional].contents
        : plateInfo.contents

    for (let i = 0; i < plateContents.length; i++) {
        let subPlateContent = plateContents[i].subPlateContent
        for (let j = 0; j < subPlateContent.length; j++) {
            let buttonsContent = subPlateContent[j].slotContent.buttonsContent;
            for (let k = 0; k < (buttonsContent ? buttonsContent.length : 0); k++) {
                let buttonContent = buttonsContent[k]
                if (buttonContent.articleCode === "--") 
                    emptySpaces += 1        
            }
        }
    }
    return emptySpaces > 1
}

const addPushButton = (allModule, item, props, setItem, setModal) => {
    
    let { numPlate, idSubPlate, idSlot, type, additional, indexAdditional, idButton, location } = props
     
    let newButtonContent = {
        idButton: idButton,
        location: location,
        articleCode: item.articleCode,
        deepLink: item.deepLink,
        src: item.src,
        eanCode: item.eanCode,
        pvp: item.pvp,
        relatedArticles: item.relatedArticles,
        numCalls: item.numCalls,
        itemType: item.itemType,
        description: item.description,
        toolTip: item.toolTip,
        idCategory: item.idCategory,
        category: item.category
    }

    allModule 
        ? props.addPushButtonToAllPlate(numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional)
        : props.addPushButtonToSlot(numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional)
        
    setItem(null)
    setModal(false)
}

export const PushButton = function PushButton(props) {
    
    let { src, position, plateInfo, idButton, articleCode, deepLink, eanCode, description, toolTip, idCategory, category, pvp, relatedArticles, numCalls, location } = props
    let { numPlate, idSubPlate, idSlot, type, additional, indexAdditional, plateModel, assembly } = props
    let { addPushButtonToSlot, addPushButtonToFirstSlot } = props

    const [modal, setModal] = useState(false);
    const [item, setItem] = useState(null);

    // Solo dejamos drag en panel de añadir
    let canDrag = location === "simulator"
    let pushButtonItem = { idButton, articleCode, src, deepLink, eanCode, description, toolTip, idCategory, category, pvp, relatedArticles, numCalls, location }

    const [collectedProps, drop] = useDrop(() => ({
        accept: [ItemTypes.PUSH_BUTTON],
        drop(item, monitor){
            // const didDrop = monitor.didDrop();
            if ( emptySpacesPlate(plateInfo, additional, indexAdditional)) {
                setItem(item)
                setModal(true)
            }
            else if ( item && location !== "simulator" ){
                // buttonContent
                let newButtonContent = {
                    ...item,
                    idButton: idButton,
                    location: location
                }
                addPushButtonToSlot(numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional)
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            endDrag: monitor.getDropResult(),
            didDrop: monitor.didDrop()
        }),
    }), [props]);

    let [{ isDragging }, drag ] = useDrag(() => ({
        type: ItemTypes.PUSH_BUTTON,
        item: pushButtonItem,  
        // canDrag: location === "simulator",
        canDrag: canDrag,
        end: (item, monitor) => {
            // const dropResult = monitor.getDropResult();
            if (item && location !== "simulator") {
                if (numPlate && idSubPlate && idSlot) {
                    // removePushButtonOfSlot(numPlate, idSubPlate, idSlot, type, additional, indexAdditional, dropResult ? dropResult.dropEffect : null)
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
            canDrag: monitor.canDrag()
        }),
    }), [ location ] );

    const opacity = isDragging ? 0.4 : 1;

    let {canDrop, isOver} = collectedProps
    const isActive = canDrop && isOver;
    let backgroundColor = 'transparent';
    if (isActive) {
        backgroundColor = '#e9e9e94f';
    }

    let style = {}
    if (plateModel==="ultra"){
        style = assembly==="embedded" ? {...styleUltraEmbedded} : {...styleUltraSurface}
    }
    if (isOver){
        style.outline = '2px solid #009440'
    }

    let renderedModal = ''
    if ( modal )
        renderedModal = renderModalApply( item, props, setItem, setModal )        

    return (   
        <div>
            { renderedModal }      
            <div className = { location !== 'simulator' ? `absolute w-full ${position}` : '' } ref={ location !== 'simulator' ? drop : null } style={{ ...style, backgroundColor }}>
                <img 
                    ref = { location === 'simulator' ? drag : null}
                    style = {{opacity}}
                    src = {src}
                    alt = { src !== "--" ? `${props.t("imagen_producto")} ${articleCode}` : '' }
                    title = { toolTip }
                    className = {`${location === 'simulator' ? 'w-24 max-w-1/2 mx-auto p-2 cursor-move' : ''}`}
                    onDoubleClick = { () => addPushButtonToFirstSlot( numPlate, pushButtonItem, type, additional, indexAdditional ) }
                />
            </div>
        </div>
    );    
};


