import { combineReducers } from 'redux'

import userAuthReducer from './userAuthReducer'
import { edificeReducer } from './edificeReducer'
import { kitReducer } from './kitReducer'

const rootReducer = combineReducers({
    userAuth: userAuthReducer,
    edifice: edificeReducer,
    kit: kitReducer
})

export default rootReducer