import React from 'react';

import { Text, View, Image, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import { getTableBudgetTitle } from '../../../other/Utils';

import fontThin from '../../../fonts/ttf/HelveticaNeueLTW1G-Th.ttf'
import fontBold from '../../../fonts/ttf/HelveticaNeueLTW1G-Bd.ttf'
import fontBlack from '../../../fonts/ttf/HelveticaNeueLTW1G-Blk.ttf'
import fontMedium from '../../../fonts/ttf/HelveticaNeueLTW1G-Md.ttf'

Font.register({
    family: 'Helvetica Neue',
    fonts: [
        { src: fontThin, fontWeight: 'thin' },
        { src: fontBold, fontWeight: 'bold' },
        { src: fontBlack, fontWeight: 'heavy' },
        { src: fontMedium, fontWeight: 'medium' },
    ]
  })

const styles = StyleSheet.create({
    fontLight:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'light' 
    },
    fontBold:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'bold' 
    },
    title:{
        color: '#009640',
        fontSize: '14',
        padding: '5px 10px 5px 10px',
        fontFamily: 'Helvetica Neue',
        fontWeight: 'bold',
        textAlign: 'center'
    },    
    imagesContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-between',
        flexWrap: 'wrap'
    },
    content: {
        flexBasis: '45%',
        padding: '20px'
    },
    image:{  
        width: 'auto',
        height: 'auto',
        maxWidth: '100%',
        maxHeight: '400px',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
})

const PlatesDisplay = (props) => {
    let { type, plates, portalInfo, simulatorType } = props
    
    let title = ''
    if (simulatorType === "association")
        title = type === "main" ? `${props.t("placas_principales")}` : `${props.t("placas_secundarias")}`
    if (simulatorType === "edifice")
        title = type === "main" ? `${props.t("accesos_principales")}` : `${props.t("portales")}`
        
    let content = []

    for (let i = 0; i < plates.length; i++) {
        let plate = plates[i];
        if (plate.image){
            content.push(
                <View style={ styles.content } key={i}>
                    <Text style={[styles.fontLight , {marginTop:'5px', marginBottom:'5px'}]}>{ getTableBudgetTitle(type, i+1, portalInfo, simulatorType, i, props.t) }</Text>
                    <Image style={styles.image} src={plate.image}/>
                </View>
            )
        }
        
        if (plate.additionalContents.length && plate.additionalContents.length > 0){
            for (let j = 0; j < plate.additionalContents.length; j++) {
                let additionalPlate = plate.additionalContents[j]
                content.push(
                    <View style={ styles.content } key={`${i}A`}>
                        <Text style={[styles.fontLight , {marginTop:'5px', marginBottom:'5px'}]}>{ getTableBudgetTitle(type, `${i+1}-A`, portalInfo, simulatorType, i, props.t) }</Text>
                        <Image style={styles.image} src={additionalPlate.image}/>
                    </View>
                )                
            }
        }
    }

    return (
        <View>
            <Text style={[styles.fontBold, {textAlign: 'center'}]} wrap={false}>{title}</Text>
            <View style={styles.imagesContainer}>
                {content}
            </View>
        </View>
    )
}

const PDFPlatesBudget = ( props ) => {
    return (
        <View style={{ width: '100%' }} break>
            <Text style={styles.title}>{props.t("placas_configuradas")}</Text>
            {
                (props.mainPlates && props.mainPlates.length > 0) &&
                    <PlatesDisplay type="main" plates={props.mainPlates} portalInfo={props.portalInfo} simulatorType={props.simulatorType} t={props.t} />
            }
            {
                (props.secondaryPlates && props.secondaryPlates.length > 0) &&
                    <PlatesDisplay type="secondary" plates={props.secondaryPlates} portalInfo={props.portalInfo} simulatorType={props.simulatorType} t={props.t} />
            }
        </View>
    )
}

export default PDFPlatesBudget