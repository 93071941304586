import React, { Component } from 'react'

export class InputText extends Component {
    
    updateValue = (event) => {
        this.props.onChangeText(event.target.value)
    }

    render() {

        const { name, label, secondLabel, image, value, dataKey } = this.props        

        return (
            <div className={`mt-4 text-lg`}>
                {image}
                <label className = {`items-center cursor-pointer block text-center`} htmlFor={name}> 
                    <span className="font-semibold">
                        {label}
                    </span>
                    <input 
                        id = {name}
                        name = {name}
                        type = "text"
                        onChange = {this.updateValue}
                        className = {`ml-2 bg-gray-100 mx-auto w-9/12 rounded-md text-left pl-2 pr-2 pt-1 pb-1`}
                        value = {value}
                        data-key = {dataKey}
                    />
                    {secondLabel}
                </label>
            </div>
        )
    }
}