import { useDrag } from "react-dnd";
import { ItemTypes } from '../../other/ItemTypes'
import { PushButtonContainer } from "./PushButtonContainer";

export const ModuleItem = function ModuleItem(props) {
    
    let { articleCode, eanCode, description, toolTip, idCategory, category,  deepLink, pvp, relatedArticles, numCalls, numColumns, src, location, buttonsContent, switchLocation } = props
    let { numPlate, idSubPlate, idSlot, type, itemType, additional, indexAdditional, assembly, plateModel } = props
    let { addPushButtonToSlot, removeItemOfSlot, addItemToFirstSlot, addPushButtonToFirstSlot, addPushButtonToAllPlate } = props

    // Calculamos el número de pulsadores posible
    let pushButtons = 0
    if (itemType === ItemTypes.MODULE_PUSH_BUTTON){
        pushButtons = 1
    }
    if (itemType === ItemTypes.MODULE_FULL_BUTTONS){
        pushButtons = 4
    }
    
    let moduleItem = {
        articleCode, eanCode, deepLink, pvp, relatedArticles, numCalls, numColumns, src, location, itemType, pushButtons, buttonsContent, toolTip, idCategory, category, description
    }

    let emptyPushButton = {        
        idButton: 1, 
        articleCode: "--", 
        src: "--", 
        deepLink: "--",
        eanCode: "--", 
        description: "--", 
        toolTip: "--", 
        idCategory: "--",
        category: "--",
        pvp: "--",
        relatedArticles: "--",
        numCalls: '--',
        numColumns: '--'
    }

    let [{ isDragging }, drag ] = useDrag(() => ({
        type: itemType,
        item: moduleItem,  
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();

            if (item && location !== "simulator") {
                if (numPlate && idSubPlate && idSlot) {
                    removeItemOfSlot(numPlate, idSubPlate, idSlot, type, additional, indexAdditional, dropResult ? dropResult.dropEffect : null)
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    }), [props]);

    const opacity = isDragging ? 0.4 : 1;

    // let previewImage = new Image(96, 96);
    // previewImage.src = src;
    // <DragPreviewImage connect={preview} src={previewImage.src}/>

    let renderPushButtonsContainer = []
    if (location !== "simulator" && pushButtons > 0){
        if (!buttonsContent.length){
            for (let i = 0; i < pushButtons; i++) {
                buttonsContent.push({
                    ...emptyPushButton,
                    idButton: i+1
                })
            }
        }

        renderPushButtonsContainer.push(
            <PushButtonContainer 
                key = { 1 }
                numPlate = { numPlate } 
                idSubPlate = { idSubPlate } 
                idSlot = { idSlot } 
                additional = { additional } 
                indexAdditional = { indexAdditional } 
                plateInfo = { props.plateInfo }
                type = { type } 
                itemType = { itemType }
                numPushButtons = { pushButtons }
                assembly = { assembly } 
                plateModel = { plateModel } 
                buttonsContent = { buttonsContent } 
                addPushButtonToSlot = { addPushButtonToSlot }
                addPushButtonToAllPlate = { addPushButtonToAllPlate }
                addPushButtonToFirstSlot = { addPushButtonToFirstSlot }
                t = {props.t}
            />
        )
    }
    
    let style = {}
    if (location !== 'simulator' && plateModel !== "switch"){
        style.outline = '2px solid rgb(137 137 137)'
    }

    return (
        <div className="relative">
            <img 
                ref = {drag}
                style = {{...style, opacity}}
                src = {src}
                alt = {`${props.t("imagen_producto")} ${articleCode}` }
                title = { toolTip }
                className = {`cursor-move ${location === 'simulator' ? 'w-24 max-w-1/2 mx-auto p-2' : ''}`}
                // draggable = "true"
                onDoubleClick = { () => location === "simulator" && addItemToFirstSlot( numPlate, moduleItem, type, additional, indexAdditional, switchLocation ) }
                // onClick = { () => addItemToFirstSlot( numPlate, {id: id, src: src}, type ) }
            />
            { renderPushButtonsContainer }
        </div>
    );    
};