import React, { Component } from 'react'
import Select from 'react-select';

export class Combo extends Component {
    
    handleChange = (selectedOption) => {
        let event = {
            target : {
                name: this.props.name ,
                value: selectedOption.value,
                dataKey: this.props.dataKey
            }
        }
        this.props.onChange(event)
    }

    render() {

        const { name, label, secondLabel, image, value, options } = this.props
        
        let optionsSelect = []
        options.forEach(opt => {
            optionsSelect.push(
                {
                    value: opt,
                    label: opt
                }
            )
        });

        return (
            <div className={`mt-4 text-lg`}>
                <label className = {`items-center cursor-pointer block text-center`} htmlFor = { name }> 
                    {image}
                    <div className = "flex flex-wrap items-center justify-center mt-2">
                        <div className="mr-2">
                            {label}                             
                        </div>
                        <Select
                            // defaultInputValue = { 1 }
                            onChange = { this.handleChange }
                            options = { optionsSelect }
                            value = { 
                                optionsSelect.filter( (option) => {
                                    return option.value === value;
                                })
                            }
                            placeholder = ''
                            className = {"w-24 h-12"}
                            id = { name }
                            name = { name }
                            noOptionsMessage={() => `${this.props.t("opcion_no_disponible")}`}
                        />
                        <div className="ml-2">
                            {secondLabel}                         
                        </div>
                    </div>
                </label>
            </div>
        )
    }
}