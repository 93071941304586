export function resetReducer(){
    return {
        type: 'RESET_REDUCER'
    }
}

export function changeStep(step){
    return {
        type: 'CHANGE_STEP',
        payload: step
    }
}

export function changeReference(reference){
    return {
        type: 'CHANGE_REFERENCE',
        payload: reference
    }
}

export function changeSystemType(systemType){
    return {
        type: 'CHANGE_SYSTEM_TYPE',
        payload: systemType
    }
}

export function changeEdificeInfo(caller, value, simulatorType){
    if (caller === "mainAccess") {
        return {
            type: 'CHANGE_MAIN_ACCESS',
            payload: value
        }
    }
    if (caller === "internalPortals") {
        return {
            type: 'CHANGE_INTERNAL_PORTALS',
            payload: value
        }
    }
    if (caller === "houses") {
        return {
            type: 'CHANGE_HOUSES',
            payload: {value, simulatorType}
        }
    }
}

export function changeInstalationType(type){
    return {
        type: 'CHANGE_INSTALATION_TYPE',
        payload: type
    }
}

export function changePortalInfo(portalInfo){
    return {
        type: 'CHANGE_PORTAL_INFO',
        payload: portalInfo
    }
}

export function changeElementPortalInfo(parentPortal, applyPortals){
    return {
        type: 'CHANGE_ELEMENT_PORTAL_INFO',
        payload: {parentPortal, applyPortals}
    }
}

export function setPortalsNotApplied(){
    return {
        type: 'SET_PORTALS_NOT_APPLIED'
    }
}

export function changeMainConcierge(event){
    return {
        type: 'CHANGE_MAIN_CONCIERGE',
        payload: event.target.checked
    }
}

export function changeConciergeSystemType(conciergeSystem){
    return {
        type: 'CHANGE_CONCIERGE_SYSTEM_TYPE',
        payload: conciergeSystem
    }
}

export function changeMainPlates(plates){
    return {
        type: 'CHANGE_MAIN_PLATES',
        payload: plates
    }
}

export function changeSecondaryPlates(plates){
    return {
        type: 'CHANGE_SECONDARY_PLATES',
        payload: plates
    }
}

export function handlePlateApply(type, parentPlate, applyPlates){
    return {
        type: 'HANDLE_PLATE_APPLY',
        payload: { type, parentPlate, applyPlates }
    }
}

export function changePlateAssemblyType(numPlate, type, additional, indexAdditional, assembly){
    return {
        type: 'CHANGE_PLATE_ASSEMBLY',
        payload: { numPlate, type, additional, indexAdditional, assembly }
    }
}

export function addSubPlate(numPlate, type, additional, indexAdditional){
    return {
        type: 'ADD_SUBPLATE',
        payload: { numPlate, type, additional, indexAdditional }
    }
}

export function removeSubPlate(numPlate, type, additional, indexAdditional){
    return {
        type: 'REMOVE_SUBPLATE',
        payload: { numPlate, type, additional, indexAdditional }
    }
}

export function addSlotToSubPlate(numPlate, numSubPlate, type, additional, indexAdditional, location = '--'){
    return {
        type: 'ADD_SLOT_SUBPLATE',
        payload: { numPlate, numSubPlate, type, additional, indexAdditional, location }
    }
}

export function removeSlotOfSubPlate(numPlate, numSubPlate, type, additional, indexAdditional, location = '--'){
    return {
        type: 'REMOVE_SLOT_SUBPLATE',
        payload: { numPlate, numSubPlate, type, additional, indexAdditional, location }
    }
}

export function addItemToSlot(numPlate, idSubPlate, idSlot, slotContent, originalItem, type, additional, indexAdditional, plateInfo = null){
    return {
        type: 'ADD_ITEM_SLOT',
        payload: { numPlate, idSubPlate, idSlot, slotContent, originalItem, type, additional, indexAdditional }
    }
}

export function removeItemOfSlot(numPlate, idSubPlate, idSlot, type, additional, indexAdditional, dropResult){
    return {
        type: 'REMOVE_ITEM_SLOT',
        payload: { numPlate, idSubPlate, idSlot, type, additional, indexAdditional, dropResult }
    }
}

export function addItemToFirstSlot(numPlate, slotContent, type, additional, indexAdditional, switchLocation){
    return {
        type: 'ADD_ITEM_FIRST_SLOT',
        payload: { numPlate, slotContent, type, additional, indexAdditional, switchLocation }
    }
}

export function restartPlateDisplay(numPlate, type, additional, indexAdditional){
    return {
        type: 'RESTART_PLATE_DISPLAY',
        payload: { numPlate, type, additional, indexAdditional }
    }
}

export function setPlateVisorValue(value, numPlate, type, additional, indexAdditional){
    return {
        type: 'SET_PLATE_VISOR_VALUE',
        payload: { value, numPlate, type, additional, indexAdditional }
    }
}

export function setPlateTrimFrameValue(value, numPlate, type, additional, indexAdditional){
    return {
        type: 'SET_PLATE_TRIM_VALUE',
        payload: { value, numPlate, type, additional, indexAdditional }
    }
}

export function changePlateConfigurated(numPlate, type, additional, indexAdditional, completed, image){
    return {
        type: 'CHANGE_PLATE_DISPLAY_COMPLETE',
        payload: { numPlate, type, additional, indexAdditional, completed, image }
    }
}

export function applyDisplayConfiguration(type, numPlate, idAdditional, applyPlates){
    return {
        type: 'APPLY_DISPLAY_CONFIGURATION',
        payload: { type, numPlate, idAdditional, applyPlates }
    }
}

export function addPushButtonToSlot(numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional){
    return {
        type: 'ADD_PUSH_BUTTON_TO_SLOT',
        payload: { numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional }
    }
}

export function addPushButtonToAllPlate(numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional){
    return {
        type: 'ADD_PUSH_BUTTON_TO_ALL_PLATE',
        payload: { numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional }
    }
}

export function addPushButtonToFirstSlot(numPlate, buttonContent, type, additional, indexAdditional){
    return {
        type: 'ADD_PUSH_BUTTON_TO_FIRST_SLOT',
        payload: { numPlate, buttonContent, type, additional, indexAdditional }
    }
}

export function restartPlatePushButtons(numPlate, type, additional, indexAdditional){
    return {
        type: 'RESTART_PLATE_PUSH_BUTTONS',
        payload: { numPlate, type, additional, indexAdditional }
    }
}

export function changeAccessoryQuantity(articleCode, description, image, link, pvp, relatedArticles, category, quantity){
    return {
        type: 'CHANGE_ACCESORY_QUANTITY',
        payload: { articleCode, description, image, link, pvp, relatedArticles, category, quantity }
    }
}

export function setBudget(budget){
    return {
        type: 'SET_BUDGET',
        payload: { budget }
    }
}

export function changeDiscount(articleCode, type, numPlate, target, value){
    return {
        type: 'CHANGE_DISCOUNT',
        payload: { articleCode, type, numPlate, target, value }
    }
}

export function changeDiscountToAllProducts(target, value){
    return {
        type: 'CHANGE_ALL_DISCOUNTS',
        payload: { target, value }
    }
}

export function restartPlateContents(indexPlate, type){
    return {
        type: 'RESTART_PLATE_CONTENTS',
        payload: { indexPlate, type }
    }
}

export function changeClientName(clientName){
    
    return {
        type: 'CHANGE_CLIENT_NAME',
        payload: clientName.target ? clientName.target.value : clientName
    }
}

export function changeClientAtt(clientAtt){
    return {
        type: 'CHANGE_CLIENT_ATT',
        payload: clientAtt.target ? clientAtt.target.value : clientAtt
    }
}

export function changeClientMail(clientMail){
    return {
        type: 'CHANGE_CLIENT_MAIL',
        payload: clientMail.target ? clientMail.target.value : clientMail
    }
}

export function changeClientDirection(clientDirection){
    return {
        type: 'CHANGE_CLIENT_DIRECTION',
        payload: clientDirection.target ? clientDirection.target.value : clientDirection
    }
}

export function changeClientPhone(clientPhone){
    return {
        type: 'CHANGE_CLIENT_PHONE',
        payload: clientPhone.target ? clientPhone.target.value : clientPhone
    }
}

export function changeClientLogo(logo){
    return {
        type: 'CHANGE_CLIENT_LOGO',
        payload: logo
    }
}

export function changeComelitComercial(comelitComercial){
    return {
        type: 'CHANGE_COMELIT_COMERCIAL',
        payload: comelitComercial.target ? comelitComercial.target.value : comelitComercial
    }
}

export function changeComelitEmail(comelitEmail){
    return {
        type: 'CHANGE_COMELIT_EMAIL',
        payload: comelitEmail.target ? comelitEmail.target.value : comelitEmail
    }
}

export function changeExportType(exportType){
    return {
        type: 'CHANGE_EXPORT_TYPE',
        payload: exportType
    }
}

export function addLineToBudget(budgetLine){
    return{
        type: 'ADD_LINE_TO_BUDGET',
        payload: budgetLine
    }
}

export function deleteAditionalLineBudget(additionalLineIndex){
    return{
        type: 'DELETE_ADITIONAL_LINE_OF_BUDGET',
        payload: additionalLineIndex
    }
}

export function changeInstallationCost(installationCost){
    return{
        type: 'CHANGE_INSTALLATION_COST',
        payload: installationCost
    }
}