import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useFormFields } from '../other/helpers/hooksFormInput'
import { displayErrorMessages } from '../other/helpers/displayErrors'

import { useDispatch, useSelector } from 'react-redux'
import { loginAction, clearUserAuthState } from '../store/actions/AuthActions'

export function Login( props ) {        
    
    const dispatch = useDispatch()
    const authResponse = useSelector(state => state.userAuth.userAuthResponse)
    const { t } = props

    const userLocation = localStorage.getItem("comelit-user-language")

    useEffect(() => {
        dispatch(clearUserAuthState())
    },[])

    const [fields, handleFieldChange] = useFormFields({
        email: '',
        password: '',
        user_location: userLocation
    })

    const clearAuthErrDiv = () =>{
      let authErr = document.querySelector("#authError");
      authErr.innerHTML = "";
    }

    const successMessage = (successMessage) => {
        return <div dangerouslySetInnerHTML={{__html:'<div class="p-4 bg-green-400 text-green-800">' + successMessage + '</div>'}} ></div>
    }

    const userLogin = (e) => {
        e.preventDefault()
        clearAuthErrDiv()
        dispatch(loginAction(fields, props))
    }

    return(
        <form className='container flex mx-auto home' onSubmit={userLogin}>
            <div className="w-full max-w-xl pt-10 pb-10 pl-6 pr-6 mt-8 mb-8 bg-gray-200 sm:w-3/4">
                <div className="flex items-center justify-around">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 mr-2" viewBox="0 0 20 20" fill="#009640">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                    </svg>
                    {/* <label htmlFor="usuario" className="p-2 mr-2 font-semibold text-left w-36">Usuario: </label> */}
                    <input type="text" value={fields.email} onChange={handleFieldChange} name="email" id="email" placeholder={`${t("introduzca_correo")}`} className="w-full p-2 rounded-md bg-gray-50" autoComplete='on'/>
                </div>        
                <div className="flex justify-around mt-6 mb-4">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 mr-2" viewBox="0 0 20 20" fill="#009640">
                        <path fillRule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clipRule="evenodd" />
                    </svg>
                    {/* <label htmlFor="password" className="p-2 mr-2 font-semibold text-left w-36">Contraseña: </label> */}
                    <input type="password" value={fields.password} onChange={handleFieldChange} name="password" id="password" placeholder={`${t("introduzca_contrasenha")}`} className="w-full p-2 rounded-md bg-gray-50" autoComplete='on'/>
                </div>
                
                <Link to = {{pathname: '/user/forgot-password'}} className="underline hover:cursor-pointer">
                    <p className='mt-4 text-sm font-semibold text-right' >{t("olvide_contrasenha")}</p>
                </Link>  

                <div id="authError" className='mt-2'></div>
                <div id="authResponse">
                    {
                        authResponse && authResponse !== undefined && authResponse !== '' && authResponse.success && authResponse.success === true
                            ? successMessage(authResponse.message)
                            : authResponse && authResponse.success !== null && authResponse.success === false 
                                && displayErrorMessages(authResponse.error, document.querySelector("#authError"))
                    }
                </div>

                <div className='text-center'>
                    <input type="submit" value={`${t("entrar")}`} className="pt-4 pb-4 pl-8 pr-8 mx-auto mt-6 font-semibold text-white rounded cursor-pointer bg-green-comelit"/>
                </div>
                
                <Link to = {{pathname: '/user/register'}} className="text-center underline hover:cursor-pointer">
                    <p className='mt-4' >{t("no_usuario")} <span className='font-semibold'>{t("registrese_usuario")}</span></p>
                </Link>
            </div>   
        </form>
    )
}