import { useDrop } from 'react-dnd';
import { ItemTypes } from '../../other/ItemTypes'
import { areSameObjects } from '../../other/Utils';
import { ModuleItem } from './ModuleItem';

const styleUltraEmbedded = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '162px',
    height: '151px',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    outline: 'none'
};

const styleUltraSurface = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '194px',
    height: '181px',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    outline: 'none'
};

const styleVandalcom = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '140px',
    height: '140px',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    outline: 'none'
};

const styleSwitch = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '140px',
    textAlign: 'center',
    fontSize: '1rem',
    lineHeight: 'normal',
    outline: 'none'
};

function checkDoubleSpaceAvailable (plateInfo, additional, indexAdditional, numPlate, idSubPlate, idSlot) {
    let contents = additional ? plateInfo.additionalContents[indexAdditional].contents : plateInfo.contents
    let subContent = contents.find(subContent => subContent.idSubPlate === idSubPlate)
    let subPlateContent = subContent.subPlateContent
    if (subPlateContent.length < idSlot + 1) return false

    let slot = subPlateContent.find( slot => slot.idSlot === idSlot )
    let nextSlot = subPlateContent.find( slot => slot.idSlot === idSlot + 1 )

    if (areSameObjects(slot.slotContent, []) && nextSlot && nextSlot.slotContent.articleCode === "UT9270") 
        return true

    return areSameObjects(nextSlot.slotContent, [])
}

export const PlateSlot = (props) => {
    let { numPlate, idSubPlate, idSlot, slotContent, type, assembly, plateModel, additional, indexAdditional, switchLocation } = props
    let { addPushButtonToSlot, removeItemOfSlot, addItemToFirstSlot, addPushButtonToFirstSlot, addPushButtonToAllPlate } = props
    let itemsAccepted = [ ItemTypes.MODULE, ItemTypes.MODULE_DOUBLE, ItemTypes.MODULE_FULL_BUTTONS, ItemTypes.MODULE_PUSH_BUTTON ]
    
    if (switchLocation === "center"){
        itemsAccepted = [ ItemTypes.MONOBLOCK_PLATE ]
    }
    if (switchLocation === "top"){
        itemsAccepted = [ ItemTypes.MODULE_TOP, ItemTypes.MODULE_TURN ]
    }
    if (switchLocation === "bottom"){
        itemsAccepted = [ ItemTypes.MODULE_BOTTOM, ItemTypes.MODULE_TURN ]
    }
    
    const [collectedProps, drop] = useDrop(() => ({
        accept: itemsAccepted,
        drop(item, monitor){
            // const didDrop = monitor.didDrop();
            
            if (props.switchLocation === "top" && item.itemType === ItemTypes.MODULE_TURN && !item.src.includes("Top.png"))
                item.src = item.src.replace(".png","Top.png")

            // SlotContent
            let newItem = {
                articleCode: item.articleCode,
                deepLink: item.deepLink,
                src: item.src,
                eanCode: item.eanCode,
                pvp: item.pvp,
                relatedArticles: item.relatedArticles,
                numCalls: item.numCalls,
                numColumns: item.numColumns || 0,
                location: {
                    numPlate: numPlate,
                    idSubPlate: idSubPlate,
                    idSlot: idSlot
                },
                itemType: item.itemType,
                pushButtons: item.pushButtons,
                description: item.description,
                toolTip: item.toolTip,
                idCategory: item.idCategory,
                category: item.category,
                buttonsContent: [...item.buttonsContent]
            }
            
            let available = true
            if (item.itemType === ItemTypes.MODULE_DOUBLE){
                available = checkDoubleSpaceAvailable(props.plateInfo, additional, indexAdditional, numPlate, idSubPlate, idSlot)
            }

            available && props.addItemToSlot(numPlate, idSubPlate, idSlot, newItem, item, type, additional, indexAdditional)
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
            endDrag: monitor.getDropResult(),
            didDrop: monitor.didDrop()
        }),
    }),[props]);

    let {canDrop, isOver} = collectedProps
    const isActive = canDrop && isOver;
    let backgroundColor = '#f3f4f6';
    if (isActive) {
        backgroundColor = '#E9E9E9';
    }

    let style = {}
    if ( plateModel === "ultra" ){
        style = assembly==="embedded" ? {...styleUltraEmbedded} : {...styleUltraSurface}
    }
    if ( plateModel === "vandalcom" ){
        style = {...styleVandalcom}
    }
    if ( plateModel === "switch" ){
        style = {...styleSwitch, height: (slotContent && Object.keys(slotContent).length !== 0) ? 'auto' : (switchLocation === 'center' ? '240px' : '120px')}
    }

    if (isOver){
        style.outline = '2px solid #009440'
    }

    return (
        <div className={`plate-container ${plateModel} ${assembly} bg-transparent`}>            
            <div ref={drop} style={{ ...style, backgroundColor }}>
                {
                    (slotContent && slotContent !== "occupied_by_double" && Object.keys(slotContent).length !== 0)
                        && <ModuleItem 
                                src = { slotContent.src }
                                deepLink = { slotContent.deepLink }
                                articleCode = { slotContent.articleCode }
                                eanCode = { slotContent.eanCode }
                                description = { slotContent.description }
                                toolTip = { slotContent.toolTip }
                                idCategory = { slotContent.idCategory }
                                category = { slotContent.category }
                                pvp = { slotContent.pvp }
                                relatedArticles = { slotContent.relatedArticles }
                                numCalls = { slotContent.numCalls }
                                numColumns = { slotContent.numColumns }
                                location = { slotContent.location }
                                itemType = { slotContent.itemType }
                                buttonsContent = { slotContent.buttonsContent }
                                plateInfo = { props.plateInfo }
                                plateModel = { plateModel } 
                                assembly = { assembly }
                                numPlate = { numPlate }
                                idSubPlate = { idSubPlate }
                                idSlot = { idSlot }
                                type = { type }
                                additional = { additional }
                                switchLocation = { switchLocation }
                                indexAdditional = { indexAdditional }
                                removeItemOfSlot = { removeItemOfSlot }
                                addItemToFirstSlot = { addItemToFirstSlot }
                                addPushButtonToSlot = { addPushButtonToSlot }
                                addPushButtonToAllPlate = { addPushButtonToAllPlate }
                                addPushButtonToFirstSlot = { addPushButtonToFirstSlot }
                                t = {props.t}
                            />
                }
            </div> 
        </div>
    );
};
