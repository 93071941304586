import { areSameObjects } from "../../other/Utils"
import { cloneDeep } from "lodash"
import { ItemTypes } from "../../other/ItemTypes"

// Crear estado inicial App
const INITIAL_STATE = {
    reference: '',
    step: 1,
    systemType: '',
    mainAccess: 1,
    internalPortals: 1,
    houses: 1,
    instalationType: '',
    portalInfo: [
        {
            "id": 1,
            "verticals": 1,
            "homes": 1,
            "applied": false
        }
    ],
    mainPlates: [],
    mainConcierge: false,
    conciergeSystemType: '',
    secondaryPlates: [],
    alert: {
        showAlert: false,
        title: '',
        message: '',
        buttons: false,
        type: ''
    },
    accessories: [],
    budget: [],
    clientName: '',
    clientAtt: '',
    clientMail: '',
    clientDirection: '',
    clientPhone: '',
    clientLogo: { image:{} },
    comelitComercial: '',
    comelitEmail: '',
    exportType: ''
}

const EMPTY_CONTENT = [{
    idSubPlate: 1,
    subPlateContent: [
        {
            idSlot: 1,
            slotContent: [],
            location: '--'
        }
    ]
}]

const restartPlatesConfiguration = (state) => {
    let { mainPlates, secondaryPlates } = state
    mainPlates.forEach(plate => {
        plate.contents = EMPTY_CONTENT
        plate.configurationComplete = false
        plate.image = "--"    
        plate.visor = false
        plate.trimFrame = false    
    });
    secondaryPlates.forEach(plate => {
        plate.contents = EMPTY_CONTENT
        plate.configurationComplete = false
        plate.image = "--"    
        plate.visor = false
        plate.trimFrame = false    
    });
    return { newMainPlates: mainPlates, newSecondaryPlates: secondaryPlates }
}

// Crear reducer
export function edificeReducer(state = INITIAL_STATE, action){

    switch (action.type){
        case 'RESET_REDUCER':
            return INITIAL_STATE

        case 'CHANGE_REFERENCE':
            return {
                ...state,
                reference: action.payload
            }

        case 'CHANGE_STEP':
            return {
                ...state,
                step: action.payload
            }

        case 'CHANGE_SYSTEM_TYPE':
            let { newMainPlates, newSecondaryPlates } = restartPlatesConfiguration(cloneDeep(state))
            
            return {
                ...state,
                mainPlates: newMainPlates || state.mainPlates,
                secondaryPlates: newSecondaryPlates || state.secondaryPlates,
                systemType: action.payload,
                conciergeSystemType: (action.payload === "audio" && state.mainConcierge) ? action.payload : ''
            }

        case 'CHANGE_MAIN_ACCESS':
            return {
                ...state,
                mainAccess: action.payload
            } 

        case 'CHANGE_INTERNAL_PORTALS':
            return {
                ...state,
                internalPortals: action.payload
            }

        case 'CHANGE_HOUSES':

            let simulatorType = action.payload.simulatorType
            if ( simulatorType === "edifice" && state.internalPortals === 1 ){
                state.portalInfo[0].homes = action.payload.value
            }
        
            return {
                ...state,
                houses: action.payload.value
            }

        case 'CHANGE_INSTALATION_TYPE':
            return {
                ...state,
                instalationType: action.payload
            }

        case 'CHANGE_PORTAL_INFO':
            return {
                ...state,
                portalInfo: [...action.payload]
            }

        case 'CHANGE_ELEMENT_PORTAL_INFO':
            let newPortalInfo = [...state.portalInfo]
            let indexParent = newPortalInfo.findIndex((portal => portal.id === action.payload.parentPortal))
        
            let homes = newPortalInfo[indexParent].homes
            let verticals = newPortalInfo[indexParent].verticals

            for (let i = 0; i < action.payload.applyPortals.length; i++) {
                newPortalInfo[action.payload.applyPortals[i]].homes = homes
                newPortalInfo[action.payload.applyPortals[i]].verticals = verticals
                newPortalInfo[action.payload.applyPortals[i]].applied = true                
            }
            
            return {
                ...state,
                portalInfo: newPortalInfo
            }

        case 'SET_PORTALS_NOT_APPLIED':            
            let newPortals = state.portalInfo.map(portal => {
                return portal === undefined 
                    ?  undefined
                    : 
                        {
                            ...portal, 
                            applied: false
                        }
            })

            return {
                ...state,
                portalInfo: newPortals
            }
            
        case 'CHANGE_MAIN_CONCIERGE':
            
            // Si es tipo de sistema audio, el tipo de conserjería solo puede ser AUDIO
            let conciergeSystemType = state.systemType === 'audio' ? 'audio' : state.conciergeSystemType
            return {
                ...state,
                conciergeSystemType: action.payload ? conciergeSystemType : '',
                mainConcierge: action.payload
            }  

        case 'CHANGE_CONCIERGE_SYSTEM_TYPE':
            return {
                ...state,
                conciergeSystemType: action.payload
            }  

        case 'CHANGE_MAIN_PLATES':
            return {
                ...state,
                mainPlates: action.payload
            }  

        case 'CHANGE_SECONDARY_PLATES':
            return {
                ...state,
                secondaryPlates: action.payload
            }  

        case 'HANDLE_PLATE_APPLY':
            let newPlates = action.payload.type === "main" ? cloneDeep(state.mainPlates) : cloneDeep(state.secondaryPlates)

            for (let i = 0; i < action.payload.applyPlates.length; i++) {
                newPlates[action.payload.applyPlates[i]] = cloneDeep(action.payload.parentPlate)
                newPlates[action.payload.applyPlates[i]].contents = cloneDeep(EMPTY_CONTENT)
                newPlates[action.payload.applyPlates[i]].applyPlates = []
                newPlates[action.payload.applyPlates[i]].configurationComplete = false
                newPlates[action.payload.applyPlates[i]].applyConfiguration = false
                newPlates[action.payload.applyPlates[i]].modalApplyVisible = false
                newPlates[action.payload.applyPlates[i]].numSubPlates = 1
                newPlates[action.payload.applyPlates[i]].image = "--"
                newPlates[action.payload.applyPlates[i]].assembly = "--"      
                
                newPlates[action.payload.applyPlates[i]].additionalContents = cloneDeep(action.payload.parentPlate.additionalContents)
                newPlates[action.payload.applyPlates[i]].additionalContents.image = "--"
                newPlates[action.payload.applyPlates[i]].additionalContents.configurationComplete = false
                newPlates[action.payload.applyPlates[i]].additionalContents.contents = cloneDeep(EMPTY_CONTENT)  
                newPlates[action.payload.applyPlates[i]].additionalContents.numSubPlates = 1
                newPlates[action.payload.applyPlates[i]].additionalContents.assembly = "--"
            }

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: cloneDeep(newPlates)
            }

        case 'CHANGE_PLATE_ASSEMBLY':
            let newPlatesAssembly = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]

            // Buscamos si es la placa principal o adicional
            let plateBaseAssembly = action.payload.additional 
                ? newPlatesAssembly[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesAssembly[action.payload.numPlate]

            plateBaseAssembly.assembly = action.payload.assembly

            // Reiniciamos el diseño de la placa
            plateBaseAssembly.numSubPlates = 1
            plateBaseAssembly.contents = cloneDeep(EMPTY_CONTENT)

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: newPlatesAssembly
            }

        case 'ADD_SUBPLATE':
            let newPlatesAdd = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]

            // Buscamos si es la placa principal o adicional
            let plateBaseAddSubPlate = action.payload.additional 
                ? newPlatesAdd[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesAdd[action.payload.numPlate]

            plateBaseAddSubPlate.numSubPlates += 1
            plateBaseAddSubPlate.contents.push(
                {
                    idSubPlate: plateBaseAddSubPlate.numSubPlates,
                    subPlateContent: [
                        {
                            idSlot: 1,
                            slotContent: [],
                            location: '--'
                        }
                    ]
                }
            )
            
            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesAdd]
            }

        case 'REMOVE_SUBPLATE':
            let newPlatesRemove = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]

            // Buscamos si es la placa principal o adicional
            let plateBaseRemoveSubPlate = action.payload.additional 
                ? newPlatesRemove[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesRemove[action.payload.numPlate]

            plateBaseRemoveSubPlate.numSubPlates -= 1
            plateBaseRemoveSubPlate.contents.pop()

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesRemove]
            }

        case 'ADD_SLOT_SUBPLATE':
            let newPlatesAddSlot = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]            

            // Buscamos si es la placa principal o adicional
            let plateBaseAddSlot = action.payload.additional 
                ? newPlatesAddSlot[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesAddSlot[action.payload.numPlate]

            // Buscamos el elemento al que añadir el SLOT por su ID
            const subPlateAdd = plateBaseAddSlot.contents.find( content => content.idSubPlate === action.payload.numSubPlate );
            
            // Buscamos el máximo idSlot del array
            let maxIDSlot = Math.max.apply(Math, subPlateAdd.subPlateContent.map(function(slot) { return slot.idSlot }))

            subPlateAdd.subPlateContent.push({
                idSlot: maxIDSlot + 1,
                slotContent: [],
                location: action.payload.location
            })

            // Si tenemos dos bloques en simulador, hay que añadir dos placas
            if ( subPlateAdd.subPlateContent.length === 7 || subPlateAdd.subPlateContent.length === 8 ) {
                subPlateAdd.subPlateContent.push({
                    idSlot: maxIDSlot + 2,
                    slotContent: [],
                    location: action.payload.location
                })
            }
            
            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesAddSlot]
            }

        case 'REMOVE_SLOT_SUBPLATE':
            let newPlatesRemoveSubplate = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]

            // Buscamos si es la placa principal o adicional
            let plateBaseRemoveSlot = action.payload.additional 
                ? newPlatesRemoveSubplate[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesRemoveSubplate[action.payload.numPlate]

            // Buscamos el elemento al que eliminar el SLOT por su ID
            const subPlateRemove = plateBaseRemoveSlot.contents.find( content => content.idSubPlate === action.payload.numSubPlate );

            // Si no estamos eliminando por encima o debajo de la Switch
            if ( action.payload.location === "--"){
                subPlateRemove.subPlateContent.pop()
            } else{
                let indexRemove = subPlateRemove.subPlateContent.findIndex(element => element.location === action.payload.location)
                subPlateRemove.subPlateContent.splice(indexRemove, 1)
            }
            
            if ( subPlateRemove.subPlateContent.length === 6 || subPlateRemove.subPlateContent.length === 7 ) {
                subPlateRemove.subPlateContent.pop()
            }
            // // Si tenemos dos bloques en simulador, hay que eliminar dos placas
            // ( subPlateRemove.subPlateContent.length === 5 || subPlateRemove.subPlateContent.length === 7 ) &&
            //     subPlateRemove.subPlateContent.pop()

            // // Si tenemos dos bloques en simulador, hay que eliminar tres placas
            // ( subPlateRemove.subPlateContent.length === 8 ) && 
            //     subPlateRemove.subPlateContent.pop()

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesRemoveSubplate]
            }
            
        case 'ADD_ITEM_SLOT':

            // Obtenemos la placa antes de editar nada
            let originalPlates = action.payload.type === "main" ? cloneDeep(state.mainPlates) : cloneDeep(state.secondaryPlates)

            // Buscamos si es la placa principal o adicional
            let originalPlateBase = action.payload.additional 
                ? originalPlates[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : originalPlates[action.payload.numPlate]

            // Buscamos el contenido que hay realmente en el SLOT que se está moviendo
            let subPlateRealContent = originalPlateBase.contents.find( content => content.idSubPlate === action.payload.originalItem.location.idSubPlate );
            let slotAddRealContent = subPlateRealContent 
                ? subPlateRealContent.subPlateContent.find( content => content.idSlot === action.payload.originalItem.location.idSlot )
                : null
            
            // Obtenemos la copia de las placas a modificar
            let newPlatesAddItem = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            // Buscamos si es la placa principal o adicional
            let plateBaseAdd = action.payload.additional 
                ? newPlatesAddItem[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesAddItem[action.payload.numPlate]
            // Buscamos el elemento al que añadir el item
            let subPlateAddItem = plateBaseAdd.contents.find( content => content.idSubPlate === action.payload.idSubPlate );
            // Buscamos el slot al que añadir el item
            let slotAdd = subPlateAddItem.subPlateContent.find( content => content.idSlot === action.payload.idSlot )

            // Si se está arrastrando un elemento dentro de la placa
            if (action.payload.originalItem.location !== "simulator"){
                
                // Si se está arrastrando sobre un item que no está vacío
                if (!areSameObjects(slotAdd.slotContent, [])){
                    // Obtenemos la localización original del elemento que estamos arrastrando
                    let originalItemLocation = action.payload.originalItem.location
                    // Obtenemos el contenido sobre el que estamos arrastrando
                    let originalSlotContent = {...slotAdd.slotContent}
                    
                    // Buscamos si la placa a sustituir es la placa principal o adicional
                    let plateBaseReplace = action.payload.additional 
                        ? newPlatesAddItem[originalItemLocation.numPlate].additionalContents[action.payload.indexAdditional] 
                        : newPlatesAddItem[originalItemLocation.numPlate]  

                    // Buscamos el elemento al que reemplazar el item
                    let subPlateReplace = plateBaseReplace.contents.find( content => content.idSubPlate === originalItemLocation.idSubPlate );
                    // Buscamos el slot al que reemplazar
                    let slotReplace = subPlateReplace.subPlateContent.find( content => content.idSlot === originalItemLocation.idSlot )  
                    
                    // Si el artículo a reemplazar es un módulo doble, el siguiente hueco tiene que estar vacío
                    if (originalSlotContent.articleCode === "UT9270"){     
                        // Buscamos el siguiente slot al que reemplazar
                        let slotReplaceNext = subPlateReplace.subPlateContent.find( content => content.idSlot === originalItemLocation.idSlot+1 )
                        
                        if (slotReplaceNext !== undefined && areSameObjects(slotReplaceNext.slotContent,[])){
                            // Reemplazamos el contenido y le ponemos la localización correcta
                            slotReplace.slotContent = {...originalSlotContent}
                            slotReplace.slotContent.location = originalItemLocation
                            // Reemplazamos el contenido del siguiente SLOT y le ponemos la localización correcta
                            slotReplaceNext.slotContent = "occupied_by_double"
                        } else{
                            slotReplace.slotContent = []
                        }
                        
                        // Buscamos el slot a vaciar
                        let slotReplaceDelete = subPlateReplace.subPlateContent.find( content => content.idSlot === originalSlotContent.location.idSlot+1 )
                        slotReplaceDelete.slotContent = []
                    } else{
                        // Reemplazamos el contenido y le ponemos la localización correcta
                        slotReplace.slotContent = {...originalSlotContent}
                        slotReplace.slotContent.location = originalItemLocation
                    }

                    // Si el que se ha movido es el módulo doble, quitamos el siguiente como OCUPADO
                    if (slotAdd.slotContent.articleCode === "UT9270"){
                        let slotAddNext = subPlateAddItem.subPlateContent.find( content => content.idSlot === action.payload.idSlot+1 )    
                        slotAddNext.slotContent = []
                    }

                    // Si estamos reemplazando el módulo doble, hay que poner el siguiente como ocupado
                    if (slotAddRealContent.slotContent.articleCode === "UT9270"){            
                        // Buscamos el slot siguiente al que reemplazar
                        let slotReplaceNext = subPlateReplace.subPlateContent.find( content => content.idSlot === originalItemLocation.idSlot+1 )
                        slotReplaceNext.slotContent = []
                    }
                }
                // Si el módulo destino está vacío borramos el módulo de origen
                else{

                    // Buscamos el elemento que arrastra, para borrarlo
                    let subPlateOriginalItemRemove = plateBaseAdd.contents.find( content => content.idSubPlate === action.payload.originalItem.location.idSubPlate );
                    // Buscamos el slot al que añadir el item
                    let slotOriginalItemRemove = subPlateOriginalItemRemove.subPlateContent.find( content => content.idSlot === action.payload.originalItem.location.idSlot )
                    slotOriginalItemRemove.slotContent = []
                    
                    // Si el que se ha movido es el módulo doble, quitamos el siguiente como OCUPADO
                    if (slotAddRealContent.slotContent.articleCode === "UT9270"){
                        let slotOriginalItemRemoveNext = subPlateOriginalItemRemove.subPlateContent.find( content => content.idSlot === action.payload.originalItem.location.idSlot+1 )
                        slotOriginalItemRemoveNext.slotContent = []
                    }

                }
            }

            // Si no existe la placa, la creamos
            if (!slotAdd){
                subPlateAddItem.subPlateContent.push({
                    idSlot: action.payload.idSlot,
                    slotContent: [],
                    location: '--'
                })
                slotAdd = subPlateAddItem.subPlateContent.find( content => content.idSlot === action.payload.idSlot )    
            }
            
            // Añadimos la placa
            slotAdd.slotContent = slotAddRealContent ? slotAddRealContent.slotContent : action.payload.slotContent
            slotAdd.slotContent.location = action.payload.slotContent.location

            // Comprobamos si el siguiente está como "occupied_by_double" para quitarlo            
            if ( subPlateAddItem.subPlateContent.find( content => content.idSlot === action.payload.idSlot + 1 ) &&
                    subPlateAddItem.subPlateContent.find( content => content.idSlot === action.payload.idSlot + 1 ).slotContent === "occupied_by_double" ){
                subPlateAddItem.subPlateContent.find( content => content.idSlot === action.payload.idSlot + 1 ).slotContent = []
            }

            let articleCodeMove = slotAddRealContent ? slotAddRealContent.articleCode : action.payload.originalItem.articleCode
            // Si es modulo doble, añadimos el siguiente ocupado
            if (articleCodeMove === "UT9270"){
                // Si estamos añadiendo UT9270 (doble)
                let slotAddNext = subPlateAddItem.subPlateContent.find( content => content.idSlot === action.payload.idSlot+1 )
                slotAddNext.slotContent = "occupied_by_double"
            }
            
            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesAddItem]
            }

        case 'REMOVE_ITEM_SLOT':
            let newPlatesRemoveItem = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            
            // Buscamos si es la placa principal o adicional
            let plateBaseRemove = action.payload.additional 
                ? newPlatesRemoveItem[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesRemoveItem[action.payload.numPlate]
            // Buscamos el elemento al que borrar el item
            let subPlateRemoveItem = plateBaseRemove.contents.find( content => content.idSubPlate === action.payload.idSubPlate );
            // Buscamos el slot al que borrar el item
            let slotRemove = subPlateRemoveItem.subPlateContent.find( content => content.idSlot === action.payload.idSlot )
            
            if (action.payload.dropResult !== "move"){
                slotRemove.slotContent = []
            }

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesRemoveItem]
            }

        case 'ADD_ITEM_FIRST_SLOT':
            let newPlatesAddItemFirstAvailable = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]

            // Buscamos el elemento al que añadir el item
            let plateContents = action.payload.additional 
                ? newPlatesAddItemFirstAvailable[action.payload.numPlate].additionalContents[action.payload.indexAdditional].contents
                : newPlatesAddItemFirstAvailable[action.payload.numPlate].contents
            
            outer_for:
            for (let i = 0; i < plateContents.length; i++) {
                for (let j = 0; j < plateContents[i].subPlateContent.length; j++) {
                    if (plateContents[i].subPlateContent[j].slotContent.length === 0){
                        // Si es módulo doble, el siguiente tiene que estar vacío
                        if ( action.payload.slotContent.articleCode === "UT9270" ){
                            // Si está vacío el siguiente, añadimos los dos
                            if ( plateContents[i].subPlateContent[j+1] && plateContents[i].subPlateContent[j+1].slotContent.length === 0 ){
                                plateContents[i].subPlateContent[j].slotContent = action.payload.slotContent
                                plateContents[i].subPlateContent[j].slotContent.location = {
                                    numPlate: action.payload.numPlate,
                                    idSubPlate: i+1,
                                    idSlot: j+1
                                }
                                plateContents[i].subPlateContent[j+1].slotContent = "occupied_by_double"
                                break outer_for
                            }
                        }
                        // Si es módulo normal
                        else{

                            // Si es SWITCH y estamos insertando en la parte superior de la placa, giramos
                            if (newPlatesAddItemFirstAvailable[action.payload.numPlate].plateModel === "switch" && plateContents[i].subPlateContent[j].location === "top"){
                                if ( action.payload.slotContent.itemType === ItemTypes.MODULE_TURN 
                                        && !action.payload.slotContent.src.includes("Top.png"))
                                    action.payload.slotContent.src = action.payload.slotContent.src.replace(".png","Top.png")
                            }

                            // Si es SWITCH y no MODULE_TURN no puede ir arriba
                            if (!(newPlatesAddItemFirstAvailable[action.payload.numPlate].plateModel === "switch" 
                                    && plateContents[i].subPlateContent[j].location === "top"
                                    && action.payload.slotContent.itemType !== ItemTypes.MODULE_TURN)
                            ){
                                plateContents[i].subPlateContent[j].slotContent = action.payload.slotContent
                                plateContents[i].subPlateContent[j].slotContent.location = {
                                    numPlate: action.payload.numPlate,
                                    idSubPlate: i+1,
                                    idSlot: j+1
                                }                   
                                break outer_for
                            }
                        }

                    }
                }                
            }

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesAddItemFirstAvailable]
            }
            
        case 'RESTART_PLATE_DISPLAY':
            let newPlatesRestart = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            
            let plateBaseRestart = action.payload.additional 
                ? newPlatesRestart[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesRestart[action.payload.numPlate]

            // Buscamos el elemento al que reiniciar el display
            plateBaseRestart.numSubPlates = 1
            plateBaseRestart.contents = cloneDeep(EMPTY_CONTENT)

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesRestart]
            }
            
        case 'SET_PLATE_VISOR_VALUE':
            let newPlatesVisor = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            
            let plateBaseVisor = action.payload.additional 
                ? newPlatesVisor[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesVisor[action.payload.numPlate]

            // Buscamos el elemento al que reiniciar el display
            plateBaseVisor.visor = action.payload.value

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesVisor]
            }

        case 'SET_PLATE_TRIM_VALUE':
            let newPlatesTrim = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            
            let plateBaseTrim = action.payload.additional 
                ? newPlatesTrim[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesTrim[action.payload.numPlate]

            // Buscamos el elemento al que reiniciar el display
            plateBaseTrim.trimFrame = action.payload.value

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesTrim]
            }

        case 'CHANGE_PLATE_DISPLAY_COMPLETE':
            let newPlatesConfigurated = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            
            let plateBaseConfigurated = action.payload.additional 
                ? newPlatesConfigurated[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesConfigurated[action.payload.numPlate]

            // Buscamos el elemento que marcar como configurado o no
            plateBaseConfigurated.configurationComplete = action.payload.completed
            plateBaseConfigurated.image = action.payload.image

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesConfigurated]
            }

        case 'APPLY_DISPLAY_CONFIGURATION':
            let newPlatesApplyDisplay = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
                        
            // Obtenemos la placa base que queremos copiar
            let additionalIndexCopy = newPlatesApplyDisplay[action.payload.numPlate].additionalContents.findIndex((value => value.idAdditional === action.payload.idAdditional))
            let plateBaseCopy = action.payload.idAdditional !== 0 
                ? newPlatesApplyDisplay[action.payload.numPlate].additionalContents[additionalIndexCopy] 
                : newPlatesApplyDisplay[action.payload.numPlate]

            // Obtenemos la información a copiar
            let assembly = plateBaseCopy.assembly
            let configurationComplete = plateBaseCopy.configurationComplete
            let contents = cloneDeep(plateBaseCopy.contents)
            let image = plateBaseCopy.image
            let numSubPlates = plateBaseCopy.numSubPlates
            let trimFrame = plateBaseCopy.trimFrame
            let visor = plateBaseCopy.visor      

            for (let i = 0; i < action.payload.applyPlates.length; i++) {
                const element = action.payload.applyPlates[i];
                if (element.type === "normal"){
                    newPlatesApplyDisplay[element.numPlate].assembly = assembly
                    newPlatesApplyDisplay[element.numPlate].configurationComplete = configurationComplete
                    newPlatesApplyDisplay[element.numPlate].contents = cloneDeep(contents)
                    newPlatesApplyDisplay[element.numPlate].image = image
                    newPlatesApplyDisplay[element.numPlate].numSubPlates = numSubPlates
                    newPlatesApplyDisplay[element.numPlate].trimFrame = trimFrame
                    newPlatesApplyDisplay[element.numPlate].visor = visor
                }  
                if (element.type === "additional"){
                    let additionalApply = newPlatesApplyDisplay[element.parent].additionalContents.findIndex((value => value.idAdditional === element.numPlate))
                    
                    newPlatesApplyDisplay[element.parent].additionalContents[additionalApply].assembly = assembly
                    newPlatesApplyDisplay[element.parent].additionalContents[additionalApply].configurationComplete = configurationComplete
                    newPlatesApplyDisplay[element.parent].additionalContents[additionalApply].contents = cloneDeep(contents)
                    newPlatesApplyDisplay[element.parent].additionalContents[additionalApply].image = image
                    newPlatesApplyDisplay[element.parent].additionalContents[additionalApply].numSubPlates = numSubPlates
                    newPlatesApplyDisplay[element.parent].additionalContents[additionalApply].trimFrame = trimFrame
                    newPlatesApplyDisplay[element.parent].additionalContents[additionalApply].visor = visor
                }
            }

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesApplyDisplay]
            }
                
        case 'ADD_PUSH_BUTTON_TO_SLOT':
            // Obtenemos la copia de las placas a modificar
            let newPlatesAddPush = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            // Buscamos si es la placa principal o adicional
            let plateBaseAddPush = action.payload.additional 
                ? newPlatesAddPush[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesAddPush[action.payload.numPlate]
            // Buscamos el subPlate al que añadir el pulsador
            let subPlateAddPush = plateBaseAddPush.contents.find( content => content.idSubPlate === action.payload.idSubPlate );
            // Buscamos el slot al que añadir el pulsador
            let slotAddPush = subPlateAddPush.subPlateContent.find( content => content.idSlot === action.payload.idSlot )

            // Buscamos la posicion a la que añadir
            let pushButtonAdd = slotAddPush.slotContent.buttonsContent.find( pushButton => pushButton.idButton === action.payload.newButtonContent.idButton )
            
            pushButtonAdd.articleCode = action.payload.newButtonContent.articleCode
            pushButtonAdd.deepLink = action.payload.newButtonContent.deepLink
            pushButtonAdd.description = action.payload.newButtonContent.description
            pushButtonAdd.eanCode = action.payload.newButtonContent.eanCode
            pushButtonAdd.pvp = action.payload.newButtonContent.pvp
            pushButtonAdd.relatedArticles = action.payload.newButtonContent.relatedArticles
            pushButtonAdd.numCalls = action.payload.newButtonContent.numCalls
            pushButtonAdd.numColumns = action.payload.newButtonContent.numColumns
            pushButtonAdd.src = action.payload.newButtonContent.src
            pushButtonAdd.toolTip = action.payload.newButtonContent.toolTip
            pushButtonAdd.idCategory = action.payload.newButtonContent.idCategory
            pushButtonAdd.category = action.payload.newButtonContent.category

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesAddPush]
            }

        case 'ADD_PUSH_BUTTON_TO_FIRST_SLOT':
            let newPlatesAddPushButtonFirstAvailable = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]

            // Buscamos el elemento al que añadir el item
            let plateButtonContents = action.payload.additional 
                ? newPlatesAddPushButtonFirstAvailable[action.payload.numPlate].additionalContents[action.payload.indexAdditional].contents
                : newPlatesAddPushButtonFirstAvailable[action.payload.numPlate].contents
            
            outer_for:
            for (let i = 0; i < plateButtonContents.length; i++) {
                for (let j = 0; j < plateButtonContents[i].subPlateContent.length; j++) {
                    let slotContent = plateButtonContents[i].subPlateContent[j].slotContent
                    if (slotContent.buttonsContent){
                        let availableButton = slotContent.buttonsContent.find( pushButton => pushButton.src === "--" || pushButton.articleCode === "--")
                        if (availableButton){
                            
                            availableButton.articleCode = action.payload.buttonContent.articleCode
                            availableButton.deepLink = action.payload.buttonContent.deepLink
                            availableButton.description = action.payload.buttonContent.description
                            availableButton.eanCode = action.payload.buttonContent.eanCode
                            availableButton.pvp = action.payload.buttonContent.pvp
                            availableButton.relatedArticles = action.payload.buttonContent.relatedArticles
                            availableButton.numCalls = action.payload.buttonContent.numCalls
                            availableButton.numColumns = action.payload.buttonContent.numColumns
                            availableButton.src = action.payload.buttonContent.src
                            availableButton.toolTip = action.payload.buttonContent.toolTip
                            availableButton.idCategory = action.payload.buttonContent.idCategory
                            availableButton.category = action.payload.buttonContent.category

                            break outer_for
                        }
                    }
                }                
            }

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesAddPushButtonFirstAvailable]
            }

        case 'RESTART_PLATE_PUSH_BUTTONS':
            let newPlatesRestartButtons = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            
            let plateBaseRestartButtons = action.payload.additional 
                ? newPlatesRestartButtons[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesRestartButtons[action.payload.numPlate]

            // Buscamos el elemento al que reiniciar el display
            // newPlatesRestartButtons[action.payload.numPlate].contents = cloneDeep(EMPTY_CONTENT)
            for (let i = 0; i < plateBaseRestartButtons.contents.length; i++) {
                const subPlateContent = plateBaseRestartButtons.contents[i].subPlateContent;
                for (let j = 0; j < subPlateContent.length; j++) {
                    const slotContent = subPlateContent[j].slotContent;
                    if (slotContent.buttonsContent) {
                        for (let k = 0; k < slotContent.buttonsContent.length; k++) {
                            const buttonContent = slotContent.buttonsContent[k];
                            
                            buttonContent.articleCode = "--"
                            buttonContent.deepLink = "--"
                            buttonContent.description = "--"
                            buttonContent.eanCode = "--"
                            buttonContent.pvp = "--"
                            buttonContent.relatedArticles = "--"
                            buttonContent.numCalls = "--"
                            buttonContent.numColumns = "--"
                            buttonContent.src = "--"
                            buttonContent.toolTip = "--"
                            buttonContent.idCategory = "--"
                            buttonContent.category = "--"
                        }
                    }                    
                }                
            }

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesRestartButtons]
            }
             
        case 'ADD_PUSH_BUTTON_TO_ALL_PLATE':
            // Obtenemos la copia de las placas a modificar
            let newPlatesAddPushAll = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            // Buscamos si es la placa principal o adicional
            let plateBaseAddPushAll = action.payload.additional 
                ? newPlatesAddPushAll[action.payload.numPlate].additionalContents[action.payload.indexAdditional] 
                : newPlatesAddPushAll[action.payload.numPlate]

            for (let i = 0; i < plateBaseAddPushAll.contents.length; i++) {
                let subPlateContentAdd = plateBaseAddPushAll.contents[i].subPlateContent;
                for (let j = 0; j < (subPlateContentAdd ? subPlateContentAdd.length : 0) ; j++) {
                    let buttonsContentAdd = subPlateContentAdd[j].slotContent.buttonsContent;
                    for (let k = 0; k < (buttonsContentAdd ? buttonsContentAdd.length : 0) ; k++) {
                        let buttonContentAdd = buttonsContentAdd[k];
                        if (buttonContentAdd.src === "--" || buttonContentAdd.articleCode === "--"){                            
                            buttonContentAdd.articleCode = action.payload.newButtonContent.articleCode
                            buttonContentAdd.deepLink = action.payload.newButtonContent.deepLink
                            buttonContentAdd.description = action.payload.newButtonContent.description
                            buttonContentAdd.eanCode = action.payload.newButtonContent.eanCode
                            buttonContentAdd.pvp = action.payload.newButtonContent.pvp
                            buttonContentAdd.relatedArticles = action.payload.newButtonContent.relatedArticles
                            buttonContentAdd.numCalls = action.payload.newButtonContent.numCalls
                            buttonContentAdd.numColumns = action.payload.newButtonContent.numColumns
                            buttonContentAdd.src = action.payload.newButtonContent.src
                            buttonContentAdd.toolTip = action.payload.newButtonContent.toolTip
                            buttonContentAdd.idCategory = action.payload.newButtonContent.idCategory
                            buttonContentAdd.category = action.payload.newButtonContent.category
                        }
                    }                    
                }
            }      

            return {
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...newPlatesAddPushAll]
            }

        case 'CHANGE_ACCESORY_QUANTITY':
            let newAccesories = cloneDeep(state.accessories)
            let articleCode = action.payload.articleCode
            let description = action.payload.description
            let imageAccesory = action.payload.image
            let link = action.payload.link
            let pvp = action.payload.pvp
            let relatedArticles = action.payload.relatedArticles
            let quantity = action.payload.quantity
            let category = action.payload.category

            let indexCategory = newAccesories.findIndex((accessory => accessory.articleCode === action.payload.articleCode))
            if (indexCategory !== -1){
                if (quantity === 0){
                    newAccesories.splice(indexCategory, 1)
                } else{
                    newAccesories[indexCategory].quantity = quantity
                }
            } else if (quantity !== 0) {
                newAccesories.push({articleCode, description, image: imageAccesory, link, pvp, relatedArticles, category, quantity})
            }
            
            return{
                ...state,
                accessories: [...newAccesories]
            }

        case 'SET_BUDGET':
            return{
                ...state,
                budget: action.payload.budget
            }

        case 'CHANGE_DISCOUNT':
            let newBudget = cloneDeep(state.budget)

            let articleCodeBudget = action.payload.articleCode
            let type = action.payload.type
            let numPlate = action.payload.numPlate
            let target = action.payload.target
            let value = parseFloat(action.payload.value)

            let indexType = newBudget.findIndex((budget => budget.type === type))
            let budgetPlates = newBudget[indexType].plates
            let indexPlate = budgetPlates.findIndex(plate => plate.numPlate === numPlate)
            let budgetContent = budgetPlates[indexPlate] ? budgetPlates[indexPlate].content : budgetPlates[0].content
            let indexArticle = budgetContent.findIndex(content => content.articleCode === articleCodeBudget)
            
            let article = budgetContent[indexArticle]
            article[target] = value

            if (target === "nt_und"){
                article.sub_tn = parseFloat(article.nt_und === 0 ? article.pvp : article.nt_und * article.quantity)
            }
            else{

                let totalDiscount = ((1 - ((1 - (article.dto_1/100)) * (1 - (article.dto_2/100)) * (1 - (article.dto_3/100)))) * 100).toFixed(2)
                totalDiscount = totalDiscount > 100 ? 100 : totalDiscount

                if (totalDiscount > 0){
                    article.nt_und = parseFloat((article.pvp * ( 1 - (totalDiscount / 100))))
                    article.sub_tn = article.nt_und * article.quantity
                } else{
                    article.nt_und = 0
                    article.sub_tn = article.pvp * article.quantity
                }
            }

            return{
                ...state,
                exportType: '',
                budget: [...newBudget]
            }
            
        case 'CHANGE_ALL_DISCOUNTS':
            let newBudgetAllDiscounts = cloneDeep(state.budget)
            let targetDiscount = action.payload.target
            let valueDiscount = parseFloat(action.payload.value)
            
            newBudgetAllDiscounts.forEach(budget => {
                if ( !["individual","installation"].includes(budget.type) ){
                    budget.plates.forEach(plate => {
                        plate.content.forEach(product => {
                                                        
                            product[targetDiscount] = valueDiscount

                            if (targetDiscount === "nt_und"){
                                product.sub_tn = parseFloat(product.nt_und === 0 ? product.pvp : product.nt_und * product.quantity)
                            }
                            else{
                                
                                let totalDiscount = ((1 - ((1 - (product.dto_1/100)) * (1 - (product.dto_2/100)) * (1 - (product.dto_3/100)))) * 100).toFixed(2)
                                totalDiscount = totalDiscount > 100 ? 100 : totalDiscount
                                if (totalDiscount > 0){
                                    product.nt_und = parseFloat((product.pvp * ( 1 - (totalDiscount / 100))))
                                    product.sub_tn = product.nt_und * product.quantity
                                } else{
                                    product.nt_und = 0
                                    product.sub_tn = product.pvp * product.quantity
                                }
                            }
                        })
                    })
                }
            })

            return{
                ...state,
                exportType: '',
                budget: [...newBudgetAllDiscounts]
            }

        case 'RESTART_PLATE_CONTENTS':

            let restartedPlates = action.payload.type === "main" ? [...state.mainPlates] : [...state.secondaryPlates]
            let indexPlateReplace = action.payload.indexPlate

            if (!areSameObjects(restartedPlates, [])){

                restartedPlates[indexPlateReplace].contents = EMPTY_CONTENT
                restartedPlates[indexPlateReplace].configurationComplete = false
                restartedPlates[indexPlateReplace].image = "--"
                restartedPlates[indexPlateReplace].visor = false
                restartedPlates[indexPlateReplace].trimFrame = false

                if (action.payload.type === "secondary"){
                    restartedPlates[indexPlateReplace].additionalContents.forEach(plate => {
                        plate.contents = EMPTY_CONTENT
                        plate.configurationComplete = false
                        plate.image = "--"
                        plate.visor = false
                        plate.trimFrame = false
                    });
                }
            }

            return{
                ...state,
                [action.payload.type === "main" ? 'mainPlates' : 'secondaryPlates']: [...restartedPlates]
            }
            
        case 'CHANGE_CLIENT_NAME':
            return{
                ...state,
                clientName: action.payload
            }    

        case 'CHANGE_CLIENT_ATT':
            return{
                ...state,
                clientAtt: action.payload
            }  
            
        case 'CHANGE_CLIENT_MAIL':
            return{
                ...state,
                clientMail: action.payload
            }   

        case 'CHANGE_CLIENT_DIRECTION':
            return{
                ...state,
                clientDirection: action.payload
            }      

        case 'CHANGE_CLIENT_PHONE':
            return{
                ...state,
                clientPhone: action.payload
            }
            
        case 'CHANGE_CLIENT_LOGO':
            return{
                ...state,
                clientLogo: action.payload
            }
            
        case 'CHANGE_COMELIT_COMERCIAL':
            return{
                ...state,
                comelitComercial: action.payload
            }
            
        case 'CHANGE_COMELIT_EMAIL':
            return{
                ...state,
                comelitEmail: action.payload
            }

        case 'CHANGE_EXPORT_TYPE':
            return {
                ...state,
                exportType: action.payload
            }

        case 'ADD_LINE_TO_BUDGET':
            let newBudgetLines = [...state.budget]
            let newLine = {
                "articleCode": action.payload.reference,
                "link": "",
                "image": "",
                "description": action.payload.description,
                "quantity": parseInt(action.payload.quantity),
                "type": "",
                "pvp": parseFloat(action.payload.pvp_und),
                "relatedArticles": 0,
                "sub_pvp": parseFloat(action.payload.pvp_und * action.payload.quantity),
                "dto_1": parseInt(action.payload.dto_1),
                "dto_2": parseInt(action.payload.dto_2),
                "dto_3": parseInt(action.payload.dto_3),
                "nt_und": parseFloat(action.payload.nt_und),
                "sub_tn": (action.payload.nt_und === '' ? action.payload.pvp_und : action.payload.nt_und) * action.payload.quantity
            }

            let additionalLinesIndex = newBudgetLines.findIndex(element => element.type === "additional_lines")

            if (additionalLinesIndex === -1){
                let newBudgetLinesType = {
                    type: "additional_lines",
                    plates: [{numPlate: null, content: [newLine]}]
                }
                newBudgetLines.push(newBudgetLinesType)
            } else{
                newBudgetLines[additionalLinesIndex].plates[0].content.push(newLine)
            }

            return{
                ...state,
                budget: newBudgetLines
            }

        case 'DELETE_ADITIONAL_LINE_OF_BUDGET':
            let newBudgetLinesDeleted = [...state.budget]
            let additionalLinesDeletedIndex = newBudgetLinesDeleted.findIndex(element => element.type === "additional_lines")
            newBudgetLinesDeleted[additionalLinesDeletedIndex].plates[0].content.splice(action.payload, 1)

            return{
                ...state,
                budget: newBudgetLinesDeleted
            }

        case 'CHANGE_INSTALLATION_COST':
            let newBudgetChangeInstallation = [...state.budget]
            let installationCostIndex = newBudgetChangeInstallation.findIndex(element => element.type === "installation")
            newBudgetChangeInstallation[installationCostIndex].pvp = action.payload

            return{
                ...state,
                budget: newBudgetChangeInstallation
            }
    
        default:
            return state
    }
}

// // Crear store
// const store = createStore(appReducer)