import { connect } from "react-redux";
import * as kitActions from '../store/actions/kitActions';

import { KitSimulator } from '../pages/KitSimulator'

const mapStateToProps = state => {
    return {        
        reference: state.reference,
        step: state.step,
        systemType: state.systemType,
        houses: state.houses,
        surface: state.surface,
        embedded: state.embedded,
        plateModel: state.plateModel,
        kit: state.kit,
        additionalAccess: state.additionalAccess,
        additionalInternalUnit: state.additionalInternalUnit,
        internalUnitsHouse: state.internalUnitsHouse,
        budget: state.budget,
        clientName: state.clientName,
        clientAtt: state.clientAtt,
        clientMail: state.clientMail,
        clientDirection: state.clientDirection,
        clientPhone: state.clientPhone,
        clientLogo: state.clientLogo,
        comelitComercial: state.comelitComercial,
        comelitEmail: state.comelitEmail,
        exportType: state.exportType
    }
}

const mapDispatchToProps = dispatch => {
    return {
        resetReducer: () => dispatch(kitActions.resetReducer()),
        changeReference: (reference) => dispatch(kitActions.changeReference(reference)),
        changeStep: (step) => dispatch(kitActions.changeStep(step)),
        changeSystemType: (type) => dispatch(kitActions.changeSystemType(type)),
        changeHouses: (houses) => dispatch(kitActions.changeHouses(houses)),
        changeSurface: (surface) => dispatch(kitActions.changeSurface(surface)),
        changeEmbedded: (embedded) => dispatch(kitActions.changeEmbedded(embedded)),
        changePlateModel: (plateModel) => dispatch(kitActions.changePlateModel(plateModel)),
        changeSelectedKit: (kitSelected) => dispatch(kitActions.changeSelectedKit(kitSelected)),
        changeAdditionalAccess: (additionalAccess) => dispatch(kitActions.changeAdditionalAccess(additionalAccess)),
        changeAdditionalInternalUnit: (additionalInternalUnit) => dispatch(kitActions.changeAdditionalInternalUnit(additionalInternalUnit)),
        changeInternalUnitHouse: (articleCode, house) => dispatch(kitActions.changeInternalUnitHouse(articleCode, house)),
        changeDiscount: (articleCode, type, target, value) => dispatch(kitActions.changeDiscount(articleCode, type, target, value)),
        changeDiscountToAllProducts: (discountName, value) => dispatch(kitActions.changeDiscountToAllProducts(discountName, value)),
        setBudget: (budget) => dispatch(kitActions.setBudget(budget)),
        changeClientName: (clientName) => dispatch(kitActions.changeClientName(clientName)),
        changeClientAtt: (clientAtt) => dispatch(kitActions.changeClientAtt(clientAtt)),
        changeClientMail: (clientMail) => dispatch(kitActions.changeClientMail(clientMail)),
        changeClientDirection: (clientDirection) => dispatch(kitActions.changeClientDirection(clientDirection)),
        changeClientPhone: (clientPhone) => dispatch(kitActions.changeClientPhone(clientPhone)),
        changeClientLogo: (clientLogo) => dispatch(kitActions.changeClientLogo(clientLogo)),
        changeComelitComercial: (comelitComercial) => dispatch(kitActions.changeComelitComercial(comelitComercial)),
        changeComelitEmail: (comelitEmail) => dispatch(kitActions.changeComelitEmail(comelitEmail)),
        changeExportType: (exportType) => dispatch(kitActions.changeExportType(exportType)),
        addLineToBudget: (budgetLine) => dispatch(kitActions.addLineToBudget(budgetLine)),
        deleteAditionalLineBudget: (additionalLineIndex) => dispatch(kitActions.deleteAditionalLineBudget(additionalLineIndex)),
        changeInstallationCost: (installationCost) => dispatch(kitActions.changeInstallationCost(installationCost))
    }
}

const createConnection = connect(
    mapStateToProps,
    mapDispatchToProps
)

export const KitSimulatorContainer = createConnection(KitSimulator)