import React, { Component } from 'react'

// import { SecondaryTitle } from '../elements/SecondaryTitle';
import { Separator } from '../elements/Separator';

import { Combo } from '../elements/Combo';
import { Button } from '../elements/Button';

import { loader, searchValueInObject } from '../../other/Utils';
import { getAvailableProductsStep, getRelatedProducts } from '../../other/ApiCalls';

export class StepFour extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            products: {},
            isLoading: true,
            modalVisible: false,
            modalArticle: '',
            modalType: '',
            modalProducts: []
        }
    }

    componentDidMount(){
        getAvailableProductsStep(null, this.props.systemType, null, null, 2)
            .then((products) => {
                this.setState({ products: products})
            })    
    }
    
    handleAccesoryQuantityChange = (articleCode, description, image, link, pvp, relatedArticles, category) => (event) => {
        if (articleCode === "6801W"){   
            this.setState({ isLoading: true, modalVisible: "--" })         
            getRelatedProducts(articleCode, this.props.simulatorType)
                .then(products => {
                    this.setState({ modalArticle: '6801W', modalProducts: products, modalType:'related', modalVisible: true, isLoading: false })
                    this.props.changeAccessoryQuantity(articleCode, description, image, link, pvp, relatedArticles, category, event.target.value)
                })            
        }
        else{            
            this.props.changeAccessoryQuantity(articleCode, description, image, link, pvp, relatedArticles, category, event.target.value)
        }
    }

    getMaxAccesories = (product) => {
        // El producto 1229A tiene límite en el número de viviendas, el resto 3 veces el número de viviendas
        let maxAccesory = product.article_code === "1229A" ? this.props.houses : this.props.houses * 3
        // Los artículos 1259C, 1256 y 1409, tienen límite el número de placas principales, secundarias y accesos adicionales
        if (["1259C", "1256", "1409"].includes(product.article_code)){
            // En los edificios no cuenta el acceso principal
            maxAccesory = ( this.props.simulatorType === "association" ? this.props.mainAccess : 0 ) + this.props.internalPortals
            this.props.secondaryPlates.forEach(secondaryPlate => {
                maxAccesory += secondaryPlate.numAditionalAccess
            });
        }
        return maxAccesory
    }

    checkIfAddProduct = (product) => {
        
        // Si es tipo MANCOMUNIDAD y AUDIO, no añadimos el elemento 6751W
        if (this.props.simulatorType === "association" && this.props.systemType === "audio" && product.article_code === "6751W" )
            return false

        // SK9050B/A y SK9052 solo deben salir si alguna placa tiene control de accesos BASIC o ADVANCE o existe el articulo UT9270
        if ( product.article_code === "SK9050B/A" || product.article_code === "SK9052"){
            // Comprobamos control de accesos en placas principales
            for (let i = 0; i < this.props.mainPlates.length; i++) {
                if (this.props.mainPlates[i].controlType === "basic" || this.props.mainPlates[i].controlType === "advance"){
                    return true
                }
            }
            // Comprobamos control de accesos en placas secundarias
            for (let i = 0; i < this.props.secondaryPlates.length; i++) {
                if (this.props.secondaryPlates[i].controlType === "basic" || this.props.secondaryPlates[i].controlType === "advance"){
                    return true
                }
            }
            // Comprobamos si existe el artículo UT9270 en alguna placa
            if ( searchValueInObject(this.props.mainPlates, "UT9270")) return true 
            if ( searchValueInObject(this.props.secondaryPlates, "UT9270")) return true
            
            // Si no cumple ninguna condición, no se añade
            return false
        }

        return true

    }

    acceptRelated = (articleCode) => {
        if (articleCode === "6801W"){

            // Vemos la cantidad de artículos de 6801W
            let articleIndex = this.props.accessories.findIndex(accesory => accesory.articleCode === articleCode)
            let numAccesory = this.props.accessories[articleIndex].quantity
            
            // Obtenemos la cantidad de articulos relacionados a este añadidos (6817, 6812 y 6820)
            let numRelated = 0
            // Articulo 6817
            articleIndex = this.props.accessories.findIndex(accesory => accesory.articleCode === "6817")
            numRelated += articleIndex !== -1 ? this.props.accessories[articleIndex].quantity : 0
            // Articulo 6812            
            articleIndex = this.props.accessories.findIndex(accesory => accesory.articleCode === "6812")
            numRelated += articleIndex !== -1 ? this.props.accessories[articleIndex].quantity : 0
            // Articulo 6820            
            articleIndex = this.props.accessories.findIndex(accesory => accesory.articleCode === "6820")
            numRelated += articleIndex !== -1 ? this.props.accessories[articleIndex].quantity : 0

            if ( numAccesory === numRelated ){                
                this.setState({
                    modalVisible: false,
                    modalArticle: '',
                    modalType: '',
                    modalProducts: []
                })
            }
            else{
                this.setState({
                    modalType: 'continue'
                })
            }
        }

    }

    renderAccesories(results, modal = false) {
        
        // Obtenemos las distintas categorías obtenidas
        let categories = [...new Set(results.map(product => product.ds_category))];
        let productsGrouped = {}

        results.forEach(product => {

            let addProduct = this.checkIfAddProduct(product)
            
            if (addProduct){
                if( !productsGrouped.hasOwnProperty(product.ds_category)){
                    productsGrouped[product.ds_category] = {
                        products: []
                    }
                }
                productsGrouped[product.ds_category].products.push(product)
            }
        });

        let renderedAccesories = []
        let key = 1
        categories.forEach(category => {
            let renderedCategory = []
            if (productsGrouped[category]){
                productsGrouped[category].products.forEach(product => {
                    let productAccesory = this.props.accessories.find( accessory => accessory.articleCode === product.article_code)

                    let maxAccesory = this.getMaxAccesories(product)

                    renderedCategory.push(      
                        <div className="border-2 border-green-comelit rounded-md border-opacity-10 h-full p-2" key={key}>    
                            <Combo 
                                key = { key }
                                dataKey = { product.article_code }
                                name = { product.article_code }
                                label = { product.description }
                                secondLabel = ""
                                onChange = { this.handleAccesoryQuantityChange(product.article_code, product.description, product.image_url, product.link, product.pvp, product.related_articles, category) }
                                value = { productAccesory ? productAccesory.quantity : 0 }
                                image = {                            
                                    <img src={ product.image_url.includes('http') ? product.image_url : `../products/${ product.image_url }` } 
                                    alt = {`${this.props.t("imagen_articulo")} ${product.article_code}`}
                                    title = { product.tooltip }
                                    className = "w-32 mx-auto"/>
                                }
                                options = {[...Array(maxAccesory + 1).keys()].map( i => i)}
                                t = {this.props.t}
                            />
                        </div>   
                    )
                    key += 1
                });

                renderedAccesories.push(
                    <div key={category} className={`${renderedAccesories.length > 0 ? 'mt-12' : ''}`}>
                        { renderedAccesories.length > 0 &&
                            <Separator /> }    
                        { !modal && <h3 className={`mt-8 mb-2 font-semibold text-center text-xl`}>{ category }</h3>}
                        <div className="grid items-center grid-cols-2 gap-4 sm:grid-cols-3 md:gap-8 auto-rows-max">
                            { renderedCategory }
                        </div>                    
                    </div>
                )
            }
        })

        if (this.state.isLoading && this.state.modalVisible !== "--")
            this.setState({ isLoading: false })

        return renderedAccesories
    }

    renderModal = (modalArticle, modalType, products) => {
        let renderedRelated = this.renderAccesories(products, true)
        return(
            <div>
                <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex items-center justify-center">
                    <div className="bg-white max-h-5/6 overflow-y-auto z-30 mt-4 mb-4 relative pt-6 pb-6 pl-4 pr-4 md:p-14 rounded mr-4 ml-4">
                        <div className='absolute cursor-pointer right-2 top-2'
                            onClick = { 
                                () => this.setState({ 
                                        modalVisible: false, 
                                        modalArticle: '',
                                        modalProducts: []
                                    })
                            }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className="text-center text-xl uppercase font-semibold">
                            {this.props.t("articulos_relacionados")}
                        </div>
                        {
                            modalType === "related" && 
                                <div>
                                    <div className="text-center text-lg mt-2 mb-4 font-semibold">
                                        {this.props.t("accesorio_necesario")}
                                    </div>
                                    { renderedRelated }
                                    <div className="text-center mt-6">
                                        <Button 
                                            type = 'normal' 
                                            text = {`${this.props.t("aceptar")}`}
                                            onClick = {() => this.acceptRelated(modalArticle) }
                                            className = 'p-2 pl-6 pr-6'
                                        />
                                    </div>
                                </div>
                        }
                        {
                            modalType === "continue" && 
                                <div>
                                    <div className="text-center text-lg mt-2 mb-4 font-semibold">
                                        {this.props.t("accesorios_diferencia_numero")}
                                    </div>
                                    <div className="text-center text-lg mt-2 mb-4 font-regular">
                                        {this.props.t("desea_continuar")}
                                    </div>
                                    <div className="text-center mt-6">
                                        <Button 
                                            text= {`${this.props.t("no")}`}
                                            type='secondary' 
                                            className='uppercase mt-2 sm:mt-0 w-28' 
                                            onClick = { () => this.setState({ modalType: 'related' })}
                                        />
                                        <Button 
                                            text= {`${this.props.t("si")}`}
                                            type='normal' 
                                            className='uppercase w-28' 
                                            onClick = { () => {
                                                this.setState({
                                                    modalVisible: false,
                                                    modalArticle: '',
                                                    modalType: '',
                                                    modalProducts: []
                                                })
                                            } }
                                        />
                                    </div>
                                </div>
                        }                        
                    </div>
                </div>
            </div>
        )
    }

    render() {
        
        let { products, isLoading, modalVisible, modalArticle, modalType, modalProducts } = this.state 
        let renderedAccesories = []
        
        if (products && products.length){
            renderedAccesories = this.renderAccesories(products)
        }

        let renderedModal = []
        if ( modalVisible === true ) {
            renderedModal = this.renderModal( modalArticle, modalType, modalProducts)
        }

        return (
            <div>     
                {isLoading 
                    && loader(this.props.t)
                }     
                {modalVisible 
                    && renderedModal
                }
                { renderedAccesories }
            </div>
        )
    }
}