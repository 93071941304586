import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { useFormFields } from '../other/helpers/hooksFormInput'
import { useDispatch, useSelector } from 'react-redux'
import { updatePasswordAction, clearUserAuthState } from '../store/actions/AuthActions'
import { displayErrorMessages } from '../other/helpers/displayErrors'
import { Separator } from '../components/elements/Separator'

export function ChangePassword(props) {    
    const dispatch = useDispatch()
    const authResponse = useSelector(state => state.userAuth.userAuthResponse)

    const initialForm = {
        password: '',
        new_password: '',
        new_password_confirmation: ''
    }

    const [fields, handleFieldChange] = useFormFields( initialForm )
        
    useEffect(
        () => { dispatch(clearUserAuthState()) }, []
    )

    const successMessage = (successMessage) => {
        return <div dangerouslySetInnerHTML={{__html:'<div class="p-4 bg-green-400 text-green-800">' + successMessage + '</div>'}} ></div>
    }
    
    const clearAuthErrDiv = () => {
        let authErr = document.querySelector("#authError");
        authErr.innerHTML = "";
    }

    const userChangePassword = (e) => {
        e.preventDefault()
        clearAuthErrDiv()
        
        dispatch(updatePasswordAction(fields, props))
    }
    
    return(
        <div className='home container mx-auto flex'>
            <form id="password_change" className="sm:w-3/4 w-full max-w-3xl border-2 pt-10 pb-10 pr-6 pl-6 rounded-md mt-14 mb-14" onSubmit={userChangePassword}>
                <h1 className='text-center text-green-comelit text-2xl mb-6 uppercase'>{props.t("modificar_contrasenha")}</h1>

                <label htmlFor="password" className="mr-2 font-semibold text-left w-36">{props.t("contrasenha_actual")}</label>
                <input type="password" name="password" id="password" placeholder={`${props.t("contrasenha_actual")}`} className="mb-4 mt-1 p-2 w-full border-2" autoComplete='off' value={fields.password} onChange={handleFieldChange}/>
                   
                <Separator className="mb-4" />

                <label htmlFor="new_password" className="mr-2 font-semibold text-left w-36">{props.t("nueva_contrasenha")}</label>
                <input type="password" name="new_password" id="new_password" placeholder={`${props.t("nueva_contrasenha")}`} className="mb-4 mt-1 p-2 w-full border-2" autoComplete='off' value={fields.new_password} onChange={handleFieldChange}/>

                <label htmlFor="new_password_confirmation" className="mr-2 font-semibold text-left w-36">{props.t("confirmar_contrasenha")}</label>
                <input type="password" name="new_password_confirmation" id="new_password_confirmation" placeholder={`${props.t("confirmar_contrasenha")}`} className="mb-4 mt-1 p-2 w-full border-2" autoComplete='off' value={fields.new_password_confirmation} onChange={handleFieldChange}/>

                <div id="authError" className='mt-2'></div>
                
                <div id="authResponse">
                {
                    authResponse !== '' && authResponse !== "loading" && authResponse.success === true
                        ? successMessage(authResponse.message)
                        : authResponse.success === false 
                            && displayErrorMessages(authResponse.error, document.querySelector("#authError"))
                }
                </div>
                
                <div className='text-center'>
                    <input type="submit" value={`${props.t("modificar_contrasenha").toUpperCase()}`} className="mx-auto mt-4 pt-4 pb-4 pl-8 pr-8 font-semibold rounded bg-green-comelit text-white cursor-pointer"/>
                </div>

                <Link to = {{pathname: '/'}} className="hover:cursor-pointer text-center">
                    <p className='mt-4 underline' >{props.t("volver_inicio")}</p>
                </Link>
            </form>
        </div>
    )
}