import { useTranslation } from 'react-i18next'

import './App.css';
import { Header } from './components/Header';
import { ContentApp } from './components/ContentApp';
import { Footer } from './components/Footer';

import React from 'react'

export default function App() {

  const { t } = useTranslation()

  // Valores dinámicos
  // Archivo de lenguaje:
  // "days_since_release": "Its been {{number_of_days}} sin release"

  // Llamada desde front
  // {this.props.t( 'days_since_release', { number_of_days } )}

  return (
    <div className="App">
        <Header t={t}/>
        <ContentApp t={t}/>
        <Footer t={t}/>
    </div>
  );
}