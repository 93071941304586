import HttpService  from './HttpService'

export const SignUpService = (credentials) => {

    const http = new HttpService()
    let signUpUrl = 'user/register'

    return http.postData(credentials, signUpUrl).then((data) => {
        return data
    })
    .catch((error) => {
        return error
    })
}

export const LoginUserService = (credentials) => {

    const http = new HttpService()
    let loginUrl = 'user/login'

    return http.postData(credentials, loginUrl).then((data) => {
        return data
    })
    .catch((error) => {
        return error
    })

}

export const LogoutUserService = () => {
    
    const http = new HttpService()
    let logoutUrl = 'user/logout'
    const tokenId = "user-token"
    
    return http.getData(logoutUrl, tokenId).then((data) => {
        return data
    })
    .catch((error) => {
        return error
    })

}

export const UpdatePasswordService = (credentials) => {

    const http = new HttpService()
    let updatePasswordUrl = 'user/update-password'

    return http.postData(credentials, updatePasswordUrl, "user-token").then((data) => {
        return data
    })
    .catch((error) => {
        return error
    })

}

export const RequestRestorePasswordService = (form) => {
    
    const http = new HttpService()
    let requestRestorePasswordUrl = 'user/request-reset-password'
    
    return http.postData(form, requestRestorePasswordUrl).then((data) => {
        return data
    })
    .catch((error) => {
        return error
    })
}

export const RestorePasswordService = (credentials) => {
    
    const http = new HttpService()
    let restorePasswordUrl = 'user/reset-password'
    
    return http.postData(credentials, restorePasswordUrl).then((data) => {
        return data
    })
    .catch((error) => {
        return error
    })
}

export const MailConfirmService = (mailToken) => {
    
    const http = new HttpService()
    let mailConfirmUrl = 'user/mail-confirm'
    
    return http.postData({"mail_token": mailToken}, mailConfirmUrl).then((data) => {
        return data
    })
    .catch((error) => {
        return error
    })
}