import React, { Component } from 'react'

import { Button } from './Button'

export class AlertCenterCombo extends Component{
    changeComboValue(props){
        let comboValue = document.querySelector("#comboAlert").value
        props.changeComboValue(comboValue)
    }

    render() {
        
        let { changeShowAlert } = this.props
        let { title, message, comboOptions, defaultValue } = this.props.alert

        return (
            <div>
                <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex items-center justify-center">
                    <div className="bg-white max-h-5/6 overflow-y-auto z-30 mt-4 mb-4 relative pt-6 pb-6 pl-4 pr-4 md:p-14 rounded mr-4 ml-4">
                        <div className='absolute cursor-pointer right-2 top-2'
                            onClick = { changeShowAlert(false, ) }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>

                        <div className="text-center text-xl uppercase font-semibold">
                            { title }
                        </div>
                        <div className="text-center text-lg mt-2 font-semibold">
                            { message }
                        </div>
                        <select id="comboAlert" defaultValue={defaultValue} className='mt-4 mb-8 bg-gray-200 p-2 hover:cursor-pointer border w-full'>
                            { comboOptions }
                        </select>
                        <div className="text-center mt-4">
                            <div className="text-center mt-4">
                                <Button 
                                    text={`${this.props.t("cancelar")}`}
                                    type='secondary' 
                                    className='uppercase mt-2 sm:mt-0 w-28' 
                                    onClick = { changeShowAlert(false) }
                                />
                                <Button 
                                    text={`${this.props.t("aceptar")}`}
                                    type='normal' 
                                    className='uppercase w-28' 
                                    onClick = { () => this.changeComboValue(this.props) }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}   