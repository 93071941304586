import React from 'react';

import { Text, View, Image, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import fontThin from '../../../fonts/ttf/HelveticaNeueLTW1G-Th.ttf'
import fontBold from '../../../fonts/ttf/HelveticaNeueLTW1G-Bd.ttf'
import fontBlack from '../../../fonts/ttf/HelveticaNeueLTW1G-Blk.ttf'
import fontMedium from '../../../fonts/ttf/HelveticaNeueLTW1G-Md.ttf'

Font.register({
    family: 'Helvetica Neue',
    fonts: [
        { src: fontThin, fontWeight: 'thin' },
        { src: fontBold, fontWeight: 'bold' },
        { src: fontBlack, fontWeight: 'heavy' },
        { src: fontMedium, fontWeight: 'medium' },
    ]
  })

const styles = StyleSheet.create({
    fontLight:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'light' 
    },
    fontBold:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'bold' 
    },
    header:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    image:{
        width: '150px',
        height: 'auto'
    },
    subheader:{
        textAlign: 'center',
        width: '100%'
    },
    title:{
        color: '#009640',
        fontSize: '14',
        padding: '5px 10px 5px 10px',
        fontFamily: 'Helvetica Neue',
        fontWeight: 'bold'
    },
    subtitle:{
        width: '100%',
        fontFamily: 'Helvetica Neue',
        fontWeight: 'bold'
    },
    date:{
        width: '100%',
        fontFamily: 'Helvetica Neue',
        fontWeight: 'light',
        fontSize: 10
    },
    info:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: '20px',
        marginBottom: '10px'
    }
})

let date = new Date()

const PDFHeader = ({props}) => (
    <View fixed>
        <View style={styles.header}>
            {
                // Si es oferta para terceros se pone el logo del cliente si existe, sino el de Comelit Comelit
                props.thirdPartyBudget 
                    ? props.clientLogo.image.src 
                        ? <Image style={styles.image} src={props.clientLogo.image.src}/>
                        : <Text style={{width: '150px'}}>    </Text>
                    : <Image style={styles.image} src='../logo.png'/>
            }
            {/* <Image style={styles.image} src='../logo.png'/> */}
            <View style={styles.subheader}>
                <Text style={styles.title}>
                    { ([3,4].includes(props.userInfo.id_user_type) && !props.thirdPartyBudget) ? `${props.t("auto_presupuesto")}` : `${props.t("presupuesto")}` }
                </Text>
                <Text style={styles.subtitle}>{props.budgetReference}</Text>
                <Text style={styles.date}>{date.toLocaleDateString('es-ES')}</Text>
            </View>
            {
                // Si es oferta para terceros no tenemos el logo del tercero, va sin logo
                ( !props.thirdPartyBudget && props.clientLogo.image.src )
                    ? <Image style={styles.image} src={props.clientLogo.image.src}/>
                    : <Text style={{width: '150px'}}>    </Text>
            }
        </View>                
        <View style={styles.info}>
                {
                    props.thirdPartyBudget
                        ? 
                            <View style={{ flexBasis: '45%' }}>
                                <Text style={styles.fontBold}>{ props.accountInfo.name }</Text>
                                <Text style={styles.fontLight}>{ props.accountInfo.address1 }</Text>
                                <Text style={styles.fontLight}>{ props.accountInfo.postcode } { props.accountInfo.city } ({ props.accountInfo.region })</Text>
                                <Text style={styles.fontLight}>{ props.accountInfo.phone }</Text>
                                <Text style={styles.fontLight}>{ props.accountInfo.email }</Text>                
                            </View>
                        :
                            <View style={{ flexBasis: '45%'}}>
                                <Text style={styles.fontBold}>{props.t("firma_comelit")}</Text>
                                <Text style={styles.fontLight}>{props.t("direccion_comelit")}</Text>
                                <Text style={styles.fontLight}>{props.t("telefono_completo")}</Text>
                                <Text style={styles.fontLight}>{props.t("comercial")}: { props.comelitComercial }</Text>
                                <Text style={styles.fontLight}>{props.t("email")}: { props.comelitEmail }</Text>
                            </View>
                }
                {
                    props.thirdPartyBudget
                        ? 
                            <View style={{ flexBasis: '45%' }}>
                                <Text style={styles.fontBold}>{ props.thirdPartyFields.social_reason }</Text>
                                <Text style={styles.fontLight}>{ props.thirdPartyFields.att }</Text>
                                <Text style={styles.fontLight}>{ props.thirdPartyFields.address }</Text>
                                <Text style={styles.fontLight}>{ props.thirdPartyFields.phone }</Text>
                                <Text style={styles.fontLight}>{ props.thirdPartyFields.email }</Text>                
                            </View>
                        :
                            (props.userInfo.id_user_type === 3 || (props.userInfo.id_user_type === 4 && props.accountInfo.length > 0))
                                ?
                                    <View style={{ flexBasis: '45%' }}>
                                        <Text style={styles.fontBold}>{ props.accountInfo.name }</Text>
                                        <Text style={styles.fontLight}>{ props.accountInfo.address1 }</Text>
                                        <Text style={styles.fontLight}>{ props.accountInfo.postcode } { props.accountInfo.city } ({ props.accountInfo.region })</Text>
                                        <Text style={styles.fontLight}>{ props.accountInfo.phone }</Text>
                                        <Text style={styles.fontLight}>{ props.accountInfo.email }</Text>                
                                    </View>
                                : 
                                    <View style={{ flexBasis: '45%' }}>
                                        <Text style={styles.fontBold}>{ props.clientName }</Text>
                                        <Text style={styles.fontLight}>{ props.clientAtt }</Text>
                                        <Text style={styles.fontLight}>{ props.clientDirection }</Text>
                                        <Text style={styles.fontLight}>{ props.clientPhone }</Text>
                                        <Text style={styles.fontLight}>{ props.clientMail }</Text>                
                                    </View>
                }                
        </View>
    </View>    
)

export default PDFHeader