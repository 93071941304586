import React, { Component } from 'react'

import { ButtonPrevious } from '../components/elements/ButtonPrevious'
import { ButtonNext } from '../components/elements/ButtonNext'
import { MainTitle } from '../components/elements/MainTitle'
import { Collapsible } from '../components/elements/Collapsible'
import { Separator } from '../components/elements/Separator'
import { AlertCenter } from '../components/elements/AlertCenter'
import { CheckBoxInput } from '../components/elements/CheckBoxInput'
import { ButtonScrollDown } from '../components/elements/ButtonScrollDown'
import { InputText } from '../components/elements/InputText'

import { SystemType } from '../components/compounds/SystemType'
import { EdificeInfo } from '../components/compounds/EdificeInfo'
import { InstalationType } from '../components/compounds/InstalationType'
import { PortalInfo } from '../components/compounds/PortalInfo'
import { Plates } from '../components/compounds/Plates'
import { InstalationSummary } from '../components/compounds/InstalationSummary'
import { StepThree } from '../components/compounds/StepThree'
import { StepFour } from '../components/compounds/StepFour'
import StepFive from '../components/compounds/StepFive'

import { scrollTop, searchValueInObject } from '../other/Utils'
import { sumPropertyInArray } from '../other/Utils'

export class EdificeSimulator extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alert: {
                showAlert: false,
                title: '',
                message: '',
                continueText: false,
                buttons: false,
                type: ''
            }
        }
    }

    componentDidMount(){
        this.props.resetReducer()
        scrollTop()
    }

    handleNextStep = (step) => (e) => {
        let nextStep = true
        if (step === 1){
            nextStep = this.checkTotalHomes()
        }

        if (step === 4){
            nextStep = this.checkInternalUnits()
        }

        if (nextStep) { 
            this.props.changeStep(step+1)
            scrollTop()
        }
    }

    handlePreviousStep = (step) => (e) => {
        this.props.changeStep(step-1)
        scrollTop()
    }

    changeShowAlert = (response) => (e) => {
        // let pasoActualState = this.state.pasoActual
        if (response) {

            if (this.state.alert.type === 'checkTotalHomes'){            
                this.props.changeStep(2)
            }
                
            if (this.state.alert.type === 'checkInternalUnits' && response){            
                this.props.changeStep(5)
            }        
            scrollTop()
        }

        this.setState({
            alert: {
                ...alert,
                showAlert: !this.state.alert.showAlert
            }
        })
    }

    checkTotalHomes = () => {
        let homesPortals = parseInt(sumPropertyInArray(this.props.portalInfo, 'homes'))
        let homesInfo = parseInt(this.props.houses)

        if (homesPortals !== homesInfo){
            this.setState({
                alert: {
                    showAlert: true,
                    title: `${this.props.t("numero_viviendas_total")}`,
                    message: `${this.props.t("numero_viviendas_no_coincide")}`,
                    continueText: true,
                    buttons: true,
                    type: 'checkTotalHomes'
                }
            })
            return false
        }
        return true
    }

    checkInternalUnits = () => {
        let homesInfo = parseInt(this.props.houses)
        let totalInternalUnits = 0

        let internalUnits = this.props.accessories.filter(accesory => accesory.category === "UNIDADES INTERNAS")
        totalInternalUnits = internalUnits.reduce((totalInternalUnits, accesory) => {
                return totalInternalUnits + accesory.quantity
            }, 0
         )

        if (totalInternalUnits > homesInfo * 3){
            this.setState({
                alert: {
                    showAlert: true,
                    title: `${this.props.t("número_de_unidades_internas")}`,
                    message: `${this.props.t("unidades_internas_mayor")}`,
                    continueText: true,
                    buttons: true,
                    type: 'checkInternalUnits'
                }
            })
            return false
        }
        else if (totalInternalUnits !== homesInfo){
            this.setState({
                alert: {
                    showAlert: true,
                    title: `${this.props.t("número_de_unidades_internas")}`,
                    message: `${this.props.t("unidades_internas_no_coincide")}`,
                    continueText: true,
                    buttons: true,
                    type: 'checkInternalUnits'
                }
            })
            return false
        }
        return true
    }

    checkNextStep = (step, simulatorType) => {
        if (step === 1) {
            return this.checkStep_One(simulatorType)
        }
        if (step === 2){
            return this.checkStep_Two(simulatorType)
        }
        if (step === 3){
            return this.checkStep_Three()
        }
        return step === 4
    }

    checkStep_One = (simulatorType) => {
        
        if (this.props.systemType === '') return false
        // if (this.state.tipoTecnologia === '') return false
        if (this.props.houses === '') return false
        if (this.props.portalInfo === undefined) return false
        if (simulatorType === "association" && this.props.systemType !== "audio" && this.props.mainAccess > 0 && this.props.internalPortals > 1 && this.props.instalationType === '') return false
        return true
    }
    
    checkStep_Two = (simulatorType) => {
        // Si hay placas principales pero con algún elemento vacío
        if (simulatorType === "association" && Object.keys(this.props.mainPlates).length !== 0 && searchValueInObject(this.props.mainPlates,'')) return false
        // Si hay seleccionada consejeria en las placas principales, que haya seleccionado tipo de sistema
        if (this.props.mainConcierge && this.props.conciergeSystemType === "") return false
        // Si hay placas secundarias (SIEMPRE) con algún elemento vacío
        return !searchValueInObject(this.props.secondaryPlates,'')
    }
    
    checkStep_Three = () => {       
        
        // Si hay placas principales sin acabar de configurar
        if (this.props.mainPlates.filter(plate => { return plate.configurationComplete === false }).length > 0) return false
        // Si hay placas secundarias sin acabar de configurar
        if (this.props.secondaryPlates.filter(plate => { return plate.configurationComplete === false }).length > 0) return false
        // Si hay placas secundarias adicionales sin acabar de configurar
        for (let i = 0; i < this.props.secondaryPlates.length; i++) {
            let secondaryPlate = this.props.secondaryPlates[i];
            if (secondaryPlate.additionalContents.filter(additionalPlate => { return additionalPlate.configurationComplete === false }).length > 0) return false
        }
        return true
    
    }

    renderStep_One = (simulatorType) => {        
        return ( 
            <div>
                <InputText 
                    name = "reference"
                    label = {`${this.props.t("referencia")}: `}
                    value = {this.props.reference}
                    onChangeText = {this.props.changeReference}
                />
                <Collapsible title = {`${this.props.t("tipo_sistema")}`} >
                    <SystemType type = "edificio"
                        onChange = { this.props.changeSystemType}
                        selected = { this.props.systemType }
                        t = { this.props.t }
                    />                    
                    {/*
                        <Separator/>
                        <TechnologyType type = "edificio"
                            onChange = { this.handleChange("TechnologyType") }
                            selected = { tipoTecnologia }
                        />
                    */}
                     
                </Collapsible>

                <Collapsible title = {`${this.props.t("datos_edificio")}`} >
                    <EdificeInfo 
                        houses = { this.props.houses }
                        mainAccess = { this.props.mainAccess }                        
                        internalPortals = { this.props.internalPortals }
                        onChangeEdificeInfo = { this.props.changeEdificeInfo }
                        simulatorType = { simulatorType }
                        t = { this.props.t }
                    /> 
                </Collapsible>

                <Collapsible title = {`${this.props.t("distribucion_sistema")}`} >
                    <PortalInfo 
                        onChangePortalInfo = { this.props.changePortalInfo }
                        internalPortals = { this.props.internalPortals }
                        portalInfo = { this.props.portalInfo }
                        onChangeElementPortalInfo = { this.props.changeElementPortalInfo }
                        setPortalsNotApplied = { this.props.setPortalsNotApplied }
                        restartPlateContents = { this.props.restartPlateContents }
                        t = { this.props.t }
                    /> 
                    {this.props.mainAccess > 0 
                        && this.props.internalPortals > 1 
                        && simulatorType === "association"
                        && this.props.systemType !== "audio"
                        && 
                            <div>
                                <Separator/>
                                <InstalationType onChangeInstalationType = { this.props.changeInstalationType }
                                    selected = { this.props.instalationType }
                                    portalesInternos = { this.props.internalPortals }
                                    accesosPrincipales = { this.props.mainAccess }
                                    t = {this.props.t}
                                /> 
                            </div>                            
                    }
                </Collapsible>
            </div>
        )
    }

    renderStep_Two = (simulatorType) => {
        return ( 
            <div>
                <InstalationSummary 
                    reference = {this.props.reference}
                    systemType = {this.props.systemType}
                    mainAccess = {this.props.mainAccess}
                    internalPortals = {this.props.internalPortals}
                    portalInfo = {this.props.portalInfo}
                    houses = {this.props.houses}
                    simulatorType = {simulatorType}
                    t = {this.props.t}
                />
                <Collapsible title = {`${this.props.t("definicion_placas")}`} >
                    { simulatorType === "association" && this.props.mainAccess > 0 && 
                        <div>
                            <Plates 
                                type = 'main'
                                simulatorType = {simulatorType}
                                platesSection = { this.props.mainAccess }
                                totalPlates = { this.props.mainAccess }
                                plates = { this.props.mainPlates }
                                handlePlatesChange = { this.props.changeMainPlates }
                                handlePlateApply = { this.props.handlePlateApply }
                                houses = { this.props.houses }
                                t = {this.props.t}
                            />
                            <Separator/>
                            <CheckBoxInput 
                                name = "mainConcierge"
                                label = {this.props.t("conserjería")}
                                onChange = { this.props.changeMainConcierge }
                                checked = {this.props.mainConcierge}
                            />
                            {this.props.mainConcierge && this.props.systemType === "video" &&
                                <SystemType 
                                    type = "concierge"
                                    onChange = { this.props.changeConciergeSystemType}
                                    selected = { this.props.conciergeSystemType }
                                    t = { this.props.t }
                                /> 
                            }
                            <Separator mode="big" />
                        </div>
                    }
                    <Plates 
                        type = 'secondary'
                        simulatorType = {simulatorType}
                        portalInfo = {this.props.portalInfo}
                        platesSection = { this.props.internalPortals }
                        totalPlates = { this.props.internalPortals }
                        plates = { this.props.secondaryPlates }
                        systemType = { this.props.systemType }
                        handlePlatesChange = { this.props.changeSecondaryPlates }
                        handlePlateApply = { this.props.handlePlateApply }
                        houses = { this.props.houses }
                        t = {this.props.t}
                    /> 
                </Collapsible> 
            </div>
        )
    }
    
    renderStep_Three = (simulatorType) => {
        return ( 
            <div>
                <InstalationSummary 
                    reference = {this.props.reference}
                    systemType = {this.props.systemType}
                    mainAccess = {this.props.mainAccess}
                    internalPortals = {this.props.internalPortals}
                    portalInfo = {this.props.portalInfo}
                    houses = {this.props.houses}
                    simulatorType = {simulatorType}
                    t = {this.props.t}
                />
                <StepThree 
                    systemType = { this.props.systemType }
                    mainPlates = {this.props.mainPlates}
                    secondaryPlates = {this.props.secondaryPlates}
                    portalInfo = { this.props.portalInfo }
                    houses = {this.props.houses}
                    simulatorType = {simulatorType}
                    changePlateAssemblyType = { this.props.changePlateAssemblyType }
                    addSubPlate = { this.props.addSubPlate }
                    removeSubPlate = { this.props.removeSubPlate }
                    addSlotToSubPlate = { this.props.addSlotToSubPlate }
                    removeSlotOfSubPlate = { this.props.removeSlotOfSubPlate }
                    addItemToSlot = { this.props.addItemToSlot }
                    removeItemOfSlot = { this.props.removeItemOfSlot }
                    addItemToFirstSlot = { this.props.addItemToFirstSlot }
                    restartPlateDisplay = { this.props.restartPlateDisplay }
                    restartPlatePushButtons = { this.props.restartPlatePushButtons }
                    setPlateVisorValue = { this.props.setPlateVisorValue }
                    setPlateTrimFrameValue = { this.props.setPlateTrimFrameValue }
                    changePlateConfigurated = { this.props.changePlateConfigurated }
                    applyDisplayConfiguration = { this.props.applyDisplayConfiguration }
                    addPushButtonToSlot = { this.props.addPushButtonToSlot }
                    addPushButtonToAllPlate = { this.props.addPushButtonToAllPlate }
                    addPushButtonToFirstSlot = { this.props.addPushButtonToFirstSlot }
                    t = {this.props.t}
                />
            </div>
        )
    }
    
    renderStep_Four = (simulatorType) => {
        return ( 
            <div>
                <InstalationSummary 
                    reference = {this.props.reference}
                    systemType = {this.props.systemType}
                    mainAccess = {this.props.mainAccess}
                    internalPortals = {this.props.internalPortals}
                    portalInfo = {this.props.portalInfo}
                    houses = {this.props.houses}
                    simulatorType = {simulatorType}
                    t = {this.props.t}
                />
                <StepFour
                    systemType = { this.props.systemType }
                    accessories = { this.props.accessories }
                    changeAccessoryQuantity = { this.props.changeAccessoryQuantity }
                    houses = {this.props.houses}
                    simulatorType = {simulatorType}
                    mainAccess = {this.props.mainAccess}
                    internalPortals = {this.props.internalPortals}
                    portalInfo = {this.props.portalInfo}
                    mainPlates = {this.props.mainPlates}
                    secondaryPlates = { this.props.secondaryPlates }
                    t = {this.props.t}
                />
            </div>
        )
    }

    renderStep_Five = (simulatorType) => {
        return ( 
            <div>
                <InstalationSummary 
                    reference = {this.props.reference}
                    systemType = {this.props.systemType}
                    mainAccess = {this.props.mainAccess}
                    internalPortals = {this.props.internalPortals}
                    portalInfo = {this.props.portalInfo}
                    houses = {this.props.houses}
                    simulatorType = { simulatorType }
                    t = {this.props.t}
                />
                <StepFive
                    systemType = { this.props.systemType } 
                    mainAccess = { this.props.mainAccess }
                    mainConcierge = { this.props.mainConcierge }
                    conciergeSystemType = { this.props.conciergeSystemType }
                    internalPortals = { this.props.internalPortals }
                    instalationType = { this.props.instalationType }
                    mainPlates = { this.props.mainPlates }
                    secondaryPlates = { this.props.secondaryPlates }
                    accessories = { this.props.accessories }
                    reference = { this.props.reference }
                    simulatorType = { simulatorType }
                    portalInfo = { this.props.portalInfo }
                    houses = {this.props.houses}
                    budget = {this.props.budget}
                    setBudget = {this.props.setBudget}
                    changeDiscount = {this.props.changeDiscount}
                    changeDiscountToAllProducts = {this.props.changeDiscountToAllProducts}
                    clientName = { this.props.clientName }
                    clientAtt = { this.props.clientAtt }
                    clientMail = { this.props.clientMail }
                    clientDirection = { this.props.clientDirection }
                    clientPhone = { this.props.clientPhone }
                    clientLogo = { this.props.clientLogo }
                    comelitComercial = { this.props.comelitComercial }
                    comelitEmail = { this.props.comelitEmail }
                    changeComelitComercial = { this.props.changeComelitComercial }
                    changeComelitEmail = { this.props.changeComelitEmail }
                    changeClientName = { this.props.changeClientName }
                    changeClientAtt = { this.props.changeClientAtt }
                    changeClientMail = { this.props.changeClientMail }
                    changeClientDirection = { this.props.changeClientDirection }
                    changeClientPhone = { this.props.changeClientPhone }
                    changeClientLogo = { this.props.changeClientLogo }
                    exportType = { this.props.exportType }
                    changeExportType = { this.props.changeExportType }
                    addLineToBudget = { this.props.addLineToBudget }
                    deleteAditionalLineBudget = { this.props.deleteAditionalLineBudget }
                    changeInstallationCost = { this.props.changeInstallationCost }
                    t = {this.props.t}
                />
            </div>
        )
    }

    render() {
        
        //console.log("EdificeSimulator PROPS: ", this.props);

        let simulatorType = this.props.match.path === "/edificio" ? "edifice" : "association"

        simulatorType !== "edifice" 
            && this.props.mainAccess > 0 
            && this.props.internalPortals > 9 
            && this.props.changeEdificeInfo('internalPortals', 9)

        let stepRender
        if (this.props.step === 1) {
            stepRender = this.renderStep_One(simulatorType)
        }
        if (this.props.step === 2) {
            stepRender = this.renderStep_Two(simulatorType)
        }
        if (this.props.step === 3) {
            stepRender = this.renderStep_Three(simulatorType)
        }
        if (this.props.step === 4) {
            stepRender = this.renderStep_Four(simulatorType)
        }
        if (this.props.step === 5) {
            stepRender = this.renderStep_Five(simulatorType)
        }

        return ( 
            <div className = "container relative mx-auto" >  
                {
                    this.state.alert.showAlert && 
                        <AlertCenter 
                            alert = { this.state.alert }
                            changeShowAlert = {this.changeShowAlert}
                            t = { this.props.t }
                        />
                }           
                <MainTitle> { this.props.match.path === "/edificio" ? `${this.props.t("edificio")}` : `${this.props.t("mancomunidad")}`} </MainTitle>
                { stepRender } 
                <ButtonScrollDown />
                <div className = "flex justify-between ">
                    <ButtonPrevious onClick = { this.handlePreviousStep(this.props.step) }
                        step = { this.props.step }
                        resetReducer = { this.props.resetReducer }
                        t = { this.props.t }
                    /> 
                    { this.props.step !== 5 &&
                        <ButtonNext onClick = { this.handleNextStep(this.props.step) }
                            disabled = {!this.checkNextStep(this.props.step, simulatorType) }
                            t = { this.props.t }
                        /> 
                    }
                </div>
            </div>
        )
    }
}