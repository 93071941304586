import React, { useEffect, useState } from 'react'
import { useTable, useFilters, useGlobalFilter } from 'react-table'
import { useDispatch, useSelector } from 'react-redux'

// A great library for fuzzy filtering/sorting items
import { matchSorter } from 'match-sorter'
import HttpService from '../../services/HttpService'
import { AlertCenter } from './AlertCenter'
import { areSameObjects, loader } from '../../other/Utils'
import { AlertCenterCombo } from './AlertCenterCombo'
import AlertCRMRelation from './AlertCRMRelation'
import AlertUserInfo from './AlertUserInfo'
import AlertCenterCountrySwitcher from './AlertCenterCountrySwitcher'

import { requestRestorePasswordAction } from '../../store/actions/AuthActions'

export default function UsersTable(props) {

    const dispatch = useDispatch()
    const authResponse = useSelector(state => state.userAuth.userAuthResponse)

    const [users, setUsers] = useState([])
    const [userTypes, setUserTypes] = useState([])
    const [alert, setAlert] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    
    const userLocation = localStorage.getItem("comelit-user-language")
    
    const http = new HttpService()

    useEffect(() => {

        http.getData("admin/get-user-types", "admin-token")
        .then( userTypes => {
            let comboOptions = []
        
            for (let i = 0; i < userTypes.length; i++) {
                const userType = userTypes[i];
                comboOptions.push(
                    <option key={i} value={userType.id}>{userType.ds_type}</option>
                )
            }

            setUserTypes(comboOptions)
        })
    }, [])

    useEffect(() => {

        let fetched = true
        http.getData("admin/get-users", "admin-token")
        .then( users => {
            setUsers(users)
        })

        return () => fetched = false
    }, [alert])

    const columns = React.useMemo(
        () => [
            {
                Header: '',
                accessor: '1',
                filterable: false,
                Cell: ({ cell }) => (
                    <div onClick = { () => showUserInfo(cell.row.original) } className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto text-green-comelit" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9 9a2 2 0 114 0 2 2 0 01-4 0z" />
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a4 4 0 00-3.446 6.032l-2.261 2.26a1 1 0 101.414 1.415l2.261-2.261A4 4 0 1011 5z" clipRule="evenodd" />
                        </svg>
                    </div>
                )
            },
            {
                Header: `${props.t("empresa")}`,
                accessor: 'company',
                filterable: true
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: true
            },
            {
                Header: `${props.t("tipo")}`,
                accessor: 'user_type',
                filterable: true,
                Cell: ({ cell }) => (                              
                    <div value={cell.row.values.name} 
                        onClick={ () => handleClickUserType(cell.row.original.id, cell.row.original.id_user_type, userTypes) }
                        className = "flex justify-between cursor-pointer items-center"
                    >
                        { cell.row.original.user_type }
                        <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5 w-5 text-green-comelit" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                    </div>
                )
            },
            { 
                Header: `${props.t("relacion_crm")}`,
                accessor: '',
                filterable: false,
                Cell: ({ cell }) => (
                    
                    <div onClick = { changeUserCRMRelation(cell.row.original.id, cell.row.original.id_user_crm, cell.row.original.id_account_crm, cell.row.original.user_type) } className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto text-green-comelit" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                        </svg>
                    </div>
                )
            },
            {
                Header: `${props.t("activo")}`,
                accessor: 'active',
                filterable: true,
                Filter: BooleanColumnFilter,
                Cell: ({ cell }) => (
                    <div onClick = { changeUserActive(cell.row.original.id, cell.row.original.active, cell.row.original.email) } className="cursor-pointer" >
                    { 
                        cell.row.original.active === 1 
                            ? 
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto text-green-comelit" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto text-red-700" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                            </svg>
                    }
                </div>
                )
            },
            {
                Header: '',
                accessor: '2',
                filterable: false,
                Cell: ({ cell }) => (
                    <div onClick = { () => showResetPassword(cell.row.original.email) } className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto text-green-comelit" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clipRule="evenodd" />
                        </svg>
                    </div>
                )
            },
            {
                Header: 'País',
                accessor: '3',
                filterable: false,
                Cell: ({ cell }) => (
                    <div onClick = { () => showSelectUserCountry(cell.row.original) } className="cursor-pointer">
                        <div value = {cell.row.original.country_abbreviation} className = {`flag ${cell.row.original.country_abbreviation} mx-auto`} ></div>
                    </div>
                )
            },
            {
                Header: '',
                accessor: '4',
                filterable: false,
                Cell: ({ cell }) => (
                    <div onClick = { () => showDeleteUser(cell.row.original.id) } className="cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mx-auto text-green-comelit" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                        </svg>
                    </div>
                )
            },
        ],
        []
    )
    
    function BooleanColumnFilter( {column: { filterValue, setFilter, preFilteredRows, id },} ){
        // Render a select box
        return (
            <select
                value={filterValue}
                onChange={e => { setFilter(e.target.value || undefined) }}
                className='p-1 pr-2 text-sm mt-2 w-full'
            >
                <option value=""></option>
                <option key={1} value={1}>{props.t("si").toUpperCase()}</option>
                <option key={2} value={0}>{props.t("no").toUpperCase()}</option>
            </select>
        )
    }

    function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter }, }) {
        return (
            <input
                value = { filterValue || '' }
                onChange = {
                    e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                className = "p-1 text-sm mt-2 w-full"
                placeholder = {`${props.t("filtrar")}`}
            />
        )
    }
    
    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }
    
    const changeAlertResult = (response) => (resul) => {
        
        if (alert.type === "userStatus" && response){
            http.postData({ 
                userId: alert.userId,
                value: !alert.actualValue,
                user_location: userLocation
            }, "admin/user-state", "admin-token")
            .then((resul) => {
                
                if (resul.success){
                    setAlert([])
                }
                else{
                    setAlert({
                        title: `${props.t("estado_usuario").toUpperCase()}`,
                        message: resul.message,
                        buttons: true,
                        buttonsType: "close",
                        type: "genericAlert"
                    })
                }
                return
            })
        }

        if (alert.type === "userPassword" && response){
            dispatch(requestRestorePasswordAction({email: alert.userEmail}, null))
        }

        if (alert.type === "userDelete" && response){
            http.deleteData({ 
                userId: alert.userId
            }, "admin/user", "admin-token")
            .then((resul) => {
                
                if (resul.success){
                    setAlert({
                        title: `${props.t("usuario_eliminado")}`,
                        message: `${props.t("usuario_eliminado_correctamente")}`,
                        buttons: true,
                        buttonsType: "close",
                        type: "genericAlert"
                    })
                }
                else{
                    setAlert({
                        title: `${props.t("usuario_no_eliminado")}`,
                        message: `${props.t("usuario_no_eliminado_error")}`,
                        buttons: true,
                        buttonsType: "close",
                        type: "genericAlert"
                    })
                }
                return
            })
        }
        
        if (alert.type === "userInfo" && response){
            setAlert({
                title: `${props.t("usuario_modificado")}`,
                message: `${props.t("usuario_modificado_correctamente")}`,
                buttons: true,
                buttonsType: "close",
                type: "genericAlert"
            })
            return
        }
        
        setAlert([])
    }

    const changeAlertComboResult = (response) => (resul) => {
        setAlert([])
    }

    const changeComboValue = (value) => {
        if ( alert.type === "userType"){
            let userId = alert.userId

            http.postData({
                userId: userId,
                value: value
            },"admin/user-type", "admin-token")            
        }

        if ( alert.type === "userCountrySelect"){
            http.postData({
                id: alert.user.id,
                country_id: value
            },"admin/user-country", "admin-token")            
        }

        setAlert([])
    }

    const changeUserActive = (userId, actualValue, userEmail) => (e) => {
        e.preventDefault()

        let message = actualValue ? `${props.t("desactivar_usuario")}` : `${props.t("activar_usuario")}`

        setAlert({
            title: `${props.t("estado_usuario")}`,
            message: message,
            buttons: true,
            userId: userId,
            userEmail: userEmail,
            actualValue: actualValue,
            type: "userStatus"
        })
    }

    const handleClickUserType = ( userId, id_user_type) => {
        let message = `${props.t("modificar_tipo_usuario")}`
        
        setAlert({ 
            title: `${props.t("tipo_usuario")}`,
            message: message,
            defaultValue: id_user_type,
            buttons: true,
            continueText: true,
            userId: userId,
            type: "userType"
        })
    }
    
    const changeUserCRMRelation = (userId, crmUserId, crmAccountID, userType) => (e) => {
        e.preventDefault()

        setAlert({ 
            userId: userId,
            crmUserId: crmUserId,
            crmAccountID: crmAccountID,
            userType: userType,
            type: "userCRM"
        })
    }

    const showUserInfo = (user) => {

        setIsLoading(true)
        let idAccountCRM = user.id_account_crm
        let idUserCRM = user.id_user_crm
        let endPoint = 'accounts/1'

        if (idAccountCRM)
            endPoint = `accounts/${idAccountCRM}`
        if (idUserCRM)
            endPoint = `users/${idUserCRM}`
            
        // Obtenemos la relación con el CRM
        http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + endPoint )
        .then( info => {
            
            let crmRelation = idAccountCRM ? info.name : idUserCRM ? info.nic : ''
            let applyRates = [1,2].includes(user.id_user_type) ? `${props.t("si")}` : user.apply_rate ? `${props.t("si")}` : `${props.t("no")}`

            setAlert({
                userInfo: user,
                crmRelation: crmRelation,
                applyRates: applyRates,
                idAccountCRM: idAccountCRM,
                type: "userInfo"
            })

            setIsLoading(false)
        })
    }
    
    const showResetPassword = (email) => {
        let message = `${props.t("enviar_mail_contrasenha")}`

        setAlert({
            title: `${props.t("restablecer_contrasenha")}`,
            message: message,
            buttons: true,
            userEmail: email,
            type: "userPassword"
        })
    }
    
    const showDeleteUser = (userId) => {
        let message = `${props.t("eliminar_usuario_pregunta")}`

        setAlert({
            title: `${props.t("eliminar_usuario")}`,
            message: message,
            buttons: true,
            userId: userId,
            type: "userDelete"
        })
    }

    const showSelectUserCountry = (user) => {      
        let message = `${props.t("seleccione_pais")}`

        setAlert({
            title: `${props.t("modificar_pais")}`,
            message: message,
            buttons: true,
            user: user,
            type: "userCountrySelect"
        })
    }

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = val => !val
    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            })
          },
        }),
        []
    )
    
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const data = users || []
    const tableInstance = useTable({ columns, data , defaultColumn, filterTypes, initialState: {hiddenColumns: [userLocation !== "es" ? `${props.t("relacion_crm")}` : '']} }, useFilters, useGlobalFilter)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    return (       
        <div className='overflow-x-scroll'>
            { isLoading && loader(props.t)}
            { !areSameObjects(alert, []) && ["userStatus", "userPassword", "userDelete", "genericAlert"].includes(alert.type) && 
                <AlertCenter 
                    alert = { alert }
                    changeShowAlert = { changeAlertResult }
                    t = { props.t}
                />
            }
            { !areSameObjects(alert, []) && alert.type === "userInfo" && 
                <AlertUserInfo 
                    userInfo = { alert.userInfo }
                    crmRelation = { alert.crmRelation }
                    applyRates = { alert.applyRates }
                    changeShowAlert = { changeAlertResult }
                    idAccountCRM = { alert.idAccountCRM }
                    t = { props.t}
                />
            }
            { !areSameObjects(alert, []) && alert.type === "userType" &&  
                <AlertCenterCombo 
                    alert = { {...alert, comboOptions: userTypes} }
                    changeShowAlert = { changeAlertComboResult }
                    changeComboValue = { changeComboValue }
                    t = { props.t}
                />
            }
            { !areSameObjects(alert, []) && alert.type === "userCRM" && 
                <AlertCRMRelation
                    alert = {{...alert}}
                    changeShowAlert = { changeAlertResult }
                    t = { props.t}
                />
            }
            { !areSameObjects(alert, []) && alert.type === "userCountrySelect" && 
                <AlertCenterCountrySwitcher
                    alert = {{...alert}}
                    changeShowAlert = { changeAlertResult }
                    changeComboValue = { changeComboValue }
                    t = { props.t}
                />
            }
            <table {...getTableProps()} className="w-full mt-8 border border-gray-300 rounded">
                <thead>
                    {
                        headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                        <th
                                            {...column.getHeaderProps()}
                                            className="pt-2 pb-2 pl-4 pr-4 bg-gray-200 text-left font-semibold border-r border-gray-300"
                                        >
                                            {column.render('Header')}
                                            <div>{column.filterable ? column.render('Filter') : null}</div>
                                        </th>
                                    ))
                                }
                            </tr>
                        ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()} 
                                    className = { `${row.index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100` }
                                >
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    className={`pt-2 pb-2 pl-4 pr-4 border-r border-gray-300`}
                                                >
                                                    {
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
