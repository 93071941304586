import React from 'react'
import { Link } from 'react-router-dom'

import { MainTitle } from '../components/elements/MainTitle'
import { ButtonPrevious } from '../components/elements/ButtonPrevious'
import UsersTable from '../components/elements/UsersTable'

export default function UserAdmin(props) {
  return (
    <div className='container mx-auto'>        
        <MainTitle>{props.t("gestion_usuarios")}</MainTitle>
        <UsersTable t = {props.t} />        
        <Link to = {{pathname: '/'}} className="hover:cursor-pointer text-center">
          <p className='mb-12 mt-8 underline'>{props.t("volver_inicio")}</p>
        </Link>
    </div>
  )
}
