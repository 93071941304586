import React, {Component} from 'react'
import {Link} from 'react-router-dom'

export class ButtonHome extends Component {    
    render() {
        let srcImage = ''
        if ( this.props.type === 'kit' ){
            srcImage = './home-icon-green.png'
        }
        if ( this.props.type === 'edificio' ){
            srcImage = './edifice-icon-green.png'
        }
        if ( this.props.type === 'mancomunidad' ){
            srcImage = './association-icon-green.png'
        }
        return (
            <Link
                to = {{
                    pathname: '/' + this.props.type === 'kit' ? '' : (this.props.type),
                    state: { logged: true }
                }}
                className='pt-6 pb-6 pl-6 pr-6 font-semibold text-center border rounded-md shadow-md cursor-pointer home-btn hover:bg-gray-200 text-green-comelit'
                state = {{logged: true}}
            >      
                {
                    this.props.type === 'simulador-placas' ?
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-full pb-4" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                            <path fillRule="evenodd" d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm3 4a1 1 0 000 2h.01a1 1 0 100-2H7zm3 0a1 1 0 000 2h3a1 1 0 100-2h-3zm-3 4a1 1 0 100 2h.01a1 1 0 100-2H7zm3 0a1 1 0 100 2h3a1 1 0 100-2h-3z" clipRule="evenodd" />
                        </svg>
                    :
                        <img src={ srcImage } alt={`${this.props.t("icono_boton")} ${this.props.type}`} className='pb-8' /> 
                }      
                <h2 className='mt-6 text-xl font-semibold uppercase'>{this.props.children}</h2>
            </Link>
        )
    }
}