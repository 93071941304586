
import React, { Component } from "react";

export class PlateSeparator extends Component {

    render() {
        return (            
            <img src="../vandalcom/separator-2.png" alt="Separador Vandalcom" />
        )
    }
}