export const errorMessage = (dataObject) => {
    let str = ''

    for (const objects in dataObject) {
        if (typeof dataObject[objects][0] === "string"){
            str += `<div class="p-4 bg-red-200 text-red-800 rounded-md mt-2">${dataObject[objects][0]}</div>`
        } else{
            str += '<div class="p-4 bg-red-200 text-red-800 rounded-md mt-2">' + (dataObject[objects][0] + '</div>')
        }
    }

    return str
}

export const displayErrorMessages = (errorResponse, displayError) => {
    
    if (displayError){
        if (typeof errorResponse === "string"){
            displayError.innerHTML = `<div class="p-4 bg-red-200 text-red-800 rounded-md">${errorResponse}</div>`
        }
        else if ( typeof errorResponse === undefined){
            displayError.innerHTML = `<div class="p-4 bg-red-200 text-red-800 rounded-md">${this.props.t("error_pruebe")}</div>`
        }
        else{
            if (Object.keys(errorResponse).length > 0){
                const error = errorMessage(errorResponse)
                displayError.innerHTML = error
            }
        }
    }
}


