import React, {Component} from 'react'

export class ButtonBig extends Component {
    render() {
        const {label, selected, value, classes, onChange} = this.props

        return (            
            <button 
                className = {`pt-6 pb-6 pl-10 pr-10 border-4 rounded-md text-lg font-semibold cursor-pointer 
                                hover:border-green-comelit ${selected ? "border-green-comelit" : ""} ${classes}`}
                onClick = {onChange}
                value = {value}
            >
                {label}
            </button>
        )
    }
}