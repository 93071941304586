import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { useFormFields } from '../other/helpers/hooksFormInput'
import { useDispatch, useSelector } from 'react-redux'
import { requestRestorePasswordAction, clearUserAuthState } from '../store/actions/AuthActions'
import { displayErrorMessages } from '../other/helpers/displayErrors'

export function ForgotPassword(props) {    
    const dispatch = useDispatch()
    const authResponse = useSelector(state => state.userAuth.userAuthResponse)
    const { t } = props
   
    const userLocation = localStorage.getItem("comelit-user-language")

    const initialForm = {
        email: '',
        user_location: userLocation
    }

    const [fields, handleFieldChange] = useFormFields( initialForm )
        
    useEffect(
        () => { dispatch(clearUserAuthState()) }, []
    )

    const successMessage = (successMessage) => {
        return <div dangerouslySetInnerHTML={{__html:'<div class="p-4 bg-green-400 text-green-800">' + successMessage + '</div>'}} ></div>
    }
    
    const clearAuthErrDiv = () => {
        let authErr = document.querySelector("#authError");
        authErr.innerHTML = "";
    }

    const userChangePassword = (e) => {
        e.preventDefault()
        clearAuthErrDiv()
        
        dispatch(requestRestorePasswordAction(fields, props))
    }

    return(
        <div className='home container mx-auto flex'>
            <form id="password_change" className="sm:w-3/4 w-full max-w-3xl border-2 pt-10 pb-10 pr-6 pl-6 rounded-md mt-14 mb-14 bg-gray-50" onSubmit={userChangePassword}>
                <h1 className='text-center text-green-comelit text-2xl mb-4 font-bold'>{t("contrasenha_olvidada")}</h1>
                <p className='text-center mb-2' >{t("correo_instrucciones")}</p>
                <div className='text-center'>
                    <input type="mail" name="email" id="email" placeholder={`${t("introduzca_correo")}`} className="mt-2 p-2 max-w-lg mx-auto w-full border-2" autoComplete='off' value={fields.email} onChange={handleFieldChange}/>
                </div>

                <div id="authError" className='mt-2'></div>
                
                <div id="authResponse">
                {
                    authResponse && authResponse !== undefined && authResponse !== '' && authResponse !== "loading" && authResponse.success && authResponse.success === true
                        ? successMessage(authResponse.message)
                        : authResponse && authResponse.success !== null && authResponse.success === false 
                            && displayErrorMessages(authResponse.error, document.querySelector("#authError"))
                }
                </div>
                
                <div className='text-center'>
                    <input type="submit" value={`${t("restablecer_contrasenha")}`} className="mx-auto mt-4 mb-2 pt-4 pb-4 pl-8 pr-8 rounded bg-green-comelit text-white cursor-pointer"/>
                </div>

                <Link to = {{pathname: '/'}} className="hover:cursor-pointer text-center">
                    <p className='mt-4 underline'>{t("volver_inicio")}</p>
                </Link>
            </form>
        </div>
    )
}