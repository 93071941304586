import React, { Component } from 'react'

import {ButtonBig} from '../elements/ButtonBig'

export class InstalationType extends Component {
    render() {
        return (
            <div className="flex justify-around pt-4">
                <ButtonBig value="estrella" label={`${this.props.t("estrella")}`} selected={this.props.selected === "estrella"} onChange={() => this.props.onChangeInstalationType("estrella")} />
                <ButtonBig value="en-serie" label={`${this.props.t("en_serie")}`} selected={this.props.selected === "en-serie"} onChange={() => this.props.onChangeInstalationType("en-serie")} />
            </div>
        )
    }
}