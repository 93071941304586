import { areSameObjects, formatoEuro } from "./Utils";
import HttpService from "../services/HttpService";
const http = new HttpService()

export const updateBudgetKitWithRates = (budget, changeDiscount, familyRates, productRates) => {
    // Recorremos el presupuesto, buscando los descuentos
    budget.forEach(budgetFor => {
        if (budgetFor.products){
            budgetFor.products.forEach(product => {
                
                let productDiscount = searchProductDiscount(productRates, product, changeDiscount, budgetFor.type, "kit")
                if (!productDiscount){

                    searchFamilyDiscount(familyRates, product, changeDiscount, budgetFor.type, "kit")
                }

            });
        }
    });
}

export const updateBudgetWithRates = (budget, changeDiscount, familyRates, productRates) => {

    // Recorremos el presupuesto, buscando los descuentos
    budget.forEach(budgetFor => {
        if (budgetFor.plates){
            budgetFor.plates.forEach(plate => {
                plate.content.forEach(product => {
                    let productDiscount = searchProductDiscount(productRates, product, changeDiscount, budgetFor.type, "association-edifice", plate.numPlate)
                    if (!productDiscount){

                        searchFamilyDiscount(familyRates, product, changeDiscount, budgetFor.type, "association-edifice", plate.numPlate)
                    }
                })

            });
        }
    });
}

async function searchFamilyDiscount(familyRates, product, changeDiscount, budgetType, type, numPlate = null) {

    let productCategory = await getProductCategoryCRM(product)
    let productCLevel2 = await getProductCLevel2(productCategory)
    // Buscamos los descuentos de la familia
    let productFamilyDiscounts = familyRates.find(rate => rate.familyId === productCLevel2)
    if (productFamilyDiscounts){
        let {discount1, discount2, discount3} = productFamilyDiscounts
        return updateProductWithDiscount(0, discount1, discount2, discount3, type, budgetType, changeDiscount, product, numPlate)
    }
}

const searchProductDiscount = (productRates, product, changeDiscount, budgetType, type, numPlate = null) => {

    // Buscamos los descuentos del producto
    let productDiscounts = productRates.find(rate => rate.productId.value === product.articleCode)
    if (productDiscounts) {
        let {salePrice, discount1, discount2, discount3} = productDiscounts
        return updateProductWithDiscount(parseFloat(salePrice), discount1, discount2, discount3, type, budgetType, changeDiscount, product, numPlate)        
    }

}

const updateProductWithDiscount = (salePrice, discount1, discount2, discount3, type, budgetType, changeDiscount, product, numPlate = null) => {

    if (type !== "kit" && ["individual","installation"].includes(budgetType) )
        return

    if (salePrice !== 0){
        type === "kit" 
            ? changeDiscount(product.articleCode, budgetType, "nt_und", salePrice)
            : changeDiscount(product.articleCode, budgetType, numPlate, "nt_und", salePrice)
        return true
    } else{
        discount1 !== 0 && 
            type === "kit" 
                ? changeDiscount(product.articleCode, budgetType, "dto_1", discount1)
                : changeDiscount(product.articleCode, budgetType, numPlate, "dto_1", discount1)
            
        discount2 !== 0 &&
            type === "kit" 
                ? changeDiscount(product.articleCode, budgetType, "dto_2", discount2)
                : changeDiscount(product.articleCode, budgetType, numPlate, "dto_2", discount2)
        discount3 !== 0 &&
            type === "kit" 
                ? changeDiscount(product.articleCode, budgetType, "dto_3", discount3)
                : changeDiscount(product.articleCode, budgetType, numPlate, "dto_3", discount3)

        if (discount1 !== 0 || discount2 !== 0 || discount3 !== 0 )
            return true
    }
    return false
}

export const restartBudgetKit = (budget, changeDiscount) => {
    // Recorremos el presupuesto, reiniciando
    budget.forEach(budgetFor => {
        if (budgetFor.products){            
            budgetFor.products.forEach(product => {
                restartProductDiscount(product, changeDiscount, budgetFor.type, "kit")
            });
        }
    });
}

export const restartBudget = (budget, changeDiscount) => {
    // Recorremos el presupuesto, reiniciando
    budget.forEach(budgetFor => {
        if (budgetFor.plates){            
            budgetFor.plates.forEach(plate => {
                plate.content.forEach(product => {
                    restartProductDiscount(product, changeDiscount, budgetFor.type, "association-edifice", plate.numPlate)
                })
            });
        }
    });
}

const restartProductDiscount = (product, changeDiscount, budgetType, type, numPlate = null) => {
    if ( type === "kit" ){
        changeDiscount(product.articleCode, budgetType, "nt_und", 0)
        changeDiscount(product.articleCode, budgetType, "dto_1", 0)
        changeDiscount(product.articleCode, budgetType, "dto_2", 0)
        changeDiscount(product.articleCode, budgetType, "dto_3", 0)

    } else{
        changeDiscount(product.articleCode, budgetType, numPlate, "nt_und", 0)
        changeDiscount(product.articleCode, budgetType, numPlate, "dto_1", 0)
        changeDiscount(product.articleCode, budgetType, numPlate, "dto_2", 0)
        changeDiscount(product.articleCode, budgetType, numPlate, "dto_3", 0)
    }
}

export const getFamilyRates = (rateId) => {
    // Obtenemos las tarifas por familia
    return http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "familyRates?where=rateId.id=" + rateId )
    .then( async familyRates => {
        return familyRates
    })
}

export async function getProductRates(rateId, budget) {
    
    let products = []

    budget.forEach(budgetType => {
        if (budgetType.products){
            budgetType.products.forEach(product => {
                products.push(product.articleCode)
            });
        } else if (budgetType.plates){
            budgetType.plates.forEach(plate => {
                plate.content.forEach(product => {
                    products.push(product.articleCode)
                });
            });
        }
    });

    let productBudgetRates = await getBudgetProductsRates(products, rateId)
    return productBudgetRates
}

async function getBudgetProductsRates(products, rateId){
    
    let productBudgetRates = []
    for (let i = 0; i < products.length; i++) {
        const product = products[i];
        let productId = await getProductIdCRM(product)
        let productRate = await getProductRateDetail(productId, rateId)

        productRate &&
            productBudgetRates.push(productRate)
    }
    return productBudgetRates
}

const getProductIdCRM = (product) => {
    return http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "products?where=extId='" + product + "' AND deleted='false'", false )
    .then( productInfo => productInfo[0].id)
}

const getProductCategoryCRM = (product) => {
    return http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "products?where=extId='" + product.articleCode + "' AND deleted='false'", false )
    .then( productInfo => productInfo[0].categoryId.id)
}

const getProductCLevel2 = (productCategory) => {
    return http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "tblCLevel3?where=id='" + productCategory + "' AND deleted='false'", false )
    .then( tblCLevel3Info => tblCLevel3Info[0].cLevel2)
}

const getProductRateDetail = (productId, rateId) => {
    return http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "productRates?where=rateId.id=" + rateId + "%20AND%20productId.id='" + productId + "'", false)
    .then( productRate => productRate[0])
}

export const checkDiscount = (product, type, idRow, changeDiscount, simulatorType, exportToCRM, setExportToCRM, numPlate = null) => (e) => {
    let targetName = e.target.name
    let targetValue = e.target.value === '' ? 0 : e.target.value

    // Si es campo de DTO.1 / DTO.2 / DTO.3
    let dtos = ["dto_1","dto_2","dto_3"]
    if (dtos.includes(targetName)) {
        
        // Obtenemos los descuentos
        let dto_1 = document.querySelector(`#${idRow} #dto_1`).value === '' ? 0 : parseFloat(document.querySelector(`#${idRow} #dto_1`).value)
        let dto_2 = document.querySelector(`#${idRow} #dto_2`).value === '' ? 0 : parseFloat(document.querySelector(`#${idRow} #dto_2`).value)
        let dto_3 = document.querySelector(`#${idRow} #dto_3`).value === '' ? 0 : parseFloat(document.querySelector(`#${idRow} #dto_3`).value)

        // Comprobamos si se ha introducido más de un 100% de descuento
        let dtoTotal = (1 - ((1 - (dto_1/100)) * (1 - (dto_2/100)) * (1 - (dto_3/100)))) * 100
        if (dtoTotal > 100){
            e.preventDefault()
            return
        }

        if (targetValue > 0 
                || (targetName !== "dto_1" && product.dto_1 > 0) 
                || (targetName !== "dto_2" && product.dto_2 > 0)
                || (targetName !== "dto_3" && product.dto_3 > 0)){
            document.querySelector(`#${idRow} #nt_und`).disabled = true
        }
        else{
            document.querySelector(`#${idRow} #nt_und`).disabled = false
        }
    }

    // Si ya hemos exportado, establecemos de nuevo la exportación por el cambio
    !exportToCRM && setExportToCRM(true)

    simulatorType === "kit"
        ? changeDiscount(product.articleCode, type, targetName, targetValue)
        : changeDiscount(product.articleCode, type, numPlate, targetName, targetValue)
}

const getPriceMinuteInstallation = () => {
    return http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "products/4902", false )
    .then( productInfo => productInfo ? productInfo.price : 0 )
}

const getFixedMinutesInstallation = () => {
    return http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "products/4903", false )
    .then( productInfo => productInfo.Z_U_T_PM || 0 )
}

const getProductMinutes = (articleCode) => {
    return http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "products?where=extId='" + articleCode + "' AND deleted='false'", false )
    .then( productInfo => productInfo[0].Z_U_T_PM || 0 )
}

async function getInstallationPrice(budget, priceMinute){

    let budgetInstallationPrice = 0
    // Recorremos los productos del presupuesto, consultando su valor de minutos y su cantidad
    for (let i = 0; i < budget.length; i++) {
        const budgetFor = budget[i];
        if ( !["individual","installation"].includes(budgetFor.type) ){            
            for (let j = 0; j < budgetFor.plates.length; j++) {
                const plate = budgetFor.plates[j];
                for (let z = 0; z < plate.content.length; z++) {
                    const product = plate.content[z];
                    const quantity = product.quantity
                    const minutes = await getProductMinutes(product.articleCode)

                    const productInstallationPrice = quantity * minutes * priceMinute
                    budgetInstallationPrice += productInstallationPrice
                }
            }
        }
    }
    
    return budgetInstallationPrice
}

async function getKitInstallationPrice(budget, priceMinute){

    let budgetInstallationPrice = 0
    
    // Recorremos los productos del presupuesto, consultando su valor de minutos y su cantidad
    for (let i = 0; i < budget.length; i++) {
        const budgetFor = budget[i];

        if ( !["individual","installation"].includes(budgetFor.type) ){
            for (let j = 0; j < budgetFor.products.length; j++) {
                const product = budgetFor.products[j];
                const quantity = product.quantity
                const minutes = await getProductMinutes(product.articleCode)
                const productInstallationPrice = quantity * minutes * priceMinute
                
                budgetInstallationPrice += productInstallationPrice
            }
        }
    }
    return budgetInstallationPrice
}

async function getBudgetInstallationPrice(budget, type, priceMinute){

    if (type === "kit")
        return await getKitInstallationPrice(budget, priceMinute)

    return await getInstallationPrice(budget, priceMinute)
}

export async function getInstallationCost(budget, type){
    // Obtenemos la variable de precio/minuto
    const priceMinute = await getPriceMinuteInstallation()
    // Obtenemos la variable de mínimo de minutos de instalación
    const fixedMinutes = await getFixedMinutesInstallation()

    let totalInstallationPrice = priceMinute * fixedMinutes
    let budgetInstallationPrice = await getBudgetInstallationPrice(budget, type, priceMinute)
    
    totalInstallationPrice += budgetInstallationPrice

    return totalInstallationPrice
}

export function getRenderInstallationCost(budget, userInfo, changeInstallationCost){    

    // Solo se añade el coste estimado de puesta en marcha para usuarios de ESPAÑA
    if (areSameObjects(userInfo, []) || (userInfo.country_id && userInfo.country_id !== 1)){
        return null
    }

    let id_user_type = userInfo.id_user_type
    let indexType = budget.findIndex(info => info.type === "installation")
    let budgetInstallation = budget[indexType]
    let installationCost = 0
    if (budgetInstallation)
        installationCost = budgetInstallation.pvp || 0

    // Usuario con permisos de edición
    if ( [1,2].includes(id_user_type) )
        return(
            <div className='flex justify-end mt-6'>
                <p>
                    <span className='font-semibold'>(*) </span> 
                    Valoración estimada de puesta en marcha:                     
                    <span className='font-semibold'>
                        <input 
                            type = "text" 
                            name = "installationCost" 
                            id = "installationCost" 
                            className = "p-2 ml-2 font-semibold border" 
                            placeholder = "Coste"
                            value = { installationCost === 0 ? '' : installationCost }
                            onChange = { e => changeInstallationCost(e.target.value) }
                        /> €
                    </span>
                </p>
            </div>
        )

    // Usuario sin permisos de edición
    if (installationCost === 0) return []

    return(
        <div className='flex justify-end mt-6'>
            <p><span className='font-semibold'>(*)</span> Valoración estimada de puesta en marcha: <span className='font-semibold'>{ formatoEuro(installationCost, true) }</span></p>
        </div>
    )

}