import React, { useState } from 'react'
import { areSameObjects, capitalize } from '../other/Utils'
import { ItemTypes } from '../other/ItemTypes'

import { DisplaySimulator } from '../components/compounds/DisplaySimulator'
import { RadioInput } from '../components/elements/RadioInput'
import { ButtonNext } from '../components/elements/ButtonNext'
import { Button } from '../components/elements/Button'

import cloneDeep from 'lodash.clonedeep'

import { PDFDownloadLink, Document, Image, Page, Text, View, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import fontThin from '../fonts/ttf/HelveticaNeueLTW1G-Th.ttf'
import fontBold from '../fonts/ttf/HelveticaNeueLTW1G-Bd.ttf'
import fontBlack from '../fonts/ttf/HelveticaNeueLTW1G-Blk.ttf'
import fontMedium from '../fonts/ttf/HelveticaNeueLTW1G-Md.ttf'

const EMPTY_CONTENT = [{
    idSubPlate: 1,
    subPlateContent: [
        {
            idSlot: 1,
            slotContent: [],
            location: '--'
        }
    ]
}]

Font.register({
    family: 'Helvetica Neue',
    fonts: [
        { src: fontThin, fontWeight: 'thin' },
        { src: fontBold, fontWeight: 'bold' },
        { src: fontBlack, fontWeight: 'heavy' },
        { src: fontMedium, fontWeight: 'medium' },
    ]
})

const styles = StyleSheet.create({
    body: {
        padding: "20px",
        paddingBottom: "30px",
        fontSize: '12',
        position: "relative"
    },
    fontLight:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'light' 
    },
    fontBold:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'bold' 
    },
    header:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px'
    },
    image:{
        width: '150px',
        height: 'auto'
    },
    plateImage:{
        width: '60%',
        height: 'auto',
        textAlign: 'center',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    subheader:{
        textAlign: 'center',
        width: '100%'
    },
    title:{
        color: '#009640',
        fontSize: '14',
        padding: '5px 10px 5px 10px',
        fontFamily: 'Helvetica Neue',
        fontWeight: 'bold'
    },
    subtitle:{
        width: '100%',
        fontFamily: 'Helvetica Neue',
        fontWeight: 'light'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 10,
        left: 0,
        right: 0,
        textAlign: 'center'
    }
})

export function VisualPlateSimulator(props) {
    const t = props.t

    const [ pdfDocument, setPDFDocument] = useState(null)
    const [ showConfigurator, setShowConfigurator ] = useState(false)
    const [ plateBase, setPlateBase ] = useState({
        "plateModel": '',
        "assembly": "--",
        "numSubPlates": 1,
        "configurationComplete": false,
        "image": "--",
        "visor": false,
        "trimFrame": false,
        "contents": [
            {
                "idSubPlate": 1,
                "subPlateContent": [
                    {
                        "idSlot": 1,
                        "slotContent": [],
                        "location": "--"
                    }
                ]
            }
        ]
    })

    const setPlateModel = ( model ) => {
        setPlateBase({
            ...plateBase,
            plateModel: model
        })
    }

    const setPlateAssembly = ( assembly ) => {
        setPlateBase({
            ...plateBase,
            assembly: assembly
        })
    }
    
    const setConfigurationComplete = ( value ) => {
        setPlateBase({
            ...plateBase,
            configurationComplete: value
        })
    }

    const addSlotToSubPlate = (numPlate, numSubPlate, type, additional, indexAdditional, location = '--') => {        
        const plateBaseCopy = cloneDeep(plateBase)

        const subPlateAdd = plateBaseCopy.contents.find( content => content.idSubPlate === numSubPlate );
        let maxIDSlot = Math.max.apply(Math, subPlateAdd.subPlateContent.map(function(slot) { return slot.idSlot }))
        subPlateAdd.subPlateContent.push({
            idSlot: maxIDSlot + 1,
            slotContent: [],
            location: location
        })

        // Si tenemos dos bloques en simulador, hay que añadir dos placas
        if ( subPlateAdd.subPlateContent.length === 7 || subPlateAdd.subPlateContent.length === 8 ) {
            subPlateAdd.subPlateContent.push({
                idSlot: maxIDSlot + 2,
                slotContent: [],
                location: location
            })
        }

        setPlateBase(plateBaseCopy)
    }

    const removeSlotOfSubPlate = (numPlate, numSubPlate, type, additional, indexAdditional, location = '--') => {  
        const plateBaseCopy = cloneDeep(plateBase)

        // Buscamos el elemento al que eliminar el SLOT por su ID
        const subPlateRemove = plateBaseCopy.contents.find( content => content.idSubPlate === numSubPlate );

        // Si no estamos eliminando por encima o debajo de la Switch
        if ( location === "--"){
            subPlateRemove.subPlateContent.pop()
        } else{
            let indexRemove = subPlateRemove.subPlateContent.findIndex(element => element.location === location)
            subPlateRemove.subPlateContent.splice(indexRemove, 1)
        }
        
        if ( subPlateRemove.subPlateContent.length === 6 || subPlateRemove.subPlateContent.length === 7 ) {
            subPlateRemove.subPlateContent.pop()
        }
        
        setPlateBase(plateBaseCopy)
    }

    const addSubPlate = (numPlate, type, additional, indexAdditional) => {
        const plateBaseCopy = cloneDeep(plateBase)

        plateBaseCopy.numSubPlates += 1
        plateBaseCopy.contents.push(
            {
                idSubPlate: plateBaseCopy.numSubPlates,
                subPlateContent: [
                    {
                        idSlot: 1,
                        slotContent: [],
                        location: '--'
                    }
                ]
            }
        )
        
        setPlateBase(plateBaseCopy)
    }

    const removeSubPlate = (numPlate, type, additional, indexAdditional) => {
        const plateBaseCopy = cloneDeep(plateBase)
        
        plateBaseCopy.numSubPlates -= 1
        plateBaseCopy.contents.pop()

        setPlateBase(plateBaseCopy)
    }

    const restartPlateDisplay = (numPlate, type, additional, indexAdditional) => {
        const plateBaseCopy = cloneDeep(plateBase)

        // Buscamos el elemento al que reiniciar el display
        plateBaseCopy.numSubPlates = 1
        plateBaseCopy.contents = cloneDeep(EMPTY_CONTENT)
        
        setPlateBase(plateBaseCopy)
    }

    const restartPlatePushButtons = (numPlate, type, additional, indexAdditional) => {
        const plateBaseCopy = cloneDeep(plateBase)

        // Buscamos el elemento al que reiniciar el display
        for (let i = 0; i < plateBaseCopy.contents.length; i++) {
            const subPlateContent = plateBaseCopy.contents[i].subPlateContent;
            for (let j = 0; j < subPlateContent.length; j++) {
                const slotContent = subPlateContent[j].slotContent;
                if (slotContent.buttonsContent) {
                    for (let k = 0; k < slotContent.buttonsContent.length; k++) {
                        const buttonContent = slotContent.buttonsContent[k];
                        
                        buttonContent.articleCode = "--"
                        buttonContent.deepLink = "--"
                        buttonContent.description = "--"
                        buttonContent.eanCode = "--"
                        buttonContent.pvp = "--"
                        buttonContent.relatedArticles = "--"
                        buttonContent.numCalls = "--"
                        buttonContent.numColumns = "--"
                        buttonContent.src = "--"
                        buttonContent.toolTip = "--"
                        buttonContent.idCategory = "--"
                        buttonContent.category = "--"
                    }
                }                    
            }                
        }

        setPlateBase(plateBaseCopy)
    }
    
    const addItemToSlot = (numPlate, idSubPlate, idSlot, slotContent, originalItem, type, additional, indexAdditional) => {
        // console.log(plateBase)
        const plateBaseCopy = cloneDeep(plateBase)
                
        // Buscamos el contenido que hay realmente en el SLOT que se está moviendo
        let subPlateRealContent = plateBaseCopy.contents.find( content => content.idSubPlate === originalItem.location.idSubPlate );
        
        let slotAddRealContent = subPlateRealContent 
            ? subPlateRealContent.subPlateContent.find( content => content.idSlot === originalItem.location.idSlot )
            : null
        
        // Obtenemos la copia de las placas a modificar
        let plateBaseAdd = cloneDeep(plateBase)

        // Buscamos el elemento al que añadir el item
        let subPlateAddItem = plateBaseAdd.contents.find( content => content.idSubPlate === idSubPlate );
        
        // Buscamos el slot al que añadir el item
        let slotAdd = subPlateAddItem.subPlateContent.find( content => content.idSlot === idSlot )
        
        // Si se está arrastrando un elemento dentro de la placa
        if (originalItem.location !== "simulator"){
            
            // Si se está arrastrando sobre un item que no está vacío            
            if (!areSameObjects(slotAdd.slotContent, [])){
                // Obtenemos la localización original del elemento que estamos arrastrando
                let originalItemLocation = originalItem.location
                // Obtenemos el contenido sobre el que estamos arrastrando
                let originalSlotContent = {...slotAdd.slotContent}
                
                // Buscamos la placa a sustituir 
                let plateBaseReplace = cloneDeep(plateBaseAdd)

                // Buscamos el elemento al que reemplazar el item
                let subPlateReplace = plateBaseReplace.contents.find( content => content.idSubPlate === originalItemLocation.idSubPlate );
                // Buscamos el slot al que reemplazar
                let slotReplace = subPlateReplace.subPlateContent.find( content => content.idSlot === originalItemLocation.idSlot )  
                
                // Si el artículo a reemplazar es un módulo doble, el siguiente hueco tiene que estar vacío
                if (originalSlotContent.articleCode === "UT9270"){     
                    // Buscamos el siguiente slot al que reemplazar
                    let slotReplaceNext = subPlateReplace.subPlateContent.find( content => content.idSlot === originalItemLocation.idSlot+1 )
                    
                    if (slotReplaceNext !== undefined && areSameObjects(slotReplaceNext.slotContent,[])){
                        // Reemplazamos el contenido y le ponemos la localización correcta
                        slotReplace.slotContent = {...originalSlotContent}
                        slotReplace.slotContent.location = originalItemLocation
                        // Reemplazamos el contenido del siguiente SLOT y le ponemos la localización correcta
                        slotReplaceNext.slotContent = "occupied_by_double"
                    } else{
                        slotReplace.slotContent = []
                    }
                    
                    // Buscamos el slot a vaciar
                    let slotReplaceDelete = subPlateReplace.subPlateContent.find( content => content.idSlot === originalSlotContent.location.idSlot+1 )
                    slotReplaceDelete.slotContent = []
                } else{
                    // Reemplazamos el contenido y le ponemos la localización correcta
                    slotReplace.slotContent = {...originalSlotContent}
                    slotReplace.slotContent.location = originalItemLocation
                }

                // Si el que se ha movido es el módulo doble, quitamos el siguiente como OCUPADO
                if (slotAdd.slotContent.articleCode === "UT9270"){
                    let slotAddNext = subPlateAddItem.subPlateContent.find( content => content.idSlot === idSlot+1 )    
                    slotAddNext.slotContent = []
                }

                // Si estamos reemplazando el módulo doble, hay que poner el siguiente como ocupado
                if (slotAddRealContent.slotContent.articleCode === "UT9270"){            
                    // Buscamos el slot siguiente al que reemplazar
                    let slotReplaceNext = subPlateReplace.subPlateContent.find( content => content.idSlot === originalItemLocation.idSlot+1 )
                    slotReplaceNext.slotContent = []
                }
            }
            // Si el módulo destino está vacío borramos el módulo de origen
            else{

                // Buscamos el elemento que arrastra, para borrarlo
                let subPlateOriginalItemRemove = plateBaseAdd.contents.find( content => content.idSubPlate === originalItem.location.idSubPlate );
                // Buscamos el slot al que añadir el item
                let slotOriginalItemRemove = subPlateOriginalItemRemove.subPlateContent.find( content => content.idSlot === originalItem.location.idSlot )
                slotOriginalItemRemove.slotContent = []
                
                // Si el que se ha movido es el módulo doble, quitamos el siguiente como OCUPADO
                if (slotAddRealContent.slotContent.articleCode === "UT9270"){
                    let slotOriginalItemRemoveNext = subPlateOriginalItemRemove.subPlateContent.find( content => content.idSlot === originalItem.location.idSlot+1 )
                    slotOriginalItemRemoveNext.slotContent = []
                }

            }
        }

        // Si no existe la placa, la creamos
        if (!slotAdd){
            subPlateAddItem.subPlateContent.push({
                idSlot: idSlot,
                slotContent: [],
                location: '--'
            })
            slotAdd = subPlateAddItem.subPlateContent.find( content => content.idSlot === idSlot )    
        }
        
        // Añadimos la placa
        slotAdd.slotContent = slotAddRealContent ? slotAddRealContent.slotContent : slotContent
        slotAdd.slotContent.location = slotContent.location

        // Comprobamos si el siguiente está como "occupied_by_double" para quitarlo            
        if ( subPlateAddItem.subPlateContent.find( content => content.idSlot === idSlot + 1 ) &&
                subPlateAddItem.subPlateContent.find( content => content.idSlot === idSlot + 1 ).slotContent === "occupied_by_double" ){
            subPlateAddItem.subPlateContent.find( content => content.idSlot === idSlot + 1 ).slotContent = []
        }

        let articleCodeMove = slotAddRealContent ? slotAddRealContent.articleCode : originalItem.articleCode
        // Si es modulo doble, añadimos el siguiente ocupado
        if (articleCodeMove === "UT9270"){
            // Si estamos añadiendo UT9270 (doble)
            let slotAddNext = subPlateAddItem.subPlateContent.find( content => content.idSlot === idSlot+1 )
            slotAddNext.slotContent = "occupied_by_double"
        }

        setPlateBase(plateBaseAdd)
    }
        
    const removeItemOfSlot = (numPlate, idSubPlate, idSlot, type, additional, indexAdditional, dropResult) => {
        const plateBaseCopy = cloneDeep(plateBase)
        // Buscamos el elemento al que borrar el item
        let subPlateRemoveItem = plateBaseCopy.contents.find( content => content.idSubPlate === idSubPlate );
        // Buscamos el slot al que borrar el item
        let slotRemove = subPlateRemoveItem.subPlateContent.find( content => content.idSlot === idSlot )
        
        if (dropResult !== "move"){
            slotRemove.slotContent = []
        }

        setPlateBase(plateBaseCopy)
    }

    const addItemToFirstSlot = (numPlate, slotContent, type, additional, indexAdditional, switchLocation) => {
        const plateBaseCopy = cloneDeep(plateBase)

        // Buscamos el elemento al que añadir el item
        let plateContents = plateBaseCopy.contents
            
        outer_for:
        for (let i = 0; i < plateContents.length; i++) {
            for (let j = 0; j < plateContents[i].subPlateContent.length; j++) {
                if (plateContents[i].subPlateContent[j].slotContent.length === 0){
                    // Si es módulo doble, el siguiente tiene que estar vacío
                    if ( slotContent.articleCode === "UT9270" ){
                        // Si está vacío el siguiente, añadimos los dos
                        if ( plateContents[i].subPlateContent[j+1] && plateContents[i].subPlateContent[j+1].slotContent.length === 0 ){
                            plateContents[i].subPlateContent[j].slotContent = slotContent
                            plateContents[i].subPlateContent[j].slotContent.location = {
                                numPlate: numPlate,
                                idSubPlate: i+1,
                                idSlot: j+1
                            }
                            plateContents[i].subPlateContent[j+1].slotContent = "occupied_by_double"
                            break outer_for
                        }
                    }
                    // Si es módulo normal
                    else{

                        // Si es SWITCH y estamos insertando en la parte superior de la placa, giramos
                        if (plateBaseCopy.plateModel === "switch" && plateContents[i].subPlateContent[j].location === "top"){
                            if ( slotContent.itemType === ItemTypes.MODULE_TURN 
                                    && !slotContent.src.includes("Top.png"))
                                slotContent.src = slotContent.src.replace(".png","Top.png")
                        }

                        // Si es SWITCH y no MODULE_TURN no puede ir arriba
                        if (!(plateBaseCopy.plateModel === "switch" 
                                && plateContents[i].subPlateContent[j].location === "top"
                                && slotContent.itemType !== ItemTypes.MODULE_TURN)
                        ){
                            plateContents[i].subPlateContent[j].slotContent = slotContent
                            plateContents[i].subPlateContent[j].slotContent.location = {
                                numPlate: numPlate,
                                idSubPlate: i+1,
                                idSlot: j+1
                            }                   
                            break outer_for
                        }
                    }

                }
            }                
        }
        
        setPlateBase(plateBaseCopy)
    }

    const changePlateConfigurated = (numPlate, type, additional, indexAdditional, completed, image) => {
        const plateBaseCopy = cloneDeep(plateBase)

        // Buscamos el elemento que marcar como configurado o no
        plateBaseCopy.configurationComplete = completed
        plateBaseCopy.image = image

        setPlateBase(plateBaseCopy)
    }

    const addPushButtonToSlot = (numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional) => {
        let plateBaseCopy = cloneDeep(plateBase)
            
        // Buscamos el subPlate al que añadir el pulsador
        let subPlateAddPush = plateBaseCopy.contents.find( content => content.idSubPlate === idSubPlate );
        // Buscamos el slot al que añadir el pulsador
        let slotAddPush = subPlateAddPush.subPlateContent.find( content => content.idSlot === idSlot )

        // Buscamos la posicion a la que añadir
        let pushButtonAdd = slotAddPush.slotContent.buttonsContent.find( pushButton => pushButton.idButton === newButtonContent.idButton )

        pushButtonAdd.articleCode = newButtonContent.articleCode
        pushButtonAdd.deepLink = newButtonContent.deepLink
        pushButtonAdd.description = newButtonContent.description
        pushButtonAdd.eanCode = newButtonContent.eanCode
        pushButtonAdd.pvp = newButtonContent.pvp
        pushButtonAdd.relatedArticles = newButtonContent.relatedArticles
        pushButtonAdd.numCalls = newButtonContent.numCalls
        pushButtonAdd.numColumns = newButtonContent.numColumns
        pushButtonAdd.src = newButtonContent.src
        pushButtonAdd.toolTip = newButtonContent.toolTip
        pushButtonAdd.idCategory = newButtonContent.idCategory
        pushButtonAdd.category = newButtonContent.category

        setPlateBase(plateBaseCopy)
    }

    const addPushButtonToAllPlate = (numPlate, idSubPlate, idSlot, newButtonContent, item, type, additional, indexAdditional) => {
        let plateBaseCopy = cloneDeep(plateBase)

        for (let i = 0; i < plateBaseCopy.contents.length; i++) {
            let subPlateContentAdd = plateBaseCopy.contents[i].subPlateContent;
            for (let j = 0; j < (subPlateContentAdd ? subPlateContentAdd.length : 0) ; j++) {
                let buttonsContentAdd = subPlateContentAdd[j].slotContent.buttonsContent;
                for (let k = 0; k < (buttonsContentAdd ? buttonsContentAdd.length : 0) ; k++) {
                    let buttonContentAdd = buttonsContentAdd[k];
                    if (buttonContentAdd.src === "--" || buttonContentAdd.articleCode === "--"){                            
                        buttonContentAdd.articleCode = newButtonContent.articleCode
                        buttonContentAdd.deepLink = newButtonContent.deepLink
                        buttonContentAdd.description = newButtonContent.description
                        buttonContentAdd.eanCode = newButtonContent.eanCode
                        buttonContentAdd.pvp = newButtonContent.pvp
                        buttonContentAdd.relatedArticles = newButtonContent.relatedArticles
                        buttonContentAdd.numCalls = newButtonContent.numCalls
                        buttonContentAdd.numColumns = newButtonContent.numColumns
                        buttonContentAdd.src = newButtonContent.src
                        buttonContentAdd.toolTip = newButtonContent.toolTip
                        buttonContentAdd.idCategory = newButtonContent.idCategory
                        buttonContentAdd.category = newButtonContent.category
                    }
                }                    
            }
        }      
        
        setPlateBase(plateBaseCopy)
    }
    
    const addPushButtonToFirstSlot = (numPlate, buttonContent, type, additional, indexAdditional) => {
        let plateBaseCopy = cloneDeep(plateBase)

        // Buscamos el elemento al que añadir el item
        let plateButtonContents = plateBaseCopy.contents
        
        outer_for:
        for (let i = 0; i < plateButtonContents.length; i++) {
            for (let j = 0; j < plateButtonContents[i].subPlateContent.length; j++) {
                let slotContent = plateButtonContents[i].subPlateContent[j].slotContent
                if (slotContent.buttonsContent){
                    let availableButton = slotContent.buttonsContent.find( pushButton => pushButton.src === "--" || pushButton.articleCode === "--")
                    if (availableButton){
                        
                        availableButton.articleCode = buttonContent.articleCode
                        availableButton.deepLink = buttonContent.deepLink
                        availableButton.description = buttonContent.description
                        availableButton.eanCode = buttonContent.eanCode
                        availableButton.pvp = buttonContent.pvp
                        availableButton.relatedArticles = buttonContent.relatedArticles
                        availableButton.numCalls = buttonContent.numCalls
                        availableButton.numColumns = buttonContent.numColumns
                        availableButton.src = buttonContent.src
                        availableButton.toolTip = buttonContent.toolTip
                        availableButton.idCategory = buttonContent.idCategory
                        availableButton.category = buttonContent.category

                        break outer_for
                    }
                }
            }                
        }
        
        setPlateBase(plateBaseCopy)
    }

    const handleConfiguratorClose = (mode) => {
        // console.log(mode)
    }

    const createPDF = () => {
        return (
            <Document>
                <Page style={styles.body}>
                    <View style={styles.header}>
                        <Image style={styles.image} src='../logo.png'/>
                    </View>
                    {/* IMAGEN GENERADA */}
                    <View wrap={false}>
                        <Image style={styles.plateImage} src={plateBase.image}/>
                    </View>
                    {/* PAGE NUMBER */}
                    <Text style={[ styles.pageNumber, styles.fontLight ]} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )} fixed />
                </Page>
            </Document>
        )      
    }

    const downloadImage = () => {
        let img = plateBase.image

        let imageLink = document.createElement('a');
        imageLink.href = img;
        imageLink.download = `${t("simulacion_comelit_placa")}_${capitalize(plateBase.plateModel)}.png`;
        document.body.appendChild(imageLink);
        imageLink.click();
        document.body.removeChild(imageLink);
    }

    if (showConfigurator && plateBase.configurationComplete && pdfDocument === null) {
        let pdfDocument = createPDF()
        setPDFDocument(pdfDocument)
    }

    return(
        <div className='container flex mx-auto home'>
            { 
                // Selección de Placas
                !showConfigurator &&
                    <div className='mb-16 mt-28'>
                        <h1 className="mt-8 mb-0 ml-8 mr-8 text-2xl font-semibold tracking-wide text-center uppercase center">
                            {t("simulador_placas")}
                        </h1>                        
                        <h2 className="text-lg font-semibold tracking-wide text-center uppercase center">
                            {t("seleccione_modelo_simular")}
                        </h2>
                        <div className="sm:grid sm:grid-cols-3 sm:gap-x-2">
                            <RadioInput
                                name = {`ultra`}
                                value = 'ultra'
                                image = {
                                    <img src = "../../logo-ultra.png" alt={`${props.t("logo")} ULTRA"`} className="mx-auto w-28" />
                                }
                                className = "w-1"
                                onChange = { () => setPlateModel('ultra')} 
                                checked = { plateBase.plateModel === 'ultra' }
                            />
                            <RadioInput
                                name = {`switch`}
                                value = 'switch'
                                image = {
                                    <img src = "../../logo-switch.png" alt={`${props.t("logo")} SWITCH"`} className={`w-28 mx-auto`} />
                                }
                                className = "w-1"
                                onChange = { () => setPlateModel('switch') }
                                checked = { plateBase.plateModel === 'switch' }
                            />
                            <RadioInput
                                name = {`vandalcom`}
                                value = 'vandalcom'
                                image = {
                                    <img src = {`../../logo-vandalcom.png`} alt={`${props.t("logo")} VANDALCOM"`} className="mx-auto w-28" />
                                }
                                className = "w-1"
                                onChange = { () => setPlateModel('vandalcom') }
                                checked = { plateBase.plateModel === 'vandalcom' }
                            />
                        </div>
                        <div className='flex justify-center'>
                            <ButtonNext 
                                onClick = { () => setShowConfigurator(true) }
                                disabled = { plateBase.plateModel === '' }
                                t = { props.t }
                            />
                        </div>
                    </div>
            }
            {
                // Configurador de placas
                showConfigurator && !plateBase.configurationComplete &&
                    <DisplaySimulator 
                        onClose = { () => {} }
                        plateBase = { plateBase }
                        location = { 'only-simulator' }
                        changePlateAssemblyType = { setPlateAssembly }
                        addSubPlate = { addSubPlate }
                        removeSubPlate = { removeSubPlate }
                        addSlotToSubPlate = { addSlotToSubPlate }
                        removeSlotOfSubPlate = { removeSlotOfSubPlate }
                        addItemToSlot = { addItemToSlot }
                        removeItemOfSlot = { removeItemOfSlot }
                        addItemToFirstSlot = { addItemToFirstSlot }
                        restartPlateDisplay = { restartPlateDisplay }
                        restartPlatePushButtons = { restartPlatePushButtons }
                        changePlateConfigurated = { changePlateConfigurated }
                        addPushButtonToSlot = { addPushButtonToSlot }    
                        addPushButtonToAllPlate = { addPushButtonToAllPlate }
                        addPushButtonToFirstSlot = { addPushButtonToFirstSlot } 
                        t = { t }
                    />
            }
            {
                // Descarga de PDF e Imagen
                showConfigurator && plateBase.configurationComplete &&
                    <div className='mb-16 mt-28'>
                        <h1 className="mt-8 mb-0 ml-8 mr-8 text-2xl font-semibold tracking-wide text-center uppercase center">
                            {t("simulador_placas")}
                        </h1>   
                        <h2 className="text-lg font-semibold tracking-wide text-center uppercase center">
                            {t("placa_configurada")}
                        </h2>
                        <img src={ plateBase.image } alt="" className="mx-auto mt-2 w-72" />       
                        <div className="flex mt-6">
                            <PDFDownloadLink 
                                document = { pdfDocument } 
                                fileName = { `${t("simulacion_comelit_placa")}_${capitalize(plateBase.plateModel)}.pdf` }
                                className = "col-span-2 p-2 mx-4 text-lg text-white rounded cursor-pointer disabled:opacity-50 sm:col-span-1 bg-green-comelit "
                            >
                                {({ blob, url, loading, error }) =>
                                    <span className="flex items-center mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                        {t("descargar_pdf")}
                                    </span>
                                }      
                            </PDFDownloadLink>     
                            <Button
                                type = 'normal' 
                                text = {
                                    <span className="flex items-center mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
                                        </svg>
                                        {t("descargar_imagen")}
                                    </span>
                                }
                                onClick = { downloadImage }
                            />
                        </div>
                        <div className="mt-6 mb-8 text-center">
                            <Button
                                type = 'normal' 
                                text = {
                                    <span className="flex items-center mr-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
                                        </svg>
                                        {t("volver")} 
                                    </span>
                                }
                                onClick = { 
                                    () => { 
                                        setConfigurationComplete(false) 
                                        setPDFDocument(null)
                                    }
                                }
                            />
                        </div>
                    </div>
            }
        </div>
    )
}