import React, { useState, useEffect, Fragment } from 'react'
import AsyncSelect from 'react-select/async';

import BudgetHeader from './budget/BudgetHeader';
import BudgetExport from './budget/BudgetExport';
import { Separator } from '../elements/Separator';
import { AlertCenter } from '../elements/AlertCenter';
import { Button } from '../elements/Button';

import HttpService from '../../services/HttpService'

import { areSameObjects, loader, getTableBudgetTitle, formatoEuro } from '../../other/Utils';
import { getConfigurationProducts, getRelatedProducts, getFrameBudget, getProductTrimFrame, getProductVisor } from '../../other/ApiCalls';
import { checkDiscount, getFamilyRates, getProductRates, restartBudget, updateBudgetWithRates, getInstallationCost, getRenderInstallationCost } from '../../other/BudgetUtils';

import Select from 'react-select'

export default function StepFive(props) {

    const [isLoading, setIsLoading] = useState(true)
    const [showSearchClient, setShowSearchClient] = useState(false)
    const [userInfo, setUserInfo] = useState([])
    const [accountInfo, setAccountInfo] = useState([])
    const [familyRates, setFamilyRates] = useState([])
    const [productRates, setProductRates] = useState([])
    const [externalRate, setExternalRate] = useState(-1)
    const [ratesOptions, setRatesOptions] = useState([])
    const [thirdPartyBudget, setThirdPartyBudget] = useState(false)
    const [exportToCRM, setExportToCRM] = useState(true)
    const [userRatesChecked, setUserRatesChecked] = useState(false)

    const userLocation = localStorage.getItem("comelit-user-language")

    const [alert, setAlert] = useState({
        showAlert: false,
        title: '',
        message: '',
        continueText: false,
        buttons: false,
        value: '',
        name: ''
    })

    const http = new HttpService()

    useEffect(() => {

        let budget = []
        let additionalLinesIndex = props.budget.findIndex(element => element.type === "additional_lines")
        if (additionalLinesIndex !== -1)
            budget.push(props.budget[additionalLinesIndex])

        // Estudiamos los elementos relacionados con la configuración
        getConfigurationRelatedProducts(budget)
            .then(async(budget) => {

                let individualProducts = budget[budget.findIndex(subBudget => subBudget.type === "individual")].plates[0].content

                if (props.simulatorType === "association") {
                    // Estudiamos las placas principales
                    let mainPlatesProducts = await getProductsPlate(props.mainPlates, "main", individualProducts)
                    budget.push(getBudgetInfo(mainPlatesProducts, "main"))
                }

                // Estudiamos las placas secundarias
                let secondaryPlatesProducts = await getProductsPlate(props.secondaryPlates, "secondary", individualProducts)
                budget.push(getBudgetInfo(secondaryPlatesProducts, "secondary"))

                // Estudiamos las unidades internas
                let internalUnits = props.accessories.filter(accessory => accessory.category === "UNIDADES INTERNAS")
                if (internalUnits.length > 0) {
                    let internalUnitsProducts = [{
                        type: "accessories",
                        products: internalUnits
                    }]

                    budget.push(getBudgetInfo(internalUnitsProducts, "internal_units"))
                }

                // Estudiamos los accesorios
                let accessories = props.accessories.filter(accessory => accessory.category !== "UNIDADES INTERNAS")
                if (accessories.length > 0) {
                    let accessoriesProducts = [{
                        type: "accessories",
                        products: accessories
                    }]

                    budget.push(getBudgetInfo(accessoriesProducts, "accessories"))
                }

                getRelatedItems(budget)

            })
    }, [])

    async function getRelatedItems(budget) {

        for (let i = 0; i < budget.length; i++) {
            if (["additional_lines", "installation"].includes(budget[i].type))
                continue

            let subBudgetPlates = budget[i].plates;
            for (let j = 0; j < subBudgetPlates.length; j++) {
                let plate = subBudgetPlates[j]
                let content = plate.content;
                let related = content.filter(product => product.relatedArticles && product.articleCode !== "6801W")

                if (related.length) {
                    for (let z = 0; z < related.length; z++) {
                        let articleCode = related[z].articleCode
                        let quantity = related[z].quantity
                        let productsRelated = await getRelatedProducts(articleCode, props.simulatorType)

                        productsRelated.forEach(productRelated => {

                            if (checkIfAddProduct(budget[i], articleCode, productRelated, plate.numPlate)) {

                                // Buscamos si el producto a añadir ya está
                                let indexProduct = content.findIndex(product => product.articleCode === productRelated.article_code)

                                if (indexProduct !== -1) {
                                    content[indexProduct].quantity += quantity
                                } else {
                                    content.push({
                                        articleCode: productRelated.article_code,
                                        link: productRelated.link,
                                        image: productRelated.image_url,
                                        description: productRelated.description,
                                        quantity: quantity,
                                        type: productRelated.type,
                                        pvp: productRelated.pvp,
                                        relatedArticles: productRelated.relatedArticles,
                                        sub_pvp: quantity * productRelated.pvp,
                                        dto_1: 0,
                                        dto_2: 0,
                                        dto_3: 0,
                                        nt_und: 0,
                                        sub_tn: quantity * productRelated.pvp
                                    })
                                }
                            }
                        })
                    }
                }
            }
        }

        const installationCost = await getInstallationCost(budget, "normal")
        budget.push({
            type: "installation",
            pvp: installationCost
        })

        // this.sortBudgetByArticleCode(budget)
        props.setBudget(budget)

        http.getData("user/view-profile", "user-token")
            .then(res => {

                if (res && res.data) {
                    setUserInfo(res.data)

                    // Solo si estamos en ESPAÑA, consultamos CRM
                    if (userLocation === "es") {
                        // Si es tipo 1 o 2 (ADMINISTRADOR o CLIENTE INTERNO), obtenemos las posibles tarifas a aplicar
                        if ([1, 2].includes(res.data.id_user_type)) {

                            // Obtenemos los entornos del usuario
                            http.getDataForceManager(process.env.REACT_APP_FORCE_MANAGER_URL + "users/" + res.data.id_user_crm, false)
                                .then(userInfoCRM => {

                                    let userBranches = userInfoCRM.branches
                                    if (userBranches) {
                                        let branchesId = []
                                        if (Array.isArray(userBranches))
                                            branchesId.push(userBranches.map(branch => branch.id))
                                        else
                                            branchesId.push(userBranches.id)

                                        http.getDataForceManager(process.env.REACT_APP_FORCE_MANAGER_URL + "rates?where=deleted='false'%20AND%20endDate%20is%20null", false)
                                            .then(ratesRes => {

                                                let userRates = []
                                                ratesRes.forEach(rate => {
                                                    for (let j = 0; j < rate.branchVisibility.length; j++) {
                                                        const branch = rate.branchVisibility[j];
                                                        if (branchesId.includes(branch.id)) {
                                                            userRates.push(rate)
                                                            break
                                                        }
                                                    }
                                                })

                                                let rates = [{ label: "-- Sin tarifa --", value: -1 }]
                                                for (let z = 0; z < userRates.length; z++) {
                                                    const rate = userRates[z];
                                                    rates.push({ label: rate.descriptionES, value: rate.id })
                                                }
                                                setRatesOptions(rates)

                                            })
                                    }

                                })
                        }

                        // Si es tipo 3 o 4 (CLIENTE EXTERNO o CLIENTE DESCONOCIDO, obtenemos sus datos asociados)
                        if ([3, 4].includes(res.data.id_user_type)) {
                            let accountCRM = res.data.id_account_crm
                            if (accountCRM && accountCRM !== "") {
                                http.getDataForceManager(process.env.REACT_APP_FORCE_MANAGER_URL + "accounts/" + accountCRM, false)
                                    .then(accountRes => {

                                        // Obtenemos los datos del responsable de la empresa
                                        http.getDataForceManager(process.env.REACT_APP_FORCE_MANAGER_URL + "users/" + accountRes.salesRepId1.id, false)
                                            .then(respAccountRes => {
                                                accountRes.responsibleEmail = respAccountRes.email
                                                accountRes.responsiblePhone = respAccountRes.phone
                                                accountRes.responsibleName = respAccountRes.nic

                                                setAccountInfo(accountRes)
                                            })

                                    })
                            } else {
                                setIsLoading(false)
                            }
                            return
                        }
                    }

                    setIsLoading(false)
                    return

                }
            })

        props.changeClientName('')
        props.changeClientDirection('')
        props.changeClientPhone('')
        props.changeClientMail('')
    }

    // sortBudgetByArticleCode(budget){
    //     budget.forEach(subBudget => {
    //         subBudget.plates.forEach(plate => {
    //             plate.content.sort((a,b) => {
    //                 if (a.articleCode > b.articleCode) {
    //                     return -1;
    //                 }
    //                 if (a.articleCode < b.articleCode) {
    //                     return 1;
    //                 }
    //                 return 0;
    //             })
    //         })
    //     })
    // }

    async function getAccountRates(rateId, updateHook = true) {
        setIsLoading(true)

        if (!areSameObjects(props.budget, []))
            restartBudget(props.budget, props.changeDiscount)

        let familyRatesAux = await getFamilyRates(rateId)
        updateHook && setFamilyRates(familyRatesAux)

        // let budgetStudy = areSameObjects(props.budget, []) ? budgetIni : props.budget
        let productRatesAux = await getProductRates(rateId, props.budget)
        updateHook && setProductRates(productRatesAux)

        await updateBudgetWithRates(props.budget, props.changeDiscount, familyRatesAux, productRatesAux)

        setIsLoading(false)
    }

    const changeAccountCombo = (e) => {
            setIsLoading(true)

            http.getDataForceManager(process.env.REACT_APP_FORCE_MANAGER_URL + "accounts/" + e.value, false) // 7030
                .then(async resul => {

                        // Obtenemos los datos del responsable de la empresa
                        http.getDataForceManager(process.env.REACT_APP_FORCE_MANAGER_URL + "users/" + resul.salesRepId1.id, false)
                            .then(respAccountRes => {

                                    // Si ya hemos exportado, establecemos de nuevo la exportación por el cambio
                                    !exportToCRM && setExportToCRM(true)

                                    props.changeClientName(`${resul.name} ${resul.vatNumber ? `(${resul.vatNumber})` : ''}` || '')
                props.changeClientDirection(`${ resul.address1 } - ${resul.postcode} ${resul.city} ${resul.region && resul.region !== '' ? `(${resul.region})` : ''}` || '')
                props.changeClientPhone(resul.phone || '')
                props.changeClientMail(resul.email || '')
    
                resul.responsibleEmail = respAccountRes.email
                resul.responsiblePhone = respAccountRes.phone
                resul.responsibleName = respAccountRes.nic

                setAccountInfo(resul)
    
                // // Si tiene tarifa asociada y no hay seleccionada en el combo de externa
                if ( userInfo.apply_rate && resul.rateId && resul.rateId.id && externalRate === -1 ) {
                    getAccountRates(resul.rateId.id)
                } else {
                    setIsLoading(false)
                }
            })
        })
    }
    
    const checkIfAddProduct = (budget, articleCode, relatedProduct, numPlate) => {

        let plateType = budget.type
        let systemType = props.systemType
        let relatedArticleCode = relatedProduct.article_code
        let plate, concierge, controlType

        // Obtenemos la placa relacionada
        if (numPlate) {
            if (numPlate.toString().includes("A"))
                numPlate = numPlate.substring(0, numPlate.indexOf("-"))
            if (plateType === "secondary"){
                plate = props.secondaryPlates[numPlate-1]
            }
            if (plateType === "main"){
                plate = props.mainPlates[numPlate-1]
            }
        }
        if (plate) {
            concierge = plate.concierge
            controlType = plate.controlType
        }

        // Artículos que tienen excepciones en los relacionadoos
        let articlesException = ["3268S/0", "3268S/1", "3268S/2", "3262S/0", "3262S/1", "3262S/2", "3179S", "SK9000U", "IX9102", "IX9202", "IX9302"]
        if ( articlesException.includes(articleCode) && numPlate ){
            
            // 3268S/0 , 3268S/1 y 3268S/2
            if (["3268S/0", "3268S/1", "3268S/2"].includes(articleCode)){
                // Simulador de Edificio
                if ( props.simulatorType === "edifice" ){
                    // Vídeo con conserjería - 4680C
                    if (( systemType === "video" && concierge) || systemType === "audio"){
                        if (relatedArticleCode !== "4680C") return false
                    }
                    // Video sin conserjería - 4681
                    if (systemType === "video" && !concierge){
                        if (relatedArticleCode !== "4681") return false
                    }
                }
                // Simulador de Mancomunidades
                if ( props.simulatorType === "association" ){
                    // Las placas principales - 4680C
                    if (plateType === "main"){
                        if (relatedArticleCode !== "4680C") return false
                    }
                    // Las placas secundarias - 4681
                    if (plateType === "secondary"){
                        if (relatedArticleCode !== "4681") return false
                    }
                }
            }

            // 3262S/0, 3262S/1 y 3262S/2
            if (["3262S/0", "3262S/1", "3262S/2"].includes(articleCode)){

                // Simulador de Edificio
                if ( props.simulatorType === "edifice" ){
                    // Vídeo con conserjería o audio - 1622
                    if (( systemType === "video" && concierge) || systemType === "audio"){
                        if (relatedArticleCode !== "1622") return false
                    }
                    // Vídeo sin conserjería - 1621
                    if ( systemType === "video" && !concierge){
                        if (relatedArticleCode !== "1621") return false
                    }
                }
                // Simulador de Mancomunidades
                if ( props.simulatorType === "association" ){
                    // Placas principales y video - 1622
                    if (systemType === "video" && plateType === "main"){
                        if (relatedArticleCode !== "1622") return false
                    }
                    // Placas secundarias y video - 1621
                    if (systemType === "video" && plateType === "secondary"){
                        if (relatedArticleCode !== "1621") return false
                    }
                    // Tipo audio - 1621
                    if (systemType === "audio"){
                        if (relatedArticleCode !== "1621") return false
                    }
                }
            }

            // 3179S o IX9102/IX9202/IX9302
            if (articleCode === "3179S" || ["IX9102", "IX9202", "IX9302"].includes(articleCode)){
                
                if (( systemType === "video" && concierge) || systemType === "audio"){
                    if (controlType === "basic" && relatedArticleCode !== "SK9000I") return false
                    if (controlType === "advance" && relatedArticleCode !== "SK9001I") return false
                }
                if ((systemType === "video" && !concierge)){
                    if (controlType === "basic" && !["SK9015","SK9000I"].includes(relatedArticleCode)) return false
                    if (controlType === "advance" && !["SK9015","SK9001I"].includes(relatedArticleCode)) return false
                }
            }


            // SK9000U
            if (articleCode === "SK9000U"){
                if (plateType === "main" && relatedArticleCode !== "SK9015") return false
                if (plateType !== "main" && (systemType === "video" && concierge)) return false
            }
        }

        // Controles de acceso
        let accessControlExceptions = ["SK9000I", "SK9001I", "SK9015"]
        if (accessControlExceptions.includes(relatedArticleCode) && plate && plate.plateModel === "switch"){

            if (systemType === "audio" || (systemType === "video" && concierge) 
                    || (props.simulatorType === "association" && systemType === "video" && plateType === "main")){
                if (controlType === "basic" && relatedArticleCode !== "SK9000I") return false
                if (controlType === "advance" && relatedArticleCode !== "SK9001I") return false
            }
            if ((props.simulatorType === "edifice" && systemType === "video" && !concierge) 
                    || (props.simulatorType === "association" && systemType === "video" && plateType === "secondary")){
                if (controlType === "basic" && relatedArticleCode !== "SK9000I" && relatedArticleCode !== "SK9015") return false
                if (controlType === "advance" && relatedArticleCode !== "SK9001I" && relatedArticleCode !== "SK9015") return false
                if (controlType === "teclado" && relatedArticleCode !== "SK9015") return false
            }

        }
        
        // SK9015 nunca en placas principales
        if (relatedArticleCode === "SK9015" && plateType === "main") return false

        return true
    }

    const checkIfAddProductPlate = (subPlate, type, product, plateType, products, numVerticals = 0) => {

        let systemType = props.systemType
        let concierge = subPlate.concierge
        let simulatorType = props.simulatorType
        
        // Articulo 1595, solo se añade en Mancomunidad VIDEO para placas principales, para audio en secundarias para edificios TODOS los de audio o video con conserjería
        if (product.articleCode === "1595"){
            if (simulatorType === "association" && systemType === "video" && type !== "main")
                return false
            if (simulatorType === "edifice" && type === "secondary" && systemType === "video" && !concierge)
                return false
        }
        
        // Articulo 1200 - Para MANCOMUNIDAD y ACCESO ADICIONAL no se tiene que añadir
        if (product.articleCode === "1200"){
            if (simulatorType === "association" && plateType !== "additional")
                return false
        }

        // Articulo 4840, solo se añade en edificio video sin conserjería con más de una vertical o mancomunidad video, en los portales 
        // internos con más de una vertical
        if (product.articleCode === "4840"){
            if (simulatorType === "association"){
                if (type === "main" || systemType !== "video" || (type === "secondary" && numVerticals <= 1) )
                    return false
            }
        }

        // Articulo 1424 para mancomunidad video no debe aparecer
        if (product.articleCode === "1424" && simulatorType === "association" && systemType === "video")
            return false

            
        // SK9015 nunca en placas principales
        if (product.articleCode === "SK9015" && type === "main") return false

        // Productos asociados a Switch
        let switchProducts = ["4680C", "4681", "1621", "1622"]
        // Si es alguno de los productos de Switch pero no estamos en Switch no lo añadimos
        if ( switchProducts.includes(product.articleCode) && subPlate.plateModel !== "switch" )
            return false

        if ( switchProducts.includes(product.articleCode)){
                
            // Obtenemos si es tipo vídeo o audio
            let monoBlockAudio = products.find(article => article.articleCode.includes("-AD")) !== undefined
            let monoBlockVideo = products.find(article => article.articleCode.includes("-VD")) !== undefined
            if ( !monoBlockAudio && !monoBlockVideo){
                monoBlockAudio = props.systemType === "audio"
                monoBlockVideo = props.systemType === "video"
            }
            
            if ( product.articleCode === "4680C" ){
                // Edificio VIDEO CON conserjería - Portales y accesos adicionales
                // Mancomunidad - Placas principales con tipo vídeo
                if ((simulatorType === "edifice" && monoBlockVideo && concierge && type === "secondary") 
                        || (simulatorType === "association" && plateType === "main" && monoBlockVideo))
                    return true
                return false
            }

            if ( product.articleCode === "4681" ){
                // Edificio VIDEO SIN conserjería - Portales y accesos adicionales
                // Mancomunidad - Placas secundarias con tipo vídeo
                if ((simulatorType === "edifice" && monoBlockVideo && !concierge && type === "secondary") 
                        || (simulatorType === "association" && type === "secondary" && monoBlockVideo))
                    return true
                return false
            }

            if ( product.articleCode === "1621" ){
                // Edificio VIDEO SIN conserjeria - Acceso adicional tipo audio
                // Mancomunidad VIDEO secundarias tipo audio
                if ((simulatorType === "edifice" && monoBlockAudio && props.systemType === "video" 
                            && !concierge && plateType === "additional") 
                        || (simulatorType === "association" && type === "secondary" && monoBlockAudio && props.systemType === "video" ))
                    return true
                return false
            }

            if ( product.articleCode === "1622" ){
                // Sistema tipo AUDIO
                // Edificio VIDEO CON conserjeria - Acceso adicional tipo audio
                // Mancomunidad VIDEO - Acceso principal tipo AUDIO
                if ( props.systemType === "audio" 
                        || (simulatorType === "edifice" && monoBlockAudio && props.systemType === "video" 
                            && concierge && plateType === "additional")
                        || (simulatorType === "association" && type === "main" && monoBlockAudio))
                    return true
                return false
            }
        }

        return true
    }

    const checkQuantityProductFrame = (plateModel, product, numAditionals) => {
        
        let quantity = 1
        let switchAddtionalModules = ["IX9156", "IX9157", "IX9158", "IX9160A", "IX9161A", "IX9162A", "IX9163A", "IX9164A", "IX9165A"]

        if (plateModel === "switch" && switchAddtionalModules.includes(product.article_code)){
            quantity = numAditionals
        }
        return quantity
    }

    const getConfigurationRelatedProducts = (budget) => {
        return new Promise( (resolve ,reject) => {
            let additionalAccess = 0, audioConcierges = 0, videoConcierges = 0, portalsMultipleVertical = 0, platesWithSK = 0

            // Obtenemos la cantidad de conserjerías de audio y de video totales
            audioConcierges += props.conciergeSystemType === "audio" ? 1 : 0
            videoConcierges += props.conciergeSystemType === "video" ? 1 : 0
            let allPlates = props.mainPlates.concat(props.secondaryPlates)
            allPlates.forEach(plate => {
                audioConcierges += plate.conciergeSystemType === "audio" ? 1 : 0
                videoConcierges += plate.conciergeSystemType === "video" ? 1 : 0            
            });

            // Obtenemos la cantidad de portales con más de una vertical
            portalsMultipleVertical = props.portalInfo.filter(portal => portal.verticals > 1).length
            
            // Si se añade SK9000U o SK9001U en el diseño, insertar un SK9015 por placa configurada con alguna de estas referencias 
            //         -> Portales secundarios o acceso adicional y VIDEO
            allPlates.forEach(plate => {
                // Accesos adicionales
                additionalAccess += plate.numAditionalAccess

                // Contenido placa principal
                let numSK = 0
                let plateContents = plate.contents
                plateContents.forEach(contents => {
                    let subPlateContent = contents.subPlateContent
                    numSK += subPlateContent.filter(slot => slot.slotContent.articleCode === "SK9000U" || slot.slotContent.articleCode === "SK9001U").length
                });
                platesWithSK += numSK > 0 ? 1 : 0

                // Contenido accesos adicionales
                plate.additionalContents.forEach(additional => {
                    numSK = 0
                    additional.contents.forEach(content => {
                        let subPlateContent = content.subPlateContent
                        numSK += subPlateContent.filter(slot => slot.slotContent.articleCode === "SK9000U" || slot.slotContent.articleCode === "SK9001U").length                    
                    });
                    platesWithSK += numSK > 0 ? 1 : 0
                });
            })

            getConfigurationProducts(props.simulatorType, props.systemType , props.mainAccess, props.internalPortals, 
                props.houses, props.instalationType, additionalAccess, audioConcierges, videoConcierges, portalsMultipleVertical, platesWithSK)
            .then(products => {

                let extraProducts = []
                if (products.filter(product => product.type === "common_element" && product.quantity > 0).length > 0){
                    extraProducts = [{
                        type: "common_elements",
                        products: products.filter(product => product.type === "common_element" && product.quantity > 0)
                    }]
                    budget.push(getBudgetInfo(extraProducts, "common_elements")) 
                }
                
                if (products.filter(product => product.type === "concierge" && product.quantity > 0).length > 0){
                    extraProducts = [{
                        type: "concierge",
                        products: products.filter(product => product.type === "concierge")
                    }] 
                    budget.push(getBudgetInfo(extraProducts, "concierge"))
                    
                }
                
                if (products.filter(product => product.type === "individual" && product.quantity > 0)){
                    extraProducts = [{
                        type: "individual",
                        products: products.filter(product => product.type === "individual" && product.quantity > 0)
                    }]
                    budget.push(getBudgetInfo(extraProducts, "individual"))
                }
                
                resolve(budget)
            })
        })
    }

    const getBudgetInfo = (platesProduct, type) => {

        let budgetInfo = {
            type: type,
            plates: []
        }

        for (let i = 0; i < platesProduct.length; i++) {
            let plateProducts = platesProduct[i].products;
            budgetInfo.plates.push({
                numPlate: platesProduct[i].numPlate, 
                content: []
            })

            for (let j = 0; j < plateProducts.length; j++) {
                const product = plateProducts[j];
                if (product.quantity > 0) {
                    let articleCode = product.article_code || product.articleCode
                    if (articleCode.includes("-VD"))
                        articleCode = articleCode.replace("-VD","")
                        if (articleCode.includes("-AD"))
                            articleCode = articleCode.replace("-AD","")

                    // Main product
                    budgetInfo.plates[i].content.push({
                        articleCode: articleCode,
                        link: product.link,
                        image: product.image_url ? product.image_url : product.image,
                        description: product.description,
                        quantity: product.quantity,
                        type: product.type,
                        pvp: product.pvp,
                        relatedArticles: product.relatedArticles,
                        sub_pvp: product.quantity * product.pvp,
                        dto_1: 0,
                        dto_2: 0,
                        dto_3: 0,
                        nt_und: 0,
                        sub_tn: product.quantity * product.pvp
                    })
                }
            }
        }

        return budgetInfo
    }

    const getProductFrame = (plateModel, assembly, totalSlots, houses, switchColumns, plateMonoblock) => {
        return new Promise( (resolve ,reject) => {
            getFrameBudget(plateModel, assembly, totalSlots, houses, switchColumns, plateMonoblock)
            .then( results => {
                resolve(results)
            })
        })
    }

    const getProducts = (plateBudget, content, plateModel, assembly, trimFrame, visor) => {
        return new Promise( async (resolve ,reject) => {

            let subPlateContent = content.subPlateContent
            
            // Recorremos los slots de la placa
            subPlateContent.forEach(slot => {
                if ( slot.slotContent !== "occupied_by_double" && !areSameObjects(slot.slotContent, []) ) {
                    
                    // Buscamos si el producto ya está añadido
                    let indexProduct = plateBudget.products.findIndex(product => product.articleCode === slot.slotContent.articleCode)
                    if ( indexProduct !== -1 ){
                        plateBudget.products[indexProduct].quantity += 1
                    }
                    else {
                        plateBudget.products.push({
                            articleCode: slot.slotContent.articleCode,
                            description: slot.slotContent.description,
                            pvp: slot.slotContent.pvp,                                
                            relatedArticles: slot.slotContent.relatedArticles,
                            image: slot.slotContent.src,
                            link: slot.slotContent.deepLink,
                            quantity: 1
                        })
                    }

                    // Recorremos los botones, en caso de tener
                    if ( slot.slotContent.buttonsContent ){
                        slot.slotContent.buttonsContent.forEach(button => {
                            
                            // Buscamos si el producto ya está añadido
                            let indexProduct = plateBudget.products.findIndex(product => product.articleCode === button.articleCode)
                            if ( indexProduct !== -1 ){
                                plateBudget.products[indexProduct].quantity += 1
                            }
                            else {
                                plateBudget.products.push({
                                    articleCode: button.articleCode,
                                    description: button.description,
                                    pvp: button.pvp,                                
                                    relatedArticles: button.relatedArticles,
                                    image: button.src,
                                    quantity: 1
                                })
                            }
                        })
                    }
                }
            })

            // Para Switch, obtenemos las columnas y la placa monoblock principal
            let switchColumns = '--'
            let plateMonoblock = '--'
            if (plateModel === "switch"){
                let content = subPlateContent.find(item => item.location === "--")
                switchColumns = content.slotContent.numColumns
                plateMonoblock = content.slotContent.articleCode
            }

            let productsFrame = await getProductFrame(plateModel, assembly, subPlateContent.length, props.houses, switchColumns, plateMonoblock)
            if (productsFrame.length){
                
                productsFrame.forEach(productFrame => {

                    let numAditionals = subPlateContent.filter(element => element.location !== "--").length
                    let quantity = checkQuantityProductFrame(plateModel, productFrame, numAditionals)

                    // Comprobamos si es placa SWITCH y superficie, con un módulo, solo se añaden los módulos para adicionales
                    if (!( plateModel === "switch" && assembly === "surface" && numAditionals === 1
                            && !["IX9160A", "IX9161A", "IX9162A", "IX9163A", "IX9164A", "IX9165A"].includes(productFrame.article_code)))
                        addProduct(plateBudget, productFrame, quantity)

                });                
            }

            // Estudiamos embellecedores
            if (trimFrame){
                let trimFrameProducts = await getProductTrimFrame(subPlateContent.length)
                if (trimFrameProducts.length){
                    trimFrameProducts.forEach(trimFrameProduct => {
                        addProduct(plateBudget, trimFrameProduct, 1)
                    });
                }
            }

            // Estudiamos viseras
            if (visor){
                let visorProducts = await getProductVisor(assembly, subPlateContent.length)
                if (visorProducts.length){
                    visorProducts.forEach(visorProduct => {
                        addProduct(plateBudget, visorProduct, 1)                        
                    })
                }
            }
            
            resolve()
        })
    }

    const addProduct = (plateBudget, product, quantity = 1) => {
        
        let articleCode = product.articleCode === undefined ? product.article_code : product.articleCode
        let image = product.image === undefined ? product.image_url : product.image
        let relatedArticles = product.relatedArticles === undefined ? product.related_articles : product.relatedArticles

        // Buscamos si el producto a añadir ya está
        let indexProduct = plateBudget.products.findIndex(productBudget => productBudget.articleCode === articleCode)
        if (indexProduct !== -1){
            plateBudget.products[indexProduct].quantity += quantity
        }
        else {
            plateBudget.products.push({
                articleCode: articleCode,
                description: product.description,
                pvp: product.pvp,
                relatedArticles: relatedArticles,
                image: image,
                link: product.link,
                quantity: quantity
            })
        }
    }

    const getProductsPlate = (plate, type, individualProducts = null) => {
        return new Promise( async (resolve ,reject) => {
            
            let products = []
            
            let extraProductsMainPlate = ["1595","4680C","4681", "1621", "1622"]
            let extraProductsSecondaryPlate = ["4840", "1210", "1595", "SK9015", "4680C","4681", "1621", "1622"]
            let extraProductsAdditionalAccess = ["1210", "1404", "1595", "1424", "1200", "1224A", "SK9015", "4680C","4681", "1621", "1622"]

            // Obtenemos los elementos de la placa recibida
            for (let i = 0; i < plate.length; i++) {

                const subPlate = plate[i];
                let plateModel = subPlate.plateModel
                let assembly = subPlate.assembly
                let plateContents = subPlate.contents
                let trimFrame = subPlate.trimFrame
                let visor = subPlate.visor

                let plateBudget = {
                    type: type,
                    numPlate: i+1,
                    products: []
                }

                // Recorremos las subplacas
                for (let j = 0; j < plateContents.length; j++) {
                    const content = plateContents[j];
                    await getProducts(plateBudget, content, plateModel, assembly, trimFrame, visor)
                }
                
                // Añadimos los artículos individual para las placas principales
                if (type === "main"){
                    individualProducts.forEach(product => {      
                        
                        if (product.type === "individual" && extraProductsMainPlate.includes(product.articleCode)){
                            
                            if (checkIfAddProductPlate(subPlate, type, product, "main", plateBudget.products)) {
                                addProduct(plateBudget, product)
                            }
                        }
                    })
                }
                
                // Añadimos los artículos extra para las placas secundarias
                if (type === "secondary"){
                    individualProducts.forEach(product => {
                        
                        if (product.type === "individual" && extraProductsSecondaryPlate.includes(product.articleCode)){
                            
                            let numVerticals = props.portalInfo[i].verticals
                            if (checkIfAddProductPlate(subPlate, type, product, "secondary", plateBudget.products, numVerticals)) {
                                addProduct(plateBudget, product)
                            }
                        }
                    })
                }

                products.push(plateBudget)

                // Si hay accesos adicionales, los recorremos
                if (subPlate.additionalContents){
                    for (let j = 0; j < subPlate.additionalContents.length; j++) {
                        let additionalContents = subPlate.additionalContents[j].contents;
                        let assembly = subPlate.additionalContents[j].assembly

                        let plateBudget = {
                            type: type,
                            numPlate: `${i+1}-${j+1}A`,
                            products: []
                        }
                        // Recorremos los accesos adicionales
                        for (let x = 0; x < additionalContents.length; x++) {
                            const content = additionalContents[x];
                            await getProducts(plateBudget, content, plateModel, assembly, trimFrame, visor)
                        }              
                
                        // Añadimos los artículos individual para los accesos adicionales
                        individualProducts.forEach(product => {
                            if (product.type === "individual" && extraProductsAdditionalAccess.includes(product.articleCode)){

                                if (checkIfAddProductPlate(subPlate, type, product, "additional", plateBudget.products )) {
                                    addProduct(plateBudget, product)
                                }
                            }
                        })

                        products.push(plateBudget)                    
                    }
                }
            }
            resolve(products)
        })
    }

    const alertDiscount = (e) => {
        
        e.preventDefault()

        if (e.relatedTarget){
            if (e.relatedTarget.localName === "button")
                return
        }
        
        let name = e.target.name
        let value = e.target.value
        
        if (value !== '' && value > 0)
            setAlert({
                showAlert: true,
                title: `${props.t("aplicar_descuento")}`,
                message: `${props.t("desea_aplicar_descuento")}`,
                continueText: false,
                buttons: true,
                value: value,
                name: name
            })
    }

    const changeAlert = (response) => (resul) => {

        let {name, value} = alert
        if (response){
            props.changeDiscountToAllProducts(name, value)
            
            // Si ya hemos exportado, establecemos de nuevo la exportación por el cambio
            !exportToCRM && setExportToCRM(true)
        }

        setAlert({
            showAlert: false,
            title: '',
            message: '',
            continueText: false,
            buttons: false,
            value: '',
            name: ''
        })
    }
    
    const deleteAditionalLine = (index) => {
        props.deleteAditionalLineBudget(index)
    }

    const _handleKeyDown = (e) => {
        e.key === "Enter" && alertDiscount(e)
    }

    const getBudgetType = (type, totalRows) => {

        let tables = []
        let pvpTotal = 0
        let pvpNeto = 0

        let indexType = props.budget.findIndex(info => info.type === type)
        let budgetType = props.budget[indexType]
        for (let i = 0; i < budgetType.plates.length; i++) {
            let tableRows = []
            let numPlate = budgetType.plates[i].numPlate

            for (let j = 0; j < budgetType.plates[i].content.length; j++) {                
                let product = budgetType.plates[i].content[j]
                let idRow = `${type}-${numPlate}-${j}`
                let discount = product.dto_1 + product.dto_2 + product.dto_3
                
                tableRows.push(
                    <tr id={idRow} key={idRow}>
                        <td className="p-2 font-semibold border-2">{ totalRows }</td> {/* N */}
                        {
                            type === "additional_lines" 
                                ? <td className="p-2 text-center border-2">--</td>
                                :
                                    <td className="p-2 text-center border-2">
                                        {
                                            product.link !== "" ?
                                                <a href={product.link} target="_blank" rel="noopener noreferrer">
                                                    <img src={ product.image.includes('http') ? product.image : `../products/${ product.image }` } 
                                                        className="w-12 mx-auto" alt={`${props.t("imagen")} ${product.articleCode}`} />
                                                </a>
                                            : <img src={ product.image && product.image.includes('http') ? product.image : `../products/${ product.image }` } 
                                                className="w-12 mx-auto" alt={`${props.t("imagen")} ${product.articleCode}`} />
                                        }
                                    </td>
                        } {/* IMAGEN */}
                        <td className="p-2 text-center border-2">{ product.articleCode }</td> {/* REFERENCIA */}
                        <td className="p-2 border-2">{ product.description }</td> {/* DESCRIPCION */}
                        <td className="p-2 text-center border-2">{ product.quantity }</td> {/* UND */}
                        {
                        // Precios solo para ESPAÑA
                        userLocation === "es" && 
                            <Fragment>
                                <td className="p-1 text-center border-2">{ formatoEuro( product.pvp )}</td> {/* PVP/UN. */}
                                <td className="p-1 text-center border-2">{ formatoEuro( product.sub_pvp )}</td> {/* SUB.PVP */}
                                {
                                    userInfo.id_user_type !== 4 && 
                                        <Fragment>
                                            <td className="p-1 text-center border-2"><input className="w-full text-center bg-gray-100 disabled:bg-gray-300" max="100" min="0" onChange={ checkDiscount(product, type, idRow, props.changeDiscount, props.simulatorType, exportToCRM, setExportToCRM, numPlate) } onKeyDown={_handleKeyDown} type="number" name="dto_1" id="dto_1" onBlur={alertDiscount} value={ product.dto_1 === 0 ? '' : product.dto_1 }/></td> {/* DTO.1 */}
                                            <td className="p-1 text-center border-2"><input className="w-full text-center bg-gray-100 disabled:bg-gray-300" max="100" min="0" onChange={ checkDiscount(product, type, idRow, props.changeDiscount, props.simulatorType, exportToCRM, setExportToCRM, numPlate) } onKeyDown={_handleKeyDown} type="number" name="dto_2" id="dto_2" onBlur={alertDiscount} value={ product.dto_2 === 0 ? '' : product.dto_2 }/></td> {/* DTO.2 */}
                                            <td className="p-1 text-center border-2"><input className="w-full text-center bg-gray-100 disabled:bg-gray-300" max="100" min="0" onChange={ checkDiscount(product, type, idRow, props.changeDiscount, props.simulatorType, exportToCRM, setExportToCRM, numPlate) } onKeyDown={_handleKeyDown} type="number" name="dto_3" id="dto_3" onBlur={alertDiscount} value={ product.dto_3 === 0 ? '' : product.dto_3 }/></td> {/* DTO.3 */}
                                            <td className="p-1 text-center border-2"><input className="w-full text-center bg-gray-100 disabled:bg-gray-300" min="0" onChange={ checkDiscount(product, type, idRow, props.changeDiscount, props.simulatorType, exportToCRM, setExportToCRM, numPlate) } onKeyDown={_handleKeyDown} type="number" name="nt_und" id="nt_und" value={ product.nt_und === 0 ? '' : (discount > 0 ? product.nt_und.toFixed(2) : product.nt_und) }  disabled={ discount > 0 } /></td> {/* NT/UND */}
                                            <td className="p-1 text-center border-2">{ formatoEuro( product.sub_tn ) }</td> {/* SUB.TN */}
                                        </Fragment>
                                }
                            </Fragment>
                        } 
                        {
                            type === "additional_lines" &&
                            <td className="p-1 text-center border-2 cursor-pointer" onClick={ () => deleteAditionalLine(i)}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                                    <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                            </td>
                        }
                    </tr>
                )                   
                pvpTotal += product.sub_pvp
                pvpNeto += product.sub_tn
                totalRows += 1
            }

            let title = getTableBudgetTitle(type, numPlate, props.portalInfo, props.simulatorType, i, props.t)

            tables.push(
                <div className={`${i>0 ? "mt-8" : "mt-2"} overflow-x-auto`}>
                    {
                        !["common_elements", "concierge", "accessories", "internal_units"].includes(type) &&
                            <h4 className="mb-2 font-semibold text-center uppercase">{ title }</h4>
                    }
                    <table className="w-full bg-white bg-opacity-75 border-2 border-collapse">
                        <thead>
                            <tr className="bg-gray-400 bg-opacity-75 border-2" id={i}>
                                <th className="p-1 border-2">N</th>
                                <th className="p-1 uppercase border-2">{props.t("imagen")}</th>
                                <th className="p-1 uppercase border-2">{props.t("referencia")}</th>
                                <th className="p-1 uppercase border-2">{props.t("descripcion")}</th>
                                <th className="p-1 uppercase border-2">{props.t("und")}</th>
                                {
                                    // Precios solo para ESPAÑA
                                    userLocation === "es" && 
                                        <Fragment>
                                            <th className="p-1 uppercase border-2">{props.t("sub_pvp")}</th>
                                            {
                                                userInfo.id_user_type !== 4 && 
                                                    <Fragment>
                                                        <th className="p-1 uppercase border-2">{props.t("pvp_un")}</th>
                                                        <th className="p-1 uppercase border-2">{props.t("dto_1")}</th>
                                                        <th className="p-1 uppercase border-2">{props.t("dto_2")}</th>
                                                        <th className="p-1 uppercase border-2">{props.t("dto_3")}</th>
                                                        <th className="p-1 uppercase border-2">{props.t("nt_un")}</th>
                                                        <th className="p-1 uppercase border-2">{props.t("sub_tn")}</th>
                                                    </Fragment>
                                            }
                                        </Fragment>
                                    }
                                {
                                    type === "additional_lines" &&
                                        <th className="p-1 border-2"> </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            { tableRows }
                        </tbody>
                    </table>
                </div>
            )        
        }

        return { tables, pvpTotal, pvpNeto, totalRows }
    }

    const renderBudget = () => {
        
        let renderedBudget = []
        let pvpTotal = 0
        let pvpNeto = 0
        let totalRows = 1

        if (props.simulatorType === "association"){
            // Estudiamos las placas principales
            let budget = getBudgetType("main", totalRows)
            let renderedMainPlatesBudget = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows

            renderedBudget.push(
                <div className="p-4 pt-8 pb-8 mt-8 bg-gray-100">
                    <h4 className="text-xl font-semibold text-center uppercase">{props.t("placas_principales")}</h4>
                    { renderedMainPlatesBudget }
                </div>
            )
        }

        // Estudiamos las placas secundarias
        let budget = getBudgetType("secondary", totalRows)
        let renderedSecondaryPlatesBudget = budget.tables
        pvpTotal += budget.pvpTotal
        pvpNeto += budget.pvpNeto
        totalRows = budget.totalRows
        
        renderedBudget.push(
            <div className="mt-8">
                <Separator />
                <div className="p-4 pt-8 pb-8 mt-8 bg-gray-100">
                    <h4 className="mb-2 text-xl font-semibold text-center uppercase">{props.simulatorType === "association" ? `${props.t("placas_secundarias")}` : `${props.t("portales")}`}</h4>
                    { renderedSecondaryPlatesBudget }
                </div>
            </div>
        )

        // Estudiamos Internal Units
        if (props.budget.findIndex(info => info.type === "internal_units") !== -1){
            budget = getBudgetType("internal_units", totalRows)
            let renderedInternalUnitsBudget = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows

            renderedBudget.push(
                <div className="mt-8">
                    <Separator />
                    <div className="p-4 pt-8 pb-8 mt-8 bg-gray-100">
                        <h4 className="mb-2 text-xl font-semibold text-center uppercase">{props.t("unidades_internas")}</h4>
                        { renderedInternalUnitsBudget }
                    </div>
                </div>
            )
        }

        // Estudiamos los accesorios
        if (props.budget.findIndex(info => info.type === "accessories") !== -1){
            budget = getBudgetType("accessories", totalRows)
            let renderedAccesoriesBudget = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows

            renderedBudget.push(
                <div className="mt-8">
                    <Separator />
                    <div className="p-4 pt-8 pb-8 mt-8 bg-gray-100">
                        <h4 className="mb-2 text-xl font-semibold text-center uppercase">{props.t("accesorios")}</h4>
                        { renderedAccesoriesBudget }
                    </div>
                </div>
            )
        }
        
        // Estudiamos los elementos comunes
        if (props.budget.findIndex(info => info.type === "common_elements") !== -1){
            budget = getBudgetType("common_elements", totalRows)
            let renderedCommonElements = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows

            renderedBudget.push(
                <div className="mt-8">
                    <Separator />
                    <div className="p-4 pt-8 pb-8 mt-8 bg-gray-100">
                        <h4 className="mb-2 text-xl font-semibold text-center">{props.simulatorType === "association" ? `${props.t("elementos_comunes")}` : `${props.t("equipos")}`}</h4>
                        { renderedCommonElements }
                    </div>
                </div>
            )
        }
        
        // Estudiamos las conserjerías
        if (props.budget.findIndex(info => info.type === "concierge") !== -1){
            budget = getBudgetType("concierge", totalRows)
            let renderedConciergeItems = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows

            renderedBudget.push(
                <div className="mt-8">
                    <Separator />
                    <div className="p-4 pt-8 pb-8 mt-8 bg-gray-100">
                        <h4 className="mb-2 text-xl font-semibold text-center uppercase">{props.t("conserjería")}</h4>
                        { renderedConciergeItems }
                    </div>
                </div>
            )
        }
        
        // Si el usuario ha añadido líneas adicionales
        let additionalLinesIndex = props.budget.findIndex(element => element.type === "additional_lines")
        if (thirdPartyBudget && additionalLinesIndex !== -1 && props.budget[additionalLinesIndex].plates[0].content.length > 0){

            budget = getBudgetType("additional_lines", totalRows)
            let renderedAdditionalLinesBudget = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows

            renderedBudget.push(
                <div className="mt-8">
                    <Separator />
                    <div className="p-4 pt-8 pb-8 mt-8 bg-gray-100">
                        <h4 className="mb-2 text-xl font-semibold text-center uppercase">{props.t("lineas_adicionales")}</h4>
                        { renderedAdditionalLinesBudget }
                    </div>
                </div>
            )

        }
        
        return { renderedBudget, pvpTotal, pvpNeto }
    }

    const getCompleteBudget = (renderedBudget, pvpTotal, pvpNeto) => {

        let renderedInstallationCost = getRenderInstallationCost(props.budget, userInfo, props.changeInstallationCost)

        return(
            <div className="p-6 border-2" id="budget">
                {/* Cabecera */}
                <BudgetHeader 
                    clientName = { props.clientName }
                    clientAtt = { props.clientAtt }
                    clientMail = { props.clientMail }
                    clientDirection = { props.clientDirection }
                    clientPhone = { props.clientPhone }
                    clientLogo = { props.clientLogo } 
                    comelitComercial = { props.comelitComercial }
                    comelitEmail = { props.comelitEmail }
                    changeComelitComercial = { props.changeComelitComercial }
                    changeComelitEmail = { props.changeComelitEmail }
                    changeClientName = { props.changeClientName }
                    changeClientAtt = { props.changeClientAtt }
                    changeClientMail = { props.changeClientMail }
                    changeClientDirection = { props.changeClientDirection }
                    changeClientPhone = { props.changeClientPhone }
                    changeClientLogo = { props.changeClientLogo }
                    userInfo = { userInfo }
                    accountInfo = { accountInfo }
                    t = { props.t }
                />
                {
                    userLocation === "es" && [1,2].includes(userInfo.id_user_type ) && 
                        <div className='flex flex-col justify-center max-w-lg mx-auto mt-6'>
                            <Button text="Buscar cliente" onClick = { () => setShowSearchClient(true) }/>
                            { 
                                showSearchClient &&
                                    <AsyncSelect cacheOptions defaultOptions loadOptions={promiseAccountOptions} onChange={changeAccountCombo} className="m-4" />
                            }
                        </div>
                }
                {
                    userLocation === "es" && [1,2].includes(userInfo.id_user_type ) && ratesOptions.length > 1 &&
                        <div className='flex flex-col justify-center max-w-lg mx-auto mt-8 border'>
                            <p className='mt-4 ml-4 font-semibold'>Aplicar tarifa externa</p>
                            <Select options={ratesOptions} className="m-4 cursor-pointer" onChange={changeRatesCombo} />
                        </div>
                }
                {
                    props.reference !== "" &&
                        <h3 className="text-lg font-semibold uppercase">{props.reference}</h3>
                }
                <div className="mt-4">
                    { renderedBudget }
                </div>
                {
                    userLocation === "es" &&
                        <Fragment>
                            <div className="p-4 pt-8 mt-12 border-t-2">
                                <p className="text-center">{props.t("total_pvp")}: <span className="text-xl font-semibold">{ formatoEuro(pvpTotal, true) }</span></p>                    
                            </div>
                            {
                                userInfo.id_user_type !== 4 && 
                                    <div className="flex justify-end">
                                        <div className="flex items-center pt-2 pb-2 pl-10 pr-10 border-2 border-gray-400">
                                            <p className="mr-6 text-center">{props.t("total_neto")}: <br/> ({props.t("sin_iva")})</p>
                                            <p className="text-xl font-semibold text-center">{ formatoEuro(pvpNeto, true) }</p>
                                        </div>       
                                    </div>     
                            }   
                            {
                                renderedInstallationCost
                            }
                        </Fragment>
                }
            </div>
        )
    }

    const promiseAccountOptions = (inputValue) => {

        if ( inputValue !== "" &&  inputValue.length > 2 ) {
            setIsLoading(true)

            return new Promise((resolve) => {
                http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "accounts?where=deleted='false'%20AND%20name%20LIKE%20'%25" + inputValue + "%25'" )
                .then( resul => {        
                    let accountsInfo = resul.map( ({ id, name }) => ({ id, name }) )
                    let comboOptions = []
                    accountsInfo.forEach(account => {
                        comboOptions.push({ label: account.name, value: account.id })
                    })
        
                    resolve(comboOptions)
                    setIsLoading(false)
                })
            })
        }
    }
    
    const changeRatesCombo = (e) => {

        // Si ya hemos exportado, establecemos de nuevo la exportación por el cambio
        !exportToCRM && setExportToCRM(true)

        if ( e.value !== -1 ){
            getAccountRates(e.value, false)
            setExternalRate(e.value)
        } else{
            !areSameObjects(accountInfo, []) 
                ? getAccountRates(accountInfo.rateId.id)
                : restartBudget(props.budget, props.changeDiscount)

            setExternalRate(-1)
        }
    }

    const getUserRates = () => {

        setUserRatesChecked(true)
        // Si es tipo 3 o 4 (CLIENTE EXTERNO o CLIENTE DESCONOCIDO, obtenemos sus datos asociados)
        let accountCRM = userInfo.id_account_crm
        if ( accountCRM && accountCRM !== ""){
            http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "accounts/" + accountCRM, false)
            .then( accountRes => {                                
                // Si tiene tarifa asociada
                ( accountRes.rateId && accountRes.rateId.id ) ? getAccountRates(accountRes.rateId.id) : setIsLoading(false)
            })
        }

    }

    let budget = []
    let renderedBudget = []
    let pvpTotal = 0
    let pvpNeto = 0
    if (props.budget.length > 0){
        budget = renderBudget()
        renderedBudget = budget.renderedBudget
        pvpTotal = budget.pvpTotal
        pvpNeto = budget.pvpNeto
    }

    // Renderizamos el presupuesto
    let completeBudget = getCompleteBudget(renderedBudget, pvpTotal, pvpNeto )

    if (!areSameObjects(props.budget,[]) && !areSameObjects(userInfo,[])
        && [3,4].includes(userInfo.id_user_type) && !userRatesChecked){
            getUserRates()
    }

    return (
        <div>
            { alert.showAlert &&
                <AlertCenter 
                    alert = { alert }
                    changeShowAlert = { changeAlert }
                    t = { props.t}
                />
            }
            { isLoading 
                && loader(props.t) }
            {/* Presupuesto */}
            { completeBudget }
            <BudgetExport
                reference = { props.reference }
                simulatorType = { props.simulatorType }
                budget = { props.budget }
                clientName = { props.clientName }
                clientAtt = { props.clientAtt }
                clientMail = { props.clientMail }
                clientDirection = { props.clientDirection }
                clientPhone = { props.clientPhone }
                clientLogo = { props.clientLogo }
                portalInfo = { props.portalInfo }
                comelitComercial = { props.comelitComercial }
                comelitEmail = { props.comelitEmail }
                mainPlates = { props.mainPlates }
                secondaryPlates = { props.secondaryPlates }
                exportType = { props.exportType }
                changeExportType = { props.changeExportType }
                userInfo = { userInfo }
                accountInfo = { accountInfo }
                addLineToBudget = { props.addLineToBudget }
                thirdPartyBudget = { thirdPartyBudget }
                setThirdPartyBudget = { setThirdPartyBudget }
                exportToCRM = { exportToCRM }
                setExportToCRM = { setExportToCRM }
                t = { props.t }
            />
        </div>
    )
}