import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async';

import HttpService from '../../services/HttpService'
import { areSameObjects, loader } from '../../other/Utils'
import { Button } from './Button'
import { CheckBoxInput } from './CheckBoxInput'
import { Separator } from './Separator'

export default function AlertCRMRelation(props) {

    const http = new HttpService()

    let { changeShowAlert } = props
    let { userId, crmUserId, crmAccountID, userType } = props.alert

    const [crmType, setCrmType] = useState("")
    const [applyRates, setApplyRates] = useState(false)
    const [formError, setFormError] = useState("")
    const [formSuccess, setFormSuccess] = useState("")

    const [loading, setLoading] = useState(false)
    const [changeRelation, setChangeRelation] = useState(false)
    const [usersOptions, setUsersOptions] = useState([])
    const [accountOptions, setAccountOptions] = useState([])

    const [showAccountCombo, setShowAccountCombo] = useState(false)
    const [userCRMSelected, setUserCRMSelected] = useState("")
    const [accountCRMSelected, setAccountCRMSelected] = useState("")

    useEffect(() => {
        
        if ( crmType === "user" ){
            setLoading(true)
            
            http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "users" )
            .then( resul => {

                let usersInfo = resul.map( ({ id, email }) => ({ id, email }) )                
                let comboOptions = []
                usersInfo.forEach(user => {
                    comboOptions.push({ label: user.email, value: user.id })
                })

                setUsersOptions(comboOptions)
                setLoading(false)
            })
        }

        if ( crmType === "account" ){
            
            setShowAccountCombo(true)
        }

    }, [crmType])

    const promiseAccountOptions = (inputValue) => {

        if ( inputValue !== "" &&  inputValue.length > 2 ) {
            setLoading(true)

            return new Promise((resolve) => {
                http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "accounts?where=deleted='false'%20AND%20name%20LIKE%20'%25" + inputValue + "%25'" )
                .then( resul => {        
                    let accountsInfo = resul.map( ({ id, name }) => ({ id, name }) )
                    let comboOptions = []
                    accountsInfo.forEach(account => {
                        comboOptions.push({ label: account.name, value: account.id })
                    })
        
                    resolve(comboOptions)
                    setAccountOptions(comboOptions)
                    setLoading(false)
                })
            })
        }
    }

    const changeUserCombo = (e) => {
        setFormError("")
        setUserCRMSelected(e.value)
    }

    const changeAccountCombo = (e) => {
        setFormError("")
        setAccountCRMSelected(e.value)
    }

    const acceptUserRelation = (e) => {
        e.preventDefault()
        setFormError("")

        if (crmType === ""){
            setFormError("Debe seleccionar un tipo de relación para el CRM")
            return
        }

        if (crmType === "account" && accountCRMSelected === ""){ 
            setFormError("Debe seleccionar una empresa del CRM a la que asociar el seleccionado")
            return
        }

        if (crmType === "user" && userCRMSelected === ""){
            setFormError("Debe seleccionar un usuario del CRM al que asociar el seleccionado")
            return
        }

        http.postData({ userId, crmType, accountCRMSelected, userCRMSelected, applyRates }, "admin/user-relation", "admin-token")
        .then( resul => {

            if ( resul.success ){
                setFormSuccess(resul.message)
                setTimeout(changeShowAlert(false), 1500);
            } else{
                setFormError(resul.message)
            }
        })
    }

    return (
        <div>
            { loading &&
                    loader(props.t)
            }
            <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex justify-center items-center">
                <div className="bg-white overflow-y-auto z-30 mt-4 h-3/4 mb-4 relative p-4 sm:pl-16 sm:pr-16 rounded w-2xl ml-4 mr-4 flex flex-col justify-center">
                    <div className='absolute cursor-pointer right-2 top-2'
                        onClick = { changeShowAlert(false) }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>

                    <div className="text-center text-xl uppercase font-semibold">
                        Relación CRM
                    </div>
                    <div className="text-center text-lg mt-2 font-semibold">
                        Modificar la relación del usuario con el CRM
                    </div>
                    <div className='mt-2 text-center'>
                        <p>{props.t("tipo_de_usuario")}: <span className='font-semibold'>{userType}</span></p>
                    </div>
                    { ( crmUserId !== null || crmAccountID !== null ) &&
                            <div className='mt-2 text-center'>
                                <Button 
                                    text='Modificar' 
                                    type='normal' 
                                    className='uppercase w-28 mt-2 mb-4'
                                    onClick = { () => {
                                        setChangeRelation(!changeRelation)
                                        setUserCRMSelected("")
                                        setAccountCRMSelected("")
                                    }}
                                />
                            </div>
                    }
                    { ((crmUserId === null && crmAccountID === null) || changeRelation ) &&
                            <div className='mt-2 text-center'>
                                <p>
                                    Seleccione tipo de relación:
                                </p>
                                <div className='flex justify-center gap-8'>
                                    <CheckBoxInput
                                        key = { 'user' }
                                        name = { 'checkUser' }
                                        label = { 'Usuario' }
                                        onChange = { () => {
                                            setCrmType('user')
                                            setUserCRMSelected("")
                                            setAccountCRMSelected("")
                                            setFormError("")
                                        }}
                                        checked = { crmType === 'user' }
                                    />
                                    <CheckBoxInput
                                        key = { 'account' }
                                        name = { 'checkAccount' }
                                        label = { `${props.t("empresa")}` }
                                        onChange = { () => {
                                            setCrmType('account')
                                            setUserCRMSelected("")
                                            setAccountCRMSelected("")
                                            setFormError("")
                                        }}
                                        checked = { crmType === 'account' }
                                    />
                                </div>
                            </div>
                    }
                    { crmType === "user" && !areSameObjects(usersOptions, []) && 
                            <Select options={ usersOptions } className="mt-4 mb-4" onChange={changeUserCombo} />
                            // <select defaultValue={ -1 } className="mt-4 mb-8 bg-gray-100 p-2 cursor-pointer border w-full">
                            //     { usersOptions }
                            // </select>
                    }
                    { crmType === "account" && showAccountCombo &&
                            <div>
                                <AsyncSelect cacheOptions defaultOptions loadOptions={promiseAccountOptions} className="mt-4 mb-4" onChange={changeAccountCombo}/>
                                <CheckBoxInput
                                    key = { 'applyRates' }
                                    name = { 'applyRates' }
                                    label = { 'Aplica tarifas' }
                                    onChange = { () => setApplyRates(!applyRates) }
                                    checked = { applyRates }
                                />
                            </div>
                    }
                    { (changeRelation || crmType !== "") &&
                        <div className="text-center mt-4">
                            <Separator/>
                            <div className="text-center mt-4">
                                {
                                    formError !== "" &&
                                        <div className="p-4 bg-red-200 text-red-800 rounded-md mb-4">
                                            {formError}
                                        </div>
                                }
                                <Button 
                                    text={`${props.t("cancelar")}`}
                                    type='secondary' 
                                    className='uppercase mt-2 sm:mt-0 w-28' 
                                    onClick = { changeShowAlert(false) }
                                />
                                <Button 
                                    text={`${props.t("aceptar")}`}
                                    type='normal' 
                                    className='uppercase w-28' 
                                    onClick = { acceptUserRelation }
                                />
                                {
                                    formSuccess !== "" &&
                                        <div className="p-4 bg-green-400 text-green-800 rounded-md mt-4">
                                            {formSuccess}
                                        </div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )    
}   