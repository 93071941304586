import React, { Component } from 'react'
import { PlateContainer } from './PlateContainer'

import { Button } from '../elements/Button'
import { RadioInput } from '../elements/RadioInput'
import { CheckBoxInput } from '../elements/CheckBoxInput'
import { Separator } from '../elements/Separator'

import { isOverFlown } from '../../other/Utils'

export class PlateConfigurator extends Component {
    constructor(props){
        super(props)
        this.state = {
            showAlert: false,
            typeAlert: '',
            background: 'gray'
        }
    }

    renderPlates = (plateInfo, numPlate, numPlates, type, additional, indexAdditional) => {

        let plates = [];
        for (let i = 1; i <= numPlates; i++) {                            
            plates.push(
                <PlateContainer 
                    key = { i } 
                    idSubPlate = { i }
                    numPlate = { numPlate }
                    plateInfo = { plateInfo }
                    addSlotToSubPlate = { this.props.addSlotToSubPlate }
                    removeSlotOfSubPlate = { this.props.removeSlotOfSubPlate }
                    type = { type }
                    additional = { additional }
                    indexAdditional = { indexAdditional }
                    addItemToSlot = { this.props.addItemToSlot }
                    removeItemOfSlot = { this.props.removeItemOfSlot }
                    addPushButtonToSlot = { this.props.addPushButtonToSlot }
                    addPushButtonToAllPlate = { this.props.addPushButtonToAllPlate }
                    addPushButtonToFirstSlot = { this.props.addPushButtonToFirstSlot }
                    t = {this.props.t}
                    plateBase = { this.props.plateBase }
                />
            )
        }
        return plates
    }

    componentDidUpdate = () => {
        document.querySelector("#plates-container") 
            && isOverFlown(document.querySelector("#plates-container"))
                ? document.querySelector("#plates-container").classList.remove("justify-center","items-center") 
                : document.querySelector("#plates-container").classList.add("justify-center","items-center") 
    }

    restartPlateDesign = () => {
        this.props.restartPlateDisplay(this.props.numPlate, this.props.type, this.props.additional, this.props.indexAdditional)
        this.setState({
            showAlert: false,
            typeAlert: ''
        })        
    }
    
    restartPlatePushButtons = () => {
        this.props.restartPlatePushButtons(this.props.numPlate, this.props.type, this.props.additional, this.props.indexAdditional)
        this.setState({
            showAlert: false,
            typeAlert: ''
        })        
    }

    setPlateVisorValue = (event) => {        
        this.props.setPlateVisorValue(event.target.checked, this.props.numPlate, this.props.type, this.props.additional, this.props.indexAdditional)
    }

    setPlateTrimFrameValue = (event) => {        
        this.props.setPlateTrimFrameValue(event.target.checked, this.props.numPlate, this.props.type, this.props.additional, this.props.indexAdditional)
    }

    renderAlert = () => {
        let title = ''
        let question = ''
        let onClick = ''
        if (this.state.typeAlert === "restart-design"){
            title = `${this.props.t("reiniciar_diseño_placa").toUpperCase()}`
            question = `${this.props.t("reiniciar_diseño_placa_pregunta")}`
            onClick = this.restartPlateDesign
        }
        if (this.state.typeAlert === "restart-buttons"){
            title = `${this.props.t("reiniciar_pulsadores").toUpperCase()}`
            question = `${this.props.t("reiniciar_pulsadores_pregunta")}`
            onClick = this.restartPlatePushButtons
        }

        return(
            <div>
                <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-full h-screen overflow-y-auto bg-gray-500 bg-opacity-75 bg-op">
                    <div className="relative z-30 pt-6 pb-6 pl-4 pr-4 mt-4 mb-4 ml-4 mr-4 overflow-y-auto bg-white rounded max-h-5/6 md:p-14">
                        <div className='absolute cursor-pointer right-2 top-2'
                            onClick={() => this.setState( {showAlert: false, typeAlert: ''} )} >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className="text-xl font-semibold text-center uppercase">{ title }</div>
                        <div className="mt-2 text-lg font-semibold text-center">{ question }</div>
                        <div className="text-lg text-center">{this.props.t("proceso_irreversible")}</div>                        
                        <div className="mt-6 text-center">
                            <Button 
                                type='normal' 
                                text = {`${this.props.t("si")}`}
                                onClick = { onClick }
                                className='uppercase w-28' 
                            />
                            <Button 
                                text = {`${this.props.t("no")}`}
                                type='secondary' 
                                onClick = {() => this.setState( {showAlert: false, typeAlert: ''} )}
                                className='mt-2 uppercase sm:mt-0 w-28' 
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
    render() {
        
        let overFlow = document.querySelector("#plates-container") ? isOverFlown(document.querySelector("#plates-container")) : false
        let { plateInfo, numPlate, type, additional, indexAdditional } = this.props

        let plateBase = additional ? plateInfo.additionalContents[indexAdditional] : plateInfo
        let numSubPlates = plateBase.numSubPlates

        let plates = this.renderPlates(plateInfo, numPlate, numSubPlates, type, additional, indexAdditional)

        let { showAlert, background } = this.state
        let renderedAlert = this.renderAlert()

        let canExpand = numSubPlates < maxPlates
        let canReduce = numSubPlates > minPlates

        let backgroundColor = ''
        if (background === 'gray')
            backgroundColor = 'bg-gray-50'
        if (background === 'granite')
            backgroundColor = 'bg-granite'
        if (background === 'brick')
            backgroundColor = 'bg-brick'

        return (
            <div className={`bg-gray-50 sm:w-3/4 border-2 sm:border-l-2 br-gray-500 m-4 sm:mr-4 sm:ml-3 sm:p-6`} id="div-configurator">
                {showAlert && renderedAlert}
                <div className="flex items-center flex-nowrap justify-evenly">
                    <RadioInput
                        name = {'gray'}
                        label = {`${this.props.t("fondo_gris")}`}
                        checked = {background === 'gray'}
                        // image = {  }
                        onChange = { () => this.setState({ background: 'gray' })}
                        className = "w-1"
                    />
                    <RadioInput
                        name = {'granite'}
                        label = {`${this.props.t("fondo_granito")}`}
                        checked = {background === 'granite'}
                        // image = {  }
                        onChange = { () => this.setState({ background: 'granite' })}
                        className = "w-1"
                    />
                    <RadioInput
                        name = {'brick'}
                        label = {`${this.props.t("fondo_ladrillo")}`}
                        checked = {background === 'brick'}
                        // image = {  }
                        onChange = { () => this.setState({ background: 'brick' })}
                        className = "w-1"
                    />
                </div>

                <div className={`flex flex-nowrap pr-4 pl-4 overflow-x-auto pt-4 ${backgroundColor} ${ !overFlow && 'justify-center items-center' }`} id="plates-container">
                    {plates}
                </div>
                
                {/* Visera - Marco embellecedor -> Solo para ULTRA*/}
                {
                    plateInfo.plateModel === "ultra" && this.props.location !== 'only-simulator' &&
                        <div className="items-center mt-2 mb-4 sm:flex justify-evenly">
                            <CheckBoxInput 
                                name = {`visor`}
                                label = {`${this.props.t("visera")}`}
                                onChange = { this.setPlateVisorValue }
                                checked = { plateBase.visor }
                            />
                            {
                                plateBase.assembly === "embedded" && 
                                    <CheckBoxInput 
                                        name = {`trim_frame`}
                                        label = {`${this.props.t("marco_embellecedor")}`}
                                        onChange = { this.setPlateTrimFrameValue }
                                        checked = { plateBase.trimFrame }
                                    />
                            }
                        </div>
                }
                

                {/* Visera - Marco embellecedor -> Solo para ULTRA*/}
                {
                    plateInfo.plateModel !== "switch" &&
                        <div>
                            <Separator />
                            <div>
                                <div className="flex justify-center pl-2 pr-2 mt-6 mb-6">
                                    <div className={`text-center hover:bg-gray-100 w-28 rounded pt-2 p-1 shadow-md border border-gray-100 mr-4 flex flex-col flex-wrap items-center cursor-${canExpand ? 'pointer' : 'not-allowed'}`} onClick={()=> canExpand && this.props.addSubPlate(numPlate, type, additional, indexAdditional)}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${ canExpand ? ' text-gray-500' : ' text-gray-300'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                                        </svg>
                                        <p className={`${ canExpand ? ' text-gray-500' : ' text-gray-300'}`}>{this.props.t("anhade_placa")}</p>
                                    </div>
                                    <div className={`text-center hover:bg-gray-100 w-28 rounded pt-2 p-1 shadow-md border border-gray-100 ml-4 flex flex-col flex-wrap items-center cursor-${canReduce ? 'pointer' : 'not-allowed'}`} onClick={()=> canReduce && this.props.removeSubPlate(numPlate, type, additional, indexAdditional)}>                                         
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`h-6 w-6 ${ canReduce ? ' text-gray-500' : ' text-gray-300'}`} fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
                                        </svg>
                                        <p className={`${ canReduce ? ' text-gray-500' : ' text-gray-300'}`}>{this.props.t("elimina_placa")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                
                <Separator />
                <div className="flex justify-center pl-2 pr-2 mt-6 mb-2">
                    {
                        plateInfo.plateModel === "ultra" && 
                            <div className={`hover:bg-gray-100 w-40 rounded pt-2 p-1 shadow-md border border-gray-100 mr-4 flex flex-col flex-wrap items-center cursor-pointer`} onClick = { () => this.setState({ showAlert: true, typeAlert: 'restart-buttons' }) }>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 10h18M3 14h18m-9-4v8m-7 0h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                                </svg>    
                                <p className={`text-gray-500 text-center`}>{this.props.t("reiniciar_pulsadores")}</p>
                            </div>
                    }
                    <div className={`hover:bg-gray-100 w-40 rounded pt-2 p-1 shadow-md border border-gray-100 ml-4 flex flex-col flex-wrap items-center cursor-pointer`} onClick = { () => this.setState({ showAlert: true, typeAlert: 'restart-design'  }) }>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                        </svg>
                        <p className={`text-gray-500 text-center`}>{this.props.t("reiniciar_diseño")}</p>
                    </div>
                </div>
            </div>
        )
    }
}

const maxPlates = 4
const minPlates = 1