import React, { Fragment } from 'react';

import { Text, View, Font } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import PDFTableRows from './PDFTableRows';

import fontThin from '../../../fonts/ttf/HelveticaNeueLTW1G-Th.ttf'
import fontBold from '../../../fonts/ttf/HelveticaNeueLTW1G-Bd.ttf'
import fontBlack from '../../../fonts/ttf/HelveticaNeueLTW1G-Blk.ttf'
import fontMedium from '../../../fonts/ttf/HelveticaNeueLTW1G-Md.ttf'

Font.register({
    family: 'Helvetica Neue',
    fonts: [
        { src: fontThin, fontWeight: 'thin' },
        { src: fontBold, fontWeight: 'bold' },
        { src: fontBlack, fontWeight: 'heavy' },
        { src: fontMedium, fontWeight: 'medium' },
    ]
  })

const styles = StyleSheet.create({
    fontLight:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'light' 
    },
    fontBold:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'bold' 
    },
    fontMedium:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'medium' 
    },
    fSize6: {
        fontSize: 6    
    },
    container: {
        marginTop: '10px',
        flexDirection: 'row',
        backgroundColor: '#b4b9c2',
        alignItems: 'center',
        padding: '5px',
        flexGrow: 1,
        fontSize: 10
    },
    // borderRight: {
    //     // borderRightWidth: '0.5',
    //     // borderRightColor: "black"
    // },
    cell: {
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    numRow: {
        width: '5%'
    },
    image: {
        width: '10%'
    },
    reference: {
        width: '15%'
    },
    description: {
        width: '30%'
    },
    units: {
        width: '10%'
    },
    pvpUnit: {
        width: '15%'
    },
    subPvp: {
        width: '15%'
    },
    referenceNetDto: {
        width: '10%'
    },
    descriptionNetDto: {
        width: '15%'
    },
    unitsNetDto: {
        width: '5%'
    },
    pvpUnitNetDto: {
        width: '10%'
    },
    subPvpNetDto: {
        width: '10%'
    },
    dtoNet: {
        width: '5%'
    },
    ntUnitNetDto: {
        width: '10%'
    },
    subTnNetDto: {
        width: '10%'
    }
})

const Header = (props) => {
    let { pdfExport, t } = props

    switch (pdfExport) {
        case 'no-prices':
            return (
                <View style={styles.container} wrap={false}>
                    <Text style={[styles.numRow, styles.fontMedium, styles.cell]}>N</Text>
                    <Text style={[styles.image, styles.fontMedium, styles.cell]}>{t("imagen").toUpperCase()}</Text>
                    <Text style={[styles.reference, styles.fontMedium, styles.cell]}>{t("referencia").toUpperCase()}</Text>
                    <Text style={[styles.description, styles.fontMedium, styles.cell]}>{t("descripcion").toUpperCase()}</Text>
                    <Text style={[styles.units, styles.fontMedium, styles.cell]}>{t("und").toUpperCase()}</Text>
                </View>
            )

        case 'only-pvp':
            return (
                <View style={styles.container} wrap={false}>
                    <Text style={[styles.numRow, styles.fontMedium, styles.cell]}>N</Text>
                    <Text style={[styles.image, styles.fontMedium, styles.cell]}>{t("imagen").toUpperCase()}</Text>
                    <Text style={[styles.reference, styles.fontMedium, styles.cell]}>{t("referencia").toUpperCase()}</Text>
                    <Text style={[styles.description, styles.fontMedium, styles.cell]}>{t("descripcion").toUpperCase()}</Text>
                    <Text style={[styles.units, styles.fontMedium, styles.cell]}>{t("und").toUpperCase()}</Text>
                    <Text style={[styles.pvpUnit, styles.fontMedium, styles.cell]}>{t("pvp_un").toUpperCase()}</Text>
                    <Text style={[styles.subPvp, styles.fontMedium, styles.cell]}>{t("sub_pvp").toUpperCase()}</Text>
                </View>
            )

        case 'net-without-discount':
            return (
                <View style={styles.container} wrap={false}>
                    <Text style={[styles.numRow, styles.fontMedium, styles.cell]}>N</Text>
                    <Text style={[styles.image, styles.fontMedium, styles.cell]}>{t("imagen").toUpperCase()}</Text>
                    <Text style={[styles.reference, styles.fontMedium, styles.cell]}>{t("referencia").toUpperCase()}</Text>
                    <Text style={[styles.description, styles.fontMedium, styles.cell]}>{t("descripcion").toUpperCase()}</Text>
                    <Text style={[styles.units, styles.fontMedium, styles.cell]}>{t("und").toUpperCase()}</Text>
                    <Text style={[styles.pvpUnit, styles.fontMedium, styles.cell]}>{t("nt_un").toUpperCase()}</Text>
                    <Text style={[styles.subPvp, styles.fontMedium, styles.cell]}>{t("sub_tn").toUpperCase()}</Text>
                </View>
            )

        case 'pvp-total-net':
            return (
                <View style={styles.container} wrap={false}>
                    <Text style={[styles.numRow, styles.fontMedium, styles.cell]}>N</Text>
                    <Text style={[styles.image, styles.fontMedium, styles.cell]}>{t("imagen").toUpperCase()}</Text>
                    <Text style={[styles.reference, styles.fontMedium, styles.cell]}>{t("referencia").toUpperCase()}</Text>
                    <Text style={[styles.description, styles.fontMedium, styles.cell]}>{t("descripcion").toUpperCase()}</Text>
                    <Text style={[styles.units, styles.fontMedium, styles.cell]}>{t("und").toUpperCase()}</Text>
                    <Text style={[styles.pvpUnit, styles.fontMedium, styles.cell]}>{t("pvp_un").toUpperCase()}</Text>
                    <Text style={[styles.subPvp, styles.fontMedium, styles.cell]}>{t("sub_tn").toUpperCase()}</Text>
                </View>
            )

        case 'pvp-discount-net':
            return (
                <View style={styles.container} wrap={false}>
                    <Text style={[styles.numRow, styles.fontMedium, styles.cell, styles.fSize6]}>N</Text>
                    <Text style={[styles.image, styles.fontMedium, styles.cell, styles.fSize6]}>{t("imagen").toUpperCase()}</Text>
                    <Text style={[styles.referenceNetDto, styles.fontMedium, styles.cell, styles.fSize6]}>{t("referencia").toUpperCase()}</Text>
                    <Text style={[styles.descriptionNetDto, styles.fontMedium, styles.cell, styles.fSize6]}>{t("descripcion").toUpperCase()}</Text>
                    <Text style={[styles.unitsNetDto, styles.fontMedium, styles.cell, styles.fSize6]}>{t("und").toUpperCase()}</Text>
                    <Text style={[styles.pvpUnitNetDto, styles.fontMedium, styles.cell, styles.fSize6]}>{t("pvp_un").toUpperCase()}</Text>
                    <Text style={[styles.subPvpNetDto, styles.fontMedium, styles.cell, styles.fSize6]}>{t("sub_pvp").toUpperCase()}</Text>
                    <Text style={[styles.dtoNet, styles.fontMedium, styles.cell, styles.fSize6]}>{t("dto_1").toUpperCase()}</Text>
                    <Text style={[styles.dtoNet, styles.fontMedium, styles.cell, styles.fSize6]}>{t("dto_2").toUpperCase()}</Text>
                    <Text style={[styles.dtoNet, styles.fontMedium, styles.cell, styles.fSize6]}>{t("dto_3").toUpperCase()}</Text>
                    <Text style={[styles.ntUnitNetDto, styles.fontMedium, styles.cell, styles.fSize6]}>{t("nt_un").toUpperCase()}</Text>
                    <Text style={[styles.subTnNetDto, styles.fontMedium, styles.fSize6]}>{t("sub_tn").toUpperCase()}</Text>
                </View>
            )
    
        default:
            break;
    }
}

const Content = (props) => {
    return (
        <PDFTableRows 
            products = {props.products} 
            type = {props.type} 
            simulatorType = {props.simulatorType} 
            pdfExport = {props.pdfExport} 
        />
    )
}

const PDFTable = (props) => {
    let { simulatorType, type, products, pdfExport, t } = props

    return (
        <Fragment>
            <Header
                pdfExport={pdfExport}
                t={t}
            />
            <Content 
                products={products} 
                simulatorType={simulatorType}
                type={type}
                pdfExport={pdfExport} 
                t={t}
            />
        </Fragment>
    )
}

export default PDFTable