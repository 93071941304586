import React, { Component } from 'react'

export class RadioInput extends Component {
    render() {

        const {name, label, image, tooltip, onChange, onClick, checked, value, disabled} = this.props

        return (
            <label className={`mt-4 text-lg ${ disabled ? 'cursor-not-allowed opacity:50' : 'cursor-pointer'} block text-center`} 
                onClick={ onClick } title={tooltip}
            >
                {image}
                <input 
                    name = {name}
                    value = {value !== null ? value : name}
                    type = "radio"
                    onChange = { onChange }
                    className = {`mr-2 h-5 w-5 ${ disabled ? 'cursor-not-allowed hidden' : 'cursor-pointer'}`}
                    checked = {checked}
                    disabled = {disabled}
                />           
                {label}
            </label>
        )
    }
}