export const areSameObjects = (objectA, objectB) => {
    for (let [key, val] of Object.entries(objectA)) {
        if (objectB.hasOwnProperty(key)){
            if (objectB[key] !== val) {
                return false
            }
        } else{
            return false
        } 
    }
    return true;
}

export const searchValueInObject = (object, value) => {

    if (object === null){    
        return true
    }

    for (let [key] of Object.entries(object)) {
        // Comprobamos si la key es otro objeto
        if (typeof object[key] === 'object' && searchValueInObject(object[key],value)){
            return true
        }

        if (object[key] === value) {
            return true        
        }
    }
    
    return false
}

export const sumPropertyInArray = (array, element) => {
    let sum = 0
    for (let i = 0; i < array.length; i++) {
        sum += array[i] ? array[i][element] : 0
    }
    return sum
}

export const scrollBottom = () => {
    window.scrollTo({ 
        top: document.documentElement.scrollHeight, 
        behavior: 'smooth'
     }); 
}

export const scrollTop = (behavior = 'smooth') => {
    window.scrollTo({ 
        top: 0, 
        behavior: behavior
    }); 
}

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const isOverFlown = (element) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export const isEmptyObject = (object) => {
    return object
        && Object.keys(object).length === 0
        && Object.getPrototypeOf(object) === Object.prototype
}

export const loader = (t) => {
    return(
        <div className="w-full h-screen fixed top-0 left-0 bg-gray-400 bg-opacity-75 bg-op z-30 flex items-center justify-center">      
            <div className="loader">{t("cargando")}...</div>
        </div>
    )
}

export const getTableBudgetTitle = (type, numPlate, portalInfo, simulatorType, iterator, t) => {
    let title = ''

    if ( type === "secondary" ){            
        let additional = ''
        let secondaryPlateIndex = 0
        if (numPlate.toString().includes("A")){
            secondaryPlateIndex = numPlate.toString().substring(0,1) - 1
        } else{
            secondaryPlateIndex = numPlate.toString() - 1 
        }

        // let secondaryPlateIndex = numPlate.toString().substring(0,1) - 1
        let homes = portalInfo[secondaryPlateIndex].homes
        if (numPlate.toString().includes('A')){
            additional = `${t("acceso_adicional")} ${ numPlate.toString().substring(2,3) } - `
        }

        if (simulatorType === "association"){
            title = `${t("placa_principal")} ${ secondaryPlateIndex+1 } - ${additional}${ homes } ${ homes > 1 ? `${t("viviendas")}` : `${t("vivienda")}` }`                    
        } else{
            title = `${t("portal")} ${ secondaryPlateIndex+1 } - ${additional}${ homes } ${ homes > 1 ? `${t("viviendas")}` : `${t("vivienda")}` }`  
        }
    } else{                
        if (simulatorType === "association"){
            title = `${t("placa_principal")} ${ iterator+1 }`
        }
    }
    
    return title
}

export const formatoEuro = (value, symbol = false) => {
    
    // Si no es entero formateamos
    if (value % 1 !== 0)
        value = value.toFixed(2)

    const exp = /(\d)(?=(\d{3})+(?!\d))/g
    const rep = '$1.'
    let arr = value.toString().split('.')
    arr[0] = arr[0].replace(exp,rep)
    
    let valueReturn = arr[1] ? arr.join(',') : arr[0]
    valueReturn += symbol ? ' €' : ''
    
    return valueReturn
}