import React, {Component} from 'react'
import {Link} from 'react-router-dom'

export class ButtonPrevious extends Component {

    renderButtonStep = (step) => {        
        if (step === 1) {
            return(
                <Link
                    to = '/'
                    className = 'block bg-green-comelit pt-2 pb-2 pl-4 pr-4 rounded cursor-pointer text-white'
                    onClick = { this.props.resetReducer }
                >
                    <i className="mr-2 fas fa-arrow-left"></i>
                    {this.props.t("volver")}                   
                </Link>
            )
        } 
        else{
            return (
                <button
                    className = 'block bg-green-comelit pt-2 pb-2 pl-4 pr-4 rounded cursor-pointer text-white disabled:opacity-50'
                    onClick = {this.props.onClick}
                >
                    <i className="mr-2 fas fa-arrow-left"></i>
                    {this.props.t("anterior")}
                </button>
            )
        }
    }

    render() {
        const {step} = this.props

        return (
            <div className="mt-12 mb-12">
                {this.renderButtonStep(step)}                
            </div>
        )
    }
}