import React, {Component} from 'react'

export class ButtonNext extends Component {
    render() {
        const {onClick, disabled} = this.props

        return (
            <div className="mt-12 mb-12">
                <button
                    className = 'block bg-green-comelit pt-2 pb-2 pl-4 pr-4 rounded cursor-pointer text-white disabled:opacity-50'
                    onClick = {onClick}
                    disabled = {disabled}
                >
                    {this.props.t("siguiente")}
                    <i className="ml-2 fas fa-arrow-right"></i>
                </button>
            </div>
        )
    }
}