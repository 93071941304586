import React, { useState, useEffect, Fragment } from 'react'
import AsyncSelect from 'react-select/async';

import BudgetHeader from '../budget/BudgetHeader'
import BudgetExport from '../budget/BudgetExport'
import { Separator } from '../../elements/Separator'
import { AlertCenter } from '../../elements/AlertCenter'
import { Button } from '../../elements/Button';

import HttpService from '../../../services/HttpService'

import { getKitBudget, getRelatedProducts } from '../../../other/ApiCalls'
import { loader, formatoEuro, areSameObjects } from '../../../other/Utils'
import { checkDiscount, getFamilyRates, getProductRates, restartBudgetKit, updateBudgetKitWithRates, getInstallationCost, getRenderInstallationCost } from '../../../other/BudgetUtils';

import Select from 'react-select'

export default function KitStepThree(props) {
    
    const http = new HttpService()

    const [isLoading, setIsLoading] = useState(true)
    const [showSearchClient, setShowSearchClient] = useState(false)
    const [userInfo, setUserInfo] = useState([])
    const [accountInfo, setAccountInfo] = useState([])
    const [familyRates, setFamilyRates] = useState([])
    const [productRates, setProductRates] = useState([])
    const [externalRate, setExternalRate] = useState(-1)
    const [ratesOptions, setRatesOptions] = useState([])
    const [ thirdPartyBudget, setThirdPartyBudget ] = useState(false)
    const [exportToCRM, setExportToCRM] = useState(true)
    const userLocation = localStorage.getItem("comelit-user-language")

    const { t } = props
    
    const [alert, setAlert] = useState(
        {
            showAlert: false,
            title: '',
            message: '',
            continueText: false,
            buttons: false,
            value: '',
            name: ''
        }
    )

    useEffect(() => {
        let { kit, houses, embedded, surface, additionalAccess, additionalInternalUnit, internalUnitsHouse } = props

        getKitBudget( kit, houses, embedded, surface, additionalAccess, additionalInternalUnit, internalUnitsHouse )
        .then( async (budgetProducts) => {

            let completeBudget = []
            
            // Comprobamos si hay líneas adicionales
            let additionalLinesIndex = props.budget.findIndex(element => element.type === "additional_lines")
            if ( additionalLinesIndex !== -1)
                completeBudget.push(props.budget[additionalLinesIndex])

            // Obtenemos el presupuesto para la parte de KITS
            let kitProducts = budgetProducts.filter(article => article.type === "kit")
            let kitBudgetInfo = await getBudgetInfo(kitProducts, "kit")
            completeBudget.push(kitBudgetInfo)

            // Obtenemos el presupuesto para la parte de ACCESOS ADICIONALES
            let additionalAccessProducts = budgetProducts.filter(article => article.type === "additional_access")
            let additionalAccessBudgetInfo = await getBudgetInfo(additionalAccessProducts, "additional_access")
            completeBudget.push(additionalAccessBudgetInfo)
            
            // Obtenemos el presupuesto para la parte de MONITORES ADICIONALES
            let additionalMonitors = budgetProducts.filter(article => article.type === "additional_monitors")
            let additionalMonitorsBudgetInfo = await getBudgetInfo(additionalMonitors, "additional_monitors")
            completeBudget.push(additionalMonitorsBudgetInfo)

            // Obtenemos el coste instalación
            const installationCost = await getInstallationCost(budget, "kit")
            completeBudget.push({
                type: "installation",
                pvp: installationCost
            })

            props.setBudget(completeBudget)

            props.changeClientName('')
            props.changeClientDirection('')
            props.changeClientPhone('')
            props.changeClientMail('')
            
            http.getData("user/view-profile", "user-token")
            .then( res => {
                
                if (res && res.data) {
                    setUserInfo(res.data)
                    
                    // Solo si estamos en ESPAÑA, consultamos CRM
                    if (userLocation === "es"){
                        // Si es tipo 1 o 2 (ADMINISTRADOR o CLIENTE INTERNO), obtenemos las posibles tarifas a aplicar
                        if ([1,2].includes(res.data.id_user_type)){

                            // Obtenemos los entornos del usuario
                            http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "users/" + res.data.id_user_crm, false)
                            .then( userInfoCRM => {
                                
                                let userBranches = userInfoCRM.branches                                
                                if (userBranches){
                                    let branchesId = []
                                    
                                    if ( Array.isArray(userBranches) )
                                        branchesId.push(userBranches.map( branch => branch.id))
                                    else
                                        branchesId.push(userBranches.id)

                                    http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "rates?where=deleted='false'%20AND%20endDate%20is%20null", false)
                                    .then( ratesRes => {                                
                                        
                                        let userRates = []
                                        ratesRes.forEach(rate => {
                                            for (let j = 0; j < rate.branchVisibility.length; j++) {
                                                const branch = rate.branchVisibility[j];
                                                if (branchesId.includes(branch.id) ){
                                                    userRates.push(rate)
                                                    break
                                                }                                        
                                            }
                                        })

                                        let rates = [{ label:"-- Sin tarifa --", value:-1 }]
                                        for (let z = 0; z < userRates.length; z++) {
                                            const rate = userRates[z];
                                            rates.push({ label: rate.descriptionES, value:rate.id })
                                        }
                                        setRatesOptions(rates)
                                        
                                    })
                                }
                            })               
                        }

                        // Si es tipo 3 o 4 (CLIENTE EXTERNO o CLIENTE DESCONOCIDO, obtenemos sus datos asociados)
                        if ([3,4].includes(res.data.id_user_type)){
                            let accountCRM = res.data.id_account_crm
                            if ( accountCRM && accountCRM !== ""){

                                http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "accounts/" + accountCRM, false)
                                .then( accountRes => {                                
                                    // Si tiene tarifa asociada
                                    ( accountRes.rateId && accountRes.rateId.id ) ? getAccountRates(accountRes.rateId.id) : setIsLoading(false)

                                    // Obtenemos los datos del responsable de la empresa
                                    http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "users/" + accountRes.salesRepId1.id, false)
                                    .then( respAccountRes => {
                                        accountRes.responsibleEmail = respAccountRes.email
                                        accountRes.responsiblePhone = respAccountRes.phone
                                        accountRes.responsibleName = respAccountRes.nic

                                        setAccountInfo(accountRes)
                                    })
                                })
                                
                            } else{
                                setIsLoading(false)
                            }
                            return
                        }
                    }
                    
                    setIsLoading(false)
                    return

                }
                
                setIsLoading(false)
            })
        })
    }, [])

    async function getBudgetInfo(products, type){
        
        let productsInfo = []

        for (let i = 0; i < products.length; i++) {
            const product = products[i];
            
            productsInfo.push({
                articleCode: product.article_code,
                link: product.link,
                image: product.image_url ? product.image_url : product.image,
                description: product.description,
                quantity: product.quantity,
                type: product.type,
                pvp: product.pvp,
                relatedArticles: product.relatedArticles,
                sub_pvp: product.quantity * product.pvp,
                dto_1: 0,
                dto_2: 0,
                dto_3: 0,
                nt_und: 0,
                sub_tn: product.quantity * product.pvp
            })

            // Obtenemos los productos relacionados, si tiene
            if (product.relatedArticles){
                
                let relatedProducts = await getRelatedProducts(product.article_code, "kit")
                
                relatedProducts.forEach(relatedProduct => {
                    productsInfo.push({
                        articleCode: relatedProduct.article_code,
                        link: relatedProduct.link,
                        image: relatedProduct.image_url ? relatedProduct.image_url : relatedProduct.image,
                        description: relatedProduct.description,
                        quantity: product.quantity,
                        type: relatedProduct.type,
                        pvp: relatedProduct.pvp,
                        relatedArticles: relatedProduct.relatedArticles,
                        sub_pvp: product.quantity * relatedProduct.pvp,
                        dto_1: 0,
                        dto_2: 0,
                        dto_3: 0,
                        nt_und: 0,
                        sub_tn: product.quantity * relatedProduct.pvp
                    })
                })
            }
        }

        return ({
            type: type,
            products: productsInfo
        })
    }

    const alertDiscount = (e) => {
        
        let name = e.target.name
        let value = e.target.value
        
        if (value !== '' && value > 0)        
            setAlert({
                showAlert: true,
                title: `${t("aplicar_descuento")}`,
                message: `${t("desea_aplicar_descuento")}`,
                continueText: false,
                buttons: true,
                value: value,
                name: name
            })
    }

    const changeAlert = (response) => (resul) => {
        setAlert({
            showAlert: false,
            title: '',
            message: '',
            continueText: false,
            buttons: false,
            value: '',
            name: ''
        })
        
        let {name, value} = alert
        if (response){
            props.changeDiscountToAllProducts(name, value)

            // Si ya hemos exportado, establecemos de nuevo la exportación por el cambio
            !exportToCRM && setExportToCRM(true)
        }
    }

    const deleteAditionalLine = (index) => {
        props.deleteAditionalLineBudget(index)
    }
    
    const _handleKeyDown = (e) => {
        e.key === "Enter" && alertDiscount(e)
    }

    const getBudgetType = (type, totalRows) => {
        
        let tables = []
        let pvpTotal = 0
        let pvpNeto = 0
        let tableRows = []

        let budgetType = props.budget.findIndex(budget => budget.type === type)
        let budgetProducts = props.budget[budgetType].products

        for (let i = 0; i < budgetProducts.length; i++) {
            let article = budgetProducts[i]
            let idRow = `${type}-${i}`
            let discount = article.dto_1 + article.dto_2 + article.dto_3

            tableRows.push(
                <tr id={idRow} key={idRow}>
                    <td className="border-2 p-2 font-semibold">{ totalRows }</td> {/* N */}
                    {
                        type === "additional_lines" 
                            ? <td className="border-2 p-2 text-center">--</td>
                            :
                                <td className="border-2 p-2 text-center">
                                    {
                                        article.link !== "" ?
                                            <a href={article.link} target="_blank" rel="noopener noreferrer">
                                                <img src={ article.image.includes('http') ? article.image : `../products/${ article.image }` } 
                                                    className="w-12 mx-auto" alt={`${t("imagen")} ${article.articleCode}`} />
                                            </a>
                                        : <img src={ article.image && article.image.includes('http') ? article.image : `../products/${ article.image }` } 
                                            className="w-12 mx-auto" alt={`${t("imagen")} ${article.articleCode}`} />
                                    }
                                </td>
                    }  {/* IMAGEN */}
                    <td className="border-2 p-2 text-center">{ article.articleCode }</td> {/* REFERENCIA */}
                    <td className="border-2 p-2">{ article.description }</td> {/* DESCRIPCION */}
                    <td className="border-2 p-2 text-center">{ article.quantity }</td> {/* UND */}
                    {
                        // Precios solo para ESPAÑA
                        userLocation === "es" && 
                            <Fragment>
                                <td className="border-2 p-1 text-center">{ formatoEuro( article.pvp ) }</td> {/* PVP/UN. */}
                                <td className="border-2 p-1 text-center">{ formatoEuro( article.sub_pvp) }</td> {/* SUB.PVP */}
                                {
                                    userInfo.id_user_type !== 4 && 
                                        <Fragment>
                                            <td className="border-2 p-1 text-center"><input className="disabled:bg-gray-300 bg-gray-100 text-center w-full" max="100" min="0" onChange={ checkDiscount(article, type, idRow, props.changeDiscount, "kit", exportToCRM, setExportToCRM) } onKeyDown={_handleKeyDown} type="number" name="dto_1" id="dto_1" disabled={false} onBlur={alertDiscount} value={ article.dto_1 === 0 ? '' : article.dto_1 }/></td> {/* DTO.1 */}
                                            <td className="border-2 p-1 text-center"><input className="disabled:bg-gray-300 bg-gray-100 text-center w-full" max="100" min="0" onChange={ checkDiscount(article, type, idRow, props.changeDiscount, "kit", exportToCRM, setExportToCRM) } onKeyDown={_handleKeyDown} type="number" name="dto_2" id="dto_2" disabled={false} onBlur={alertDiscount} value={ article.dto_2 === 0 ? '' : article.dto_2 }/></td> {/* DTO.2 */}
                                            <td className="border-2 p-1 text-center"><input className="disabled:bg-gray-300 bg-gray-100 text-center w-full" max="100" min="0" onChange={ checkDiscount(article, type, idRow, props.changeDiscount, "kit", exportToCRM, setExportToCRM) } onKeyDown={_handleKeyDown} type="number" name="dto_3" id="dto_3" disabled={false} onBlur={alertDiscount} value={ article.dto_3 === 0 ? '' : article.dto_3 }/></td> {/* DTO.3 */}
                                            <td className="border-2 p-1 text-center"><input className="disabled:bg-gray-300 bg-gray-100 text-center w-full" min="0" onChange={ checkDiscount(article, type, idRow, props.changeDiscount, "kit", exportToCRM, setExportToCRM) } onKeyDown={_handleKeyDown} type="number" name="nt_und" id="nt_und" value={ article.nt_und === 0 ? '' : (discount > 0 ? article.nt_und.toFixed(2) : article.nt_und) }  disabled={ discount > 0 } /></td> {/* NT/UND */}
                                            <td className="border-2 p-1 text-center">{ formatoEuro( article.sub_tn ) }</td> {/* SUB.TN */}
                                        </Fragment>
                                }
                            </Fragment>
                    }                   
                    {
                        type === "additional_lines" &&
                        <td className="border-2 p-1 text-center cursor-pointer" onClick={ () => deleteAditionalLine(i)}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        </td>
                    }
                </tr>
            )                   
                pvpTotal += article.sub_pvp
                pvpNeto += article.sub_tn
                totalRows += 1
        }

        tables.push(
            <div className={`${type!=="kit" ? "mt-8" : "mt-2"} overflow-x-auto`} key={type+totalRows}>
                <table className="border-collapse border-2 bg-white bg-opacity-75 w-full">
                    <thead>
                        <tr className="border-2 bg-gray-400 bg-opacity-75">
                            <th className="border-2 p-1">N</th>
                            <th className="border-2 p-1 uppercase">{t("imagen")}</th>
                            <th className="border-2 p-1 uppercase">{t("referencia")}</th>
                            <th className="border-2 p-1 uppercase">{t("descripcion")}</th>
                            <th className="border-2 p-1 uppercase">{t("und")}</th>
                            {
                                // Precios solo para ESPAÑA
                                userLocation === "es" && 
                                    <Fragment>
                                        <th className="border-2 p-1 uppercase">{t("pvp_un")}</th>
                                        <th className="border-2 p-1 uppercase">{t("sub_pvp")}</th>
                                        {
                                            userInfo.id_user_type !== 4 && 
                                                <Fragment>
                                                    <th className="border-2 p-1 uppercase">{t("dto_1")}</th>
                                                    <th className="border-2 p-1 uppercase">{t("dto_2")}</th>
                                                    <th className="border-2 p-1 uppercase">{t("dto_3")}</th>
                                                    <th className="border-2 p-1 uppercase">{t("nt_un")}</th>
                                                    <th className="border-2 p-1 uppercase">{t("sub_tn")}</th>
                                                </Fragment>
                                        }
                                    </Fragment>
                            }                            
                            {
                                type === "additional_lines" &&
                                    <th className="border-2 p-1"> </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        { tableRows }
                    </tbody>
                </table>
            </div>
        )

        return ({ tables, pvpTotal, pvpNeto, totalRows })
    }

    const renderBudget = () => {

        let renderedBudget = []
        let pvpTotal = 0
        let pvpNeto = 0
        let totalRows = 1
        
        // Estudiamos los KITS
        let budget = getBudgetType("kit", totalRows)
        let renderedKitBudget = budget.tables
        pvpTotal += budget.pvpTotal
        pvpNeto += budget.pvpNeto
        totalRows = budget.totalRows

        renderedBudget.push(
            <div className="mt-8 p-4 bg-gray-100 pt-8 pb-8">
                <h4 className="font-semibold text-center text-xl uppercase">{t("kit")}</h4>
                { renderedKitBudget }
            </div>
        )

        // Estudiamos los ACCESOS ADICIONALES
        if (props.additionalAccess){
            budget = getBudgetType("additional_access", totalRows)
            let renderedAdditionalAccesBudget = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows
            
            renderedBudget.push(
                <div className="mt-8">
                    <Separator />
                    <div className="mt-8 p-4 bg-gray-100 pt-8 pb-8">
                        <h4 className="font-semibold text-center mb-2 text-xl">{t("acceso_adicionales").toUpperCase()}</h4>
                        { renderedAdditionalAccesBudget }
                    </div>
                </div>
            )
        }

        // Estudiamos los MONITORES ADICIONALES
        if (props.additionalInternalUnit){
            budget = getBudgetType("additional_monitors", totalRows)
            let renderedAdditionalMonitorsBudget = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows

            renderedBudget.push(
                <div className="mt-8">
                    <Separator />
                    <div className="mt-8 p-4 bg-gray-100 pt-8 pb-8">
                        <h4 className="font-semibold text-center mb-2 text-xl">{t("monitores_adicionales").toUpperCase()}</h4>
                        { renderedAdditionalMonitorsBudget }
                    </div>
                </div>
            )
        }

        // Si el usuario ha añadido líneas adicionales
        let additionalLinesIndex = props.budget.findIndex(element => element.type === "additional_lines")
        if (thirdPartyBudget && additionalLinesIndex !== -1 && props.budget[additionalLinesIndex].products.length > 0){
            budget = getBudgetType("additional_lines", totalRows)
            let renderedAdditionalLinesBudget = budget.tables
            pvpTotal += budget.pvpTotal
            pvpNeto += budget.pvpNeto
            totalRows = budget.totalRows
            renderedBudget.push(
                <div className="mt-8">
                    <Separator />
                    <div className="mt-8 p-4 bg-gray-100 pt-8 pb-8">
                        <h4 className="font-semibold text-center mb-2 text-xl uppercase">{t("lineas_adicionales")}</h4>
                        { renderedAdditionalLinesBudget }
                    </div>
                </div>
            )

        }

        return { renderedBudget, pvpTotal, pvpNeto }
    }
    
    const promiseAccountOptions = (inputValue) => {

        if ( inputValue !== "" &&  inputValue.length > 2 ) {
            setIsLoading(true)

            return new Promise((resolve) => {                
                http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "accounts?where=deleted='false'%20AND%20name%20LIKE%20'%25" + inputValue + "%25'" )
                .then( resul => {        
                    let accountsInfo = resul.map( ({ id, name }) => ({ id, name }) )
                    let comboOptions = []
                    accountsInfo.forEach(account => {
                        comboOptions.push({ label: account.name, value: account.id })
                    })
        
                    resolve(comboOptions)
                    setIsLoading(false)
                })
            })
        }
    }

    async function getAccountRates(rateId, updateHook = true){
        setIsLoading(true)

        restartBudgetKit(props.budget, props.changeDiscount)
        
        let familyRatesAux = await getFamilyRates(rateId)
            updateHook && setFamilyRates(familyRatesAux)

        let productRatesAux = await getProductRates(rateId, props.budget)
            updateHook && setProductRates(productRatesAux)

        await updateBudgetKitWithRates(props.budget, props.changeDiscount, familyRatesAux, productRatesAux)
        
        setIsLoading(false)
    }

    const changeAccountCombo = (e) => {
        setIsLoading(true)

        http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "accounts/" + e.value, false ) // 7030
        .then( async resul => {
            
            // Si ya hemos exportado, establecemos de nuevo la exportación por el cambio
            !exportToCRM && setExportToCRM(true)

            // Obtenemos los datos del responsable de la empresa
            http.getDataForceManager( process.env.REACT_APP_FORCE_MANAGER_URL + "users/" + resul.salesRepId1.id, false)
            .then( respAccountRes => {
                props.changeClientName(`${resul.name} ${resul.vatNumber ? `(${resul.vatNumber})` : ''}` || '')
                props.changeClientDirection(`${ resul.address1 } - ${resul.postcode} ${resul.city} ${resul.region && resul.region !== '' ? `(${resul.region})` : ''}` || '')
                props.changeClientPhone(resul.phone || '')
                props.changeClientMail(resul.email || '')
    
                resul.responsibleEmail = respAccountRes.email
                resul.responsiblePhone = respAccountRes.phone
                resul.responsibleName = respAccountRes.nic

                props.changeComelitComercial(respAccountRes.nic)
                props.changeComelitEmail(respAccountRes.email)

                setAccountInfo(resul)
                
                // // Si tiene tarifa asociada y no hay seleccionada en el combo de externa
                if ( userInfo.apply_rate && resul.rateId && resul.rateId.id && externalRate === -1 ) {
                    getAccountRates(resul.rateId.id)
                } else {
                    setIsLoading(false)
                }
            })
        })
    }

    const changeRatesCombo = (e) => {

        // Si ya hemos exportado, establecemos de nuevo la exportación por el cambio
        !exportToCRM && setExportToCRM(true)

        if ( e.value !== -1 ){
            getAccountRates(e.value, false)
            setExternalRate(e.value)
        } else{
            !areSameObjects(accountInfo, []) 
                ? getAccountRates(accountInfo.rateId.id)
                : restartBudgetKit(props.budget, props.changeDiscount)

            setExternalRate(-1)
        }
    }
        
    let budget = []
    let renderedBudget = []
    let pvpTotal = 0
    let pvpNeto = 0
    if (props.budget && props.budget.length > 0){
        budget = renderBudget()
        renderedBudget = budget.renderedBudget
        pvpTotal = budget.pvpTotal
        pvpNeto = budget.pvpNeto
    }
    
    let renderedInstallationCost = getRenderInstallationCost(props.budget, userInfo, props.changeInstallationCost)
    
    return(
        <div>
            { alert.showAlert &&
                <AlertCenter 
                    alert = { alert }
                    changeShowAlert = { changeAlert }
                    t = { t }
                />
            }
            { isLoading 
                && loader(t) }
            <div className="border-2 p-6" id="budget">
                <BudgetHeader
                    clientName = { props.clientName }
                    clientAtt = { props.clientAtt }
                    clientMail = { props.clientMail }
                    clientDirection = { props.clientDirection }
                    clientPhone = { props.clientPhone }
                    clientLogo = { props.clientLogo } 
                    comelitComercial = { props.comelitComercial }
                    comelitEmail = { props.comelitEmail }
                    changeComelitComercial = { props.changeComelitComercial }
                    changeComelitEmail = { props.changeComelitEmail }
                    changeClientName = { props.changeClientName }
                    changeClientAtt = { props.changeClientAtt }
                    changeClientMail = { props.changeClientMail }
                    changeClientDirection = { props.changeClientDirection }
                    changeClientPhone = { props.changeClientPhone }
                    changeClientLogo = { props.changeClientLogo }
                    userInfo = { userInfo }
                    accountInfo = { accountInfo }
                    t = { t }
                />
                {
                    userLocation === "es" && [1,2].includes(userInfo.id_user_type ) && 
                        <div className='flex justify-center mt-6 flex-col max-w-lg mx-auto'>
                            <Button text={`${t("buscar_cliente")}`} onClick = { () => setShowSearchClient(true) }/>
                            { 
                                showSearchClient &&
                                    <AsyncSelect cacheOptions defaultOptions loadOptions={promiseAccountOptions} onChange={changeAccountCombo} className="m-4" />
                            }
                        </div>
                }
                {
                    userLocation === "es" && [1,2].includes(userInfo.id_user_type ) && ratesOptions.length > 1 &&
                        <div className='flex justify-center mt-8 flex-col max-w-lg mx-auto border'>
                            <p className='ml-4 mt-4 font-semibold'>Aplicar tarifa externa</p>
                            <Select options={ratesOptions} className="m-4 cursor-pointer" onChange={changeRatesCombo} />
                        </div>
                }
                                    
                <div className='mt-8 mb-8'>
                    <Separator />
                    { renderedBudget }
                </div>
                {
                    userLocation === "es" &&
                        <Fragment>
                            <div className="mt-12 border-t-2 p-4 pt-8">
                                <p className="text-center">{t("total_pvp")}: <span className="font-semibold text-xl">{ formatoEuro(pvpTotal, true) }</span></p>                    
                            </div>
                            {
                                userInfo.id_user_type !== 4 && 
                                    <div className="flex justify-end">
                                        <div className="flex border-2 border-gray-400 pl-10 pr-10 pt-2 pb-2 items-center">
                                            <p className="text-center mr-6">{t("total_neto")}: <br/> ({t("sin_iva")})</p>
                                            <p className="font-semibold text-center text-xl">{ formatoEuro(pvpNeto, true) }</p>
                                        </div>       
                                    </div> 
                            }                   
                            {
                                renderedInstallationCost
                            }
                        </Fragment>
                }
               
                <div className='mt-8 mb-8'>
                    <Separator />
                    <BudgetExport
                        reference = { props.reference }
                        simulatorType = { "kit" }
                        budget = { props.budget }
                        clientName = { props.clientName }
                        clientAtt = { props.clientAtt }
                        clientMail = { props.clientMail }
                        clientDirection = { props.clientDirection }
                        clientPhone = { props.clientPhone }
                        clientLogo = { props.clientLogo }
                        comelitComercial = { props.comelitComercial }
                        comelitEmail = { props.comelitEmail }
                        exportType = { props.exportType }
                        changeExportType = { props.changeExportType }
                        userInfo = { userInfo }
                        accountInfo = { accountInfo }
                        addLineToBudget = { props.addLineToBudget }
                        thirdPartyBudget = { thirdPartyBudget }
                        setThirdPartyBudget = { setThirdPartyBudget }
                        exportToCRM = { exportToCRM }
                        setExportToCRM = { setExportToCRM }
                        t = { t }
                    />
                </div>
            </div>
        </div>
    )
}