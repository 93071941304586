import React from 'react'
import { Button } from './Button'

export default function AlertThirdPartyForm(props){
    
    let { thirdPartyFields, handleFieldChange, setShowThirdPartyForm } = props

    return (
        <div>
            <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex items-center justify-center">
                <div className="bg-white max-h-5/6 overflow-y-auto z-30 mt-4 mb-4 relative pt-6 pb-6 pl-4 pr-4 md:p-14 rounded mr-4 ml-4">
                    <div className='absolute cursor-pointer right-2 top-2'
                        onClick = { () => setShowThirdPartyForm(false) }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <div className="text-center text-xl uppercase font-semibold">
                        {props.t("datos_tercero")}
                    </div>
                    <div className='text-left mt-4'>
                        <p className='font-normal'>
                            <label htmlFor="social_reason" className="text-slate-500 mr-2 font-bold text-left w-36">{props.t("razón_social")}</label>
                            <input type="text" name="social_reason" id="social_reason" placeholder={`${props.t("razón_social")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={thirdPartyFields.social_reason} onChange={handleFieldChange}/>
                        </p>    
                        <p className='font-normal'>
                            <label htmlFor="address" className="text-slate-500 mr-2 font-bold text-left w-36">{props.t("direccion")}: </label>
                            <input type="text" name="address" id="address" placeholder={`${props.t("direccion")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={thirdPartyFields.address} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="phone" className="text-slate-500 mr-2 font-bold text-left w-36">{props.t("telefono")}</label>
                            <input type="tel" name="phone" id="phone" placeholder={`${props.t("telefono")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={thirdPartyFields.phone} onChange={handleFieldChange}/>
                        </p>  
                        <p className='font-normal'>
                            <label htmlFor="att" className="text-slate-500 mr-2 font-bold text-left w-36">{props.t("att")}: </label>
                            <input type="text" name="att" id="att" placeholder={`${props.t("att")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={thirdPartyFields.att} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="email" className="text-slate-500 mr-2 font-bold text-left w-36">{props.t("correo_electronico")}</label>
                            <input type="email" name="email" id="email" placeholder={`${props.t("correo_electronico")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={thirdPartyFields.email} onChange={handleFieldChange}/>
                        </p>
                    </div>
                    <div className="text-center mt-4">
                        <Button 
                            text={`${props.t("aceptar")}`}
                            type='normal' 
                            className='uppercase w-28' 
                            onClick = { () => setShowThirdPartyForm(false) }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}