import React from 'react'
import { useFormFields } from '../../other/helpers/hooksFormInput'
import { formatoEuro } from '../../other/Utils'
import { Button } from './Button'

export default function AlertAddLineToBudget(props){
    
    let { setShowAddLine } = props
    
    const initialAddLineInfo = {
        reference: '',
        description: '',
        quantity: '',
        pvp_und: '',
        dto_1: '',
        dto_2: '',
        dto_3: '',
        nt_und: '',
        sub_tn: ''
    }

    const [ addLineBudgetFields, handleFieldChange ] = useFormFields( initialAddLineInfo )

    const changeDiscount = (event) => {

        let dto_change = event.target.id
        let dto_1 = addLineBudgetFields.dto_1 === '' ? 0 : addLineBudgetFields.dto_1
        let dto_2 = addLineBudgetFields.dto_2 === '' ? 0 : addLineBudgetFields.dto_2 
        let dto_3 = addLineBudgetFields.dto_3 === '' ? 0 : addLineBudgetFields.dto_3

        if (dto_change === "dto_1"){
            dto_1 = parseInt(event.target.value)
        }
        if (dto_change === "dto_2"){
            dto_2 = parseInt(event.target.value)
        }
        if (dto_change === "dto_3"){
            dto_3 = parseInt(event.target.value)
        }

        let totalDiscount = ((1 - ((1 - (dto_1/100)) * (1 - (dto_2/100)) * (1 - (dto_3/100)))) * 100).toFixed(2)

        if (totalDiscount > 100) {
            event.preventDefault()
            return
        }
    
        if ( totalDiscount > 0) {
            addLineBudgetFields.nt_und = parseFloat((addLineBudgetFields.pvp_und * ( 1 - (totalDiscount / 100))).toFixed(2))
            addLineBudgetFields.sub_tn = addLineBudgetFields.nt_und * addLineBudgetFields.quantity
        } else{
            addLineBudgetFields.nt_und = addLineBudgetFields.pvp_und
            addLineBudgetFields.sub_tn = addLineBudgetFields.pvp_und * addLineBudgetFields.quantity
        }

        handleFieldChange(event)
    }

    const handleAddLine = () => {

        addLineBudgetFields.dto_1 = addLineBudgetFields.dto_1 === '' ? 0 : parseInt(addLineBudgetFields.dto_1)
        addLineBudgetFields.dto_2 = addLineBudgetFields.dto_2 === '' ? 0 : parseInt(addLineBudgetFields.dto_2)
        addLineBudgetFields.dto_3 = addLineBudgetFields.dto_3 === '' ? 0 : parseInt(addLineBudgetFields.dto_3)
        addLineBudgetFields.quantity = parseInt(addLineBudgetFields.quantity)
        addLineBudgetFields.pvp_und = parseFloat(addLineBudgetFields.pvp_und)

        props.addLineToBudget(addLineBudgetFields)
        setShowAddLine(false)
    }

    let netDisabled = addLineBudgetFields.dto_1 > 0 || addLineBudgetFields.dto_2 > 0 || addLineBudgetFields.dto_3 > 0
    let dtoDisabled = !(addLineBudgetFields.quantity > 0 && addLineBudgetFields.pvp_und > 0)
    let acceptDisabled = !addLineBudgetFields.quantity > 0 || addLineBudgetFields.pvp_und === ''

    if (addLineBudgetFields.nt_und !== ''){
        addLineBudgetFields.sub_tn = addLineBudgetFields.nt_und * addLineBudgetFields.quantity
    } else{
        addLineBudgetFields.sub_tn = addLineBudgetFields.pvp_und * addLineBudgetFields.quantity
    }

    return (
        <div>
            <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex items-center justify-center">
                <div className="bg-white max-h-5/6 overflow-y-auto z-30 mt-4 mb-4 relative pt-6 pb-6 pl-4 pr-4 md:p-14 rounded mr-4 ml-4">
                    <div className='absolute cursor-pointer right-2 top-2'
                        onClick = { () => setShowAddLine(false) }
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                    <div className="text-center text-xl uppercase font-semibold">
                        {this.props.t("nueva_linea")}
                    </div>
                    <div className='text-left mt-4'>
                        <p className='font-normal'>
                            <label htmlFor="reference" className="text-slate-500 mr-2 font-bold text-left w-36">{this.props.t("referencia")}</label>
                            <input type="text" name="reference" id="reference" placeholder={`${this.props.t("referencia")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={addLineBudgetFields.reference} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="description" className="text-slate-500 mr-2 font-bold text-left w-36">{this.props.t("descripcion")}</label>
                            <input type="text" name="description" id="description" placeholder={`${this.props.t("descripcion")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={addLineBudgetFields.description} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="quantity" className="text-slate-500 mr-2 font-bold text-left w-36">{this.props.t("cantidad")}</label>
                            <input type="number" name="quantity" id="quantity" placeholder={`${this.props.t("cantidad")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={addLineBudgetFields.quantity} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="pvp_und" className="text-slate-500 mr-2 font-bold text-left w-36">{this.props.t("pvp_un")}</label>
                            <input type="number" name="pvp_und" id="pvp_und" placeholder={`${this.props.t("pvp_un")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={addLineBudgetFields.pvp_und} onChange={handleFieldChange}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="dto_1" className="text-slate-500 mr-2 font-bold text-left w-36">{this.props.t("dto_1")}</label>
                            <input disabled={dtoDisabled} type="number" min="0" max="100" name="dto_1" id="dto_1" placeholder={`${this.props.t("dto_1")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={addLineBudgetFields.dto_1} onChange={changeDiscount}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="dto_2" className="text-slate-500 mr-2 font-bold text-left w-36">{this.props.t("dto_2")}</label>
                            <input disabled={dtoDisabled} type="number" min="0" max="100" name="dto_2" id="dto_2" placeholder={`${this.props.t("dto_2")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={addLineBudgetFields.dto_2} onChange={changeDiscount}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="dto_3" className="text-slate-500 mr-2 font-bold text-left w-36">{this.props.t("dto_3")}</label>
                            <input disabled={dtoDisabled} type="number" min="0" max="100" name="dto_3" id="dto_3" placeholder={`${this.props.t("dto_3")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={addLineBudgetFields.dto_3} onChange={changeDiscount}/>
                        </p>
                        <p className='font-normal'>
                            <label htmlFor="nt_und" className="text-slate-500 mr-2 font-bold text-left w-36">{this.props.t("nt_un")}</label>
                            <input type="number" disabled={ netDisabled || dtoDisabled } name="nt_und" id="nt_und" placeholder={`${this.props.t("nt_un")}`} className="mb-4 p-2 mt-1 w-full border-2" autoComplete='on' value={ addLineBudgetFields.nt_und } onChange={handleFieldChange}/>
                        </p>                        
                        <p className='font-normal'>
                            <span className='text-slate-500 mr-2 font-bold text-left block'>{this.props.t("sub_tn")}:</span>
                            <span className='mb-4 p-2 mt-1 w-full border-2 block bg-gray-100'>{ formatoEuro(addLineBudgetFields.sub_tn) }</span>
                        </p>
                    </div>
                    <div className="text-center mt-4">
                        <Button 
                            text={`${this.props.t("cancelar")}`}
                            type='secondary' 
                            className='uppercase w-28' 
                            onClick = { () => setShowAddLine(false) }
                        />
                        <Button 
                            text = {`${this.props.t("aceptar")}`}
                            type = 'normal' 
                            className = 'uppercase w-28' 
                            disabled = { acceptDisabled }
                            onClick = { handleAddLine }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}   