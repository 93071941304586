import React, { Component } from 'react'

import { PlateSlot } from "../elements/PlateSlot"
import { PlateSeparator } from '../elements/PlateSeparator'
import { areSameObjects } from '../../other/Utils'

export class PlateContainer extends Component {

    renderSurface = ( subPlateContent, assembly, plateModel ) => {
        
        let plateBase = 1        
        plateBase = ( subPlateContent.length > 4 && subPlateContent.length < 9 ) ? 2 : 1
        plateBase = ( subPlateContent.length === 9 ) ? 3 : plateBase

        let returnRender = []
        let slotIndex = 0
        
        for (let i = 0; i < plateBase; i++) {
            let slotsRender = []
            let slots = subPlateContent.length > 4 ? subPlateContent.length - 3 : subPlateContent.length
            slots = subPlateContent.length === 8 ? 4 : slots
            slots = subPlateContent.length === 9 ? 3 : slots

            for (let j = 0; j < slots; j++) {
                slotsRender.push(
                    <PlateSlot 
                        key = { slotIndex } 
                        plateInfo = { this.props.plateInfo }
                        numPlate = { this.props.numPlate }
                        idSubPlate = { this.props.idSubPlate }
                        idSlot = { subPlateContent[slotIndex].idSlot }
                        type = { this.props.type }
                        additional = { this.props.additional }
                        indexAdditional = { this.props.indexAdditional }
                        slotContent = { subPlateContent[slotIndex].slotContent }
                        assembly = { assembly } 
                        plateModel = { plateModel }
                        addItemToSlot = { this.props.addItemToSlot }
                        removeItemOfSlot = { this.props.removeItemOfSlot }
                        addItemToFirstSlot = { this.props.addItemToFirstSlot }
                        addPushButtonToSlot = { this.props.addPushButtonToSlot }
                        addPushButtonToAllPlate = { this.props.addPushButtonToAllPlate }
                        addPushButtonToFirstSlot = { this.props.addPushButtonToFirstSlot }
                        t = {this.props.t}
                    />
                )

                // if ( slots !== 2 && plateBase !== 2 ) 
                    slotIndex += 1
                // slotIndex += subPlateContent.length < 5 ? 1 : 2       
            }

            returnRender.push(
                // <div key={slotIndex} style={{ backgroundColor:'#898989' }}>
                <div key={slotIndex}>
                    <div className="plate-header">
                        <img src="../ultra/marco-superior-superficie.png" className = "w-full" alt="" />
                    </div>
                    <div className={`plates-${ slots }`}>
                        { slotsRender }
                    </div>
                    <div className="plate-footer">
                        <img src="../ultra/marco-inferior-superficie.png" className = "w-full" alt="" />
                    </div>
                </div>
            )
        }

        return returnRender

    }
    
    renderEmbedded = ( subPlateContent, assembly, plateModel ) => {
        
        let slotsRender = []
        
        for (let i = 0; i < subPlateContent.length; i++) {
            
            if (i>0 && plateModel === "vandalcom" && subPlateContent.length > 0 ){
                slotsRender.push(<PlateSeparator key = {`sp-${i}`} />)
            }
            slotsRender.push(
                <PlateSlot 
                    key = { i } 
                    plateInfo = { this.props.plateInfo }
                    numPlate = { this.props.numPlate }
                    idSubPlate = { this.props.idSubPlate }
                    idSlot = { subPlateContent[i].idSlot }
                    type = { this.props.type }
                    additional = { this.props.additional }
                    indexAdditional = { this.props.indexAdditional }
                    slotContent = { subPlateContent[i].slotContent }
                    assembly = { assembly } 
                    plateModel = { plateModel }
                    addItemToSlot = { this.props.addItemToSlot }
                    removeItemOfSlot = { this.props.removeItemOfSlot }
                    addItemToFirstSlot = { this.props.addItemToFirstSlot }
                    addPushButtonToSlot = { this.props.addPushButtonToSlot }
                    addPushButtonToAllPlate = { this.props.addPushButtonToAllPlate }
                    t = {this.props.t}
                />
            )        
        }

        return (
            // <div style={{ backgroundColor:'#898989' }}>
            <div>
                <div className="plate-header">
                    {
                        this.props.plateInfo.plateModel === "ultra" 
                            && <img src="../ultra/marco-superior.png" className = "w-full" alt={`${this.props.t("marco_superior_ultra_empotrar")}`} />
                    }
                    {
                        this.props.plateInfo.plateModel === "vandalcom" 
                            && <img src="../vandalcom/marco-superior.png" className = "w-full" alt={`${this.props.t("marco_superior_vandalcom")}`} />
                    }
                </div>
                <div className={`plates-${ subPlateContent.length } ${this.props.plateInfo.plateModel}`}>
                    { slotsRender }
                </div>
                <div className="plate-footer">
                    {
                        this.props.plateInfo.plateModel === "ultra" 
                            && <img src="../ultra/marco-inferior.png" className = "w-full" alt={`${this.props.t("marco_inferior_ultra_empotrar")}`} />
                    }
                    {
                        this.props.plateInfo.plateModel === "vandalcom" 
                            && <img src="../vandalcom/marco-inferior.png" className = "w-full" alt={`${this.props.t("marco_inferior_vandalcom")}`} />
                    }                    
                </div>
            </div>
        )

    }

    getSwitchLocationSlots = ( location, assembly, plateModel, slots ) => {
        if (slots){

            let slotsRender = []
            for (let i = 0; i < slots.length; i++) {
                const slot = slots[i];
                
                slotsRender.push(
                    <PlateSlot 
                        key = {`${location}${i}` }
                        plateInfo = { this.props.plateInfo }
                        numPlate = { this.props.numPlate }
                        idSubPlate = { this.props.idSubPlate }
                        idSlot = { slot.idSlot }
                        type = { this.props.type }
                        additional = { this.props.additional }
                        indexAdditional = { this.props.indexAdditional }
                        slotContent = { slot.slotContent }
                        assembly = { assembly } 
                        plateModel = { plateModel }
                        switchLocation = { location }
                        addItemToSlot = { this.props.addItemToSlot }
                        removeItemOfSlot = { this.props.removeItemOfSlot }
                        addItemToFirstSlot = { this.props.addItemToFirstSlot }
                        addPushButtonToSlot = { this.props.addPushButtonToSlot }
                        addPushButtonToAllPlate = { this.props.addPushButtonToAllPlate }
                        t = {this.props.t}
                    />
                )
            }
            return slotsRender
        }
    }

    renderSwitch = ( assembly, subPlateContent, plateModel, numSlots, MAX_SLOTS ) => {

        let mainPlateIndex = subPlateContent.findIndex( element => element.location === "--")
        let canExpand = MAX_SLOTS > numSlots && !areSameObjects(subPlateContent[mainPlateIndex].slotContent, [])

        let slotsTop = this.getSwitchLocationSlots('top', assembly, plateModel, subPlateContent.filter( element => element.location === "top"))
        let slotsBottom = this.getSwitchLocationSlots('bottom', assembly, plateModel, subPlateContent.filter( element => element.location === "bottom"))

        let canReduceTop = slotsTop.length > 0
        let canReduceBottom = slotsBottom.length > 0

        return (
            <div className=''>
                <div className='flex'>
                {
                    canReduceTop &&
                        <div className = {`mx-auto mb-4 hover:bg-gray-100 shadow-md border border-gray-100 w-16 rounded pt-2 p-1 bg-gray-50 flex flex-col flex-wrap items-center cursor-pointer not-image`}
                            data-html2canvas-ignore="true"
                            onClick = {() => canReduceTop && this.props.removeSlotOfSubPlate( this.props.numPlate, this.props.idSubPlate, this.props.type, this.props.additional, this.props.indexAdditional, "top" )}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="#6b7280">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6" />
                            </svg>
                        </div>
                }
                {
                    canExpand &&
                        <div className = {`mx-auto mb-4 hover:bg-gray-100 shadow-md border border-gray-100 w-16 rounded pt-2 p-1 bg-gray-50 flex flex-col flex-wrap items-center cursor-pointer not-image`}
                            data-html2canvas-ignore="true"
                            onClick = {() => canExpand && this.props.addSlotToSubPlate( this.props.numPlate, this.props.idSubPlate, this.props.type, this.props.additional, this.props.indexAdditional, "top" )}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="#6b7280">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                        </div>
                }
                </div>
                { slotsTop }
                {/* Main Plate Monoblock */}
                <div className="mx-auto w-36 h-[140]">
                    <PlateSlot 
                        key = { 1 } 
                        plateInfo = { this.props.plateInfo }
                        numPlate = { this.props.numPlate }
                        idSubPlate = { this.props.idSubPlate }
                        idSlot = { 1 }
                        type = { this.props.type }
                        additional = { this.props.additional }
                        indexAdditional = { this.props.indexAdditional }
                        slotContent = { mainPlateIndex !== -1 && subPlateContent[mainPlateIndex].slotContent }
                        plateModel = { plateModel }
                        addItemToSlot = { this.props.addItemToSlot }
                        removeItemOfSlot = { this.props.removeItemOfSlot }
                        addItemToFirstSlot = { this.props.addItemToFirstSlot }
                        addPushButtonToSlot = { this.props.addPushButtonToSlot }
                        addPushButtonToAllPlate = { this.props.addPushButtonToAllPlate }
                        switchLocation = { "center" }
                        t = {this.props.t}
                    />
                </div>
                { slotsBottom }
                <div className='flex'>
                {
                    canReduceBottom &&
                        <div className = {`mx-auto mt-4 hover:bg-gray-100 shadow-md border border-gray-100 w-16 rounded pt-2 p-1 bg-gray-50 flex flex-col flex-wrap items-center cursor-pointer not-image`}
                            data-html2canvas-ignore="true"
                            onClick = {() => canReduceBottom && this.props.removeSlotOfSubPlate( this.props.numPlate, this.props.idSubPlate, this.props.type, this.props.additional, this.props.indexAdditional, "top" )}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="#6b7280">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 12H6" />
                            </svg>
                        </div>
                }
                {
                    canExpand && 
                        <div className = {`mx-auto mt-4 hover:bg-gray-100 shadow-md border border-gray-100 w-16 rounded pt-2 p-1 bg-gray-50 flex flex-col flex-wrap items-center cursor-pointer not-image`}
                            data-html2canvas-ignore="true"
                            onClick = {() => canExpand && this.props.addSlotToSubPlate( this.props.numPlate, this.props.idSubPlate, this.props.type, this.props.additional, this.props.indexAdditional, "bottom" )}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="#6b7280">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                            </svg>
                        </div>
                }
                </div>
            </div>
        )
    }

    renderSlots = ( assembly, plateModel, numSlots = 0, MAX_SLOTS = 0, MIN_SLOTS = 0 ) => {
        let slotsRender = []

        // Obtenemos si es la placa principal o una de las adicionales
        let plateBase = this.props.additional ? this.props.plateInfo.additionalContents[this.props.indexAdditional] : this.props.plateInfo    
        // Buscamos el elemento de idSubPlate
        let subPlate = plateBase.contents.find( content => content.idSubPlate === this.props.idSubPlate );
         
        // let idSubPlate = this.props.plateInfo.contents.find( content => content.idSubPlate === this.props.idSubPlate );
        let subPlateContent = subPlate.subPlateContent

        // Renderizamos según la placa
        // Ultra distinto según superficie o empotrar
        if (this.props.plateInfo.plateModel === "ultra"){
            slotsRender = assembly === "surface" 
                ? this.renderSurface( subPlateContent, assembly, plateModel ) 
                : this.renderEmbedded( subPlateContent, assembly, plateModel )
        }
        // Vandalcom siempre igual
        if (this.props.plateInfo.plateModel === "vandalcom"){
            slotsRender = this.renderEmbedded( subPlateContent, assembly, plateModel )        
        }
        // Switch 
        if (this.props.plateInfo.plateModel === "switch"){
            slotsRender = this.renderSwitch( assembly, subPlateContent, plateModel, numSlots, MAX_SLOTS)
        }
       
        return slotsRender
    }

    render() {

        let { plateInfo, className, idSubPlate, additional, indexAdditional } = this.props

        // Obtenemos si es la placa principal o una de las adicionales
        let plateBase = additional ? plateInfo.additionalContents[indexAdditional] : plateInfo

        let MAX_SLOTS = 0
        if (plateInfo.plateModel === "vandalcom"){
            MAX_SLOTS = 3
        } 
        if (plateInfo.plateModel === "ultra"){
            MAX_SLOTS = plateBase.assembly === 'embedded' ? 4 : 9
        }
        if (plateInfo.plateModel === "switch"){
            MAX_SLOTS = plateBase.assembly === 'embedded' ? 4 : 2
        }

        const MIN_SLOTS = 1

        let plateIndex = plateBase.contents.findIndex((value => value.idSubPlate === idSubPlate))
                
        let plateSlots = plateBase.contents[plateIndex].subPlateContent
        let numSlots = plateSlots.length
        
        let plateSlotsRender = this.renderSlots( plateBase.assembly, plateInfo.plateModel, numSlots, MAX_SLOTS, MIN_SLOTS)
        let canExpand = numSlots < MAX_SLOTS
        let canReduce = numSlots > MIN_SLOTS
        
        return(
            <div className = {`mt-4 mb-4 ${className} ${ (plateBase.assembly === "surface" && plateInfo.plateModel !== "vandalcom") && "mr-4"}`}>
            
                {/* Plates Render */}
                <div className="flex image" style={{backgroundColor: plateInfo.plateModel !== "switch" ? '#898989' : 'transparent'}}>
                    { plateSlotsRender }
                </div>
                {
                    plateInfo.plateModel !== "switch" &&
                        // Buttons Expand/Reduce
                        <div className="flex items-center justify-center mt-4 not-image" data-html2canvas-ignore="true">
                            <div className = {`hover:bg-gray-100 shadow-md border border-gray-100 w-20 rounded pt-2 p-1 bg-gray-50 flex flex-col flex-wrap items-center mr-4 cursor-${canExpand ? 'pointer' : 'not-allowed'}`}
                                onClick = {() => canExpand && this.props.addSlotToSubPlate( this.props.numPlate, this.props.idSubPlate, this.props.type, this.props.additional, this.props.indexAdditional )}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
                                    className={`h-6 w-6`}
                                >
                                    <path d="M23.09 0h-6.723a.91.91 0 1 0 0 1.82h4.526L13.6 9.113a.91.91 0 1 0 1.287 1.287l7.293-7.293v4.526a.91.91 0 1 0 1.82 0V.91a.911.911 0 0 0-.91-.91zM10.4 13.6a.91.91 0 0 0-1.287 0L1.82 20.893v-4.526a.91.91 0 1 0-1.82 0v6.723c0 .494.403.91.91.91h6.723a.91.91 0 1 0 0-1.82H3.106l7.294-7.293a.91.91 0 0 0 0-1.287z" 
                                        fill={`${ canExpand ? ' #6b7280' : '#d1d5db'}`}
                                    />
                                </svg>
                                <p className={`${ canExpand ? ' text-gray-500' : ' text-gray-300'}`}>{this.props.t("aumenta")}</p>
                            </div>

                            <div className = {`text-center hover:bg-gray-100 shadow-md border border-gray-100 w-20 rounded pt-2 p-1 bg-gray-50 flex flex-col flex-wrap items-center cursor-${canReduce ? 'pointer' : 'not-allowed'}`}
                                onClick = {() => canReduce && this.props.removeSlotOfSubPlate( this.props.numPlate, this.props.idSubPlate, this.props.type, this.props.additional, this.props.indexAdditional )}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
                                    className={`h-6 w-6`}
                                >
                                    <path d="M14.243 10.667h6.724a.91.91 0 1 0 0-1.82H16.44l7.293-7.294A.91.91 0 1 0 22.447.266L15.153 7.56V3.033a.91.91 0 1 0-1.82 0v6.724c0 .495.403.91.91.91zM.267 23.734a.91.91 0 0 0 1.286 0l7.294-7.294v4.527a.91.91 0 1 0 1.82 0v-6.724a.912.912 0 0 0-.91-.91H3.033a.91.91 0 1 0 0 1.82H7.56L.267 22.447a.91.91 0 0 0 0 1.287z" 
                                        fill={`${ canReduce ? ' #6b7280' : '#d1d5db'}`}
                                    />
                                </svg>
                                <p className={`${ canReduce ? ' text-gray-500' : ' text-gray-300'}`}>{this.props.t("reduce")}</p>
                            </div>
                        </div>
                }
            </div>
        )
    }
}