import React, { Component } from 'react'

export class CheckBoxInput extends Component {
    
    render() {

        const {name, label, image, checked, onChange, className, disabled} = this.props

        return (
            <label className={`text-lg block text-center cursor-pointer ${className} ${!className.includes("mt") && 'mt-4'} ${disabled ? 'opacity-75' : ''}` }> 
                {image}
                <input 
                    name = {name}
                    type = "checkbox"
                    onChange = {onChange}
                    className = {`mr-2 h-5 w-5 cursor-pointer`}
                    checked = {checked}
                    disabled = {disabled !== undefined ? disabled : false}
                />           
                {label}
            </label>
        )
    }
}

CheckBoxInput.defaultProps = {  
    className: ''
}  