import React, { Component, Fragment } from 'react'
 
import { ModulesSimulator } from './ModulesSimulator'
import { PlateConfigurator } from './PlateConfigurator'

import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; // or any other pipeline

import html2canvas from 'html2canvas';

import { Button } from '../elements/Button';

import { areSameObjects, isOverFlown, loader } from '../../other/Utils';

// https://www.npmjs.com/package/react-dnd-multi-backend

export class DisplaySimulator extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            configurationReady: false,
            alertVisible: false,
            alertTitle: "",
            alertMessage: "",
            alertResult: false,
            alertType: ""
        }
    }

    renderDisplaySimulator = ( plateInfo, type, plateBase ) => {
        let switchPlateEmpty
        let switchPlateNumColumns = 0

        switchPlateEmpty = plateInfo.plateModel === "switch" ? areSameObjects( plateBase.contents[0].subPlateContent[0].slotContent, [] ) : false
        if (!switchPlateEmpty) {
            switchPlateNumColumns = plateBase.contents[0].subPlateContent[0].slotContent.numColumns
        }

        return(
            <div>
                <div className="items-start p-4 sm:flex">
                    <PlateConfigurator 
                        plateInfo = { plateInfo } 
                        additional = { this.props.additional}
                        indexAdditional = { this.props.indexAdditional}
                        numPlate = { this.props.numPlate } 
                        addSubPlate = { this.props.addSubPlate } 
                        removeSubPlate = { this.props.removeSubPlate } 
                        addSlotToSubPlate = { this.props.addSlotToSubPlate }
                        removeSlotOfSubPlate = { this.props.removeSlotOfSubPlate }
                        type = { type }
                        addItemToSlot = { this.props.addItemToSlot }
                        removeItemOfSlot = { this.props.removeItemOfSlot }
                        addItemToFirstSlot = { this.props.addItemToFirstSlot }
                        restartPlateDisplay = { this.props.restartPlateDisplay }
                        restartPlatePushButtons = { this.props.restartPlatePushButtons }
                        addPushButtonToSlot = { this.props.addPushButtonToSlot }
                        addPushButtonToAllPlate = { this.props.addPushButtonToAllPlate }
                        addPushButtonToFirstSlot = { this.props.addPushButtonToFirstSlot }
                        setPlateVisorValue = { this.props.setPlateVisorValue }
                        setPlateTrimFrameValue = { this.props.setPlateTrimFrameValue }
                        t = {this.props.t}
                        location = { this.props.location }
                        plateBase = { this.props.plateBase }
                    />
                    <ModulesSimulator 
                        plateInfo = { plateInfo } 
                        systemType = { this.props.systemType }
                        additional = { this.props.additional}
                        indexAdditional = { this.props.indexAdditional }
                        numPlate = { this.props.numPlate } 
                        addItemToFirstSlot = { this.props.addItemToFirstSlot }
                        addPushButtonToFirstSlot = { this.props.addPushButtonToFirstSlot }
                        simulatorType = { this.props.simulatorType }
                        type = { type }
                        houses = { this.props.type === "secondary" ? this.props.portalInfo[this.props.numPlate].homes : this.props.houses }
                        switchPlateEmpty = { switchPlateEmpty }
                        switchPlateNumColumns = { switchPlateNumColumns }
                        t = {this.props.t}
                        location = { this.props.location }
                    />
                </div>
            </div>
        )
    }

    renderSelectAssembly = (numPlate, type, additional, indexAdditional) => {
        return(
            <div className="flex flex-col items-center justify-center mt-8 text-lg">
                <h2>{this.props.t("seleccione_tipo_montaje")}</h2>
                <div className="flex mt-4">
                    <Button 
                        type = 'normal' 
                        text = {this.props.t("empotrar")}
                        onClick = {
                            () => {
                                if (this.props.location === 'only-simulator') {
                                    this.props.changePlateAssemblyType('embedded')
                                } else {
                                    this.props.changePlateAssemblyType(numPlate, type, additional, indexAdditional,'embedded')
                                }
                            }
                        }
                    />
                    <Button 
                        type = 'normal' 
                        text = {`${this.props.t("superficie")}`}
                        onClick = {
                            () => {
                                if (this.props.location === 'only-simulator') {
                                    this.props.changePlateAssemblyType('surface')
                                } else {
                                    this.props.changePlateAssemblyType(numPlate, type, additional, indexAdditional,'surface')
                                }
                            }
                        }
                    />
                </div>
            </div>
        )
    }

    renderAlert = () => {
        return(
            <div>
                <div className="fixed top-0 left-0 z-30 flex items-center justify-center w-full h-screen bg-gray-500 bg-opacity-75 bg-op">                
                    <div className="relative z-30 pt-6 pb-6 pl-4 pr-4 ml-4 mr-4 overflow-y-auto bg-white rounded max-h-5/6 md:p-14">
                        <div className='absolute cursor-pointer right-2 top-2'
                            onClick = { 
                                () => this.setState({ 
                                        alertVisible: false, 
                                        alertResult: false,                                 
                                        alertTitle: "",
                                        alertMessage: "",
                                        alertType: ""
                                    })
                            }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>

                        <div className="text-xl font-semibold text-center uppercase">
                            { this.state.alertTitle }
                        </div>
                        <div className="mt-2 text-lg font-semibold text-center">
                            { this.state.alertMessage }
                        </div>
                        {
                            this.state.alertType === "accept"
                                ? 
                                    <div>
                                        <div className="text-lg text-center">{this.props.t("desea_continuar")}</div>
                                        <div className="mt-6 text-center">
                                            <div className="mt-6 text-center">
                                                <Button 
                                                    text= {`${this.props.t("no")}`}
                                                    type='secondary' 
                                                    className='mt-2 uppercase sm:mt-0 w-28' 
                                                    onClick = { 
                                                        () => { 
                                                            this.setState({ 
                                                                alertVisible: false, 
                                                                alertResult: false,
                                                                alertTitle: "",
                                                                alertMessage: "",
                                                                alertType: "" 
                                                            }) 
                                                        } 
                                                    }
                                                />
                                                <Button 
                                                    text= {`${this.props.t("si")}`}
                                                    type='normal' 
                                                    className='uppercase w-28' 
                                                    onClick = { 
                                                        () => { this.acceptPlateConfiguration(this.props) }
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                :
                                    <div className="mt-6 text-center">
                                        <Button 
                                            text={`${this.props.t("volver")}`}
                                            type='normal' 
                                            className='uppercase w-28' 
                                            onClick = {
                                                () => this.setState({ 
                                                    alertVisible: false, 
                                                    alertResult: false,                                 
                                                    alertTitle: "",
                                                    alertMessage: "",
                                                    alertType: ""
                                                }) 
                                            }
                                        />
                                    </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    checkIfPlateIsReady = () => {
        let { numPlate, plates, type, additional, indexAdditional, location } = this.props
        
        let plateBase
        if ( location === "only-simulator"){
            plateBase = this.props.plateBase
        } else{
            // Placa que estamos tratando, si es la principal o una de las adicionales
            plateBase = additional ? plates[numPlate].additionalContents[indexAdditional] : plates[numPlate]
        }
        
        for (let i = 0; i < plateBase.contents.length; i++) {
            let content = plateBase.contents[i];
            for (let j = 0; j < content.subPlateContent.length; j++) {
                let subContent = content.subPlateContent[j];
                
                // Comprobamos si hay algún slot sin rellenar
                if (areSameObjects(subContent.slotContent, []) && content.subPlateContent.length !== 5 && i !== 4 ){
                    this.state.configurationReady === true
                        && this.setState({ configurationReady: false })
                    plateBase.configurationComplete && 
                        this.props.changePlateConfigurated( numPlate, type, additional, indexAdditional, false, '--' )
                    return
                }
                
                // Comprobamos si hay algún pulsador sin rellenar
                if ( subContent.slotContent.buttonsContent ){
                    for (let k = 0; k < subContent.slotContent.buttonsContent.length; k++) {
                        const buttonContent = subContent.slotContent.buttonsContent[k];
                        
                        if ( buttonContent.src === "--" || buttonContent.id === "--" ){
                            this.state.configurationReady === true
                                && this.setState({ configurationReady: false })                   
                            plateBase.configurationComplete && 
                                this.props.changePlateConfigurated( numPlate, type, additional, indexAdditional, false, '--' )
                            return
                        }                
                    }
                }
            }            
        }
        
        !this.state.configurationReady &&
            this.setState({ configurationReady: true }) 
    }

    getNumCallsPlate = (plate, plateModel) => {
        let totalCalls = 0
        plate.contents.forEach(plateContent => {
            plateContent.subPlateContent.forEach(subPlateContent => {
                
                let slotContent = subPlateContent.slotContent
                if (!areSameObjects(slotContent, [])){
                    // Si es VANDALCOM, vemos el número de llamadas del slot, sino solo de los botones
                    if (plateModel === "vandalcom"){
                        totalCalls += parseInt(slotContent.numCalls || 0)      
                    }
                    if (slotContent !== "occupied_by_double"){
                        slotContent.buttonsContent.forEach(buttonContent => {
                            totalCalls += parseInt(buttonContent.numCalls || 0)                    
                        })
                    }
                }
            })
        })
        return totalCalls
    }

    checkPhonicGroupsPlate = (plate) => {
        let phonicGroups = 0
        for (let i = 0; i < plate.contents.length; i++) {
            const subPlateContent = plate.contents[i].subPlateContent;
            for (let j = 0; j < subPlateContent.length; j++) {
                const slotContent = subPlateContent[j].slotContent;
                if (slotContent !== "occupied_by_double"){
                    if ( slotContent.idCategory === 9 || slotContent.idCategory === 10)
                        phonicGroups += 1
                }
            }            
        }

        return phonicGroups === 1
    }

    checkIfExistsDisplay = (plate) => {
        for (let i = 0; i < plate.contents.length; i++) {
            const subPlateContent = plate.contents[i].subPlateContent;
            for (let j = 0; j < subPlateContent.length; j++) {
                const slotContent = subPlateContent[j].slotContent;
                if (slotContent !== "occupied_by_double"){
                    if ( slotContent.category === "DISPLAYS Y TECLADOS" )
                        return true
                }
            }            
        }

        return false
    }

    acceptPlateConfiguration = (props) => {
        this.setState({ loading: true })
    
        let element =  document.getElementById("plates-container")
        let zoom = 1
        while (isOverFlown(element)){
            zoom -= 0.05
            document.getElementById("plates-container").style.zoom = zoom
        }

        let platesElement = document.createElement("div")
        platesElement.classList.add("flex","items-center")
        platesElement.id = "imageCanvas"
        let width = 0
        document.querySelectorAll("#plates-container > div > .flex.image").forEach(plate => {
            width += plate.offsetWidth
            platesElement.appendChild(plate)
        })
        document.querySelector(".App-footer").appendChild(platesElement)

        if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) && window.screen.width < 1024 ){
            document.getElementById("viewport").setAttribute("content", "width=1200");
        }

        html2canvas( 
            platesElement, 
            { 
                backgroundColor: '#f9fafb',
                logging: false,
                width: width
            } 
        )
            .then(function(canvas) {

                document.getElementById("plates-container").style.zoom = 1
                document.getElementById("imageCanvas").remove()

                let imgBase64 = canvas.toDataURL('image/jpeg', 1);
                let dataURL = "data:image/" + imgBase64;
                props.changePlateConfigurated( props.numPlate, props.type, props.additional, props.indexAdditional, true, dataURL )
                props.onClose("completed")
                
                document.getElementById("viewport").setAttribute("content", "width=device-width, initial-scale=1");
            });
    }

    handleAcceptConfiguration = (numPlate, type, additional, indexAdditional, totalHomes, changePlateConfigurated, onClose ) => {

        // Si es solo simulador o switch se acepta
        if ( this.props.location === "only-simulator" || this.props.plates[numPlate] && this.props.plates[numPlate].plateModel === "switch" ){
            this.acceptPlateConfiguration(this.props)

        } else {

            // Placa que estamos tratando, si es la principal o una de las adicionales
            let plateBase = additional ? this.props.plates[numPlate].additionalContents[indexAdditional] : this.props.plates[numPlate]
            let plateModel = this.props.plates[numPlate].plateModel
                
            // Comprobamos si existe un grupo fónico (GRUPO AUDIO y GRUPO AUDIO/VIDEO)
            if (this.checkPhonicGroupsPlate(plateBase)){
                // Comprobamos si hay display en la placa
                let existsDisplay = this.checkIfExistsDisplay(plateBase)
                let numCalls = this.getNumCallsPlate(plateBase, plateModel)

                // Comprobamos el número de llamadas
                if ( !existsDisplay && (totalHomes !== numCalls && this.state.alertResult === false)) {
                    let calls = numCalls || 0
                    this.setState({ 
                        alertVisible: true, 
                        alertResult: false,
                        alertTitle: `${this.props.t("numero_llamadas_total").toUpperCase()}`,                        
                        alertMessage: `${this.props.t('numero_llamadas_no_coincide', { number_calls:calls, number_homes:totalHomes } )}`,
                        alertType: "accept"
                    })
                }
                else {
                    this.acceptPlateConfiguration(this.props)
                }
            }
            // Aviso de que no se puede aceptar
            else{
                this.setState({ 
                    alertVisible: true, 
                    alertResult: false,
                    alertTitle: `${this.props.t("grupos_fonicos").toUpperCase()}`,
                    alertMessage: `${this.props.t("debe_tener_grupo_fonico")}`,
                    alertType: "phonic"
                })
            }            
        }
    }

    componentDidMount (){
        this.checkIfPlateIsReady()
    }

    componentDidUpdate () {
        this.checkIfPlateIsReady()
    }

    render() {
        let { numPlate, plates, type, portalInfo, additional, indexAdditional, location } = this.props
        let totalHomes = type === "secondary" ? portalInfo[numPlate].homes : this.props.houses
        let alert = this.state.alertVisible && this.renderAlert()
        
        let isOnlySimulator = location === 'only-simulator'
        let renderDisplay = ''
        let assembly = ''
        let plateBase

        if ( isOnlySimulator ) {
            plateBase = this.props.plateBase
        } else {
            // Placa que estamos tratando, si es la principal o una de las adicionales
            plateBase = additional ? plates[numPlate].additionalContents[indexAdditional] : plates[numPlate]
        }       

        if (plateBase.assembly === "--"){
            renderDisplay = this.renderSelectAssembly(numPlate, type, additional, indexAdditional)
        }
        else{
            let plate = isOnlySimulator ? plateBase : plates[numPlate]
            renderDisplay = this.renderDisplaySimulator(plate, type, plateBase)
        }
        
        if (plateBase.assembly !== "--"){
            assembly = plateBase.assembly === "surface" ? `${this.props.t("superficie")}` : `${this.props.t("empotrar")}`
        }
        

        let loading = ''
        if (this.state.loading){
            loading = loader(this.props.t)
        }
        
        let title = ''
        if ( isOnlySimulator ){
            title = this.props.t("simulador_placas")
        } else {
            if ( this.props.simulatorType === "association"){
                title = `${ type === "secondary" ? `${this.props.t("configurador_placa_secundaria")}` : `${this.props.t("configurador_placa_principal")}` } ${numPlate+1}`
            }
            else{
                title = `${this.props.t("configurador_portal")} ${numPlate+1}`
            }
        }

        return (
            <DndProvider options={HTML5toTouch}>
                { alert }
                { loading }
                {
                    !isOnlySimulator &&
                        <div className="fixed top-0 left-0 z-10 w-full h-screen bg-gray-500 opacity-75">
                        </div>
                }
                <div className={` ${!isOnlySimulator ? 'm-8 fixed top-0 bottom-0 left-0 right-0 bg-white z-20 overflow-scroll ' : 'mt-8 mb-8 overflow-auto '} flex flex-col rounded`}>
                    <div className='absolute cursor-pointer right-2 top-2'
                        onClick={() => this.props.onClose()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>

                    <h1 className="mt-8 mb-0 ml-8 mr-8 text-2xl font-semibold tracking-wide text-center uppercase center">
                        { title }
                    </h1>
                    <h2 className="font-semibold tracking-wide text-center uppercase center text-l">
                    {
                        isOnlySimulator 
                        ? 
                            <Fragment>{this.props.t("modelo")} { plateBase.plateModel }</Fragment>
                        :
                            <Fragment>
                                {this.props.t("modelo")} { plates[numPlate].plateModel } 
                                { type === "secondary" && ` - ${totalHomes} ${totalHomes>1 ? `${this.props.t("viviendas")}` : `${this.props.t("vivienda")}`}`} <br />
                            </Fragment>
                    }
                        {assembly !== '' &&
                            <div className="flex items-center justify-center">
                                { assembly } 
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                    className="w-4 h-4 ml-1 cursor-pointer" fill="none" viewBox="0 0 24 24" 
                                    stroke="currentColor" onClick={    
                                        () => {
                                            if (this.props.location === 'only-simulator') {
                                                this.props.changePlateAssemblyType('--')
                                            } else {
                                                this.props.changePlateAssemblyType(numPlate, type, additional, indexAdditional,'--')
                                            }
                                        }
                                    }>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                </svg>
                            </div>                    
                        }
                    </h2>
                    
                    { renderDisplay }                    

                    { plateBase.assembly !== "--"  && 
                        <div className="mb-8 text-center">
                            <Button
                                type = 'normal' 
                                text = {
                                    <span className="flex mr-2 uppercase">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 mr-1" fill="none" viewBox="0 0 24 24" stroke="white">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                        </svg>
                                        {this.props.t("aceptar")} 
                                    </span>
                                }
                                disabled = { !this.state.configurationReady }
                                onClick = { () => { this.handleAcceptConfiguration( numPlate, type, additional, indexAdditional, totalHomes, this.props.changePlateConfigurated, this.props.onClose ) }}
                            />   
                        </div>         
                    }
                    
                </div>
            </DndProvider>
        )
    }
}