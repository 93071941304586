export const ItemTypes = {
    MODULE: 'module',
    MODULE_DOUBLE: 'module_double',
    PUSH_BUTTON: 'push_button',
    MODULE_PUSH_BUTTON: 'module_push_button',
    MODULE_FULL_BUTTONS: 'module_full_buttons',
    MONOBLOCK_PLATE: 'monoblock_plate',
    MODULE_TOP: 'module_top',
    MODULE_BOTTOM: 'module_bottom',
    MODULE_TURN: 'module_turn'
  }  