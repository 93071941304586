import React, { Component } from 'react'

import { getAvailableKits, getAvailableInternalUnits } from '../../../other/ApiCalls'
import { RadioInput } from '../../elements/RadioInput'
import { Collapsible } from '../../elements/Collapsible'
import { CheckBoxInput } from '../../elements/CheckBoxInput'
import { Button } from '../../elements/Button'

export class KitStepTwo extends Component {    
    constructor(props) {
        super(props)
        this.state = {
            kitsAvailable: [],
            internalUnits: [],
            modalInternalUnitsVisible: false
        }
    }

    componentDidMount(){
        getAvailableKits( this.props.systemType, this.props.houses, this.props.embedded, this.props.surface, this.props.plateModel )
        .then( products => {
            this.setState({ kitsAvailable: products })
        })
    }

    renderAvailableKits(kits) {
        let renderedKits = []
        kits.forEach(kit => {
            let kitImageSRC = kit.image_url.includes('http') ? kit.image_url : `../products/${ kit.image_url }`

            renderedKits.push(
                <RadioInput
                    key = {kit.article_code}
                    name = {kit.article_code}
                    value = {kit.article_code}
                    image = {
                        <img src = {kitImageSRC} alt={`${this.props.t("imagen_kit")} ${kit.article_code.toUpperCase()}`} className="w-48 mx-auto" />
                    }
                    tooltip = {kit.tooltip}
                    label = {kit.article_code}
                    className = "w-1"
                    onChange = { () => this.props.changeSelectedKit(kit.article_code)}
                    checked = {this.props.kit === kit.article_code}
                />
            )
        })
        return renderedKits
    }

    renderModalInternalUnits(internalUnits){
        let renderedInternalUnitsHouses = []
        for (let i = 1; i <= this.props.houses; i++) {
            
            let houseIndex = this.props.internalUnitsHouse.findIndex(element => element.house === i)
            let internalUnit = houseIndex !== -1 ? this.props.internalUnitsHouse[houseIndex].internalUnit : ''

            // Renderizamos las opciones
            let renderedInternalOptions = []
            internalUnits.forEach(product => {
                let productImageSRC = product.image_url.includes('http') ? product.image_url : `../products/${ product.image_url }`

                renderedInternalOptions.push(
                    <RadioInput
                        key = {`${i}-${product.article_code}`}
                        name = {`${i}-${product.article_code}`}
                        label = { product.description }
                        checked = { internalUnit === product.article_code }
                        image = { 
                            <img src = { productImageSRC } alt={`${this.props.t("imagen_unidad_interna")} ${product.article_code.toUpperCase()}`} className="w-48 mx-auto" />
                        }
                        tooltip = { product.article_code }
                        onChange = { () => this.handleInternalUnitHouseChange( product.article_code, i )}
                        className = "w-1"
                    />
                )
            })
        
            // Renderizamos cada vivienda
            renderedInternalUnitsHouses.push(
                <div className={`${i > 1 && 'mt-12'}`} key={i}>
                    <h3 className={`text-xl semi uppercase text-center`}>{this.props.t("vivienda")} {i}</h3>
                    <div className='grid grid-cols-2 sm:grid-cols-4 gap-8'>
                        { renderedInternalOptions }
                    </div>
                </div>
            )
        }

        return(
            <div>
                <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex items-center justify-center">
                    <div className="bg-white max-h-5/6 overflow-y-auto z-30 mt-4 mb-4 relative pt-6 pb-6 pl-4 pr-4 md:p-14 rounded mr-4 ml-4">
                        <div className='absolute cursor-pointer right-2 top-2'
                            onClick = { () => this.setState({ modalInternalUnitsVisible: false }) }
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>
                        <div className="text-center text-xl uppercase font-semibold">
                            {this.props.t("unidades_internas_adicionales")}
                        </div>                        
                        <div>
                            <div className="text-center text-lg mt-2 mb-4 font-semibold">
                                {this.props.t("seleccione_unidad_interna")}
                            </div>

                            { renderedInternalUnitsHouses }

                            <div className="text-center mt-6">
                                <Button 
                                    text={`${this.props.t("cancelar")}`}
                                    type='secondary' 
                                    className='uppercase mt-2 sm:mt-0 w-28' 
                                    onClick = { () => this.setState({ modalInternalUnitsVisible: false }) }
                                />
                                <Button 
                                    text={`${this.props.t("aceptar")}`}
                                    type='normal' 
                                    className='uppercase w-28' 
                                    onClick = { () => this.setState({ modalInternalUnitsVisible: false }) }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

    }

    handleChangeAdditionalAccess = (e) => {
        this.props.changeAdditionalAccess(e.target.checked)
    }
    
    handleChangeAdditionalInternalUnit = (e) => {
        if (e.target.checked){                
            getAvailableInternalUnits( this.props.kit, this.props.systemType )
            .then( products => {
                if (products.length > 0){
                    this.setState({ 
                        modalInternalUnitsVisible: true,
                        internalUnits: products
                    })
                } else{
                    this.props.changeAdditionalInternalUnit(false)
                }
            })
        } else{
            this.setState({ 
                modalInternalUnitsVisible: false,
                internalUnits: []
            })
        }
        this.props.changeAdditionalInternalUnit(e.target.checked)
    }

    handleInternalUnitHouseChange = (articleCode, house) => {
        this.props.changeInternalUnitHouse(articleCode, house)
    }

    render(){
        let { additionalAccess, additionalInternalUnit } = this.props
        let { kitsAvailable, modalInternalUnitsVisible, internalUnits } = this.state

        let renderedAvailableKits = kitsAvailable.length > 0 ? this.renderAvailableKits(kitsAvailable) : []
        let renderedModalInternalUnits = this.renderModalInternalUnits(internalUnits)

        return(
            <div>
                {
                    modalInternalUnitsVisible && renderedModalInternalUnits
                }
                <Collapsible title = {`${this.props.t("kits_disponibles")}`} >
                    <div className='flex flex-wrap justify-center gap-8'>
                        { renderedAvailableKits }
                    </div>
                </Collapsible>
                <Collapsible title = {`${this.props.t("adicionales")}`} >
                    <div className='flex flex-wrap justify-center gap-8'>
                        <CheckBoxInput 
                            key = 'additionalAcces'
                            name = 'additionalAcces'
                            label = {`${this.props.t("acceso_adicional")}`}
                            onChange = { this.handleChangeAdditionalAccess }
                            checked = { additionalAccess }
                        />
                        <CheckBoxInput 
                            key = 'addicionalInternalUnit'
                            name = 'addicionalInternalUnit'
                            label = {`${this.props.t("unidad_inerna_adicional")}`}
                            onChange = { this.handleChangeAdditionalInternalUnit }
                            checked = { additionalInternalUnit }
                        />
                    </div>
                </Collapsible>
            </div>
        )
    }
}