import React, { Fragment } from 'react';

import { formatoEuro } from '../../../other/Utils';
import { Text, View, Image, Font, Link } from '@react-pdf/renderer';
import { StyleSheet } from '@react-pdf/renderer';

import fontThin from '../../../fonts/ttf/HelveticaNeueLTW1G-Th.ttf'
import fontBold from '../../../fonts/ttf/HelveticaNeueLTW1G-Bd.ttf'
import fontBlack from '../../../fonts/ttf/HelveticaNeueLTW1G-Blk.ttf'
import fontMedium from '../../../fonts/ttf/HelveticaNeueLTW1G-Md.ttf'

Font.register({
    family: 'Helvetica Neue',
    fonts: [
        { src: fontThin, fontWeight: 'thin' },
        { src: fontBold, fontWeight: 'bold' },
        { src: fontBlack, fontWeight: 'heavy' },
        { src: fontMedium, fontWeight: 'medium' },
    ]
  })

const styles = StyleSheet.create({
    fontLight:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'light' 
    },
    fontBold:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'bold' 
    },
    fontMedium:{
        fontFamily: 'Helvetica Neue', 
        fontWeight: 'medium' 
    },
    row: {
        marginTop: '10px',
        flexDirection: 'row',
        padding: '5px',
        alignItems: 'center',
        flexGrow: 1,
        fontSize: 10,
        borderBottom: 1,
        borderBottomColor: '#e5e7eb'
    },
    rowNetDto: {
        marginTop: '10px',
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
        fontSize: 8,
        borderBottom: 1,
        borderBottomColor: '#e5e7eb'
    },
    borderRight: {
        borderRightWidth: '0.5',
        borderRightColor: "black"
    },
    numRow: {
        width: '5%'
    },
    image: {
        width: '10%',
        padding: '5px'
    },
    reference: {
        width: '15%'
    },
    description: {
        width: '30%',
        fontSize: 8,
    },
    units: {
        width: '10%'
    },
    pvpUnit: {
        width: '15%'
    },
    subPvp: {
        width: '15%'
    },
    cell:{
        paddingLeft: '5px',
        paddingRight: '5px'
    },
    referenceNetDto: {
        width: '10%'
    },
    descriptionNetDto: {
        width: '15%'
    },
    unitsNetDto: {
        width: '5%'
    },
    pvpUnitNetDto: {
        width: '10%'
    },
    subPvpNetDto: {
        width: '10%'
    },
    dtoNet: {
        width: '5%'
    },
    ntUnitNetDto: {
        width: '10%'
    },
    subTnNetDto: {
        width: '10%'
    }
})

const PDFTableRows = (props) => {
    let products = props.products
    let type = props.type
    let pdfExport = props.pdfExport

    let rows = []

    switch (pdfExport) {
        case 'no-prices':
            rows = products.map( item => 
                <View style={styles.row} wrap={false} key={`${type}-${item.articleCode}`}>
                    <Text style={[styles.numRow, styles.fontLight, styles.cell ]}>{item.numRow}</Text>
                    <Link style={styles.image} src={item.link}>
                        {
                            item.image.includes('http') 
                            ? <Image src = { 
                                {
                                    uri: item.image,
                                    method: 'GET', 
                                    mode: 'no-cors',
                                    headers: {
                                        "Cache-Control": "no-cache",
                                        "Content-Type": "application/json",
                                        'Access-Control-Allow-Origin': '*'
                                    }, 
                                    body: '' 
                                }
                            }/>
                            : <Image src = {"./products/" + item.image} />
                        }                        
                    </Link>
                    <Text style={[styles.reference, styles.fontLight, styles.cell ]}>{item.articleCode}</Text>
                    <Text style={[styles.description, styles.fontLight, styles.cell ]}>{item.description}</Text>
                    <Text style={[styles.units, styles.fontLight, styles.cell ]}>{item.quantity}</Text>
                </View>
            )
            break

        case 'only-pvp':
            rows = products.map( item => 
                <View style={styles.row} wrap={false} key={`${type}-${item.articleCode}`}>
                    <Text style={[styles.numRow, styles.fontLight, styles.cell ]}>{item.numRow}</Text>            
                    <Link style={styles.image} src={item.link}>
                        {
                            item.image.includes('http') 
                            ? <Image src = { 
                                {
                                    uri: item.image,
                                    method: 'GET', 
                                    mode: 'no-cors',
                                    headers: {
                                        "Cache-Control": "no-cache",
                                        "Content-Type": "application/json",
                                        'Access-Control-Allow-Origin': '*'
                                    }, 
                                    body: '' 
                                }
                            }/>
                            : <Image src = {"./products/" + item.image} />
                        }
                    </Link>
                    <Text style={[styles.reference, styles.fontLight, styles.cell ]}>{item.articleCode}</Text>
                    <Text style={[styles.description, styles.fontLight, styles.cell ]}>{item.description}</Text>
                    <Text style={[styles.units, styles.fontLight, styles.cell ]}>{item.quantity}</Text>
                    <Text style={[styles.pvpUnit, styles.fontLight, styles.cell ]}>{ formatoEuro(item.pvp) }</Text>
                    <Text style={[styles.subPvp, styles.fontLight, styles.cell ]}>{ formatoEuro(item.sub_pvp) }</Text>
                </View>
            )
            break

        case 'net-without-discount':
            rows = products.map( item =>    
                <View style={styles.row} wrap={false} key={`${type}-${item.articleCode}`}>
                    <Text style={[styles.numRow, styles.fontLight, styles.cell ]}>{item.numRow}</Text>   
                    <Link style={styles.image} src={item.link}>      
                        {
                            item.image.includes('http') 
                            ? <Image src = { 
                                {
                                    uri: item.image,
                                    method: 'GET', 
                                    mode: 'no-cors',
                                    headers: {
                                        "Cache-Control": "no-cache",
                                        "Content-Type": "application/json",
                                        'Access-Control-Allow-Origin': '*'
                                    }, 
                                    body: '' 
                                }
                            }/>
                            : <Image src = {"./products/" + item.image} />
                        }
                    </Link>
                    <Text style={[styles.reference, styles.fontLight, styles.cell ]}>{item.articleCode}</Text>
                    <Text style={[styles.description, styles.fontLight, styles.cell ]}>{item.description}</Text>
                    <Text style={[styles.units, styles.fontLight, styles.cell ]}>{item.quantity}</Text>
                    <Text style={[styles.pvpUnit, styles.fontLight, styles.cell ]}>{item.nt_und === 0 ?  formatoEuro(item.pvp)  :  formatoEuro(item.nt_und) }</Text>
                    <Text style={[styles.subPvp, styles.fontLight, styles.cell ]}>{ formatoEuro(item.sub_tn) }</Text>
                </View>
            )
            break
            
        case 'pvp-total-net':
            rows = products.map( item =>    
                <View style={styles.row} wrap={false} key={`${type}-${item.articleCode}`}>
                    <Text style={[styles.numRow, styles.fontLight, styles.cell ]}>{item.numRow}</Text>      
                    <Link style={styles.image} src={item.link}>
                        {
                            item.image.includes('http') 
                            ? <Image src = { 
                                {
                                    uri: item.image,
                                    method: 'GET', 
                                    mode: 'no-cors',
                                    headers: {
                                        "Cache-Control": "no-cache",
                                        "Content-Type": "application/json",
                                        'Access-Control-Allow-Origin': '*'
                                    }, 
                                    body: '' 
                                }
                            }/>
                            : <Image src = {"./products/" + item.image} />
                        }
                    </Link>
                    <Text style={[styles.reference, styles.fontLight, styles.cell ]}>{item.articleCode}</Text>
                    <Text style={[styles.description, styles.fontLight, styles.cell ]}>{item.description}</Text>
                    <Text style={[styles.units, styles.fontLight, styles.cell ]}>{item.quantity}</Text>
                    <Text style={[styles.pvpUnit, styles.fontLight, styles.cell ]}>{ formatoEuro(item.pvp) }</Text>
                    <Text style={[styles.subPvp, styles.fontLight, styles.cell ]}>{ formatoEuro(item.sub_tn) }</Text>
                </View>
            )
            break
            
        case 'pvp-discount-net':
            rows = products.map( item =>   
                <View style={styles.rowNetDto} wrap={false} key={`${type}-${item.articleCode}`}>
                    <Text style={[styles.numRow, styles.fontLight, styles.cell ]}>{item.numRow}</Text> 
                    <Link style={styles.image} src={item.link}>
                        {
                            item.image.includes('http') 
                            ? <Image src = { 
                                {
                                    uri: item.image,
                                    method: 'GET', 
                                    mode: 'no-cors',
                                    headers: {
                                        "Cache-Control": "no-cache",
                                        "Content-Type": "application/json",
                                        'Access-Control-Allow-Origin': '*'
                                    }, 
                                    body: '' 
                                }
                            }/>
                            : <Image src = {"./products/" + item.image} />
                        }
                    </Link>
                    <Text style={[styles.referenceNetDto, styles.fontLight, styles.cell ]}>{item.articleCode}</Text>
                    <Text style={[styles.descriptionNetDto, styles.fontLight, styles.cell ]}>{item.description}</Text>
                    <Text style={[styles.unitsNetDto, styles.fontLight, styles.cell ]}>{item.quantity}</Text>
                    <Text style={[styles.pvpUnitNetDto, styles.fontLight, styles.cell ]}>{formatoEuro(item.pvp)}</Text>
                    <Text style={[styles.subPvpNetDto, styles.fontLight, styles.cell ]}>{formatoEuro(item.sub_pvp)}</Text>
                    <Text style={[styles.dtoNet, styles.fontLight, styles.cell ]}>{parseInt(item.dto_1)}</Text>
                    <Text style={[styles.dtoNet, styles.fontLight, styles.cell ]}>{parseInt(item.dto_2)}</Text>
                    <Text style={[styles.dtoNet, styles.fontLight, styles.cell ]}>{parseInt(item.dto_3)}</Text>
                    <Text style={[styles.ntUnitNetDto, styles.fontLight, styles.cell ]}>{item.nt_und === 0 ?  formatoEuro(item.pvp)  :  formatoEuro(item.nt_und) }</Text>
                    <Text style={[styles.subTnNetDto, styles.fontLight, styles.cell ]}>{ formatoEuro(item.sub_tn) }</Text>
                </View>
            )
            break
        
        default:
            break;
    }

    return (
        <Fragment>{ rows }</Fragment>
    )    
}

export default PDFTableRows