import React, {Component} from 'react'

import { ButtonHome } from '../components/elements/ButtonHome'
import { MainTitle } from '../components/elements/MainTitle'

import { scrollTop } from '../other/Utils'

export class Home extends Component {    
    constructor(props){
        super(props)
        this.state = {
            logged: window.location.hostname === 'localhost' ? false : true
            //logged: true
        }
    }
    
    componentDidMount(){
        scrollTop('auto')
    }

    render() {
        
        let page = 
            <div className='container mx-auto home'>
                <MainTitle>{this.props.t("configurador_online")}</MainTitle>
                <div className='flex flex-wrap items-center justify-center gap-4 mt-8 mb-8 justify-items-center mx-auto'>
                    <ButtonHome logged={true} t={this.props.t} type='kit'>{this.props.t("simulador_kits")}</ButtonHome>
                    <ButtonHome logged={true} t={this.props.t} type='edificio'>{this.props.t("simulador_edificio")}</ButtonHome>
                    <ButtonHome logged={true} t={this.props.t} type='mancomunidad'>{this.props.t("simulador_mancomunidades")}</ButtonHome>
                    <ButtonHome logged={true} t={this.props.t} type='simulador-placas'>{this.props.t("simulador_placas")}</ButtonHome>
                </div>
            </div>
        
        return page
    }
}