import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Home } from '../pages/Home'
import { Login } from '../pages/Login'
import { Register } from '../pages/Register'
import { MailConfirm } from '../pages/MailConfirm'
import UserAdmin from '../pages/UserAdmin'
import { ChangePassword } from '../pages/ChangePassword'
import { ResetPassword } from '../pages/ResetPassword'
import { ForgotPassword } from '../pages/ForgotPassword'
import { NotFound } from '../pages/NotFound'
import { VisualPlateSimulator } from '../pages/VisualPlateSimulator'

import { Guard } from '../Guard'

import { KitSimulatorContainer } from '../containers/KitSimulatorContainer'
import { EdificeSimulatorContainer } from '../containers/EdificeSimulatorContainer'

import { edificeReducer } from '../store/reducers/edificeReducer'
import { kitReducer } from '../store/reducers/kitReducer'
import { createStore } from 'redux'
import { Provider } from 'react-redux'

const edificeStore = createStore( edificeReducer )
const kitStore = createStore( kitReducer )

export class ContentApp extends Component {
    
    render() {        
        const { t } = this.props
        
        return (
            <div>
                <div className='contentApp'>
                    <Switch>
                        <Route exact path = '/user/login' render={(props) => <Login {...props} t={t} />} />
                        <Route exact path = '/user/register' render={(props) => <Register {...props} t={t} />} />
                        <Route exact path = '/user/password-reset' render={(props) => <ResetPassword {...props} t={t} />} />
                        <Route exact path = '/user/password' render={(props) => <ChangePassword {...props} t={t} />} />
                        <Route exact path = '/user/mail-confirm' render={(props) => <MailConfirm {...props} t={t} />} />
                        <Route exact path = '/user/forgot-password' render={(props) => <ForgotPassword {...props} t={t} />} />
                        <Guard exact path = "/admin/users" token = "admin-token" routeRedirect = "/user/login" component = { UserAdmin } componentProps = {this.props}  />
                        <Route exact path = '/simulador-placas' render={(props) => <VisualPlateSimulator {...props} t={t} />} />
                        <Guard exact path = "/" token = "user-token" routeRedirect = "/user/login" component = { Home } componentProps = {this.props} />
                        <Guard exact path = "/simulador-placas" token = "user-token" routeRedirect = "/user/login" component = { VisualPlateSimulator } componentProps = {this.props} />
                        <Provider store={ kitStore }>
                            <Guard exact path = "/kit" token = "user-token" routeRedirect = "/user/login" component = { KitSimulatorContainer } componentProps = {this.props} />
                            <Provider store={ edificeStore }>
                                <Guard exact path = "/edificio" token = "user-token" routeRedirect = "/user/login" component = { EdificeSimulatorContainer } componentProps = {this.props}  />
                                <Guard exact path = "/mancomunidad" token = "user-token" routeRedirect = "/user/login" component = { EdificeSimulatorContainer } componentProps = {this.props}  />
                            </Provider> 
                        </Provider>
                        <Route path="*" render={(props) => <NotFound {...props} t={t} />} />
                    </Switch>
                </div>
            </div>
        )
    }
}