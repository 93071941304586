import React, { useState, useEffect } from 'react';

import { isEmptyObject, loader } from '../../../other/Utils';

export default function BudgetHeader(props) {

    const [loading, setLoading] = useState(false)
    const userInfo = props.userInfo
    const accountInfo = props.accountInfo

    const { t } = props

    useEffect(() => {

        if (userInfo.id_user_type) {
            if ([1, 2].includes(userInfo.id_user_type)) {
                if (!props.comelitComercial || props.comelitComercial === "") {
                    props.changeComelitComercial(userInfo.contact)
                }

                if (!props.comelitEmail || props.comelitEmail === "") {
                    props.changeComelitEmail(userInfo.email)
                }
            } else {
                props.changeComelitEmail(t("email_comercial"))
            }
        }

    }, [props])

    const clickUploadLogo = (e) => {
        e.preventDefault()
        const hiddenInput = document.getElementById("hidden-input");
        hiddenInput.click()
    }

    const changeLogoFile = (e) => {
        for (const file of e.target.files) {
            let image = {
                src: URL.createObjectURL(file),
                alt: file.name
            }
            props.changeClientLogo({ image })
        }
    }

    // 1 - ADMINISTRADOR - Combo de selección de cliente (todos) + editable -> Izquierda Comelit (datos del usuario) y derecha cliente
    // 2 - CLIENTE INTERNO - Combo de selección de cliente (los asociados) + editable -> Izquierda Comelit (datos del usuario) y derecha cliente
    // 3 - CLIENTE EXTERNO - Datos de Comelit izquierda y los suyos derecha, check de "presupuesto para tercero"
    // 4 - CLIENTE DESCONOCIDO - Dato
    let userType = -1
    if (userInfo.id_user_type) {
        userType = userInfo.id_user_type
    }

    let date = new Date()

    return ( <
        div > { loading && loader(t) } <
        input id = "hidden-input"
        type = "file"
        accept = "image/*"
        className = "hidden"
        onChange = { changeLogoFile }
        /> <
        div className = "grid sm:grid-cols-3 sm:justify-between justify-center items-center" >
        <
        img className = 'w-52 mx-auto sm:m-0'
        src = "../logo.png"
        alt = { `${t("logotipo")} Comelit` }
        /> <
        div >
        <
        h2 className = "max-w-12 mx-auto mt-4 sm:mt-0 border-2 font-semibold text-xl p-2 text-center uppercase text-green-comelit" > { t("presupuesto") } < /h2> <
        p className = "mt-1 text-center" > { date.toLocaleDateString() } < /p> <
        /div> {
            ([1, 2].includes(userType) || (userType === 4 && accountInfo.length === 0)) ?
            !isEmptyObject(props.clientLogo.image) ?
                <
                div className = "relative mt-4 sm:mt-0" >
                <
                img src = { props.clientLogo.image.src }
            alt = { props.clientLogo.image.alt }
            className = 'h-20 mw-48 ml-auto' / >
                <
                button id = "button"
            className = "absolute bottom-0 left-0 hover:cursor-pointer"
            onClick = { clickUploadLogo } >
                <
                svg xmlns = "http://www.w3.org/2000/svg"
            className = "h-6 w-6"
            fill = "none"
            viewBox = "0 0 24 24"
            stroke = "#1f2937" >
                <
                path strokeLinecap = "round"
            strokeLinejoin = "round"
            strokeWidth = { 2 }
            d = "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" / >
                <
                /svg> <
                /button> <
                /div>:
                <
                div className = "border-dashed border-2 border-gray-400 py-8 flex flex-col justify-center items-center mt-4 sm:mt-0" >
                <
                button id = "button"
            className = "font-semibold mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
            onClick = { clickUploadLogo } >
                { t("subir_logo_cliente") } <
                /button> <
                /div>: < div > < /div>
        } <
        /div> <
        div className = "sm:grid sm:grid-cols-2 lg:grid-cols-3 justify-between mt-6" > { /* Datos Comelit */ } <
        div id = "comelit_data" >
        <
        p className = "text-xl font-semibold" > { t("firma_comelit") } <
        /p> {
            t("direccion_comelit") !== '' &&
                <
                p className = "text-lg sm:mt-2" >
                <
                span > { t("direccion_comelit") } <
                /span> <
                /p>                     
        } {
            t("telefono_completo") !== '' &&
                <
                p className = "text-lg sm:mt-2" >
                <
                span className = "font-semibold" > Tel: < /span>{t("telefono_completo")} <
                /p>                        
        } {
            [1, 2].includes(userType) ?
                <
                div >
                <
                p className = "text-lg sm:mt-2" >
                <
                input
            type = "text"
            name = "comelitComercial"
            id = "comelitComercial"
            className = "disabled:bg-gray-300 text-left p-1 bg-gray-100 w-full text-lg"
            placeholder = { t("comercial") }
            value = { props.comelitComercial }
            onChange = { e => props.changeComelitComercial(e.target.value) }
            /> <
            /p> <
            p className = "text-lg sm:mt-2" >
                <
                input type = "text"
            name = "comelitEmail"
            id = "comelitEmail"
            className = "disabled:bg-gray-300 text-left p-1 bg-gray-100 w-full text-lg"
            placeholder = { t("email") }
            value = { props.comelitEmail }
            onChange = { e => props.changeComelitEmail(e.target.value) }
            /> <
            /p> <
            /div>:
            <
            div >
                <
                p className = "text-lg sm:mt-2" >
                <
                span className = "font-semibold" > Email: < /span>{ props.comelitEmail } <
                /p>

            <
            /div>
        } <
        /div> { /* Datos Cliente */ } <
        div id = "client_data"
        className = "mt-8 sm:mt-0 lg:col-start-3" > {
            // Si es ADMINISTRADOR o CLIENTE INTERNO puede elegir/editar el cliente o CLIENTE DESCONOCIDO sin relación con CRM
            [1, 2].includes(userType) || (userType === 4 && accountInfo.length === 0) ?
            <
            div >
            <
            textarea
            name = "clientName"
            id = "clientName"
            className = "disabled:bg-gray-300 text-left p-1 bg-gray-100 w-full text-xl font-semibold"
            placeholder = { t("razón_social") }
            value = { props.clientName }
            onChange = { props.changeClientName }
            /> <
            textarea
            className = "mt-2 disabled:bg-gray-300 text-left p-1 bg-gray-100 w-full text-lg"
            onChange = { props.changeClientDirection }
            id = "clientDir"
            name = "clientDir"
            placeholder = { `${t("direccion")}` }
            value = { props.clientDirection }
            /> <
            input type = "text"
            name = "clientPhone"
            id = "clientPhone"
            className = "mt-2 disabled:bg-gray-300 text-left p-1 bg-gray-100 w-full text-lg"
            placeholder = { t("telefono") }
            value = { props.clientPhone }
            onChange = { props.changeClientPhone }
            /> <
            input type = "text"
            name = "clientAtt"
            id = "clientAtt"
            className = "mt-2 disabled:bg-gray-300 text-left p-1 bg-gray-100 w-full text-lg"
            placeholder = { t("att") }
            value = { props.clientAtt }
            onChange = { props.changeClientAtt }
            /> <
            input type = "text"
            name = "clientMail"
            id = "clientMail"
            className = "mt-2 disabled:bg-gray-300 text-left p-1 bg-gray-100 w-full text-lg"
            placeholder = { t("email") }
            value = { props.clientMail }
            onChange = { props.changeClientMail }
            /> <
            /div>
            // Si es cliente externo / desconocido, muestra sus datos
            :
                <
                div >
                <
                p className = "text-xl font-semibold" > { accountInfo.name } - { accountInfo.vatNumber } <
                /p> <
                p className = "text-lg sm:mt-2" >
                <
                span > { accountInfo.address1 } < br / > { accountInfo.postcode } { accountInfo.city }({ accountInfo.region }) <
                /span> <
                /p> <
                p className = "text-lg sm:mt-2" >
                <
                span className = "font-semibold" > Tel: < /span>{ accountInfo.phone } <
                /p> <
                p className = "text-lg sm:mt-2" >
                <
                span className = "font-semibold" > { t("email") }: < /span>{ accountInfo.email } <
                /p> <
                /div>
        } <
        /div> <
        /div> <
        /div>
    )
}