import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import HttpInterceptor from './HttpInterceptor'
import rootReducer from './store/reducers/rootReducers'

export const middlewares = [thunk]

export const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore)

export const store = createStoreWithMiddleware(rootReducer) 

HttpInterceptor(store)