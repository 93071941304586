import React, { Component } from 'react'

import {RadioInput} from '../elements/RadioInput'

export class Houses extends Component {

    updateSelected = (event) => {
        this.props.onChange(parseInt(event.target.name.replace('house-','')))
    }

    renderHouses(houses, selected){

        let renderedHouses = []
        for (let i = 1; i <= houses; i++) {
            let icon = []
            let auxIcon = []
            for (let j = 1; j <= i; j++) {
                auxIcon.push(
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 sm:h-8 sm:w-8 text-gray-400" viewBox="0 0 20 20" fill="#388244" key={`svg-${j}`}>
                        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                    </svg>
                )
            }
            icon = (
                <div className="flex items-center justify-center">
                    { auxIcon }
                </div>
            )
            
            renderedHouses.push(
                <RadioInput
                    key = {i}
                    name = {`house-${i}`}
                    label = {`${i}`}
                    checked = {selected === i}
                    image = {icon}
                    onChange = {this.updateSelected}
                />
            )
            
        }
        return renderedHouses
    }

    render() {
        let renderHouses = this.renderHouses(this.props.numHouses, this.props.selected)
        return (
            <div className={`grid grid-cols-2 sm:grid-cols-4 gap-4 sm:items-center`}>
                { renderHouses }
            </div>
        )
    }
}