import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import { logoutAction } from '../store/actions/AuthActions'
import LanguageSwitcherSelector from './elements/LanguageSwitcherSelector'

export function Header(props){

  const { t } = props
  const history = useHistory()

  const dispatch = useDispatch()
  const authResponse = useSelector(state => state.userAuth.userAuthResponse)
  
  const [userLanguage, setUserLanguage] = useState(localStorage.getItem("comelit-user-language") || 'es')

  const userLogout = (e) => {
    dispatch(logoutAction())
    localStorage.removeItem("user-token");
    localStorage.removeItem("admin-token")

    history.push("/user/login")
  }
  
  useEffect(() => {
    if (userLanguage !== localStorage.getItem("comelit-user-language")){
      setUserLanguage(localStorage.getItem("comelit-user-language"))
    }

    if ( authResponse && authResponse.redirect ){

      localStorage.removeItem("user-token");
      localStorage.removeItem("admin-token")

      history.push("/user/login")
    }
  }, [authResponse, history])
  
  useEffect(() => {
    userLanguage && 
      localStorage.setItem("comelit-user-language", userLanguage);
  }, [userLanguage])

  const isLogged = (localStorage.getItem("user-token") && localStorage.getItem("user-token") !== '' )
                    || (localStorage.getItem("admin-token") && localStorage.getItem("admin-token") !== '')
  const isAdmin = localStorage.getItem("admin-token") && localStorage.getItem("admin-token") !== ''

  return(
    <header className="App-header">
      <div className="container mx-auto flex flex-row flex-wrap justify-between items-center">
        <Link to = {{pathname: '/'}}>
          <img className='logo' src="../logo.png" alt={`${t("logotipo")} Comelit`} />
        </Link>
        <div className='flex items-center'>
          <LanguageSwitcherSelector 
            userLanguage = { userLanguage }
            setUserLanguage = { setUserLanguage }
          />
          {
            isAdmin &&
                <Link to = {{pathname: '/admin/users'}} className="font-semibold text-sm flex p-2 hover:text-green-comelit">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                  </svg>
                </Link>
          }
          {
            isLogged &&
              <Link to = {{pathname: '/user/password'}} className="font-semibold text-sm flex mr-2 p-2 hover:text-green-comelit">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z" clipRule="evenodd" />
                </svg>
              </Link>
          }
          {
            isLogged && 
                <button className='font-semibold text-sm flex border p-2 border-gray-600 hover:border-green-comelit hover:bg-gray-200 hover:text-green-comelit rounded items-center' onClick={userLogout}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clipRule="evenodd" />
                  </svg>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                  </svg>  */}
                  {t("log_out")}
                </button>
          }
        </div>
      </div>
    </header>
  )
}