import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { mailConfirmAction, clearUserAuthState } from '../store/actions/AuthActions'
import { MainTitle } from '../components/elements/MainTitle'
import { loader } from '../other/Utils'

export function MailConfirm(props) {    
    const dispatch = useDispatch()
    const authResponse = useSelector(state => state.userAuth.userAuthResponse)
    
    const query = new URLSearchParams(props.location.search);
    const mailToken = query.get('token') || ''

    const [isLoading, setIsLoading] = useState(true)
    const [success, setSuccess] = useState(false)
    
    const t = props.t
    
    useEffect(
        () => { dispatch(mailConfirmAction(mailToken)) }, []
    )
    
    if (authResponse !== '' && authResponse.success){
        authResponse.success !== success &&
            setSuccess(authResponse.success)
        isLoading && setIsLoading(false)
    }        
    
    return(
        <div className='home container mx-auto flex'>
            {
                isLoading ? 
                    loader(t)
                :
                    <div id="password_change" className="sm:w-3/4 w-full max-w-xl border-2 pt-10 pb-10 pr-6 pl-6 rounded-md mt-14 mb-14">
                        {
                            success ?
                                <>
                                    <MainTitle>{t("correo_confirmado")}</MainTitle>
                                    <p className='mt-4 mx-auto max-w-lg'>
                                        {t("su_correo_confirmado")} <br />
                                        {t("personal_revisara")} <br />
                                        {t("recibira_mail")} <br />
                                    </p>
                                </>
                            : 
                                <>
                                    <MainTitle>{t("correo_no_confirmado_titulo")}</MainTitle>
                                    <p className='mt-4 mx-auto max-w-lg'>
                                        {t("su_correo_no_confirmado")} <br />
                                        {t("pongase_contacto")}
                                    </p>
                                </>
                        }                       

                        <Link to = {{pathname: '/'}} className="hover:cursor-pointer text-center">
                            <p className='mt-4 underline ' >{t("volver_inicio")}</p>
                        </Link>
                    </div>
            }
        </div>
    )
}