import { cloneDeep } from "lodash"

// Crear estado inicial App
const INITIAL_STATE = {
    reference: '',
    step: 1,
    systemType: '',
    houses: 0,
    surface: false,
    embedded: false,
    plateModel: '',
    kit: '',
    additionalAccess: false,
    additionalInternalUnit: false,
    internalUnitsHouse: [],
    budget: [],
    clientName: '',
    clientAtt: '',
    clientMail: '',
    clientDirection: '',
    clientPhone: '',
    clientLogo: { image:{} },
    comelitComercial: '',
    comelitEmail: '',
    exportType: ''
}

const restartConfiguration = (caller, actualState, value = null) => {

    let { houses, surface, embedded, plateModel, kit, additionalAccess, additionalInternalUnit, internalUnitsHouse } = actualState
    let restartRest = false

    if ( caller === "systemType" ){
        houses = 0
        restartRest = true
    }

    if ( caller === "houses" || restartRest ){
        surface = false
        embedded = false
        restartRest = true
    }

    if ( caller === "embedded" && value ){
        surface = false
    }
    
    if ( caller === "surface" && value ){
        embedded = false
    }
    
    if ( caller === "embedded" || caller === "surface" || restartRest ){
        plateModel = ''
        restartRest = true
    }
    
    if ( caller === "plateModel" || restartRest ){
        kit = ''
        restartRest = true
    }
    
    if ( caller === "kit" || restartRest ){
        additionalInternalUnit = false
        restartRest = true
    }
    
    if ( caller === "additional_internal_unit" || restartRest ){
        internalUnitsHouse = []
        restartRest = true
    }

    return { houses, surface, embedded, plateModel, kit, additionalAccess, additionalInternalUnit, internalUnitsHouse }
}

// Crear reducer
export function kitReducer(state = INITIAL_STATE, action){
    let newState = {}

    switch (action.type){
        case 'RESET_REDUCER':
            return INITIAL_STATE

        case 'CHANGE_REFERENCE':
            return {
                ...state,
                reference: action.payload
            }

        case 'CHANGE_STEP':
            return {
                ...state,
                step: action.payload
            }
        
        case 'CHANGE_SYSTEM_TYPE':
            newState = restartConfiguration("systemType", state)
            
            return {
                ...state,
                houses: newState.houses, 
                surface: newState.surface, 
                embedded: newState.embedded, 
                plateModel: newState.plateModel, 
                kit: newState.kit, 
                additionalAccess: newState.additionalAccess, 
                additionalInternalUnit: newState.additionalInternalUnit, 
                internalUnitsHouse: newState.internalUnitsHouse, 
                systemType: action.payload
            }

        case 'CHANGE_HOUSES':
            newState = restartConfiguration("houses", state)

            return {
                ...state,
                surface: newState.surface, 
                embedded: newState.embedded, 
                plateModel: newState.plateModel, 
                kit: newState.kit, 
                additionalAccess: newState.additionalAccess, 
                additionalInternalUnit: newState.additionalInternalUnit, 
                internalUnitsHouse: newState.internalUnitsHouse, 
                houses: action.payload
            }
        
        case 'CHANGE_SURFACE':
            newState = restartConfiguration("surface", state, action.payload)

            return {
                ...state,
                surface: action.payload,
                houses: newState.houses,
                embedded: newState.embedded, 
                plateModel: newState.plateModel, 
                kit: newState.kit, 
                additionalAccess: newState.additionalAccess, 
                additionalInternalUnit: newState.additionalInternalUnit, 
                internalUnitsHouse: newState.internalUnitsHouse, 
            }
            
        case 'CHANGE_EMBEDDED':
            newState = restartConfiguration("embedded", state, action.payload)

            return {
                ...state,
                embedded: action.payload,
                houses: newState.houses, 
                surface: newState.surface, 
                plateModel: newState.plateModel, 
                kit: newState.kit, 
                additionalAccess: newState.additionalAccess, 
                additionalInternalUnit: newState.additionalInternalUnit, 
                internalUnitsHouse: newState.internalUnitsHouse, 
            }
            
        case 'CHANGE_PLATE_MODEL':
            newState = restartConfiguration("plateModel", state)

            return {
                ...state,
                plateModel: action.payload,
                houses: newState.houses, 
                surface: newState.surface, 
                embedded: newState.embedded, 
                kit: newState.kit, 
                additionalAccess: newState.additionalAccess, 
                additionalInternalUnit: newState.additionalInternalUnit, 
                internalUnitsHouse: newState.internalUnitsHouse, 
            }

        case 'CHANGE_SELECTED_KIT':
            newState = restartConfiguration("kit", state)

            return {
                ...state,
                kit: action.payload,
                houses: newState.houses, 
                surface: newState.surface, 
                embedded: newState.embedded, 
                plateModel: newState.plateModel, 
                additionalAccess: newState.additionalAccess, 
                additionalInternalUnit: newState.additionalInternalUnit, 
                internalUnitsHouse: newState.internalUnitsHouse, 
            }
            
        case 'CHANGE_ADDITIONAL_ACCESS':
            return {
                ...state,
                additionalAccess: action.payload
            }
            
        case 'CHANGE_ADDITIONAL_INTERNAL_UNIT':
            newState = restartConfiguration("additional_internal_unit", state)

            return {
                ...state,
                additionalInternalUnit: action.payload,
                houses: newState.houses, 
                surface: newState.surface, 
                embedded: newState.embedded, 
                plateModel: newState.plateModel, 
                kit: newState.kit, 
                additionalAccess: newState.additionalAccess,
                internalUnitsHouse: newState.internalUnitsHouse, 
            }
        
        case 'CHANGE_INTERNAL_UNIT_HOUSE':
            let internalUnitsHouseChange = cloneDeep(state.internalUnitsHouse)
            let houseIndex = internalUnitsHouseChange.findIndex(element => element.house === action.payload.house)
            
            // Si no existe el dato de unidad adiucional para la vivienda
            if (houseIndex === -1){
                internalUnitsHouseChange.push({
                    house: action.payload.house,
                    internalUnit: action.payload.articleCode
                })
            } else{
                internalUnitsHouseChange[houseIndex].internalUnit = action.payload.articleCode
            }

            return {
                ...state,
                internalUnitsHouse: internalUnitsHouseChange
            }
        
        case 'SET_BUDGET':
            return{
                ...state,
                budget: action.payload
            }

        case 'CHANGE_DISCOUNT':
            let newBudget = cloneDeep(state.budget)

            let articleCodeBudget = action.payload.articleCode
            let type = action.payload.type
            let target = action.payload.target
            let value = parseFloat(action.payload.value)

            let indexType = newBudget.findIndex((budget => budget.type === type))
            let budgetProducts = newBudget[indexType].products
            let indexArticle = budgetProducts.findIndex(product => product.articleCode === articleCodeBudget)
            let article = budgetProducts[indexArticle]
            article[target] = value

            if (target === "nt_und"){
                article.sub_tn = parseFloat(article.nt_und === 0 ? article.pvp : article.nt_und * article.quantity)
            }
            else{
                
                let totalDiscount = ((1 - ((1 - (article.dto_1/100)) * (1 - (article.dto_2/100)) * (1 - (article.dto_3/100)))) * 100).toFixed(2)
                totalDiscount = totalDiscount > 100 ? 100 : totalDiscount

                if (totalDiscount > 0){
                    article.nt_und = parseFloat((article.pvp * ( 1 - (totalDiscount / 100))))
                    article.sub_tn = article.nt_und * article.quantity
                } else{
                    article.nt_und = 0
                    article.sub_tn = article.pvp * article.quantity
                }
            }

            return{
                ...state,
                exportType: '',
                budget: [...newBudget]
            }

        case 'CHANGE_ALL_DISCOUNTS':
            let newBudgetAllDiscounts = cloneDeep(state.budget)
            let targetDiscount = action.payload.target
            let valueDiscount = parseFloat(action.payload.value)
            
            newBudgetAllDiscounts.forEach(budget => {
                budget.products.forEach(product => {
                    
                    product[targetDiscount] = valueDiscount

                    if (targetDiscount === "nt_und"){
                        product.sub_tn = parseFloat(product.nt_und === 0 ? product.pvp : product.nt_und * product.quantity)
                    }
                    else{
                        let totalDiscount = product.dto_1 + product.dto_2 + product.dto_3
                        totalDiscount = totalDiscount > 100 ? 100 : totalDiscount
                        if (totalDiscount > 0){
                            product.nt_und = parseFloat((product.pvp * ( 1 - (totalDiscount / 100))))
                            product.sub_tn = product.nt_und * product.quantity
                        } else{
                            product.nt_und = 0
                            product.sub_tn = product.pvp * product.quantity
                        }
                    }
                })
            })

            return{
                ...state,
                exportType: '',
                budget: [...newBudgetAllDiscounts]
            }

        case 'CHANGE_CLIENT_NAME':
            return{
                ...state,
                clientName: action.payload
            }    

        case 'CHANGE_CLIENT_ATT':
            return{
                ...state,
                clientAtt: action.payload
            }   
            
        case 'CHANGE_CLIENT_MAIL':
            return{
                ...state,
                clientMail: action.payload
            } 

        case 'CHANGE_CLIENT_DIRECTION':
            return{
                ...state,
                clientDirection: action.payload
            }        

        case 'CHANGE_CLIENT_PHONE':
            return{
                ...state,
                clientPhone: action.payload
            }
   
        case 'CHANGE_CLIENT_LOGO':
            return{
                ...state,
                clientLogo: action.payload
            }
            
        case 'CHANGE_COMELIT_COMERCIAL':
            return{
                ...state,
                comelitComercial: action.payload
            }
            
        case 'CHANGE_COMELIT_EMAIL':
            return{
                ...state,
                comelitEmail: action.payload
            }

        case 'CHANGE_EXPORT_TYPE':
            return{
                ...state,
                exportType: action.payload
            }

        case 'ADD_LINE_TO_BUDGET':
            let newBudgetLines = [...state.budget]
            let newLine = {
                "articleCode": action.payload.reference,
                "link": "",
                "image": "",
                "description": action.payload.description,
                "quantity": parseInt(action.payload.quantity),
                "type": "",
                "pvp": parseFloat(action.payload.pvp_und),
                "relatedArticles": 0,
                "sub_pvp": parseFloat(action.payload.pvp_und * action.payload.quantity),
                "dto_1": parseInt(action.payload.dto_1),
                "dto_2": parseInt(action.payload.dto_2),
                "dto_3": parseInt(action.payload.dto_3),
                "nt_und": parseFloat(action.payload.nt_und),
                "sub_tn": (action.payload.nt_und === '' ? action.payload.pvp_und : action.payload.nt_und) * action.payload.quantity
            }

            let additionalLinesIndex = newBudgetLines.findIndex(element => element.type === "additional_lines")

            if (additionalLinesIndex === -1){
                let newBudgetLinesType = {
                    type: "additional_lines",
                    products: [newLine]
                }
                
                newBudgetLines.push(newBudgetLinesType)
            } else{
                newBudgetLines[additionalLinesIndex].products.push(newLine)
            }

            return{
                ...state,
                budget: newBudgetLines
            }
        
        case 'DELETE_ADITIONAL_LINE_OF_BUDGET':
            let newBudgetLinesDeleted = [...state.budget]
            let additionalLinesDeletedIndex = newBudgetLinesDeleted.findIndex(element => element.type === "additional_lines")

            newBudgetLinesDeleted[additionalLinesDeletedIndex].products.splice(action.payload, 1)

            return{
                ...state,
                budget: newBudgetLinesDeleted
            }

        case 'CHANGE_INSTALLATION_COST':
            let newBudgetChangeInstallation = [...state.budget]
            let installationCostIndex = newBudgetChangeInstallation.findIndex(element => element.type === "installation")
            newBudgetChangeInstallation[installationCostIndex].pvp = action.payload

            return{
                ...state,
                budget: newBudgetChangeInstallation
            }

        default:
            return state
    }
}

// // Crear store
// const store = createStore(appReducer)