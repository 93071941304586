export function resetReducer(){
    return {
        type: 'RESET_REDUCER'
    }
}

export function changeReference(reference){
    return {
        type: 'CHANGE_REFERENCE',
        payload: reference
    }
}

export function changeStep(step){
    return {
        type: 'CHANGE_STEP',
        payload: step
    }
}

export function changeSystemType(systemType){
    return {
        type: 'CHANGE_SYSTEM_TYPE',
        payload: systemType
    }
}

export function changeHouses(houses){    
    return {
        type: 'CHANGE_HOUSES',
        payload: houses
    }
}

export function changeSurface(surface){    
    return {
        type: 'CHANGE_SURFACE',
        payload: surface
    }
}

export function changeEmbedded(embedded){    
    return {
        type: 'CHANGE_EMBEDDED',
        payload: embedded
    }
}

export function changePlateModel(plateModel){    
    return {
        type: 'CHANGE_PLATE_MODEL',
        payload: plateModel
    }
}

export function changeSelectedKit(kitSelected){    
    return {
        type: 'CHANGE_SELECTED_KIT',
        payload: kitSelected
    }
}

export function changeAdditionalAccess(additionalAccess){    
    return {
        type: 'CHANGE_ADDITIONAL_ACCESS',
        payload: additionalAccess
    }
}

export function changeAdditionalInternalUnit(additionalInternalUnit){    
    return {
        type: 'CHANGE_ADDITIONAL_INTERNAL_UNIT',
        payload: additionalInternalUnit
    }
}

export function changeInternalUnitHouse(articleCode, house){
    return {
        type: 'CHANGE_INTERNAL_UNIT_HOUSE',
        payload: {articleCode, house}
    }    
}

export function setBudget(budget){
    return {
        type: 'SET_BUDGET',
        payload: budget
    }
}

export function changeDiscount(articleCode, type, target, value){
    return {
        type: 'CHANGE_DISCOUNT',
        payload: { articleCode, type, target, value }
    }
}

export function changeDiscountToAllProducts(target, value){
    return {
        type: 'CHANGE_ALL_DISCOUNTS',
        payload: { target, value }
    }
}
export function changeClientName(clientName){
    
    return {
        type: 'CHANGE_CLIENT_NAME',
        payload: clientName.target ? clientName.target.value : clientName
    }
}

export function changeClientAtt(clientAtt){
    return {
        type: 'CHANGE_CLIENT_ATT',
        payload: clientAtt.target ? clientAtt.target.value : clientAtt
    }
}

export function changeClientMail(clientMail){
    return {
        type: 'CHANGE_CLIENT_MAIL',
        payload: clientMail.target ? clientMail.target.value : clientMail
    }
}

export function changeClientDirection(clientDirection){
    return {
        type: 'CHANGE_CLIENT_DIRECTION',
        payload: clientDirection.target ? clientDirection.target.value : clientDirection
    }
}

export function changeClientPhone(clientPhone){
    return {
        type: 'CHANGE_CLIENT_PHONE',
        payload: clientPhone.target ? clientPhone.target.value : clientPhone
    }
}

export function changeClientLogo(logo){
    return {
        type: 'CHANGE_CLIENT_LOGO',
        payload: logo
    }
}

export function changeComelitComercial(comelitComercial){
    return {
        type: 'CHANGE_COMELIT_COMERCIAL',
        payload: comelitComercial.target ? comelitComercial.target.value : comelitComercial
    }
}

export function changeComelitEmail(comelitEmail){
    return {
        type: 'CHANGE_COMELIT_EMAIL',
        payload: comelitEmail.target ? comelitEmail.target.value : comelitEmail
    }
}

export function changeExportType(exportType){
    return {
        type: 'CHANGE_EXPORT_TYPE',
        payload: exportType
    }
}

export function addLineToBudget(budgetLine){
    return{
        type: 'ADD_LINE_TO_BUDGET',
        payload: budgetLine
    }
}

export function deleteAditionalLineBudget(additionalLineIndex){
    return{
        type: 'DELETE_ADITIONAL_LINE_OF_BUDGET',
        payload: additionalLineIndex
    }
}

export function changeInstallationCost(installationCost){
    return{
        type: 'CHANGE_INSTALLATION_COST',
        payload: installationCost
    }
}