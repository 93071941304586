import React, { Component } from 'react'

// import { InputNumber } from '../elements/InputNumber'
import { Separator } from '../elements/Separator'
import { CheckBoxInput } from '../elements/CheckBoxInput'
import { Combo } from '../elements/Combo'
import { Button } from '../elements/Button'

export class PortalInfo extends Component {    

    constructor (props){
        super(props)
        this.handleChange = this.handleChange.bind(this)     
        let portal = []

        // Si tenemos la info en las PROPS la copiamos
        if (Object.keys(props.portalInfo).length !== 0){
            portal = [...props.portalInfo]
        }
        // Si no, creamos el array
        else{           
            let internalPortals = this.props.internalPortals;
            for (let step = 1; step <= internalPortals; step++) {
                let value = {
                    id: step,
                    verticals: 1,
                    homes: 1,
                    applied: false
                }
                // portal[step] = value
                portal.push(value)
            }
            
            this.props.onChangePortalInfo(portal)
        }

        this.state = {
            portalInfo: portal,
            modalApplyVisible: false,
            portalsApply: [],
            numPortalApplier: ''
        }
        
        this.handleChangeApplyOtherPortals = this.handleChangeApplyOtherPortals.bind(this)
    }

    handleChangeApplyOtherPortals = (numPortal) => {
        this.props.setPortalsNotApplied()
        this.setState({ 
            modalApplyVisible: true,
            numPortalApplier: numPortal, 
            portalsApply: []
        })
    }

    savePortalApply = (id) => (event) => {
        if (event.target.checked){
            let newPortalsApply = [...this.state.portalsApply ]
            newPortalsApply.push(id)
            this.setState({
                portalsApply: newPortalsApply
            })
        }
        else{
            this.setState({
                portalsApply: this.state.portalsApply.filter( 
                    (portal) => {
                        return portal !== id;
                    })
            })

        }
    }

    acceptPortalApply = () => {

        if (this.state.portalsApply.length > 0){
            this.props.onChangeElementPortalInfo(this.state.numPortalApplier, this.state.portalsApply)
        }
        
        this.setState({ 
            modalApplyVisible: false, 
            numPortal: '',
            portalsApply: []
        })
    }
    
    static getDerivedStateFromProps(props, state) {
        
        if (props.internalPortals !== '' && parseInt(props.internalPortals) !== Object.keys(state.portalInfo).length ){
            
            let portalInfoNew = []
            for (let step = 0; step < props.internalPortals; step++) {  

                let verticals = state.portalInfo[step]
                    ? state.portalInfo[step].verticals 
                    : 1    

                let homes = state.portalInfo[step] 
                    ? state.portalInfo[step].homes 
                    : 1
                    
                let applied = state.portalInfo[step] 
                    ? state.portalInfo[step].applied 
                    : false

                let portalValue = {
                    id: step+1,
                    verticals: verticals,
                    homes: homes,
                    applied: applied
                }
                
                // portalInfoNew[step] = portalValue
                portalInfoNew.push(portalValue)
            }

            props.onChangePortalInfo(portalInfoNew)
            return {
                portalInfo: portalInfoNew
            }
        }
        
        if ( props.portalInfo.length === 0 ) 
            return state

        return props
    }

    handleChange = (idPortal) => (e) => {
        let key = e.target.dataKey
        let value = parseInt(e.target.value)
        let portalState = [...this.state.portalInfo]
        
        let index = portalState.findIndex((portal => portal.id === idPortal))
        
        if (key === "homes" && value !== portalState[index][key] ){
            this.props.restartPlateContents(index, "secondary")
        }

        portalState[index][key] = value
        portalState[index].applied = false

        this.setState({
            portalInfo: portalState
        })
        
        this.props.onChangePortalInfo(portalState)
    }

    renderPortalIcon = (numPortals) => {
        
        if (numPortals === 1){
            return(
                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" fill="none" viewBox="0 0 24 24" stroke="#388244">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 11l5-5m0 0l5 5m-5-5v12" />
                </svg>
            )
        }
        
        let portals = []
        for (let i = 0; i < numPortals; i++) {
            portals.push(
                <svg key={i} xmlns="http://www.w3.org/2000/svg" className="h-10 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="#388244">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 11l5-5m0 0l5 5m-5-5v12" />
                </svg>
            )                        
        }

        return(
            <div className="flex justify-center items-center">
                {portals}
            </div>
        )        
    }

    renderModalApply = (parentPortal) => {
        let portalsApply = []        
        let indexParent = this.state.portalInfo.findIndex((portal => portal.id === parentPortal))

        for (let i = 0; i < this.state.portalInfo.length; i++) {
            i !== indexParent &&
                portalsApply.push(                    
                        <CheckBoxInput 
                            key = {i}
                            name = {`applyPortal${i+1}`}
                            label = {`${this.props.t("portal")} ${i+1}`}
                            onChange = { this.savePortalApply(i) }
                            checked = { this.state.portalsApply.includes(i) }
                        />
                )
        }

        return(
            <div>
                <div className="w-full h-screen overflow-y-auto fixed top-0 left-0 bg-gray-500 bg-opacity-75 bg-op z-10 flex items-center justify-center">
                    <div className="bg-white max-h-5/6 overflow-y-auto z-30 mt-4 mb-4 relative pt-6 pb-6 pl-4 pr-4 md:p-14 rounded mr-4 ml-4">
                        <div className='absolute cursor-pointer right-2 top-2'
                            onClick={() => this.setState({modalApplyVisible:false, numPortal:''})}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500 " fill="none" viewBox="0 0 24 24" stroke="#388244">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </div>

                        <div className="text-center text-xl uppercase font-semibold">{this.props.t("configuracion_portal")} {parentPortal}</div>
                        <div className="text-center text-lg mt-2 font-semibold">{this.props.t("verticales")}: <span className="font-normal">{this.state.portalInfo[indexParent].verticals}</span></div>
                        <div className="text-center text-lg font-semibold">{this.props.t("viviendas")}: <span className="font-normal">{this.state.portalInfo[indexParent].homes}</span></div>
                        <Separator />
                        <div className="text-xl font-semibold mt-4">{this.props.t("aplicar_a")}:</div>
                        {portalsApply}
                        <div className="text-center mt-6">
                            <Button 
                                type = 'normal' 
                                text = {`${this.props.t("aceptar")}`}
                                onClick = { this.acceptPortalApply }
                                className = 'p-2 pl-6 pr-6'
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        let { internalPortals } = this.props
        let portalInfo = []
        if (parseInt(internalPortals) === parseInt(Object.keys(this.props.portalInfo).length - 1)){
            portalInfo = [...this.props.portalInfo]
        } else{
            portalInfo = [...this.state.portalInfo]
        }

        let items = []
        for (let step = 0; step < internalPortals; step++) {
            
            let portal = portalInfo[step]
            // let portal = this.state.portalInfo[step]
            
            // Icono de portales, una flecha por cada portal
            let portalIcon = this.renderPortalIcon(portal.verticals || 1)            

            step > 0 && items.push(
                <Separator 
                    key = {`separator${step}`}
                    className = "col-span-2 mb-4"
                />
            )
            items.push(
                <p key={step} className="col-span-2 text-center text-lg uppercase font-semibold">{this.props.t("portal")} {step+1}</p>
            )
            items.push(
                <Combo 
                    key = {`verticals${step}`}
                    dataKey = {`verticals`}
                    name = "verticals"
                    label = ''
                    secondLabel = {` ${this.props.t("verticales")}`}
                    onChange = {this.handleChange(portal.id)}
                    value = {portal.verticals || 1}
                    image = {portalIcon}
                    options = {[...Array(MAX_VERTICALS_PORTAL).keys()].map( i => i+1)}
                    t = {this.props.t}
                />  
            )
             
            items.push(
                <Combo 
                    key = {`homes${step}`}
                    dataKey = {`homes`}
                    name = "homes"
                    label = ''
                    secondLabel = {` ${this.props.t("viviendas")}`}
                    onChange = {this.handleChange(portal.id)}
                    value = {portal.homes || 1}
                    image = {
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 mx-auto text-gray-400" viewBox="0 0 20 20" fill="#388244">
                            <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>
                    }
                    options = {[...Array(MAX_HOUSES_PORTAL).keys()].map( i => i+1)}
                    t = {this.props.t}
                />  
            )
            
            internalPortals > 1 && items.push(
                <div 
                    className="col-span-2 mt-2 text-center"
                    key = {`button${step}`}
                >
                    <Button 
                        className = ""
                        name = "applyAll"
                        text = {`${this.props.t("aplicar_otros_portales")}`}
                        onClick = { () => this.handleChangeApplyOtherPortals(portal.id) }
                    />
                </div>
            )            
        }
            
        let modal = this.state.modalApplyVisible ? this.renderModalApply(this.state.numPortalApplier) : ''
        return (
            <div>
                {modal}
                <div className="grid grid-cols-2 gap-x-4 sm:items-center">
                    {items}
                </div>         
            </div>   
        )
    }

}

const MAX_HOUSES_PORTAL = 100
const MAX_VERTICALS_PORTAL = 4   