import React from 'react'
import { MainTitle } from '../components/elements/MainTitle'
import { Link } from 'react-router-dom'

export function NotFound(props) {
    const { t } = props

    return(
        <div className='container flex mx-auto home'>
            <div className="w-full max-w-xl pt-10 pb-10 pl-6 pr-6 mt-8 mb-8 text-center bg-gray-200 sm:w-3/4">
                <MainTitle>{t("pagina_no_encontrada")}</MainTitle>
                <p className='mt-4'>{t("pagina_no_existe_aviso")}</p>
                
                <Link to = {{pathname: '/'}} className="text-center hover:cursor-pointer">
                    <p className='mt-4 underline' >{t("volver_inicio")}</p>
                </Link>
            </div>
        </div>
    )
}