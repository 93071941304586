const initState = {
    userAuthResponse: ""
}

const userAuthReducer = (state=initState, action) => {
    switch(action.type){

        case 'RESTART_AUTH_RESPONSE':
            return {
                ...state,
                userAuthResponse: ""
            }

        case 'LOADING':
            return {
                ...state,
                userAuthResponse: "loading..."
            }
            
        case 'SIGNUP_SUCCESS':
            return {
                ...state,
                userAuthResponse: action.res
            }
            
        case 'SIGNUP_ERROR':
            return {
                ...state,
                userAuthResponse: action.res
            }
            
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                userAuthResponse: 'Redirigiendo a Home...'
            }
            
        case 'LOGIN_ERROR':
            return {
                ...state,
                userAuthResponse: action.res
            }
            
        case 'LOGOUT_SUCCESS':
            return {
                ...state,
                userAuthResponse: action.res,
                redirect: true
            }
            
        case 'LOGOUT_ERROR':
            return {
                ...state,
                userAuthResponse: action.res
            }
            
        case 'PASSWORD_UPDATE_SUCCESS':
            return {
                ...state,
                userAuthResponse: action.res
            }
            
        case 'PASSWORD_UPDATE_ERROR':
            return {
                ...state,
                userAuthResponse: action.res
            }
        
        case 'REQUEST_PASSWORD_RESTORE_SUCCESS':
            return {
                ...state,
                userAuthResponse: action.res
            }
            
        case 'REQUEST_PASSWORD_RESTORE_ERROR':
            return {
                ...state,
                userAuthResponse: action.res
            }
        
        case 'PASSWORD_RESTORE_SUCCESS':
            return {
                ...state,
                userAuthResponse: action.res
            }
            
        case 'PASSWORD_RESTORE_ERROR':
            return {
                ...state,
                userAuthResponse: action.res
            }
        
        case 'MAIL_CONFIRM_SUCCESS':
            return {
                ...state,
                userAuthResponse: action.res
            }
            
        case 'MAIL_CONFIRM_ERROR':
            return {
                ...state,
                userAuthResponse: action.res
            }

        case 'CODE_ERROR':
            return {
                ...state,
                userAuthResponse: "Hay un problema, reinicia la aplicación"
            }

        default: 
            return state
    }
}

export default userAuthReducer